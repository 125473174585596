import Rest from './base/Rest'

/**
 * @typedef {ContactService}
 */
export default class ContactService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/contact'


  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  getTotalNumberOfContacts(params = {}) {
    return this.get(`/getTotalNumberOfContacts?${this.createQueryString(params)}`)
  }

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  getTotalContactsCreatedBy(params = {}) {
    return this.get(`/getTotalContactsCreatedBy?${this.createQueryString(params)}`)
  }

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  getLatLongContacts(params = {}) {
    return this.get(`/getLatLongContacts?${this.createQueryString(params)}`)
  }

  /**
 * @param {Object} parameters
 * @returns {*|PromiseLike<T | never>|Promise<T | never>}
 */
  getLatLongContactsWithPurchaseMotivation(params = {}) {
    return this.get(`/getContactsLatLongWithPurchaseMotivation?${this.createQueryString(params)}`)
  }

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  getTotalContactsByType(params = {}) {
    return this.get(`/getTotalContactsByType?${this.createQueryString(params)}`)
  }

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  getTotalContactsEvs(params = {}) {
    return this.get(`/getTotalContactsEvs?${this.createQueryString(params)}`)
  }

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  getSuperintendentsFromContactsEvs(params = {}) {
    return this.get(`/getSuperintendentsFromContactsEvs?${this.createQueryString(params)}`)
  }

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  getTotalContactsEvsBySuperintendent(params = {}) {
    return this.get(`/getTotalContactsEvsBySuperintendent?${this.createQueryString(params)}`)
  }

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  getGeneralReport(params = {}) {
    return this.get(`/getGeneralReport?${this.createQueryString(params)}`)
  }

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  getContactByProductType(params = {}) {
    return this.get(`/getContactByProductType?${this.createQueryString(params)}`)
  }

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  getVisitsBySuperintendentsAndSalesCompany(params = {}) {
    return this.get(`/getVisitsBySuperintendentsAndSalesCompany?${this.createQueryString(params)}`)
  }

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  getContactsByWeek(params = {}) {
    return this.get(`/getContactsByWeek?${this.createQueryString(params)}`)
  }

  /**
 * @param {Object} parameters
 * @returns {*|PromiseLike<T | never>|Promise<T | never>}
 */
  getContactsWithMediaByProductOfInterest(params = {}) {
    return this.get(`/getContactsWithMediaByProductOfInterest?${this.createQueryString(params)}`)
  }

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  getCustomerByContacts(params = {}) {
    return this.get(`/getCustomerByContacts?${this.createQueryString(params)}`)
  }

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  getCustomerByContactsWithQuestionnaire(params = {}) {
    return this.get(`/getCustomerByContactsWithQuestionnaire?${this.createQueryString(params)}`)
  }

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  getContactsByWeekDayWithDateBegin(params = {}) {
    return this.get(`/getContactsByWeekDayWithDateBegin?${this.createQueryString(params)}`)
  }

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  getContactsBySalesCompaniesInWeek(params = {}) {
    return this.get(`/getContactsBySalesCompaniesInWeek?${this.createQueryString(params)}`)
  }

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  getContactsByWeekAndWeekend(params = {}) {
    return this.get(`/getContactsByWeekAndWeekend?${this.createQueryString(params)}`)
  }

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  getIntegratedAndNonIntegratedContactsWithDetails(params = {}) {
    return this.get(`/getIntegratedAndNonIntegratedContactsWithDetails?${this.createQueryString(params)}`)
  }

}