<template>
  <div class="echarts">
    <v-chart
      :options="chartData"
      :init-options="{ renderer }"
      ref="doughnut"
      autoresize
    />
  </div>
</template>

<script>
import ECharts from "vue-echarts";

import "echarts/lib/chart/pie";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/component/legendScroll";
import "echarts/lib/component/title";
import "echarts/lib/component/dataset";
import "zrender/lib/svg/svg";

import theme from "../theme/theme.json";
ECharts.registerTheme("ovilia-green", theme);

export default {
  name: "module-chart-doughnut",

  components: {
    "v-chart": ECharts,
  },

  props: {
    dataTitle: {
      type: String,
      default: "contatos",
    },
    chartValues: {
      type: Array,
      default: function () {
        return [];
      },
    },
    legendOrientation: {
      type: String,
      default: "bottom",
    },
    legendOptions: {
      type: Object,
      default: () => {},
    },
    renderer: {
      type: String,
      default: "svg",
    },
    /* selectedLegend: {
      type: Object,
      default: () => { return {} }
    }, */
    dataLegend: {
      type: Array,
      default: function () {
        return ["AAA", "BBB", "CCC", "DDD", "EEE"];
      },
    },
  },

  computed: {
    chartData: function () {
      return {
        tooltip: {
          trigger: "item",
          //formatter: '{c} ' + this.dataTitle + ' ({d}%)'
          formatter: "{b}: {c} ({d}%)",
        },
        legend: {
          [this.legendOrientation]:
            this.legendOrientation == "top" ? "5%" : this.legendOrientation,
          data: this.dataLegend,
          selectedMode: "multiple",
          selected: this.selectedLegend,
          ...this.legendOptions,
        },
        title: {
          text: this.dataTitle,
          left: "center",
          textStyle: {
            fontSize: 15,
          },
        },
        color: [
          "#EA1D2C",
          "#1ed760",
          "#F69A03",
          "#0061FE",
          "#221B36",
          "#F67101",
          "#9D02D9",
          "#01F75C",
          "#39DCE5",
          "#4B53BC",
          "#F02F83",
          "#91cc75",
          "#fac858",
          "#ee6666",
          "#73c0de",
          "#3ba272",
          "#fc8452",
          "#9a60b4",
          "#ea7ccc",
          "#5470c6",
        ],
        series: [
          {
            name: "Contatos",
            type: "pie",
            radius: "50%",
            //radius: ['50%', '65%'],
            //center: ['50%', '50%'],
            data: this.chartValues,
            label: {
              formatter: "{b}: {c}\n{d}%",
            },
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    },
  },

  mounted() {
    /***
      
      Lógica para desabilitar os legends conforme escolhe um específico. 
      Foi feito para o módulo ModuleVisitBySalesCompany p/ desabilitar o resto quando escolhe o total
      
      Colocar objeto na variavel selectedLegend p/ funcionar
      
      Exemplo:
      :selectedLegend="{'1ª Visita': true, 'Indicação': true,'Espontânea': true,'Retorno': true,'Total': false}"

    ***/
    /* let isVisitBySalesCompanies = true;
    
    for(const chartValue of this.chartValues){
      let validation = ['1ª Visita','Indicação','Espontânea','Retorno','Total'].includes(chartValue.name);
      if(!validation) isVisitBySalesCompanies = false;
    }

    if(isVisitBySalesCompanies){
      this.$el.addEventListener('click', (params) => {
        if(params){
          let textLegend = '';
      
          if(params.target.tagName === "path"){
            textLegend = params.target.nextElementSibling.firstChild ? params.target.nextElementSibling.firstChild.innerHTML : '';
          }else if(params.target.tagName === "tspan"){
            textLegend = params.target ? params.target.innerHTML : '';
          }

          const otherProp = ['1ª Visita','Indicação','Espontânea','Retorno'].includes(textLegend);
          
          if(textLegend === 'Total'){
            if(this.option.legend.selected['Total'] === false){
              this.option.legend.selected['1ª Visita'] = false;
              this.option.legend.selected['Indicação'] = false;
              this.option.legend.selected['Espontânea'] = false;
              this.option.legend.selected['Retorno'] = false;
              this.option.legend.selected['Total'] = true;
            }else{
              this.option.legend.selected['Total'] = false;
            }
          }else if(otherProp){
            this.option.legend.selected['Total'] = false;
            this.option.legend.selected[textLegend] = true;
          }
        }
      })
    } */
  },
};
</script>

<style lang="scss" scoped>
.echarts {
  width: 100%;
  height: 100%;
  position: relative;
  padding-bottom: 8px;
}
</style>
