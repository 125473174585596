<template>
  <dashboard id="dashboard" class="ctn-modules pt-2 mr-2 ml-2" v-if="dashboardCreated">
    <dash-layout
      v-for="(layout, layoutIndex) in dashboardLayout"
      v-bind="layout"
      :key="layout.breakpoint"
      :margin="{ x: 0, y: 0 }"
    >
      <dash-item
        v-for="(item, itemIndex) in layout.items"
        v-bind.sync="dashboardLayout[layoutIndex].items[itemIndex]"
        :key="item.id"
        :minWidth="3"
        :minHeight="3"
        @resizeEnd="dashItemResized"
        :draggable="editMode && item.data.isDraggable"
        :resizable="editMode"
      >
        <module-total-number-of-contacts
          :key="'module_' + item.id"
          v-if="item.type_base == moduleTypes.MODULE_TOTAL_NUMBER_OF_CONTACTS"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :selectedProducts="selectedProducts"
          :filterDateRange="filterDateRange"
        />

        <module-sales-company-by-contacts
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_SALES_COMPANY_BY_CONTACTS"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :selectedProducts="selectedProducts"
          :filterDateRange="filterDateRange"
        />

        <module-contact-types
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_CONTACT_TYPES"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :selectedProducts="selectedProducts"
          :filterDateRange="filterDateRange"
        />

        <module-total-contacts-created-by
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_TOTAL_CONTACTS_CREATED_BY"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :selectedProducts="selectedProducts"
          :filterDateRange="filterDateRange"
        />

        <module-total-customers-lgpd
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_TOTAL_CUSTOMERS_LGPD"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :selectedProducts="selectedProducts"
          :filterDateRange="filterDateRange"
        />

        <module-contacts-evs
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_CONTACTS_EVS"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :selectedProducts="selectedProducts"
          :filterDateRange="filterDateRange"
        />

        <module-team-contacts
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_TEAM_CONTACTS"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :selectedProducts="selectedProducts"
          :filterDateRange="filterDateRange"
        />

        <!--Mapa antigo Lefleat
        <module-contacts-map
          :key="'module_' + item.id"
          v-else-if="
            item.type_base == moduleTypes.MODULE_CONTACTS_MAP &&
            item.type_panel == panelTypes.TYPE_PANEL_CONTACTS_MAP
          "
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :selectedProducts="selectedProducts"
          :filterDateRange="filterDateRange"
        />-->

        <module-contacts-map
          :key="'module_' + item.id"
          v-else-if="
            item.type_base == moduleTypes.MODULE_CONTACTS_MAP &&
            item.type_panel == panelTypes.TYPE_PANEL_CONTACTS_MAP
          "
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :selectedProducts="selectedProducts"
          :filterDateRange="filterDateRange"
        />

        <module-contacts-types-map
          :key="'module_' + item.id"
          v-else-if="
            item.type_base == moduleTypes.MODULE_CONTACTS_TYPES_MAP &&
            item.type_panel == panelTypes.TYPE_PANEL_CONTACTS_TYPES_MAP
          "
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :selectedProducts="selectedProducts"
          :filterDateRange="filterDateRange"
        />

        <module-contacts-with-purchase-motivation-map
          :key="'module_' + item.id"
          v-else-if="
            item.type_base == moduleTypes.MODULE_CONTACTS_WITH_PURCHASE_MOTIVATION_MAP &&
            item.type_panel == panelTypes.TYPE_PANEL_CONTACTS_WITH_PURCHASE_MOTIVATION_MAP
          "
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :selectedProducts="selectedProducts"
          :filterDateRange="filterDateRange"
        />

        <module-table-list-data
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_TABLE_LIST_DATA"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :selectedProducts="selectedProducts"
          :filterDateRange="filterDateRange"
        />

        <module-purchase-goal
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_PURCHASE_GOAL"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :selectedProducts="selectedProducts"
          :filterDateRange="filterDateRange"
        />

        <module-cities
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_CITIES"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :selectedProducts="selectedProducts"
          :filterDateRange="filterDateRange"
        />

        <module-districts
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_DISTRICTS"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :selectedProducts="selectedProducts"
          :filterDateRange="filterDateRange"
        />

        <module-main-media
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_MAIN_MEDIA"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :selectedProducts="selectedProducts"
          :filterDateRange="filterDateRange"
        />

        <module-secondary-media
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_SECONDARY_MEDIA"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :selectedProducts="selectedProducts"
          :filterDateRange="filterDateRange"
        />

        <module-questionnaires
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_QUESTIONNAIRES"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :selectedProducts="selectedProducts"
          :filterDateRange="filterDateRange"
        />

        <module-products
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_PRODUCTS"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :filterDateRange="filterDateRange"
        />

        <module-tmp-camera
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_TEMP_CAMERA"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :filterDateRange="filterDateRange"
          :selectedProducts="selectedProducts"
        />

        <module-tmp-telefonia
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_TEMP_TELEFONIA"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :filterDateRange="filterDateRange"
          :selectedProducts="selectedProducts"
        />

        <module-tmp-energia
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_TEMP_ENERGIA"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :filterDateRange="filterDateRange"
          :selectedProducts="selectedProducts"
        />

        <module-roulette-amount
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_ROULETTE_AMOUNT"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :filterDateRange="filterDateRange"
          :selectedProducts="selectedProducts"
        />

        <module-roulette-average-by-broker
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_ROULETTE_AVERAGE_BY_BROKER"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :filterDateRange="filterDateRange"
          :selectedProducts="selectedProducts"
        />

        <module-roulette-average-by-roulette
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_ROULETTE_AVERAGE_BY_ROULETTE"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :filterDateRange="filterDateRange"
          :selectedProducts="selectedProducts"
        />

        <module-visits-by-sales-company
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_VISITS__BY_SALES_COMPANY"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :filterDateRange="filterDateRange"
          :selectedProducts="selectedProducts"
        />

        <module-general-report
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_GENERAL_REPORT"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :filterDateRange="filterDateRange"
          :selectedProducts="selectedProducts"
        />

        <module-general-report-by-product
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_GENERAL_REPORT_BY_PRODUCT"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :filterDateRange="filterDateRange"
          :selectedProducts="selectedProducts"
        />

        <module-products-selected
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_PRODUCTS_SELECTED"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :filterDateRange="filterDateRange"
          :selectedProducts="selectedProducts"
        />

        <module-visits-by-product-type
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_VISITS_BY_PRODUCTS_TYPE"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :filterDateRange="filterDateRange"
          :selectedProducts="selectedProducts"
        />

        <module-system-usage
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_SYSTEM_USAGE"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :filterDateRange="filterDateRange"
          :selectedProducts="selectedProducts"
        />

        <module-visits-by-superintendents-and-sales-company
          :key="'module_' + item.id"
          v-else-if="
            item.type_base ==
            moduleTypes.MODULE_VISITS_BY_SUPERINTENDENTS_AND_SALES_COMPANY
          "
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :filterDateRange="filterDateRange"
          :selectedProducts="selectedProducts"
        />

        <module-media-by-product-of-interest
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_MEDIA_BY_PRODUCT_OF_INTEREST"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :selectedProducts="selectedProducts"
          :filterDateRange="filterDateRange"
        />
        <module-contacts-by-week
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_CONTACTS_BY_WEEK"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :filterDateRange="filterDateRange"
          :selectedProducts="selectedProducts"
        />
        <module-customer-by-questionnaire-by-contacts
          :key="'module_' + item.id"
          v-else-if="
            item.type_base == moduleTypes.MODULE_CUSTOMER_BY_QUESTIONNAIRE_BY_CONTACTS
          "
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :filterDateRange="filterDateRange"
          :selectedProducts="selectedProducts"
        />
        <module-completed-questionnaires
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_COMPLETED_QUESTIONNAIRES"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :filterDateRange="filterDateRange"
          :selectedProducts="selectedProducts"
        />
        <module-customers-with-questionnaire-and-last-contact
          :key="'module_' + item.id"
          v-else-if="
            item.type_base == moduleTypes.MODULE_CLIENT_QUESTIONNAIRE_LAST_CONTACT
          "
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :filterDateRange="filterDateRange"
          :selectedProducts="selectedProducts"
        />
        <module-simplified-daily-report
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_SIMPLIFIED_DAILY_REPORT"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :filterDateRange="filterDateRange"
          :selectedProducts="selectedProducts"
        />
        <module-unified-report
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_UNIFIED_REPORT"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :filterDateRange="filterDateRange"
          :selectedProducts="selectedProducts"
        />
        <module-daily-report
          :key="'module_' + item.id"
          v-else-if="
            item.type_base == moduleTypes.MODULE_DAILY_REPORT ||
            item.type_base == moduleTypes.MODULE_PARTIAL_REPORT
          "
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :filterDateRange="filterDateRange"
          :selectedProducts="selectedProducts"
        />

        <module-whatsApp
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_HISTORY_WHATSAPP"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :filterDateRange="filterDateRange"
          :selectedProducts="selectedProducts"
        />

        <module-weekly-report
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_WEEKLY_REPORT"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :filterDateRange="filterDateRange"
          :selectedProducts="selectedProducts"
        />

        <module-whatsApp-status
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_WHATSAPP_STATUS"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :filterDateRange="filterDateRange"
          :selectedProducts="selectedProducts"
        />

        <module-customer-by-contacts
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_CUSTOMER_BY_CONTACTS"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :filterDateRange="filterDateRange"
          :selectedProducts="selectedProducts"
        />

        <module-report-logs-integration
          :key="'module_' + item.id"
          v-else-if="item.type_base == moduleTypes.MODULE_REPORT_LOGS_INTEGRATION"
          :type-panel="item.type_panel"
          :module-data="item.data"
          :editMode="editMode"
          :filterDateRange="filterDateRange"
          :selectedProducts="selectedProducts"
        />
      </dash-item>
    </dash-layout>
  </dashboard>
</template>

<script>
import { Dashboard, DashLayout, DashItem } from "vue-responsive-dash";

import * as moduleTypes from "./types/moduleTypes";
import * as panelTypes from "./types/panelTypes";

import ModuleSalesCompanyByContacts from "./modules/ModuleSalesCompanyByContacts.vue";
import ModuleTotalNumberOfContacts from "./modules/ModuleTotalNumberOfContacts.vue";
import ModuleMapCountZoom from "./base/ModuleMapCountZoom.vue";
import ModulePurchaseGoal from "./modules/ModulePurchaseGoal.vue";
import ModuleCities from "./modules/ModuleCities.vue";
import ModuleDistricts from "./modules/ModuleDistricts.vue";
import ModuleTableListData from "./base/ModuleTableListData.vue";
//import ModuleContactsLefleatMap from "./modules/ModuleContactsLefleatMap.vue";
import ModuleContactsMap from "./modules/ModuleContactsMap.vue";
import ModuleContactsTypesMap from "./modules/ModuleContactsTypesMap.vue";
import ModuleContactsWithPurchaseMotivationMap from "./modules/ModuleContactsWithPurchaseMotivationMap.vue";
import ModuleQuestionnaires from "./modules/ModuleQuestionnaires.vue";
import ModuleContactTypes from "./modules/ModuleContactTypes.vue";
import ModuleMainMedia from "./modules/ModuleMainMedia.vue";
import ModuleSecondaryMedia from "./modules/ModuleSecondaryMedia.vue";
import ModuleTotalCustomersLgpd from "./modules/ModuleTotalCustomersLgpd.vue";
import ModuleTotalContactsCreatedBy from "./modules/ModuleTotalContactsCreatedBy.vue";
import ModuleContactsEvs from "./modules/ModuleContactsEvs.vue";
import ModuleTeamContacts from "./modules/ModuleTeamContacts.vue";
import ModuleProducts from "./modules/ModuleProducts.vue";
import ModuleTmpCamera from "./modules/ModuleTmpCamera.vue";
import ModuleTmpTelefonia from "./modules/ModuleTmpTelefonia.vue";
import ModuleTmpEnergia from "./modules/ModuleTmpEnergia.vue";
import ModuleRouletteAmount from "./modules/ModuleRouletteAmount.vue";
import ModuleRouletteAverageByBroker from "./modules/ModuleRouletteAverageByBroker.vue";
import ModuleRouletteAverageByRoulette from "./modules/ModuleRouletteAverageByRoulette.vue";
import ModuleVisitsBySalesCompany from "./modules/ModuleVisitsBySalesCompany.vue";
import ModuleGeneralReport from "./modules/ModuleGeneralReport.vue";
import ModuleGeneralReportByProduct from "./modules/ModuleGeneralReportByProduct.vue";
import ModuleProductsSelected from "./modules/ModuleProductsSelected.vue";
import ModuleVisitsByProductType from "./modules/ModuleVisitsByProductType.vue";
import ModuleSystemUsage from "./modules/ModuleSystemUsage.vue";
import ModuleVisitsBySuperintendentsAndSalesCompany from "./modules/ModuleVisitsBySuperintendentsAndSalesCompany.vue";
import ModuleContactsByWeek from "./modules/ModuleContactsByWeek.vue";
import ModuleMediaByProductOfInterest from "./modules/ModuleMediaByProductOfInterest.vue";
import ModuleCustomerByContacts from "./modules/ModuleCustomerByContacts.vue";
import ModuleCompletedQuestionnaires from "./modules/ModuleCompletedQuestionnaires.vue";
import ModuleCustomersWithQuestionnaireAndLastContact from "./modules/ModuleCustomersWithQuestionnaireAndLastContact.vue";
import ModuleSimplifiedDailyReport from "./modules/ModuleSimplifiedDailyReport.vue";
import ModuleUnifiedReport from "./modules/ModuleUnifiedReport.vue";
import ModuleDailyReport from "./modules/ModuleDailyReport.vue";
import ModuleWhatsApp from "./modules/ModuleWhatsApp.vue";
import ModuleWeeklyReport from "./modules/ModuleWeeklyReport.vue";
import ModuleWhatsAppStatus from "./modules/ModuleWhatsAppStatus.vue";
import ModuleCustomerByQuestionnaireByContacts from "./modules/ModuleCustomerByQuestionnaireByContacts.vue";
import ModuleReportLogsIntegration from "./modules/ModuleReportLogsIntegration.vue";

export default {
  name: "DashboardLayout",
  components: {
    Dashboard,
    DashLayout,
    DashItem,
    ModuleTotalNumberOfContacts,
    ModuleSalesCompanyByContacts,
    ModuleMapCountZoom,
    ModuleCities,
    ModuleDistricts,
    ModulePurchaseGoal,
    ModuleTableListData,
    ModuleContactsMap,
    ModuleContactsTypesMap,
    ModuleContactsWithPurchaseMotivationMap,
    //ModuleContactsLefleatMap,
    ModuleQuestionnaires,
    ModuleContactTypes,
    ModuleMainMedia,
    ModuleSecondaryMedia,
    ModuleTotalCustomersLgpd,
    ModuleTotalContactsCreatedBy,
    ModuleContactsEvs,
    ModuleTeamContacts,
    ModuleProducts,
    ModuleTmpCamera,
    ModuleTmpTelefonia,
    ModuleTmpEnergia,
    ModuleRouletteAmount,
    ModuleRouletteAverageByBroker,
    ModuleRouletteAverageByRoulette,
    ModuleVisitsBySalesCompany,
    ModuleGeneralReport,
    ModuleGeneralReportByProduct,
    ModuleProductsSelected,
    ModuleVisitsByProductType,
    ModuleSystemUsage,
    ModuleVisitsBySuperintendentsAndSalesCompany,
    ModuleContactsByWeek,
    ModuleMediaByProductOfInterest,
    ModuleCustomerByContacts,
    ModuleCompletedQuestionnaires,
    ModuleCustomersWithQuestionnaireAndLastContact,
    ModuleSimplifiedDailyReport,
    ModuleUnifiedReport,
    ModuleDailyReport,
    ModuleWhatsApp,
    ModuleWeeklyReport,
    ModuleWhatsAppStatus,
    ModuleCustomerByQuestionnaireByContacts,
    ModuleReportLogsIntegration,
  },
  props: {
    dashboardLayout: {
      type: Array[Object],
      default: () => [],
    },
    dashboardCreated: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    filterDateRange: {
      type: Array[Date],
      default: () => [],
    },
  },
  data() {
    return {
      moduleTypes: moduleTypes,
      panelTypes: panelTypes,

      selectedProducts: [],
    };
  },
  computed: {
    getViewProducts() {
      return this.$store.getters.viewProducts;
    },
    getSelectedProducts() {
      return this.$store.getters.selectedProducts;
    },
    getIsDownloadMap() {
      return this.$store.getters.isDownloadMap;
    },
  },
  methods: {
    dashItemResized() {
      setTimeout(() => window.dispatchEvent(new Event("resize")), 250);
    },
  },
  watch: {
    getViewProducts() {
      this.selectedProducts = [];
      this.$store.dispatch("storeSelectedProducts", []);
    },
  },
  mounted() {
    this.$root.$on(
      "products-selected",
      ((products) => {
        this.selectedProducts = products;
      }).bind(this)
    );

    this.$root.$on(
      "updated-module-data",
      (() => {
        this.$forceUpdate();
      }).bind(this)
    );

    this.$parent.$on(
      "clear-products-selected",
      (() => {
        if (this.getSelectedProducts?.length > 0 && this.getIsDownloadMap) {
          this.selectedProducts = this.getSelectedProducts;
          this.$store.dispatch("storeSelectedProducts", this.getSelectedProducts);
          this.$store.dispatch("storeViewProducts", this.getViewProducts);
        } else {
          this.selectedProducts = [];
          this.$store.dispatch("storeSelectedProducts", []);
          this.$store.dispatch("storeViewProducts", "activies");
        }
      }).bind(this)
    );
  },
};
</script>

<style lang="scss" scoped>
.ctn-modules {
  .placeholder {
    border-radius: 10px;
  }
}
</style>
