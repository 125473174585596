import Rest from './base/Rest'

/**
 * @typedef {BrokerRotationBrokerActivityService}
 */
export default class BrokerRotationBrokerActivityService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/broker-rotation-broker-activity'


  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  getRouletteAmount(params = {}) {
    return this.get(`/amount?${this.createQueryString(params)}`);
  }

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  getAverageByRoulette(params = {}) {
    return this.get(`/averageByRoulette?${this.createQueryString(params)}`);
  }

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  getAverageByBroker(params = {}) {
    return this.get(`/averageByBroker?${this.createQueryString(params)}`);
  }
}
