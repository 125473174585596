import Rest from './base/Rest'

/**
 * @typedef {SendReportService}
 */
export default class SendReportService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/send-reports'

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  resendReport (params = {}) {
    return this.get(`/resendReport?${this.createQueryString(params)}`)
  }


}