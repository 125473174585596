<template>
  <div class="content">
    <div
      :class="
        'module-card d-flex flex-column bg-white overflow-auto position-absolute pb-20 ' +
        downloadImage.class
      "
    >
      <div class="d-flex flex-wrap align-items-center module-card-header">
        <div
          class="col font-weight-bold"
          v-if="showHeaderTitle && !showHeaderTitleWithDate"
        >
          {{ currentPanel?.headerTitle }}
          <v-badge
            :content="currentPanel?.tag?.name"
            :color="currentPanel?.tag?.color"
            inline
          >
          </v-badge>
        </div>
        <div
          class="col font-weight-bold d-flex flex-wrap"
          v-else-if="showHeaderTitle && showHeaderTitleWithDate"
        >
          <div style="margin-right: 10px">
            {{ currentPanel?.headerTitle }}
          </div>
          <div style="margin-right: 10px">
            {{ "Período: " + getFilterDateRange }}
          </div>
          <div
            v-for="(selectProduct, index) in getSelectedProducts"
            :key="selectProduct.id"
            style="margin-right: 3px"
          >
            {{
              index === 0
                ? getSelectedProducts?.length > 1
                  ? "Produtos: "
                  : "Produto: "
                : ""
            }}
            {{ index &lt; 3 ? selectProduct.name : "" }}
            {{(index + 1) &lt; getSelectedProducts?.length && (index + 1) &lt; 3? "|" : ""}}
            {{ index + 1 === getSelectedProducts?.length && index + 1 > 3 ? "..." : "" }}
          </div>
        </div>

        <v-col
          v-if="currentPanel?.headerTitle === 'Produtos'"
          data-html2canvas-ignore="true"
          sm="3"
          md="3"
          lg="5"
          class="p-0 mt-6 mr-3"
        >
          <v-autocomplete
            v-model="choosenViewProducts"
            :items="productsOptions"
            item-text="text"
            item-value="value"
            @change="handleIsViewProductsActivies"
            label="Escolha Produtos por Status"
            dense
            outlined
          ></v-autocomplete>
        </v-col>

        <div
          id="search-map-container"
          class="font-weight-bold d-flex align-items-center position-absolute-button-search"
          v-if="
            currentPanel?.headerTitle.includes('Mapa') && getSelectedProducts?.length > 0
          "
          data-html2canvas-ignore="true"
        >
          <div
            class="font-weight-bold d-flex align-items-center justify-content-center p-2"
            :style="{ marginLeft: '100px' }"
            v-if="
              currentPanel?.headerTitle.includes('Mapa') &&
              (totalCustomersMapContacts === 300 ||
                totalCustomersMapContactsTypes === 300 ||
                totalCustomersMapContactsTypes === 300)
            "
            data-html2canvas-ignore="true"
          >
            <span :style="{ fontSize: '10px', color: 'red' }">
              *Por questões de performance a primeira busca possui limite de 300 pinos,
              para buscar todos clique aqui:
            </span>
            <button
              class="btn btn-sm btn-custom-gray javascript:void(0) ml-1"
              @click="handleLimitPinsMap"
            >
              <i class="fas fa-search"></i> Buscar tudo
            </button>
          </div>
        </div>

        <div
          class="d-flex align-items-center"
          :class="{ 'position-absolute': !showHeaderTitle }"
          data-html2canvas-ignore="true"
        >
          <div class="col-auto" v-if="infoItems != null">
            <i
              class="fas fa-info"
              title="Informações sobre o painel"
              :style="{ color: '#000e17' }"
              @click="infoPanel()"
            ></i>
          </div>

          <div
            class="col-auto"
            data-html2canvas-ignore="true"
            v-if="
              currentPanel?.headerTitle === 'Mapa de Contatos' &&
              getSelectedProducts?.length > 0
            "
          >
            <i
              class="fas fa-users"
              @click="handleClusterMapView"
              :style="{ color: viewCluster ? 'blue' : '#000e17' }"
              title="Habilitar visualização de agrupamento de Pins"
            ></i>
          </div>

          <div
            class="col-auto"
            data-html2canvas-ignore="true"
            v-if="
              currentPanel?.headerTitle === 'Mapa de Contatos' &&
              getSelectedProducts?.length > 0
            "
          >
            <i
              class="fas fa-fire-alt"
              @click="handleHeatMapView"
              :style="{ color: viewHeatMap ? 'blue' : '#000e17' }"
              title="Habilitar visualização de mapa de Calor"
            ></i>
          </div>

          <div
            class="col-auto"
            data-html2canvas-ignore="true"
            v-if="
              currentPanel?.headerTitle === 'Mapa de Contatos' &&
              getSelectedProducts?.length > 0
            "
          >
            <i
              class="fas fa-user-alt"
              @click="handlePinsMapView"
              :style="{ color: viewPins ? 'blue' : '#000e17' }"
              title="Habilitar visualização de Pins"
            ></i>
          </div>

          <div
            class="col-auto"
            data-html2canvas-ignore="true"
            v-if="
              currentPanel?.headerTitle.includes('Mapa') &&
              getSelectedProducts?.length > 0
            "
          >
            <i
              class="fas fa-podcast"
              @click="changeNumberDistanceRadiusMap"
              :style="{ color: viewRadiusCircle ? 'blue' : '#000e17' }"
              title="Habilitar/Desabilitar visualização de raios de distância"
            ></i>
          </div>

          <div
            class="col-auto"
            data-html2canvas-ignore="true"
            v-if="
              currentPanel?.headerTitle.includes('Mapa') &&
              getSelectedProducts?.length > 0 &&
              (viewRadiusCircle || viewRadiusCircle || viewRadiusCircle)
            "
          >
            <i
              class="fas fa-list-ol"
              @click="handleRadiusCircleLegendView"
              :style="{
                color: viewLegendRadiusCircle ? 'blue' : '#000e17',
              }"
              title="Habilitar/Desabilitar visualização de legenda dos raios"
            ></i>
          </div>

          <div
            v-if="
              (getSelectedProducts?.length > 0 || totalTables > 0) && currentPanel?.hasPDF
            "
          >
            <a
              class="javascript:void(0)"
              @click="
                typePanelPDF === 'TABLE' ? generatePDFTables() : generatePDFOthersPanels()
              "
            >
              <i
                class="fas fa-file-pdf"
                title="Baixar PDF do painel"
                :style="{ color: '#000e17' }"
              ></i>
            </a>
          </div>

          <div class="col-auto">
            <a class="javascript:void(0)" @click="download()">
              <i
                class="fas fa-download"
                title="Baixar imagem do painel"
                :style="{ color: '#000e17' }"
              ></i>
            </a>
          </div>

          <vue-excel-xlsx
            v-if="currentPanel?.hasExcel"
            :data="data"
            :columns="columns"
            :file-name="currentPanel?.headerTitle + ' ' + getFilterDateRange"
            :file-type="'xlsx'"
            :sheet-name="
              currentPanel?.headerTitle?.length < 32
                ? currentPanel?.headerTitle
                : currentPanel?.headerTitle.substr(0, 31)
            "
          >
            <i
              class="fas fa-solid fa-file-excel"
              title="Baixar excel"
              :style="{ color: '#000e17', marginRight: '10px' }"
              @click="generatedExcelData"
            ></i>
          </vue-excel-xlsx>
        </div>

        <div
          class="d-flex align-items-center"
          :class="{ 'position-absolute': !showHeaderTitle }"
          v-if="editMode"
          data-html2canvas-ignore="true"
        >
          <div class="col-auto" v-if="currentPanel?.hasSettings">
            <i
              class="fas fa-cog"
              @click="settingsDialogVisible = true"
              :style="{ color: '#000e17' }"
              title="Mudar vizualização do painel"
            ></i>
          </div>
          <div class="col-auto" data-html2canvas-ignore="true">
            <i
              class="fas fa-times"
              @click="removePanel()"
              :style="{ color: '#000e17' }"
              title="Fechar painel"
            ></i>
          </div>
        </div>
      </div>

      <div
        v-if="error"
        class="d-flex flex-column justify-content-center align-items-center h-100"
      >
        <i class="far fa-3x fa-times-circle text-danger"></i>
        <span class="p-2">
          <strong class="font-weight-bold">Erro: </strong>
          {{ error.message }}
        </span>
      </div>
      <template v-else-if="loading">
        <v-card height="100%" width="100%">
          <v-row justify="center">
            <v-overlay :absolute="true" :value="loading">
              <v-progress-circular indeterminate :size="70" :width="7" color="#000e17">
              </v-progress-circular>
            </v-overlay>
          </v-row>
        </v-card>
      </template>
      <slot v-else name="content"></slot>
    </div>

    <v-dialog max-width="600" v-model="settingsDialogVisible">
      <v-card v-if="currentPanel.hasOwnProperty('displayType')">
        <v-toolbar color="#000e17" dark elevation="0">
          Configurações do painel!
        </v-toolbar>
        <v-card-text>
          Exibição:
          <v-autocomplete
            v-model="moduleData.displayType.chosen"
            :items="currentPanel.formattedAvailable"
            item-text="nameFormatted"
            item-value="name"
            class="px-4 pt-2"
            outlined
            dense
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn depressed color="primary" @click="saveModuleData">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="600" v-model="numberDistanceRadiusDialogVisible">
      <v-card>
        <v-toolbar color="#000e17" dark elevation="0">
          Defina a quantidade de raios de quilometragem em relação ao Stand:
        </v-toolbar>
        <v-card-text>
          Escolha a quantidade de raios:
          <v-autocomplete
            v-model="qtdDistanceRadiusMaps"
            :items="distanceRadiusMapsOptions"
            item-text="label"
            item-value="value"
            class="px-4 pt-2"
            outlined
            dense
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn depressed color="primary" @click="handleRadiusCircleView">
            {{ qtdDistanceRadiusMaps === 1 ? "Remover raios" : "Aplicar raios no mapa" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Loading :show="showLoading" />
  </div>
</template>

<script>
import Loading from "../../../views/Loading.vue";

import html2canvas from "html2canvas";

import * as htmlToImage from "html-to-image";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

export default {
  components: {
    Loading,
  },
  name: "module-base-builder",
  props: {
    moduleData: Object,
    editMode: {
      type: Boolean,
      default: false,
    },
    loading: {
      default: false,
    },
    error: {
      default: null,
    },
    downloadImage: {
      type: Object,
      default: null,
    },
    tableHeaders: {
      type: Array,
      default: () => [],
    },
    tableHeadersExcel: {
      type: Array,
      default: () => [],
    },
    tableItems: {
      type: Array,
      default: () => [],
    },
    tableItemsExcel: {
      type: Array,
      default: () => [],
    },
    allTableHeaders: {
      type: Array,
      default: () => [],
    },
    allTableItems: {
      type: Array,
      default: () => [],
    },
    totalTables: {
      default: 0,
      type: Number,
    },
    infoItems: {
      type: Object,
      default: null,
    },
    viewCluster: {
      type: Boolean,
    },
    viewHeatMap: {
      type: Boolean,
    },
    viewPins: {
      type: Boolean,
    },
    viewRadiusCircle: {
      type: Boolean,
    },
    viewLegendRadiusCircle: {
      type: Boolean,
    },
    totalCustomersMapContacts: {
      default: 0,
      type: Number,
    },
    totalCustomersMapContactsTypes: {
      default: 0,
      type: Number,
    },
    totalCustomersPurchaseMotivation: {
      default: 0,
      type: Number,
    },
    typePanelPDF: {
      type: String,
      default: "",
    },
    selectLabelToPDF: {
      type: String,
      default: "",
    },
    questionnaireTitleToPDF: {
      type: String,
      default: "",
    },
  },
  data: function () {
    return {
      settingsDialogVisible: false,
      numberDistanceRadiusDialogVisible: false,
      showHeaderTitleWithDate: false,
      showLoading: false,
      columns: [],
      data: [],
      limitLatAndLng: false,
      choosenViewProducts: "activies",
      qtdDistanceRadiusMaps: 1,
      distanceRadiusMapsOptions: [
        { value: 1, label: "Esconder raios" },
        { value: 2, label: 1 },
        { value: 3, label: 2 },
        { value: 4, label: 3 },
        { value: 5, label: 4 },
        { value: 6, label: 5 },
        { value: 7, label: 6 },
        { value: 8, label: 7 },
        { value: 9, label: 8 },
        { value: 10, label: 9 },
        { value: 11, label: 10 },
        { value: 12, label: 11 },
        { value: 13, label: 12 },
        { value: 14, label: 13 },
        { value: 15, label: 14 },
        { value: 16, label: 15 },
        { value: 17, label: 16 },
        { value: 18, label: 17 },
        { value: 19, label: 18 },
        { value: 20, label: 19 },
        { value: 21, label: 20 },
      ],
      productsOptions: [
        { text: "Ativos", value: "activies" },
        { text: "Inativos", value: "inactivies" },
        { text: "Todos", value: "todos" },
      ],
    };
  },
  computed: {
    showHeaderTitle() {
      return this.currentPanel?.headerTitleShow && this.currentPanel?.headerTitle;
    },
    getFilterDateRange() {
      return this.$store.getters.filterDateRange;
    },
    getSelectedProducts() {
      return this.$store.getters.selectedProducts;
    },
    getIsViewProductsActive() {
      return this.$store.getters.isViewProductsActive;
    },
    getViewProducts() {
      return this.$store.getters.viewProducts;
    },
    getIsDownloadMap() {
      return this.$store.getters.isDownloadMap;
    },
    currentPanel() {
      const item = this.$store.state.userPanelsById[this.moduleData.panelId];
      const formattedItem = {
        ...item,
        formattedAvailable: [],
      };
      item.displayType.available.forEach((available) => {
        formattedItem.formattedAvailable.push({
          name: available,
          nameFormatted: this.getChartTypeLabel(available),
        });
      });
      return formattedItem;
    },
  },
  methods: {
    checkModuleType(passed, expected) {
      return passed == expected;
    },

    removePanel() {
      this.$root.$emit("remove-panel", { moduleData: this.moduleData });
    },

    isDraggable(value) {
      this.moduleData.isDraggable = value;
      this.$root.$emit("updated-module-data");
    },

    saveModuleData() {
      this.settingsDialogVisible = false;
      this.$root.$emit("module-data-changed-" + this.moduleData.id, {
        moduleData: this.moduleData,
      });
    },

    changeNumberDistanceRadiusMap() {
      this.numberDistanceRadiusDialogVisible = !this.numberDistanceRadiusDialogVisible;
    },

    getChartTypeLabel(chartType) {
      switch (chartType) {
        case "CHART_PIZZA":
          return "Pizza";

        case "CHART_BAR":
          return "Barras";

        case "TABLE":
          return "Tabela";

        case "WORD_CLOUD":
          return "Nuvem de Palavras";

        default:
          return "";
      }
    },

    async download() {
      let showHeaderTitleWithDate = async (value) =>
        (this.showHeaderTitleWithDate = value);
      await showHeaderTitleWithDate(true);

      const downloadData = this.downloadImage;
      const loading = (value) => (this.showLoading = value);
      loading(true);

      let elementDownloadData = document.querySelector(`.${downloadData.class}`);

      let span = document.createElement("span");
      span.innerHTML = "&copy; Powered by Smart Staff";
      span.style.fontSize = "12px";
      span.style.position = "absolute";
      span.style.bottom = "0";
      span.style.right = "10px";

      elementDownloadData.appendChild(span);

      if (
        downloadData.class === "leaflet-container" ||
        downloadData.class === "module-contacts-map" ||
        downloadData.class === "module-contacts-types-map" ||
        downloadData.class === "module-contacts-with-purchase-motivation"
      ) {
        await htmlToImage
          .toPng(elementDownloadData, {
            cacheBust: true,
            filter: (node) =>
              !node?.dataset?.html2canvasIgnore &&
              node !== document.querySelector(".loading"),
          })
          .then((dataURL) => {
            const link = document.createElement("a");
            link.setAttribute("download", downloadData.title);
            link.href = dataURL;
            link.click();
            link.remove();
            elementDownloadData.removeChild(span);
            showHeaderTitleWithDate(false);
            loading(false);
          })
          .catch((err) => {
            console.log(err);
          });

        await this.$store.dispatch("storeIsDownloadMap", true);
        this.$router.go();
      } else {
        html2canvas(
          /* document.querySelector(`.${downloadData.class}`) */ elementDownloadData,
          {
            allowTaint: false,
            logging: true,
            taintTest: false,
            useCORS: true,
            backgroundColor: "#ffffff",
          }
        ).then(function (canvas) {
          const d = canvas.toDataURL("image/png", 1.0);
          const anchar = document.createElement("a");
          anchar.href = d;
          anchar.setAttribute("download", downloadData.title);
          anchar.click();
          anchar.remove();
          loading(false);
          showHeaderTitleWithDate(false);
          elementDownloadData.removeChild(span);
        });
      }
    },
    generatedExcelData() {
      if (this.tableHeadersExcel?.length > 0) {
        this.columns = this.tableHeadersExcel.map((header) => {
          return {
            label: header.text,
            field: header.value,
          };
        });
        if (this.columns?.length > 0 && this.tableItemsExcel?.length > 0) {
          this.data = this.tableItemsExcel;
        } else if (this.columns?.length > 0 && this.tableItems?.length > 0) {
          this.data = this.tableItems;
        }
      }
    },
    handleLimitPinsMap() {
      this.$emit("getData");
      this.limitLatAndLng = false;
    },
    handleIsViewProductsActivies() {
      this.$store.dispatch("storeViewProducts", this.choosenViewProducts);
    },
    infoPanel() {
      this.$swal.fire({
        title: this.infoItems.title,
        html: this.infoItems.text,
        width: 950,
      });
    },
    handleClusterMapView() {
      this.$emit("activeClusterMapView");
    },
    handleHeatMapView() {
      this.$emit("activeHeatMapView");
    },
    handlePinsMapView() {
      this.$emit("activePinsMapView");
    },
    handleRadiusCircleView() {
      this.numberDistanceRadiusDialogVisible = false;
      this.$emit("activeRadiusCircleView", this.qtdDistanceRadiusMaps);
    },
    handleRadiusCircleLegendView() {
      this.$emit("activeRadiusCircleLegendView");
    },
    async generatePDFOthersPanels() {
      let showHeaderTitleWithDateInPDF = async (value) =>
        (this.showHeaderTitleWithDate = value);

      await showHeaderTitleWithDateInPDF(true);

      const downloadDataInPDF = this.downloadImage;
      const loading = (value) => (this.showLoading = value);
      loading(true);

      let elementDownloadDataInPDF = document.querySelector(
        `.${downloadDataInPDF.class}`
      );

      let span = document.createElement("span");
      span.innerHTML = "&copy; Powered by Smart Staff";
      span.style.fontSize = "12px";
      span.style.position = "absolute";
      span.style.bottom = "0";
      span.style.right = "40px";

      elementDownloadDataInPDF.appendChild(span);

      if (
        downloadDataInPDF.class === "leaflet-container" ||
        downloadDataInPDF.class === "module-contacts-map" ||
        downloadDataInPDF.class === "module-contacts-types-map" ||
        downloadDataInPDF.class === "module-contacts-with-purchase-motivation"
      ) {
        if (pdfMake.vfs == undefined) {
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }

        await htmlToImage
          .toPng(elementDownloadDataInPDF, {
            cacheBust: true,
            filter: (node) =>
              !node?.dataset?.html2canvasIgnore &&
              node !== document.querySelector(".loading"),
          })
          .then((dataURL) => {
            const docDefinition = {
              content: [
                {
                  image: dataURL,
                  width: 780,
                  height: 520,
                },
              ],
              pageOrientation: "landscape",
              pageSize: "A4",
            };
            pdfMake.createPdf(docDefinition).download(downloadDataInPDF.title);
            showHeaderTitleWithDateInPDF(false);
            elementDownloadDataInPDF.removeChild(span);
            loading(false);

            setTimeout(() => {
              this.$store.dispatch("storeIsDownloadMap", true);
              this.$router.go();
            }, 5000);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        html2canvas(
          /* document.querySelector(`.${downloadDataInPDF.class}`) */ elementDownloadDataInPDF,
          {
            allowTaint: false,
            logging: true,
            taintTest: false,
            useCORS: true,
            backgroundColor: "#ffffff",
          }
        ).then(function (canvas) {
          const base64 = canvas.toDataURL("image/png", 1.0);

          const docDefinition = {
            content: [
              {
                image: base64,
                width: 810,
                height: 460,
              },
            ],
            pageOrientation: "landscape",
            pageSize: "A4",
          };
          pdfMake.createPdf(docDefinition).download(downloadDataInPDF.title);

          elementDownloadDataInPDF.removeChild(span);
          showHeaderTitleWithDateInPDF(false);
          loading(false);
        });
      }
    },
    generateTableLayouts() {
      //Estilização da tabela pdfmake
      const tableLayout = {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body?.length ? 1 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths?.length ? 0 : 0;
        },
        hLineColor: function (i, node) {
          return i === 0 || i === node.table.body?.length ? "#D1D1D1" : "#D1D1D1";
        },
        vLineColor: function (i, node) {
          return i === 0 || i === node.table.widths?.length ? "black" : "#D1D1D1";
        },
        fillColor: function (rowIndex, node, columnIndex) {
          return rowIndex % 2 === 0 && rowIndex !== 0 ? "#EEEEEE" : null;
        },
        paddingLeft: function (i) {
          return i === 0 ? 8 : 8;
        },
        paddingRight: function (i, node) {
          return i === node.table.widths?.length - 1 ? 8 : 8;
        },
        paddingTop: function (i) {
          return i === 0 ? 5 : 5;
        },
        paddingBottom: function (i, node) {
          return i === node.table.widths?.length - 1 ? 5 : 5;
        },
      };

      const tableLegendLayout = {
        ...tableLayout,
        fillColor: function (rowIndex, node, columnIndex) {
          return rowIndex === 0 ? "#70163D" : null;
        },
      };

      return { tableLegendLayout, tableLayout };
    },
    generateBodyHeaderInfoTablePDF() {
      //Montagem da Tabela de legenda do painel
      const painelTitle = this.currentPanel?.headerTitle;
      const period = this.getFilterDateRange;

      const bodyTableInfoPanel = [
        [{ text: "Informações gerais: ", color: "#FFFFFF" }, ""],
        [painelTitle, "Período: " + period],
      ];

      const unprintedPanelsOfSelectedProducts = [
        "Produtos",
        "Questionário",
        "Produtos Selecionados",
        "Relatório Geral por Produto",
        "Uso do Sistema",
      ];

      if (!unprintedPanelsOfSelectedProducts.includes(painelTitle)) {
        const localSelectedProducts = [];

        this.getSelectedProducts.forEach((product) => {
          localSelectedProducts.push(product.name);
        });

        bodyTableInfoPanel.push([
          "Produtos Selecionados:",
          { text: localSelectedProducts.join().replaceAll(",", " | ") },
        ]);
      }

      if (this.questionnaireTitleToPDF) {
        bodyTableInfoPanel.push(["Questionário:", this.questionnaireTitleToPDF]);

        bodyTableInfoPanel.push(["Questão:", this.selectLabelToPDF]);
      } else {
        if (this.selectLabelToPDF) {
          bodyTableInfoPanel.push(["Dados:", this.selectLabelToPDF]);
        }
      }

      return bodyTableInfoPanel;
    },
    generateLegendTableInPDFFooter(painelTitle) {
      if (this.tableHeaders?.length > 0) {
        const bodyLegendTable = [[{ text: "Legendas: ", color: "#FFFFFF" }, ""]];

        this.tableHeaders.forEach((header) => {
          if (header.tooltip) {
            bodyLegendTable.push([header.text, header.tooltip]);
          }
        });

        return bodyLegendTable;
      }
    },
    generatePDFTables() {
      const loading = (value) => (this.showLoading = value);
      loading(true);
      const painelTitle = this.currentPanel?.headerTitle;

      if (this.totalTables > 0) {
        const allLocalTableHeaders = [];
        const allTableItemsLocal = [];
        const allTableItemsArray = [];
        var allTableColumnWidths = [];
        var allTableBodies = [];

        //Gera novos arrays dentro das variaveis acima dinamicamente
        for (let i = 0; i < this.totalTables; i++) {
          allLocalTableHeaders[i] = new Array(); //torna cada elemento um array
          allTableItemsLocal[i] = new Array();
          allTableItemsArray[i] = new Array();
          allTableColumnWidths[i] = new Array();
          allTableBodies[i] = new Array();
        }

        //Defini as chaves do header das tabelas existentes no painel
        for (let index = 0; index < this.totalTables; index++) {
          const keysHeaders = Object.keys(this.allTableItems[index][index]);

          //Defini o header das tabelas primeira linha
          this.allTableHeaders[index].forEach((header, indexInterno) => {
            allLocalTableHeaders[index].push(header.text.toString());

            //Defini a quantidade de colunas e suas larguras
            if (this.allTableHeaders[index]?.length > 2) {
              if (indexInterno === 0) {
                allTableColumnWidths[index].push("30%");
              } else {
                allTableColumnWidths[index].push(
                  `${(70 / (this.allTableHeaders[index]?.length - 1)).toFixed(2)}%`
                );
              }
            } else if (this.allTableHeaders[index]?.length === 2 && indexInterno === 0) {
              allTableColumnWidths[index].push("50%", "50%");
            }

            //Separa as colunas em um array local baseado nas chaves do header
            const key = keysHeaders.find((item) => item === header.value);
            allTableItemsLocal[index].push(key);
          });

          //Defini a quantidade de linhas que a tabela vai ter
          const itemsLengthTable = this.allTableItems[index]?.length;
          for (let i = 0; i < itemsLengthTable; i++) {
            allTableItemsArray[index].push([]);

            //Permite que a montagem da tabela fique dinamica, os itens da linha seguem a ordem das chaves do header
            allTableItemsLocal[index].forEach((key) => {
              allTableItemsArray[index][i].push(this.allTableItems[index][i][key]);
            });
          }

          //Inclui o header e os itens da tabela no corpo da tabela, no formato que a biblioteca pdfmake precisa
          allTableBodies[index] = [allLocalTableHeaders[index]];
          allTableItemsArray[index].forEach((item) => allTableBodies[index].push(item));
        }
      } else {
        const headers = [];
        var columnsTableWidth = [];
        const tableItemsLocal = [];
        const itemsArray = [];

        if (this.tableItems?.length > 0) {
          //Defini o header da tabela primeira linha
          let keysHeader = Object.keys(this.tableItems[0]);

          if (painelTitle === "Uso do Sistema") {
            keysHeader = keysHeader.map((key) => {
              if (key === "account") {
                return "account.fullName";
              } else if (key === "accountId") {
                return "account.email";
              } else {
                return key;
              }
            });
          }

          this.tableHeaders.forEach((header, index) => {
            headers.push(header.text.toString());

            //Defini a quantidade de colunas e suas larguras
            if (this.tableHeaders?.length > 2) {
              let defaultFirstColumnWidth = "30%";
              let restDefaultColumns = 70;

              if (painelTitle === "Questionários Completos") {
                defaultFirstColumnWidth = "60%";
                restDefaultColumns = 40;
              }

              if (index === 0) {
                columnsTableWidth.push(defaultFirstColumnWidth);
              } else {
                columnsTableWidth.push(
                  `${restDefaultColumns / (this.tableHeaders?.length - 1)}%`
                );
              }
            } else if (this.tableHeaders?.length === 2 && index === 0) {
              columnsTableWidth.push("50%", "50%");
            }

            //Separa as colunas em um array local baseado nas chaves do header
            const key = keysHeader.find((item) => item === header.value);
            tableItemsLocal.push(key);
          });

          //Defini a quantidade de linhas que a tabela vai ter
          const itemsLength = this.tableItems?.length;
          for (let i = 0; i < itemsLength; i++) {
            itemsArray.push([]);

            //Permite que a montagem da tabela fique dinamica, os itens da linha seguem a ordem das chaves do header
            tableItemsLocal.forEach((key) => {
              if (painelTitle === "Uso do Sistema") {
                if (key === "account.fullName") {
                  itemsArray[i].push(this.tableItems[i].account.fullName);
                } else if (key === "account.email") {
                  itemsArray[i].push(this.tableItems[i].account.email);
                } else {
                  if (key === "createdAt") {
                    itemsArray[i].push(
                      new Date(this.tableItems[i][key]).toLocaleDateString("pt-br", {
                        hour: "2-digit",
                        minute: "2-digit",
                      })
                    );
                  } else {
                    itemsArray[i].push(this.tableItems[i][key]);
                  }
                }
              } else {
                itemsArray[i].push(this.tableItems[i][key]);
              }
            });
          }

          //Inclui o header e os itens da tabela no corpo da tabela, no formato que a biblioteca pdfmake precisa
          var bodySingleTable = [headers];
          itemsArray.forEach((item) => bodySingleTable.push(item));
        }
      }

      let renderedTables = [];

      const { tableLegendLayout, tableLayout } = this.generateTableLayouts();

      if (this.totalTables > 0) {
        for (let index = 0; index < this.totalTables; index++) {
          renderedTables.push({
            table: {
              // headers are automatically repeated if the table spans over multiple pages
              // you can declare how many rows should be treated as headers
              headerRows: 1,
              widths: allTableColumnWidths[index],
              body: allTableBodies[index],
            },
            layout: tableLayout,
            style: "tableStyles",
          });
        }
      } else {
        renderedTables.push({
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: columnsTableWidth,
            body: bodySingleTable,
          },
          layout: tableLayout,
          style: "tableStyles",
        });
      }

      //Retorna o corpo da primeira tabela a tabela de informações do painel
      const bodyHeaderInfoTablePDF = this.generateBodyHeaderInfoTablePDF();

      //Retorna o corpo de legendas se o painel existir
      const panelsWithFooterLegend = [
        "Produtos",
        "Produtos Selecionados",
        "Visitas X Empresa de Vendas",
        "Relatório Geral",
        "Relatório Geral por Produto",
        "Contatos x Semana",
      ];

      if (panelsWithFooterLegend.includes(painelTitle)) {
        const bodyLegendTableFooter = this.generateLegendTableInPDFFooter(painelTitle);

        if (bodyLegendTableFooter?.length > 1) {
          renderedTables.push({
            table: {
              // headers are automatically repeated if the table spans over multiple pages
              // you can declare how many rows should be treated as headers
              headerRows: 1,
              widths: ["50%", "50%"],
              body: bodyLegendTableFooter,
            },
            layout: tableLegendLayout,
            style: "tableLegendStyles",
          });
        }
      }

      //Define paineis que serão criados com orientação landscape
      const landscapePanels = [
        "Relatório Geral",
        "Visitas X Empresa de Vendas",
        "Contatos X Superintendente e Empresa de Vendas",
        "Relatório Geral por Produto",
        "Produtos",
        "Produtos Selecionados",
        "Contatos x Semana",
        "Cliente com Questionário e Última Visita",
        "Uso do Sistema",
        "Questionários Completos",
      ];

      const pageOrientation = landscapePanels.includes(painelTitle)
        ? "landscape"
        : "portrait";

      //Configurações biblioteca pdfmake
      if (pdfMake.vfs == undefined) {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }

      //Geração do documento que será impresso
      const docDefinition = {
        pageMargins: [40, 20, 40, 20],
        content: [
          {
            table: {
              // headers are automatically repeated if the table spans over multiple pages
              // you can declare how many rows should be treated as headers
              headerRows: 1,
              widths: ["50%", "50%"],
              body: bodyHeaderInfoTablePDF,
            },
            style: "tableLegendStyles",
            layout: tableLegendLayout,
          },
          ...renderedTables,
        ],
        pageOrientation,
        styles: {
          tableLegendStyles: {
            margin: [0, 10, 0, 0],
            alignment: "left",
            color: "#70163D",
            fontSize: 12,
            bold: true,
          },
          tableStyles: {
            margin: [0, 10, 0, 15],
            alignment: "left",
            color: "#000000",
            opacity: 0.6,
            fontSize: 12,
          },
          textMain: {
            fontSize: 11,
            bold: true,
            alignment: "left",
          },
          labelTitles: {
            fontSize: 11,
            lineHeight: 2,
            bold: true,
            alignment: "left",
          },
        },
        footer: {
          columns: [
            {
              alignment: "right",
              text: `©️ Powered by Smart Staff`,
              color: "#000000",
              opacity: 0.6,
            },
          ],
          margin: [10, 2, 10, 10],
        },
      };

      //Nome do arquivo igual ao nome do painel
      const downloadData = this.downloadImage;

      //Geração e dounload tabelas em formato de PDF
      pdfMake
        .createPdf(docDefinition, null, pdfMake.pdfFonts, pdfMake.vfs)
        .download(downloadData.title);

      loading(false);
    },
  },
  watch: {
    loading() {
      this.qtdDistanceRadiusMaps = 1;
    },
    deep: true,
  },
  mounted() {
    if (this.currentPanel?.headerTitle === "Produtos" && this.getIsDownloadMap) {
      this.choosenViewProducts = this.getViewProducts;
    }
  },
};
</script>

<style lang="scss">
.content {
  background-color: #fff !important;
}

.module-card {
  -webkit-box-shadow: 10px 10px 26px -15px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 26px -15px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 26px -15px rgba(0, 0, 0, 0.75);
  border-radius: 1rem;
  margin: 0.8rem 0 0 0.8rem;

  top: 3px;
  left: 3px;
  bottom: 3px;
  right: 3px;
  position: relative;
  background-color: #fff !important;
}

.module-card-header {
  background-color: #fff !important;
  color: var(--smarts-black);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.module-card-header .font-weight-bold {
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #fff !important;
}

.module-card-header .position-absolute {
  right: 0;
  top: 0;

  z-index: 500;
  background-color: #fff !important;
}

.module-card-header .position-absolute-button-search {
  position: absolute;
  left: 150px;
  top: 0;
  display: flex;
  flex-direction: column;

  z-index: 500;
  background-color: transparent !important;
}

.module-card-header .position-absolute-button-radio {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  z-index: 500;
  background-color: transparent !important;
}

.v-input--selection-controls.v-input {
  margin-top: 0 !important;
}

.v-input--selection-controls {
  margin-top: 5px;
}

.module-card-header i {
  color: #a4a8ac;
  cursor: pointer;
}

.module-card-header i:hover {
  color: #686b6d;
}

.powered {
  font-size: 12px;
  position: absolute;
  bottom: 2px;
  right: 5px;
  background-color: #fff;
}

.small-info {
  display: inline;
  color: red;
  margin: 0 10px 0 0;
}
</style>
