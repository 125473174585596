import Rest from './base/Rest'

/**
 * @typedef {EnterpriseBIService}
 */
export default class EnterpriseBIService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/enterprise-bi'


  /**
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */

  saveBI (params = {}) {
    return this.post(`/add`, params);
  }

  inactiveBI() {
    return this.get(`/inactiveBI`)
  }
}