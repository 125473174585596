<template>
  <mobule-base-builder
    :module-data="moduleData"
    :editMode="editMode"
    :loading="loading"
    ref="tableParent"
    :error="error"
    :downloadImage="dataDownload"
    :tableHeaders="tableHeaders"
    :tableItems="tableItems"
    :tableHeadersExcel="tableHeadersExcel"
    :tableItemsExcel="tableItemsExcel"
    :typePanelPDF="moduleData.displayType.chosen"
  >
    <template v-slot:content>
      <v-app id="" ref="tableApp">
        <v-layout
          fluid
          v-resize="updateDataTableHeight"
          style="max-height: 100%"
        >
          <module-chart-bar
            v-if="
              moduleData.displayType.chosen == 'CHART_BAR' &&
              barChartData.length > 0
            "
            :data="barChartData"
            :labels="labels"
            class="main-media"
          />
          <div
            class="echarts"
            v-else-if="
              moduleData.displayType.chosen == 'CHART_PIZZA' &&
              chartValues.length > 0
            "
          >
            <module-chart-doughnut
              dataTitle=""
              :dataLegend="labels"
              :legendOrientation="'top'"
              :chartValues="chartValues"
              class="main-media"
            />
          </div>
          <module-table-list-data-v2
            v-else-if="
              moduleData.displayType.chosen == 'TABLE' && tableItems.length > 0
            "
            :tableHeaders="tableHeaders"
            :tableItems="tableItems"
            :footerPropsItemsPerPageText="footerPropsItemsPerPageText"
            class="main-media"
          />
          <message-status-data
            v-else
            :selected-products="selectedProductsIds()"
            :infoLoading="loading"
          />
        </v-layout>
      </v-app>
    </template>
  </mobule-base-builder>
</template>

<script>
import MixinModuleBase from "../../../mixins/MixinModuleBase";

import ModuleChartBar from "../base/ModuleChartBar.vue";
import ModuleChartDoughnut from "../base/ModuleChartDoughnut.vue";
import ModuleTableListDataV2 from "../base/ModuleTableListDataV2.vue";
import MobuleBaseBuilder from "../base/MobuleBaseBuilder.vue";

import MediaService from "../../../services/MediaService";
const mediaService = MediaService.build();

import MessageStatusData from "../../MessageStatusData.vue";

export default {
  name: "module-main-media",
  mixins: [MixinModuleBase],
  components: {
    ModuleChartBar,
    ModuleChartDoughnut,
    ModuleTableListDataV2,
    MobuleBaseBuilder,
    MessageStatusData,
  },
  data: function () {
    return {
      tableHeaders: [
        {
          text: "Mídia primária",
          align: "start",
          sortable: true,
          value: "title",
        },
        {
          text: "Contagem",
          align: "start",
          sortable: true,
          value: "mediaCount",
        },
      ],
      tableHeadersExcel: [
        {
          text: "Produtos Selecionados",
          align: "start",
          sortable: true,
          value: "selectedProducts",
        },
        {
          text: "Mídia primária",
          align: "start",
          sortable: true,
          value: "title",
        },
        {
          text: "Contagem",
          align: "start",
          sortable: true,
          value: "mediaCount",
        },
      ],
      tableItems: [],
      tableItemsExcel: [],
      footerPropsItemsPerPageText: "Mídias Primárias por página",
      barChartData: [],
      labels: [],
      chartValues: [],
      dataDownload: { class: "main-media", title: this.moduleData.headerTitle },
    };
  },
  computed: {
    getSelectedProducts() {
      return this.$store.getters.selectedProducts;
    },
  },
  methods: {
    resetModule() {
      this.tableItems = [];
      this.tableItemsExcel = [];
      this.barChartData = [];
      this.chartValues = [];
      this.labels = [];
    },

    updateDataTableHeight() {
      let tableHeight = 0,
        headerHeight = 0;

      if (this.$refs.dataTable) {
        tableHeight = this.$refs.dataTable.$el.offsetHeight;
        headerHeight =
          this.$refs.dataTable.$children[0].$children[0].$children[0].$el
            .clientHeight;
      }

      let offsetTop =
        this.$refs.tableApp && this.$refs.tableApp.$el.offsetTop
          ? this.$refs.tableApp.$el.offsetTop
          : 0;

      this.itemsPerPage =
        parseInt((tableHeight - headerHeight - offsetTop) / 50) - 1;
      if (this.itemsPerPage <= 0) {
        this.itemsPerPage = 1;
      }
      if (
        typeof this.tableItems.length != "undefined" &&
        this.tableItems.length < this.itemsPerPage
      ) {
        this.itemsPerPage = this.tableItems.length;
      }
      if (
        this.footerProps &&
        !this.footerProps["items-per-page-options"].includes(this.itemsPerPage)
      ) {
        if (
          this.footerProps["items-per-page-options"].length ==
          this.optionsLength
        ) {
          this.footerProps["items-per-page-options"].unshift(this.itemsPerPage);
        } else {
          this.footerProps["items-per-page-options"].shift();
          this.footerProps["items-per-page-options"].unshift(this.itemsPerPage);
        }
      }
    },

    getData() {
      this.resetModule();

      if (this.dataRetrievalOk()) {
        this.loading = true;

        mediaService
          .getCountMainMedia({
            selectedProductsIds: this.selectedProductsIds(),
            dateBegin: this.filterDateRange[0],
            dateEnd: this.filterDateRange[1],
          })
          .then((r) => {
            this.resetModule();
            this.chartValues = this.convertToChartValues(r);

            const productsSelectedLength = this.getSelectedProducts.length;

            if (r.length >= productsSelectedLength) {
              r.forEach((item, index) => {
                if (index < productsSelectedLength) {
                  this.tableItems.push({ ...item });
                  this.tableItemsExcel.push({
                    selectedProducts: this.getSelectedProducts[index]?.name,
                    ...item,
                  });
                } else {
                  this.tableItems.push({ ...item });
                  this.tableItemsExcel.push({
                    selectedProducts: "",
                    ...item,
                  });
                }
              });
            } else {
              this.getSelectedProducts.forEach((product, index) => {
                const item = r[index] ? r[index] : "";
                if (item) {
                  this.tableItems.push({ ...item });
                  this.tableItemsExcel.push({
                    selectedProducts: product?.name,
                    ...item,
                  });
                } else {
                  this.tableItemsExcel.push({
                    selectedProducts: product?.name,
                  });
                }
              });
            }

            this.loading = false;
          })
          .catch(this.errorHandler);
      }
    },

    convertToChartValues(registers) {
      let ret = [];
      if (typeof registers !== "undefined" && registers.length > 0) {
        for (let i = 0; i < registers.length; i++) {
          //Chart Pizza
          ret.push({
            value: registers[i]["mediaCount"],
            name: registers[i]["title"],
          });

          //Chart Bar
          this.barChartData.push(registers[i]["mediaCount"]);
          this.labels.push(registers[i]["title"]);
        }
      }
      return ret;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-application {
  position: relative;
  min-height: 87%;
  max-height: 100%;

  .v-application--wrap {
    max-height: 100%;
    min-height: 100%;
    overflow: auto;
  }

  .v-data-table__wrapper {
    max-height: 80%;
  }

  .v-data-table {
    td {
      height: 30px !important;
    }
  }
}

.echarts {
  width: 100%;
  height: 100%;
  position: relative;
}

.main-media {
  background-color: #fff;
}
</style>
