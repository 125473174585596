import Rest from './base/Rest'

/**
 * @typedef {UserService}
 */
export default class UserService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/account'


  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  getUsersByEnterprise(params = {}) {
    return this.get(`/findAllByEnterprise?${this.createQueryString(params)}`)
  }

  /**
  * @param {Object} parameters
  * @returns {*|PromiseLike<T | never>|Promise<T | never>}
  */
  getAllInternalActiveUsers() {
    return this.get(`/findAllInternalActiveUsers`)
  }

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  findUsersByEnterpriseID(params = {}) {
    return this.get(`/findUsersByEnterpriseID?${this.createQueryString(params)}`)
  }

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  changePassword(params = {}) {
    return this.post(`/changePassword`, params)
  }

  /**
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  getLoggedUser() {
    return this.get(`/findAccount`)
  }
}