<template>
  <mobule-base-builder
    :module-data="moduleData"
    :editMode="editMode"
    :loading="loading"
    :error="error"
    :downloadImage="dataDownload"
    :tableHeaders="tableHeaders"
    :tableItems="tableItems"
    :tableHeadersExcel="tableHeadersExcel"
    :typePanelPDF="moduleData.displayType.chosen"
  >
    <template v-slot:content>
      
      <module-table-list-data-v2
        v-if="tableItems.length > 0"
        :tableHeaders="tableHeaders"
        :tableItems="tableItems"
        class="weekly-report echarts-default"
      />
      
      <message-status-data
        v-else
        :selected-products="selectedProductsIds()"
        :infoLoading="loading"
      />
    </template>
  </mobule-base-builder>
</template>

<script>
import ContactService from "../../../services/ContactService";
const contactService = ContactService.build();
import SalesCompanyService from "../../../services/SalesCompanyService"
const salesCompanyService = SalesCompanyService.build();

import MixinModuleBase from "../../../mixins/MixinModuleBase";
import MobuleBaseBuilder from "../base/MobuleBaseBuilder.vue";
import ModuleChartBar from "../base/ModuleChartBar.vue";
import ModuleChartDoughnut from "../base/ModuleChartDoughnut.vue";
import ModuleTableListDataV2 from "../base/ModuleTableListDataV2.vue";
import MessageStatusData from "../../MessageStatusData.vue";
import { date } from 'yup';

export default {
  name: "module-weekly-report",
  mixins: [MixinModuleBase],
  components: {
    MobuleBaseBuilder,
    ModuleChartBar,
    ModuleChartDoughnut,
    ModuleTableListDataV2,
    MessageStatusData,
  },
  data: function () {
    return {
      contactsBySalesCompanies: [],
      contactsBySalesCompaniesFull: [],
      tableHeaders: [
        { text: "Resumo de visitas por empresa de vendas", align: "start", sortable: true, value: "salesCompanyName" },
        { text: "No Período (Semana)", align: "start", sortable: true, value: "total" },
        { text: "Acumulado", align: "start", sortable: true, value: "fullTotal" },
      ],
      tableHeadersExcel: [
        { text: "Produtos Selecionados", align: "start", sortable: true, value: "selectedProducts", },
        { text: "Resumo de visitas por empresa de vendas", align: "start", sortable: true, value: "salesCompanyName" },
        { text: "No Período (Semana)", align: "start", sortable: true, value: "total" },
        { text: "Acumulado", align: "start", sortable: true, value: "fullTotal" },
      ],
      tableItems: [],
      dataDownload: {
        class: "module-weekly-report",
        title: this.moduleData.headerTitle,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
    };
  },
  computed: {
    getSelectedProducts() {
      return this.$store.getters.selectedProducts;
    },
  },
  methods: {
    resetModule() {
      this.tableItems = [];
      this.contactTypes = [];
      this.chartValues = [];
      this.periodAndWeekLegend = [];
    },
    convertToTablesItems(){
      this.tableItems = [{salesCompanyName: 'Total de Visitas', total: 0, fullTotal: 0}];
      if(this.contactsBySalesCompanies.length > 0){
        this.contactsBySalesCompanies.forEach(contactsBySalesCompany => {
          const hasInList = this.tableItems.filter(data => data.salesCompanyName === contactsBySalesCompany.salesCompanyName);
          if(hasInList.length === 0){
            const total = this.contactsBySalesCompanies
            .filter(data => data.salesCompanyName === contactsBySalesCompany.salesCompanyName)
            .reduce((acc, cur) => acc + Number(cur.total), 0);

            const contactBySalesCompanyFull = (this.contactsBySalesCompaniesFull.filter(contactBySalesCompanyFull => contactBySalesCompanyFull.name === contactsBySalesCompany.salesCompanyName))[0];

            this.tableItems.push({ salesCompanyName: contactsBySalesCompany.salesCompanyName, total: total, fullTotal: contactBySalesCompanyFull.contactCount});
            this.tableItems[0].total += total;
            this.tableItems[0].fullTotal += Number(contactBySalesCompanyFull.contactCount);

            this.contactsBySalesCompaniesFull = this.contactsBySalesCompaniesFull.filter(contactBySalesCompanyFull => contactBySalesCompanyFull.name !== contactsBySalesCompany.salesCompanyName);
          }
        })

        this.contactsBySalesCompaniesFull.forEach(contactBySalesCompanyFull => {
          this.tableItems[0].fullTotal += Number(contactBySalesCompanyFull.contactCount);
          this.tableItems.push({ salesCompanyName: contactBySalesCompanyFull.name, total: 0, fullTotal: contactBySalesCompanyFull.contactCount})
        })
        
      }
    },
    async getData() {
      this.resetModule();

      if (this.dataRetrievalOk()) {
        this.loading = true;

        const dateBeginToFullData = new Date(1922, 0, 1);

        this.contactsBySalesCompaniesFull = await salesCompanyService.contactsBySalesCompanies({
          selectedProductsIds: this.selectedProductsIds(),
          dateBegin: dateBeginToFullData.toISOString(),
          dateEnd: this.filterDateRange[1],
        })

        this.contactsBySalesCompanies = await contactService.getContactsBySalesCompaniesInWeek({
          selectedProductsIds: this.selectedProductsIds(),
          dateBegin: this.filterDateRange[0],
        })

       this.convertToTablesItems();

        const r = this.tableItems

        const productsSelectedLength = this.getSelectedProducts.length;

        if (r.length >= productsSelectedLength) {
          this.tableItems = r.map((item, index) => {
            if (index < productsSelectedLength) {
              return {
                selectedProducts: this.getSelectedProducts[index]?.name,
                ...item,
              };
            } else {
              return { selectedProducts: "", ...item };
            }
          });
        } else {
          this.tableItems = this.getSelectedProducts.map(
            (product, index) => {
              const item = r[index] ? r[index] : "";
              if (item) {
                return { selectedProducts: product?.name, ...item };
              } else {
                return { selectedProducts: product?.name };
              }
            }
          );
        }

        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  min-width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  min-height: 100%;
  max-height: 100%;
  background-color: #fff !important;
  padding-bottom: 50px;

  .container-pizza {
    min-height: 300px;
    max-height: 320px;
    min-width: 550px;
    max-width: 550px;
    border: 1px solid #ccc;

    span {
      min-height: 0;
      min-width: 0;
      max-width: 0;
      border: none;
      margin: 0;
    }
  }
}

.contacts-by-week {
  background-color: #fff;
}

.echarts-pizza {
  width: 100% !important;
  height: 20rem !important;
  position: relative !important;
}

.echarts-default {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
