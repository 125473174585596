var render = function render(){var _vm=this,_c=_vm._self._c;return _c('mobule-base-builder',{attrs:{"module-data":_vm.moduleData,"editMode":_vm.editMode,"loading":_vm.loading,"error":_vm.error,"downloadImage":_vm.dataDownload,"tableHeaders":_vm.listTableHeader,"tableItems":_vm.listTableItems,"tableHeadersExcel":_vm.listTableHeader,"tableItemsExcel":_vm.tableItemsExcel,"allTableHeaders":_vm.allTableHeaders,"allTableItems":_vm.allTableItems,"totalTables":_vm.totalTables,"typePanelPDF":'TABLE',"selectLabelToPDF":''},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"pa-3"},[(!_vm.hasFilterDateRange)?_c('span',[_vm._v("***É necessário preencher os campos de \"Período dos dados\" para buscar as informações!***")]):_vm._e(),_c('v-autocomplete',{staticClass:"px-4 pt-2",attrs:{"items":_vm.getSelectedProducts,"item-text":"name","item-value":"id","id":"general-report-by-product","label":"Selecione o produto","disabled":!_vm.hasFilterDateRange,"outlined":"","dense":""},on:{"change":function($event){return _vm.getData()}},model:{value:(_vm.selectedProduct),callback:function ($$v) {_vm.selectedProduct=$$v},expression:"selectedProduct"}})],1),(
        _vm.tableItemsRegisterCapture.length > 0 ||
        _vm.tableItemsResearchesCapture.length > 0
      )?[(_vm.tableItems.length > 0)?_c('module-table-list-data-v-2',{staticClass:"general-report-by-product",attrs:{"tableHeaders":_vm.tableHeaders,"tableItems":_vm.tableItems,"showFooter":false}}):_vm._e(),(_vm.tableItemsRegisterCapture.length > 0)?_c('module-table-list-data-v-2',{staticClass:"general-report-by-product",attrs:{"tableHeaders":_vm.tableHeadersRegisterCapture,"tableItems":_vm.tableItemsRegisterCapture}}):_vm._e(),(_vm.tableItemsResearchesCapture.length > 0)?_c('module-table-list-data-v-2',{staticClass:"general-report-by-product",attrs:{"tableHeaders":_vm.tableHeadersResearchesCapture,"tableItems":_vm.tableItemsResearchesCapture}}):_vm._e()]:_vm._e(),_c('message-status-data-unique-product',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.tableItemsRegisterCapture.length === 0 &&
        _vm.tableItemsResearchesCapture.length === 0
      ),expression:"\n        tableItemsRegisterCapture.length === 0 &&\n        tableItemsResearchesCapture.length === 0\n      "}],attrs:{"selected-product":_vm.hasDatasValue,"infoLoading":_vm.loading}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }