import Rest from './base/Rest'

/**
 * @typedef {MailingFormatService}
 */
export default class MailingFormatService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/mailing-format'


  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  findAll(params = {}) {
    return this.get(`/findAll?${this.createQueryString(params)}`)
  }


  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  findAllByProductIdAndEnterpriseId (params = {}) {
    return this.get(`/findAllByProductIdAndEnterpriseId?${this.createQueryString(params)}`)
  }

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  save (params = {}) {
    return this.post(`/save`, params)
  }

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  softDelete (params = {}) {
    return this.post(`/softDelete`, params)
  }

}