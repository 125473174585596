import IpifyService from '../services/IpifyService'
const ipify = IpifyService.build();

import AccessHistory from '../services/AccessHistoryService'
const accessService = AccessHistory.build();

export default {
  methods: {
    alertSuccess({ title = 'Sucesso', text = '' }) {
			return this.$swal({
        title,
        text,
        icon: 'success',
      });
    },
    alertWarning({ title = 'Atenção', text = '' }) {
			return this.$swal({
        title,
        text,
        icon: 'warning',
      });
    },
    alertError({ title = 'Erro', text = '' }) {
			return this.$swal({
        title,
        text,
        icon: 'error',
      });
    },

    errorHandler(error = { message: 'Error' }) {
      if (error.status === 401 && this.$route.matched[0].name != 'Public') {
        setTimeout(() => {
          localStorage.clear();  
          document.cookie = "PLAY_BISESSION="+ "" +";expires=Thu, 01 Jan 1970 00:00:01 GMT" + "; path=/";
          document.cookie = "EXT="+ "" +";expires=Thu, 01 Jan 1970 00:00:01 GMT" + "; path=/";
          window.location.href="/erro";
        }, 3000);

      } else this.alertError({ text: error.message });
    },
    
    confirmationDialog({ title = 'Você tem certeza?', text = '', cancelButtonText = 'Cancelar', confirmButtonText = 'Confirmar', footer = '' }) {
      return this.$swal.fire({
        title,
        text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText,
        confirmButtonText,
        footer,
      });
    },

    formatPanel: (panel) => ({
      id: panel.id,
      title: panel.title,
      type_base: panel.typeBase,
      type_panel: panel.typePanel,
      data:{
        panelId: panel.id,
        headerTitleShow: panel.headerTitleShow,
        headerTitle: panel.headerTitle,
        contentMainTitle: panel.contentMainTitle,
        canHaveDependency: panel.canHaveDependency,
        canBeDependentOn: panel.canBeDependentOn,
        hasSettings: panel.hasSettings,
        hasExcel: panel.hasExcel,
        hasPDF: panel.hasPDF,
        displayType: panel.displayType,
      },
    }),

    async getIP(){
      try {
        return (await ipify.getIp()).ip
      } catch (error) {
        return null
      }
      
    },

    async saveAccessHistory(type){
      if(type === 'login'){
        await accessService.loginSave(await this.getIP())
      }else if(type === 'logout'){
        await accessService.logoutSave(await this.getIP())
      }
    }
  },
}
