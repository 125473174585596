<template>
  <mobule-base-builder
    :module-data="moduleData"
    :editMode="editMode"
    :loading="loading"
    :error="error"
    :downloadImage="dataDownload"
    :tableHeaders="tableHeaders"
    :tableItems="tableItems"
    :tableHeadersExcel="tableHeadersExcel"
    :typePanelPDF="moduleData.displayType.chosen"
  >
    <template v-slot:content>
      <module-chart-bar
        v-if="moduleData.displayType.chosen == 'CHART_BAR' && barSeries.length > 0"
        :legend="legend"
        :labels="labels"
        :showFilters="false"
        :series="barSeries"
        :tooltip="tooltip"
        class="contacts-by-week echarts-default"
      />
      <module-table-list-data-v2
        v-else-if="moduleData.displayType.chosen == 'TABLE' && tableItems.length > 0"
        :tableHeaders="tableHeaders"
        :tableItems="tableItems"
        class="contacts-by-week echarts-default"
      />
      <div
        class="container contacts-by-week"
        v-else-if="
          moduleData.displayType.chosen == 'CHART_PIZZA' && chartValues.length > 0
        "
        ref="container"
      >
        <div
          class="container-pizza"
          v-for="(chartValue, index) in chartValues"
          :key="index"
        >
          <module-chart-doughnut
            :dataTitle="periodAndWeekLegend[index]"
            :chartValues="chartValue"
            :dataLegend="labelsChartPizza"
            :ref="'chart' + index"
            class="echarts-pizza"
          />
        </div>
      </div>
      <message-status-data
        v-else
        :selected-products="selectedProductsIds()"
        :infoLoading="loading"
      />
    </template>
  </mobule-base-builder>
</template>

<script>
import ContactService from "../../../services/ContactService";
const contactService = ContactService.build();

import MixinModuleBase from "../../../mixins/MixinModuleBase";
import MobuleBaseBuilder from "../base/MobuleBaseBuilder.vue";
import ModuleChartBar from "../base/ModuleChartBar.vue";
import ModuleChartDoughnut from "../base/ModuleChartDoughnut.vue";
import ModuleTableListDataV2 from "../base/ModuleTableListDataV2.vue";
import MessageStatusData from "../../MessageStatusData.vue";

export default {
  name: "module-contacts-by-week",
  mixins: [MixinModuleBase],
  components: {
    MobuleBaseBuilder,
    ModuleChartBar,
    ModuleChartDoughnut,
    ModuleTableListDataV2,
    MessageStatusData,
  },
  data: function () {
    return {
      contactTypes: [],
      barChartData: [],
      labels: [],
      labelsChartPizza: [
        `INDICAÇÃO`,
        `ESPONTÂNEA`,
        `RETORNO`,
        `TELEFONE`,
        `FORA DO EXPEDIENTE`,
        `TOTAL`,
      ],
      tableHeaders: [
        { text: "PERÍODO", align: "start", sortable: true, value: "period" },
        { text: "SEMANA", align: "start", sortable: true, value: "week" },
        {
          text: "IC",
          align: "start",
          sortable: true,
          value: "indication",
          tooltip: "Total de Indicações",
        },
        {
          text: "VE",
          align: "start",
          sortable: true,
          value: "spontaneous",
          tooltip: "Total de Visitas Espontâneas",
        },
        {
          text: "R",
          align: "start",
          sortable: true,
          value: "return",
          tooltip: "Total de Retornos",
        },
        {
          text: "TEL",
          align: "start",
          sortable: true,
          value: "telephone",
          tooltip: "Total de Telefonemas",
        },
        {
          text: "FE",
          align: "start",
          sortable: true,
          value: "outOfService",
          tooltip: "Total de Fora do Expediente",
        },
        {
          text: "CW",
          align: "start",
          sortable: true,
          value: "whatsapp",
          tooltip: "Total de Contatos WhatsApp",
        },
        {
          text: "TT",
          align: "start",
          sortable: true,
          value: "total",
          tooltip: "Total de Contatos",
        },
      ],
      tableHeadersExcel: [
        {
          text: "Produtos Selecionados",
          align: "start",
          sortable: true,
          value: "selectedProducts",
        },
        { text: "PERÍODO", align: "start", sortable: true, value: "period" },
        { text: "SEMANA", align: "start", sortable: true, value: "week" },
        {
          text: "INDICAÇÃO",
          align: "start",
          sortable: true,
          value: "indication",
        },
        {
          text: "ESPONTÂNEA",
          align: "start",
          sortable: true,
          value: "spontaneous",
        },
        { text: "RETORNO", align: "start", sortable: true, value: "return" },
        {
          text: "TELEFONE",
          align: "start",
          sortable: true,
          value: "telephone",
        },
        {
          text: "FORA DO EXPEDIENTE",
          align: "start",
          sortable: true,
          value: "outOfService",
        },
        {
          text: "WHATSAPP",
          align: "start",
          sortable: true,
          value: "whatsapp",
        },
        { text: "TOTAL", align: "start", sortable: true, value: "total" },
      ],
      tableItems: [],
      dataDownload: {
        class: "contacts-by-week",
        title: this.moduleData.headerTitle,
      },
      barSeries: [],
      legend: [],
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      chartValues: [],
      periodAndWeekLegend: [],
    };
  },
  computed: {
    getSelectedProducts() {
      return this.$store.getters.selectedProducts;
    },
  },
  methods: {
    resetModule() {
      this.tableItems = [];
      this.contactTypes = [];
      this.barChartData = [];
      this.barSeries = [];
      this.labels = [];
      this.legend = [];
      this.chartValues = [];
      this.periodAndWeekLegend = [];
    },
    reduceContacts(contacts) {
      return contacts.reduce(
        (acc, cur) => {
          return {
            indication: Number(acc.indication) + Number(cur.indication),
            spontaneous: Number(acc.spontaneous) + Number(cur.spontaneous),
            return: Number(acc.return) + Number(cur.return),
            telephone: Number(acc.telephone) + Number(cur.telephone),
            outOfService: Number(acc.outOfService) + Number(cur.outOfService),
            whatsapp: Number(acc.whatsapp) + Number(cur.whatsapp),
            total: Number(acc.total) + Number(cur.total),
          };
        },
        {
          indication: 0,
          spontaneous: 0,
          return: 0,
          telephone: 0,
          outOfService: 0,
          whatsapp: 0,
          total: 0,
        }
      );
    },
    convertToChartValues(contacts) {
      let ret = [];

      const totalContactsByType = this.reduceContacts(contacts);
      this.legend = [
        `INDICAÇÃO (${totalContactsByType.indication})`,
        `ESPONTÂNEA (${totalContactsByType.spontaneous})`,
        `RETORNO (${totalContactsByType.return})`,
        `TELEFONE (${totalContactsByType.telephone})`,
        `FORA DO EXPEDIENTE (${totalContactsByType.outOfService})`,
        `WHATSAPP (${totalContactsByType.whatsapp})`,
        `TOTAL (${totalContactsByType.total})`,
      ];

      this.convertToChartPizzaValues(totalContactsByType);

      if (typeof contacts !== "undefined" && contacts.length > 0) {
        let objDefault = {
          name: "",
          type: "bar",
          emphasis: {
            focus: "series",
          },
          data: [],
          label: {
            show: true,
            rotate: 0,
            position: "top",
            fontWeight: "bold",
            fontSize: 13,
            lineHeight: 20,
            overflow: "truncate",
          },
        };
        for (let i = 0; i < contacts.length; i++) {
          //Chart Pizza
          this.convertToChartPizzaValues(contacts[i]);

          if (this.barSeries.length > 0) {
            this.barSeries[0].data.push(contacts[i]["indication"]);
            this.barSeries[1].data.push(contacts[i]["spontaneous"]);
            this.barSeries[2].data.push(contacts[i]["return"]);
            this.barSeries[3].data.push(contacts[i]["telephone"]);
            this.barSeries[4].data.push(contacts[i]["outOfService"]);
            this.barSeries[5].data.push(contacts[i]["whatsapp"]);
            this.barSeries[6].data.push(contacts[i]["total"]);
          } else {
            //Chart Bar
            objDefault.name = this.legend[0];
            objDefault.data.push(contacts[i]["indication"]);
            this.barSeries.push(JSON.parse(JSON.stringify(objDefault)));
            objDefault.data = [];
            objDefault.name = this.legend[1];
            objDefault.data.push(contacts[i]["spontaneous"]);
            this.barSeries.push(JSON.parse(JSON.stringify(objDefault)));
            objDefault.data = [];
            objDefault.name = this.legend[2];
            objDefault.data.push(contacts[i]["return"]);
            this.barSeries.push(JSON.parse(JSON.stringify(objDefault)));
            objDefault.data = [];
            objDefault.name = this.legend[3];
            objDefault.data.push(contacts[i]["telephone"]);
            this.barSeries.push(JSON.parse(JSON.stringify(objDefault)));
            objDefault.data = [];
            objDefault.name = this.legend[4];
            objDefault.data.push(contacts[i]["outOfService"]);
            this.barSeries.push(JSON.parse(JSON.stringify(objDefault)));
            objDefault.data = [];
            objDefault.name = this.legend[5];
            objDefault.data.push(contacts[i]["whatsapp"]);
            this.barSeries.push(JSON.parse(JSON.stringify(objDefault)));
            objDefault.data = [];
            objDefault.name = this.legend[6];
            objDefault.data.push(contacts[i]["total"]);
            this.barSeries.push(JSON.parse(JSON.stringify(objDefault)));
          }

          this.labels.push(`${contacts[i]["period"]} (Semana ${contacts[i]["week"]})`);
        }
      }
      return ret;
    },
    convertToChartPizzaValues(data) {
      let visitsValues = [];
      for (const key in data) {
        if (key !== "period" && key !== "week" && key !== "total") {
          visitsValues.push({
            value: data[key],
            name: this.changeNameValues(key),
          });
        } else if (key === "period") {
          this.periodAndWeekLegend.push(
            `${data["period"]} (Semana ${data["week"]}) - Total de Visitas: ${data["total"]}`
          );
        }
      }

      if (!data?.period)
        this.periodAndWeekLegend.push(`Total de Visitas: ${data["total"]}`);

      this.chartValues.push(visitsValues);
    },
    changeNameValues(name) {
      switch (name) {
        case "indication":
          name = "INDICAÇÃO";
          break;
        case "spontaneous":
          name = "ESPONTÂNEA";
          break;
        case "telephone":
          name = "TELEFONE";
          break;
        case "return":
          name = "RETORNO";
          break;
        case "outOfService":
          name = "FORA DO EXPEDIENTE";
          break;
        case "whatsapp":
          name = "WHATSAPP";
          break;
      }

      return name;
    },

    getData() {
      this.resetModule();

      if (this.dataRetrievalOk()) {
        this.loading = true;

        contactService
          .getContactsByWeek({
            selectedProductsIds: this.selectedProductsIds(),
            dateBegin: this.filterDateRange[0],
            dateEnd: this.filterDateRange[1],
          })
          .then((r) => {
            this.resetModule();

            const productsSelectedLength = this.getSelectedProducts.length;

            if (r.length >= productsSelectedLength) {
              this.tableItems = r.map((item, index) => {
                if (index < productsSelectedLength) {
                  return {
                    selectedProducts: this.getSelectedProducts[index]?.name,
                    ...item,
                  };
                } else {
                  return { selectedProducts: "", ...item };
                }
              });
            } else {
              this.tableItems = this.getSelectedProducts.map((product, index) => {
                const item = r[index] ? r[index] : "";
                if (item) {
                  return { selectedProducts: product?.name, ...item };
                } else {
                  return { selectedProducts: product?.name };
                }
              });
            }

            this.tableItems.push({
              period: "Total",
              week: "-",
              ...this.reduceContacts(r),
            });
            this.convertToChartValues(r);
            this.loading = false;
          })
          .catch(this.errorHandler);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  min-width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  min-height: 100%;
  max-height: 100%;
  background-color: #fff !important;
  padding-bottom: 50px;

  .container-pizza {
    min-height: 300px;
    max-height: 320px;
    min-width: 550px;
    max-width: 550px;
    border: 1px solid #ccc;

    span {
      min-height: 0;
      min-width: 0;
      max-width: 0;
      border: none;
      margin: 0;
    }
  }
}

.contacts-by-week {
  background-color: #fff;
}

.echarts-pizza {
  width: 100% !important;
  height: 20rem !important;
  position: relative !important;
}

.echarts-default {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
