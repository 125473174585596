<template>
  <mobule-base-builder
    :module-data="moduleData"
    :editMode="editMode"
    :loading="loading"
    :error="error"
    :downloadImage="dataDownload"
    :tableHeaders="tableHeaders"
    :tableItems="tableItems"
    :tableHeadersExcel="tableHeadersExcel"
    :tableItemsExcel="tableItemsExcel"
    :typePanelPDF="moduleData.displayType.chosen"
    :infoItems="info"
  >
    <template v-slot:content>
      <module-table-list-data-v-2
        v-if="moduleData.displayType.chosen == 'TABLE' && tableItems.length > 0"
        :tableHeaders="tableHeaders"
        :tableItems="tableItems"
        :footerPropsItemsPerPageText="footerPropsItemsPerPageText"
        class="visit-by-product-type"
      />

      <module-chart-bar
        v-else-if="
          moduleData.displayType.chosen == 'CHART_BAR' &&
          barChartData.length > 0
        "
        :data="barChartData"
        :labels="labels"
        class="visit-by-product-type"
      />

      <div
        class="echarts"
        v-else-if="
          moduleData.displayType.chosen == 'CHART_PIZZA' &&
          chartValues.length > 0
        "
      >
        <module-chart-doughnut
          :dataTitle="dataTitle"
          :dataLegend="labels"
          :chartValues="chartValues"
          class="visit-by-product-type"
        />
      </div>
      <message-status-data
        v-else
        :selected-products="selectedProductsIds()"
        :infoLoading="loading"
      />
    </template>
  </mobule-base-builder>
</template>

<script>
import ContactService from "../../../services/ContactService";
const contactService = ContactService.build();

import MobuleBaseBuilder from "../base/MobuleBaseBuilder.vue";
import MixinModuleBase from "../../../mixins/MixinModuleBase";

import ModuleChartBar from "../base/ModuleChartBar.vue";
import ModuleTableListDataV2 from "../base/ModuleTableListDataV2.vue";
import ModuleChartDoughnut from "../base/ModuleChartDoughnut.vue";
import MessageStatusData from "../../MessageStatusData.vue";

export default {
  mixins: [MixinModuleBase],
  name: "module-visits-by-product-type",

  components: {
    MobuleBaseBuilder,
    ModuleChartBar,
    ModuleTableListDataV2,
    ModuleChartDoughnut,
    MessageStatusData,
  },

  data: function () {
    return {
      dataTitle: "",
      chartValues: [],

      barChartData: [],
      tableHeaders: [
        {
          text: "Produto de Interesse",
          align: "start",
          sortable: true,
          value: "productTypeName",
        },
        {
          text: "Contagem",
          align: "start",
          sortable: true,
          value: "totalContacts",
        },
      ],
      tableHeadersExcel: [
        {
          text: "Produtos Selecionados",
          align: "start",
          sortable: true,
          value: "selectedProducts",
        },
        {
          text: "Produto de Interesse",
          align: "start",
          sortable: true,
          value: "productTypeName",
        },
        {
          text: "Contagem",
          align: "start",
          sortable: true,
          value: "totalContacts",
        },
      ],
      labels: [],
      tableItems: [],
      tableItemsExcel: [],
      footerPropsItemsPerPageText: "Produtos de Interesse por página",
      dataDownload: {
        class: "visit-by-product-type",
        title: this.moduleData.headerTitle,
      },
    };
  },
  computed: {
    getSelectedProducts() {
      return this.$store.getters.selectedProducts;
    },
    info() {
      return {
        title: `Informações sobre o painel "Visitas X Produtos de Interesse"`,
        text: `<p>O painel mostra o total de contatos por produto de interesse, porém como um contato pode escolher</br> mais de um produto de interesse, a somatória deles podem gerar um total</br> maior de contatos do que realmente existe.</p>
        <p>Para obter o total de contatos deve-se considerar o seguinte cálculo: </p>
        <p><b>( ∑ contatos por produto de interesse ) - "Escolheu mais de um produto de interesse"</b></p>
        <p><b>Obs.: "∑" = somatória; "-" = subtração.</b> 
        A somatória de contatos por produto de interesse não leva em consideração o campo "Escolheu mais de um Produto".`,
      };
    },
  },
  methods: {
    resetModule() {
      this.tableItems = [];
      this.tableItemsExcel = [];
      this.barChartData = [];
      this.chartValues = [];
      this.labels = [];
    },
    getData() {
      this.resetModule();

      if (this.dataRetrievalOk()) {
        this.loading = true;

        contactService
          .getContactByProductType({
            selectedProductsIds: this.selectedProductsIds(),
            dateBegin: this.filterDateRange[0],
            dateEnd: this.filterDateRange[1],
          })
          .then((r) => {
            this.resetModule();
            this.chartValues = this.convertToChartValues(r);

            const productsSelectedLength = this.getSelectedProducts.length;

            if (r.length >= productsSelectedLength) {
              r.forEach((item, index) => {
                if (index < productsSelectedLength) {
                  this.tableItems.push({ ...item });
                  this.tableItemsExcel.push({
                    selectedProducts: this.getSelectedProducts[index]?.name,
                    ...item,
                  });
                } else {
                  this.tableItems.push({ ...item });
                  this.tableItemsExcel.push({
                    selectedProducts: "",
                    ...item,
                  });
                }
              });
            } else {
              this.getSelectedProducts.forEach((product, index) => {
                const item = r[index] ? r[index] : "";
                if (item) {
                  this.tableItems.push({ ...item });
                  this.tableItemsExcel.push({
                    selectedProducts: product?.name,
                    ...item,
                  });
                } else {
                  this.tableItemsExcel.push({
                    selectedProducts: product?.name,
                  });
                }
              });
            }

            this.loading = false;
          })
          .catch(this.errorHandler);
      }
    },

    convertToChartValues(registers) {
      let ret = [];
      if (typeof registers !== "undefined" && registers.length > 0) {
        for (let i = 0; i < registers.length; i++) {
          if (
            registers[i]["productTypeName"] !==
            "Escolheu mais de um produto de interesse"
          ) {
            //Chart Pizza
            ret.push({
              value: registers[i]["totalContacts"],
              name: registers[i]["productTypeName"],
            });
          } else {
            this.dataTitle = `Total de contatos que escolheram mais de um produto de interesse: ${registers[i]["totalContacts"]}`;
          }

          //Chart Bar
          this.barChartData.push(registers[i]["totalContacts"]);
          this.labels.push(registers[i]["productTypeName"]);
        }
      }
      return ret;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-application {
  position: relative;
  min-height: 87%;
  max-height: 100%;

  .v-application--wrap {
    max-height: 100%;
    min-height: 100%;
    overflow: auto;
  }

  .v-data-table__wrapper {
    max-height: 80%;
  }

  .v-data-table {
    td {
      height: 30px !important;
    }
  }
}

.echarts {
  width: 100%;
  height: 100%;
  position: relative;
}

.visit-by-product-type {
  background-color: #fff;
}
</style>