<template>
  <mobule-base-builder :module-data="moduleData" :editMode="editMode" :loading="loading" :error="error" :downloadImage="dataDownload">
    <template v-slot:content>
      <div class="mobule-number-total telephony echarts">
        <div>
          <p class="total">{{ totalMinutos }}</p>
          <p>minutos no período</p>  
        </div>
        <div class="total-price">
          <p class="total">{{ "R$ " + totalPrice }}</p>
          <p>preço total</p>  
        </div>
      </div>
    </template>
  </mobule-base-builder>
</template>

<script>
import MobuleBaseBuilder from '../base/MobuleBaseBuilder.vue'
import MixinModuleBase from "../../../mixins/MixinModuleBase";
import TelephonyService from '../../../services/TelephonyService';

export default {
  mixins: [MixinModuleBase],
  name: 'module-tmp-telefonia',

  components: {
    MobuleBaseBuilder
  },

  data: function () {
    return {
      telephoneService: TelephonyService.build(),
      totalMinutos: 0,
      totalPrice: 0,
      minutesByProduct : {},
      priceByProduct : {},
      dataDownload: {class: "telephony", title: this.moduleData.headerTitle}
    }
  },

  methods: {
    resetModule() {
      this.totalMinutos = 0;
      this.totalPrice = 0;
    },

    async getData() {
      this.resetModule();
      
      if (this.dataRetrievalOk()) {
        this.loading = true;
        let sumTotalMin = 0;
        let sumTotalPrice = 0;
        let listPriceTotal = [];
        const listIdsApiTelephonySelected = this.selectedProductsIdsApiTelephony().filter(id => id !== 0);

        for(const idTelApi of listIdsApiTelephonySelected){
          if(!this.minutesByProduct[idTelApi]){
            const response = await this.telephoneService.getReportCalls(
              idTelApi,
              this.filterDateRange[0].split("T")[0],
              this.filterDateRange[1].split("T")[0],
            ).catch(this.errorHandler);

            this.minutesByProduct[idTelApi] = Number(response.total_time); 
            this.priceByProduct[idTelApi] = Number(response.total_value.replace('.', '').replace(',', '.'));
          }

          sumTotalMin += this.minutesByProduct[idTelApi];
          listPriceTotal.push(this.priceByProduct[idTelApi]);
        }

        this.resetModule();
        this.totalMinutos = (sumTotalMin / 60).toFixed(0);
        listPriceTotal.sort();
        sumTotalPrice = listPriceTotal.reduce((accumulator, current) => accumulator + current, 0);
        this.totalPrice = String(Math.round(sumTotalPrice * 100) / 100).replace('.', ',');
        this.loading = false;
      }
    },

    selectedProductsIdsApiTelephony() {
      return this.selectedProducts.map((p) => {
        return parseInt(p.idTelephonyApi);
      });
    }
  },

  watch: {
    filterDateRange() {
      this.minutesByProduct = {};
      this.priceByProduct = {};

      if (this.selectedProducts && this.selectedProducts.length > 0) this.getData();
    }
  },
}
</script>

<style lang="scss" scoped>
.mobule-number-total{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  flex-direction: initial;
  text-align: center;
  height: 100%;
  background-color: #fff;

  p{
    margin: 5px;
    font-weight: bold;
    font-size: 16px;

    &.total{
      font-size: 28px;
    }
  }
}

.echarts {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
