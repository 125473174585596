import Rest from './base/Rest'

/**
 * @typedef {AccountPanelService}
 */
export default class AccountPanelService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/accountPanel'


  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  saveAccountPanel(params = []) {
    return this.post(`/save`, params)
  }

  /**
 * @param {Object} parameters
 * @returns {*|PromiseLike<T | never>|Promise<T | never>}
 */
  removeAccountPanel(params = []) {
    return this.delete(`/delete`, { data: params })
  }

  /**
* @param {Object} parameters
* @returns {*|PromiseLike<T | never>|Promise<T | never>}
*/

  findPanelsByAccountId(id) {
    return this.get(`/findByAccountId/${id}`);
  }

}