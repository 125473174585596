<template>
  <mobule-base-builder
    :module-data="moduleData"
    :editMode="editMode"
    :loading="loading"
    :error="error"
    :downloadImage="dataDownload"
    :tableHeaders="tableHeaders"
    :tableItems="tableItems"
    :tableHeadersExcel="tableHeadersExcel"
    :typePanelPDF="moduleData.displayType.chosen"
    :selectLabelToPDF="selectedQuestionTitle"
    :questionnaireTitleToPDF="selectedQuestionnaireTitle"
  >
    <template v-slot:content>
      <div class="pa-3 questionnaire" v-if="questionnaires.length > 0">
        <v-autocomplete
          v-model="selectedQuestion"
          :items="questionnaires"
          outlined
          label="Selecione uma questão"
          item-text="name"
          item-value="id"
          @change="changedQuestion"
          dense
        >
          <template v-slot:item="data">
            <template>
              <v-list-item-content>
                <v-list-item-title>{{ data.item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </div>
      <message-status-data
        v-else
        :selected-products="selectedProductsIds()"
        :infoLoading="loading"
      />

      <template
        v-if="selectedQuestion != null && selectedQuestion != '' && tableItems.length > 0"
      >
        <module-chart-bar
          v-if="moduleData.displayType.chosen == 'CHART_BAR'"
          :data="barChartData"
          :labels="labels"
          class="questionnaire"
        />
        <div class="echarts" v-else-if="moduleData.displayType.chosen == 'CHART_PIZZA'">
          <module-chart-doughnut
            dataTitle=""
            :chartValues="chartValues"
            :dataLegend="labels"
            :legendOrientation="'top'"
            class="questionnaire"
          />
        </div>
        <module-table-list-data-v2
          v-else-if="moduleData.displayType.chosen == 'TABLE'"
          :tableHeaders="tableHeaders"
          :tableItems="tableItems"
          :footerPropsItemsPerPageText="footerPropsItemsPerPageText"
          class="questionnaire"
        />
        <module-word-cloud
          v-else-if="moduleData.displayType.chosen == 'WORD_CLOUD'"
          :data="wordCloudData"
          class="questionnaire"
        />
      </template>
      <message-status-data
        v-else
        :selected-products="selectedProductsIds()"
        :infoLoading="loading"
        :selectInPanel="selectInPanel"
      />
    </template>
  </mobule-base-builder>
</template>

<script>
import QuestionnaireService from "../../../services/QuestionnaireService";
const questionnaireService = QuestionnaireService.build();

import percentageSort from "../../../utils/sorting/percentage.sort";

import MobuleBaseBuilder from "../base/MobuleBaseBuilder.vue";
import MixinModuleBase from "../../../mixins/MixinModuleBase";

import ModuleChartDoughnut from "../base/ModuleChartDoughnut.vue";
import ModuleChartBar from "../base/ModuleChartBar.vue";
import ModuleTableListDataV2 from "../base/ModuleTableListDataV2.vue";
import ModuleWordCloud from "../base/ModuleWordCloud.vue";
import MessageStatusData from "../../MessageStatusData.vue";

export default {
  mixins: [MixinModuleBase],
  name: "module-questionnaires",
  components: {
    MobuleBaseBuilder,
    ModuleChartDoughnut,
    ModuleChartBar,
    ModuleTableListDataV2,
    ModuleWordCloud,
    MessageStatusData,
  },
  data: function () {
    return {
      questions: [],
      questionnaires: [],
      selectedQuestion: "",
      questionnaire: "",
      question: "",
      selectedQuestionnaireTitle: "",
      selectedQuestionTitle: "",

      chartValues: [],

      barChartData: [],
      wordCloudData: [],
      labels: [],
      tableHeaders: [
        { text: "Resposta", align: "start", sortable: true, value: "text_for" },
        {
          text: "Quantidade",
          align: "start",
          sortable: true,
          value: "replyCount",
        },
        {
          text: "%",
          align: "start",
          sortable: true,
          value: "percentage",
          sort: percentageSort,
        },
      ],
      tableHeadersExcel: [
        {
          text: "Questionário",
          align: "start",
          sortable: true,
          value: "questionnaire",
        },
        { text: "Questão", align: "start", sortable: true, value: "question" },
        { text: "Resposta", align: "start", sortable: true, value: "text_for" },
        {
          text: "Quantidade",
          align: "start",
          sortable: true,
          value: "replyCount",
        },
        {
          text: "%",
          align: "start",
          sortable: true,
          value: "percentage",
          sort: percentageSort,
        },
      ],
      tableItems: [],
      footerPropsItemsPerPageText: "Respostas por página",
      dataDownload: {
        class: "questionnaire",
        title: this.moduleData.headerTitle,
      },
      selectInPanel: {
        active: true,
        message: "Selecione alguma questão",
      },
    };
  },
  methods: {
    resetModule() {
      this.selectedQuestion = "";
      this.questions = [];
      this.questionnaires = [];
      this.tableItems = [];
      this.chartValues = [];
      this.barChartData = [];
      this.wordCloudData = [];
      this.labels = [];
      this.selectInPanel = {
        active: true,
        message: "Selecione alguma questão",
      };
    },
    getData() {
      this.resetModule();

      if (this.dataRetrievalOk()) {
        this.loading = true;
        questionnaireService
          .getQuestionnaires({
            selectedProductsIds: this.selectedProductsIds(),
            dateBegin: this.filterDateRange[0],
            dateEnd: this.filterDateRange[1],
          })
          .then((receivedQuestionnaires) => {
            this.resetModule();

            if (receivedQuestionnaires && receivedQuestionnaires.length > 0) {
              this.questionnaires = receivedQuestionnaires.map(
                (receivedQuestionnaire) => {
                  return {
                    id: receivedQuestionnaire.id,
                    questions: receivedQuestionnaire.questions,
                    header: receivedQuestionnaire.title,
                  };
                }
              );

              questionnaireService
                .getQuestions({
                  selectedProductsIds: this.selectedProductsIds(),
                  dateBegin: this.filterDateRange[0],
                  dateEnd: this.filterDateRange[1],
                })
                .then((receivedQuestions) => {
                  if (receivedQuestions && receivedQuestions.length > 0) {
                    this.questions = receivedQuestions;

                    const formattedGroup = [];

                    this.questionnaires.forEach((questionnaire) => {
                      formattedGroup.push({
                        header: questionnaire.header,
                      });
                      this.questions.forEach((question) => {
                        if (question.questionnaire_id == questionnaire.id) {
                          formattedGroup.push({
                            id: question.id,
                            name: question.title,
                            group: questionnaire.header,
                          });
                        }
                      });
                      formattedGroup.push({
                        divider: true
                      });
                    });

                    this.questionnaires = formattedGroup;
                  }
                })
                .catch(this.errorHandler);
            } else {
              this.selectInPanel = {
                active: false,
                message: "Nenhum dado encontrado",
              };
            }

            this.loading = false;
          })
          .catch(this.errorHandler);
      }
    },
    convertToChartValues(registers) {
      let ret = [];
      this.barChartData = [];
      this.labels = [];
      if (registers && registers.length > 0) {
        for (let i = 0; i < registers.length; i++) {
          //Chart Pizza
          ret.push({
            value: registers[i]["replyCount"],
            name: registers[i]["text_for"],
          });

          //Chart Bar
          this.barChartData.push(registers[i]["replyCount"]);
          this.labels.push(registers[i]["text_for"]);
        }
      }
      return ret;
    },

    changedQuestion() {
      this.loading = true;
      this.tableItems = [];
      this.chartValues = [];
      this.barChartData = [];
      this.wordCloudData = [];

      questionnaireService
        .getReplies({
          selectedProductsIds: this.selectedProductsIds(),
          dateBegin: this.filterDateRange[0],
          dateEnd: this.filterDateRange[1],
          questionId: Number(this.selectedQuestion),
        })
        .then((r) => {
          if (r && r.length > 0) {
            this.chartValues = this.convertToChartValues(r);

            let tot = 0;
            for (let i = 0; i < r.length; i++) {
              if (r[i]["text_for"] == "") {
                r[i]["text_for"] = "Sem resposta";
              }
              tot += parseInt(r[i]["replyCount"]);
            }
            for (let i = 0; i < r.length; i++) {
              r[i]["percentage"] = ((r[i]["replyCount"] / tot) * 100).toFixed(2) + " %";
            }

            this.question = this.questions.filter(
              (question) => question.id === this.selectedQuestion
            );
            this.questionnaire = this.questionnaires.filter(
              (questionnaire) => questionnaire.id === this.selectedQuestion
            );

            this.tableItems = r.map((item, index) => {
              if (index === 0) {
                return {
                  questionnaire: this.questionnaire[0]?.group,
                  question: this.question[0]?.title,
                  ...item,
                };
              } else {
                return { questionnaire: "", question: "", ...item };
              }
            });

            this.wordCloudData = r.map((item) => {
              return {
                resposta: item.text_for,
                total: item.replyCount,
              };
            });
          }

          if (this.questionnaires.length > 0 && this.question.length > 0) {
            this.selectedQuestionTitle = this.question[0]?.title;

            this.questionnaires.forEach((questionnaire) => {
              if (questionnaire.id === this.question[0]?.questionnaire_id) {
                this.selectedQuestionnaireTitle = questionnaire.group;
              }
            });
          }

          this.loading = false;
          this.selectInPanel = {
            active: false,
            message: "Selecione alguma questão",
          };
        })
        .catch(this.errorHandler);
    },
  },
};
</script>

<style lang="scss" scoped>
.echarts {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #fff;
}

.questionnaire {
  background-color: #fff;
}

.w-100 {
  z-index: 999;
}
</style>
