<template>
  <div class="container">
    <template v-if="isMultiple">
      <v-autocomplete
        v-model="idSelectedEnterprise"
        :items="enterprises"
        @change="handleEnterprises()"
        item-text="name"
        item-value="id"
        label="Escolha uma empresa"
        class="ml-4 mr-4 mt-4"
        multiple
        clearable
        outlined
        dense
        small-chips
        deletable-chips
      ></v-autocomplete>

      <v-autocomplete
        v-model="idSelectedUser"
        :items="users"
        @change="handleUsers()"
        :disabled="!idSelectedEnterprise || idSelectedEnterprise?.length < 1"
        :item-text="(item) => `${item.name} ${item.last_name}`"
        item-value="id"
        label="Escolha um usuário"
        class="ml-4 mr-4 mt-4"
        multiple
        clearable
        outlined
        dense
        small-chips
        deletable-chips
      ></v-autocomplete>
    </template>
    <template v-else>
      <v-autocomplete
        v-model="idSelectedEnterprise"
        :items="enterprises"
        @change="handleEnterprises()"
        item-text="name"
        item-value="id"
        label="Escolha uma empresa"
        class="ml-4 mr-4 mt-4"
        clearable
        outlined
        dense
      ></v-autocomplete>

      <v-autocomplete
        v-model="idSelectedUser"
        :items="users"
        @change="handleUsers()"
        :disabled="!idSelectedEnterprise || idSelectedEnterprise?.length < 1"
        :item-text="(item) => `${item.name} ${item.last_name}`"
        item-value="id"
        label="Escolha um usuário"
        class="ml-4 mr-4 mt-4"
        clearable
        outlined
        dense
      ></v-autocomplete>
    </template>
  </div>
</template>

<script>
import EnterpriseService from "../../services/EnterpriseService";
import UserService from "../../services/UserService";
import AccountPanelService from "../../services/AccountPanelService";

import DefaultMixin from "../../mixins/DefaultMixin";

export default {
  name: "AvailableUsersInput",
  mixins: [DefaultMixin],
  props: {
    selectedEnterpriseValue: {
      type: [Object, Array],
      default: null,
    },
    selectedUserValue: {
      type: [Object, Array],
      default: null,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:selectedEnterprise", "update:selectedUser"],
  data() {
    return {
      enterpriseService: EnterpriseService.build(),
      userService: UserService.build(),
      accountPanelService: AccountPanelService.build(),
      enterprises: [],
      idSelectedEnterprise: [],
      users: [],
      idSelectedUser: null,
      timeoutID: 0,
    };
  },
  computed: {
    selectedEnterprise: {
      get() {
        return this.selectedEnterpriseValue;
      },
      set(value) {
        this.$emit("update:selectedEnterprise", value);
      },
    },
    selectedUser: {
      get() {
        return this.selectedUserValue;
      },
      set(value) {
        this.$emit("update:selectedUser", value);
      },
    },
  },
  methods: {
    getEnterprises() {
      this.enterpriseService
        .findAllEnterprisesIsActiveBI()
        .then((response) => {
          this.enterprises = response;
        })
        .catch(this.errorHandler);
    },
    async getUsers() {
      this.idSelectedUser = null;
      this.users = [];
      const usersLocal = [];
      let promises = [];

      if (this.isMultiple) {
        if (
          Array.isArray(this.idSelectedEnterprise) &&
          this.idSelectedEnterprise.length > 0 &&
          this.selectedEnterprise?.length > 1
        ) {
          for (
            let indexFirtLoop = 0;
            indexFirtLoop < this.idSelectedEnterprise.length;
            indexFirtLoop++
          ) {
            try {
              promises.push(
                this.userService.getUsersByEnterprise({
                  enterprise: this.selectedEnterprise[indexFirtLoop]?.dataBase,
                })
              );
            } catch (error) {
              throw this.errorHandler;
            }
          }

          const promisesData = await Promise.all([...promises]).then((values) => {
            return values;
          });

          if (Array.isArray(promisesData) && promisesData.length > 0) {
            for (
              let indexSecondLoop = 0;
              indexSecondLoop < this.idSelectedEnterprise.length;
              indexSecondLoop++
            ) {
              const enterprises = this.selectedEnterprise[indexSecondLoop]?.dataBase;

              const userWithEnterprise = promisesData[indexSecondLoop]?.map((user) => {
                return {
                  ...user,
                  enterprises,
                };
              });

              if (Array.isArray(userWithEnterprise) && userWithEnterprise.length > 0) {
                usersLocal.push(...userWithEnterprise);
              }
            }

            const groupAnyEnterpriseSameUser = this.groupEnterprisesFromTheSameUser(
              usersLocal
            );

            this.users = await this.removeDuplicateUsers(groupAnyEnterpriseSameUser);
          }
        } else {
          if (this.idSelectedEnterprise) {
            const users = await this.getUsersAPI(this.idSelectedEnterprise[0]);

            if (users) {
              const enterprise = this.enterprises.filter(
                (enterprise) => enterprise.id === this.idSelectedEnterprise[0]
              );

              const enterpriseName = enterprise[0]?.dataBase;

              const usersWithEnterprise = users.map((user) => {
                return {
                  ...user,
                  enterprises: [enterpriseName],
                };
              });

              this.users = usersWithEnterprise;
            }
          }
        }
      } else {
        if (this.idSelectedEnterprise) {
          this.users = await this.getUsersAPI(this.idSelectedEnterprise);
        }
      }
    },
    async getUsersAPI(enterpriseID) {
      if (enterpriseID) {
        const enterprise = this.enterprises.filter(
          (enterprise) => enterprise.id === enterpriseID
        );

        let users = [];

        try {
          users = await this.userService.getUsersByEnterprise({
            enterprise: enterprise[0]?.dataBase,
          });
        } catch (error) {
          this.errorHandler;
        }

        const usersWithPanelIds = await this.includePanelIdsThatTheUserHasAccessTo(users);

        return usersWithPanelIds;
      }
    },
    handleUsers() {
      if (Array.isArray(this.idSelectedUser)) {
        this.selectedUser = this.users.filter((user) => {
          const isSelected = this.idSelectedUser.filter((idUser) => user.id === idUser);
          if (isSelected && isSelected.length > 0) return user;
        });
      } else {
        this.selectedUser = this.users.filter(
          (user) => user.id === this.idSelectedUser
        )[0];
      }
    },
    handleEnterprises() {
      clearTimeout(this.timeoutID);

      if (Array.isArray(this.idSelectedEnterprise)) {
        let selectedEnterpriseLocal = [];
        for (let index = 0; index < this.idSelectedEnterprise?.length; index++) {
          const element = String(this.idSelectedEnterprise[index]);
          const findElement = this.enterprises.find(
            (enterprise) => enterprise.id === element
          );

          selectedEnterpriseLocal.push(findElement);
        }

        this.selectedEnterprise = selectedEnterpriseLocal;

        this.timeoutID = setTimeout(() => {
          this.getUsers();
        }, 300);
      } else {
        let selectedEnterpriseLocal = [];
        const element = this.idSelectedEnterprise;

        const findElement = this.enterprises.find(
          (enterprise) => enterprise.id === element
        );

        selectedEnterpriseLocal.push(findElement);

        this.selectedEnterprise = selectedEnterpriseLocal;

        this.timeoutID = setTimeout(() => {
          this.getUsers();
        }, 300);
      }
    },
    groupEnterprisesFromTheSameUser(users) {
      let userCheck = [];
      const groupAnyEnterpriseSameUser = users.map((user) => {
        let newUser = {};
        const checkAlreadyUser = userCheck.find((checkUser) => checkUser.id === user.id);

        if (checkAlreadyUser) {
          checkAlreadyUser.enterprises.push(user.enterprises);
          newUser = checkAlreadyUser;
        } else {
          const userEnterprises = [];
          userEnterprises.push(user.enterprises);
          newUser = {
            ...user,
            enterprises: userEnterprises,
          };
        }

        userCheck.push(newUser);
        return newUser;
      });

      return groupAnyEnterpriseSameUser;
    },
    async removeDuplicateUsers(users) {
      const setUsers = new Set();
      const filteredUsers = users.filter((user) => {
        const duplicatedUser = setUsers.has(user.id);
        setUsers.add(user.id);
        return !duplicatedUser;
      });

      const usersWithPanelIds = await this.includePanelIdsThatTheUserHasAccessTo(
        filteredUsers
      );

      return usersWithPanelIds;
    },
    async includePanelIdsThatTheUserHasAccessTo(users) {
      const findPanelsByAccountIdPromises = [];
      const usersWithPanelIds = [];
      const usersLength = users.length;

      if (usersLength > 0) {
        for (let index = 0; index < usersLength; index++) {
          try {
            findPanelsByAccountIdPromises.push(
              this.accountPanelService.findPanelsByAccountId(users[index].id)
            );
          } catch (error) {
            throw this.errorHandler;
          }
        }

        const promisesData = await Promise.all([...findPanelsByAccountIdPromises]).then(
          (values) => {
            return values;
          }
        );

        if (Array.isArray(promisesData) && promisesData.length > 0) {
          const userWithPanelIds = users.map((user, index) => {
            const panelAccountId = promisesData[index]?.accountId;
            if (user.id === panelAccountId) {
              const userContainsPanels = Object.keys(user)
              if (userContainsPanels.includes('panelIds')) {
                user.panelIds.push(promisesData[index])
                return {
                ...user,
                };
              } else {
                return {
                  ...user,
                  panelIds: [promisesData[index]],
                };
              }

            } else {
              return {
                ...user,
                panelIds: [],
              };
            }
          });

          if (Array.isArray(userWithPanelIds) && userWithPanelIds.length > 0) {
            usersWithPanelIds.push(...userWithPanelIds);
          }
        }
        return usersWithPanelIds;
      }
    },
  },
  watch: {
    /*selectedEnterpriseValue(newValue) {
      if (newValue) this.getUsers();
      if (!newValue) {
        this.idSelectedEnterprise = null;
        this.idSelectedUser = null;
      }
    },*/
    dialogVisible(visible) {
      if (!visible) {
        this.idSelectedEnterprise = [];
        this.users = [];
        this.idSelectedUser = null;
      }
    },
  },
  mounted() {
    this.getEnterprises();
  },
};
</script>

<style scoped></style>
