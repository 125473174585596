<template>
  <mobule-base-builder
    :module-data="moduleData"
    :editMode="editMode"
    :loading="loading"
    :error="error"
    :downloadImage="dataDownload"
    :tableHeaders="tableHeaders"
    :tableItems="tableItems"
    :tableHeadersExcel="tableHeadersExcel"
  >
    <template v-slot:content>
      <div class="pa-3 color-white" v-if="superintendents.length > 0">
        <v-autocomplete
          v-model="selectedSuperintendent"
          :items="superintendents"
          @change="changedSuperintendent()"
          id="team-contacts"
          label="Selecione o superintendente"
          class="px-4 pt-2"
          outlined
          dense
        ></v-autocomplete>
      </div>
      <message-status-data
        v-else
        :selected-products="selectedProductsIds()"
        :infoLoading="loading"
      />

      <template v-if="selectedSuperintendent && selectedSuperintendent != ''">
        <module-chart-bar
          :data="chartBarData"
          :labels="labels"
          class="team-contacts"
        />
      </template>
    </template>
  </mobule-base-builder>
</template>

<script>
import ContactService from "../../../services/ContactService";

import MobuleBaseBuilder from "../base/MobuleBaseBuilder.vue";
import MixinModuleBase from "../../../mixins/MixinModuleBase";

import ModuleChartBar from "../base/ModuleChartBar.vue";
import MessageStatusData from "../../MessageStatusData.vue";

const LABELS = {
  spontaneous: "Espontâneo",
  indication: "Indicação",
  return: "Retorno",
  whatsapp: "WhatsApp",
  total: "Total",
};

export default {
  mixins: [MixinModuleBase],
  name: "module-team-contacts",

  components: {
    MobuleBaseBuilder,
    ModuleChartBar,
    MessageStatusData,
  },

  data: function () {
    return {
      contactService: ContactService.build(),
      chartBarData: [],
      labels: Object.values(LABELS),
      selectedSuperintendent: "",
      superintendents: [],
      dataDownload: {
        class: "team-contacts",
        title: this.moduleData.headerTitle,
      },
      tableHeaders: [
        { text: "Superintendente", value: "superintendent" },
        { text: "Espontâneo", value: "spontaneous" },
        { text: "Indicação", value: "indication" },
        { text: "Retorno", value: "return" },
        { text: "WhatsApp", value: "whatsapp" },
        { text: "Total", value: "total" },
      ],
      tableHeadersExcel: [
        {
          text: "Produtos Selecionados",
          align: "start",
          sortable: true,
          value: "selectedProducts",
        },
        { text: "Superintendente", value: "superintendent" },
        { text: "Espontâneo", value: "spontaneous" },
        { text: "Indicação", value: "indication" },
        { text: "Retorno", value: "return" },
        { text: "WhatsApp", value: "whatsapp" },
        { text: "Total", value: "total" },
      ],
      tableItems: [],
    };
  },
  computed: {
    getSelectedProducts() {
      return this.$store.getters.selectedProducts;
    },
  },
  methods: {
    resetModule() {
      this.selectedSuperintendent = "";
      this.chartBarData = [];
      this.superintendents = [];
    },

    getData() {
      this.resetModule();

      if (this.dataRetrievalOk()) {
        this.loading = true;

        this.contactService
          .getSuperintendentsFromContactsEvs({
            selectedProductsIds: this.selectedProductsIds(),
            dateBegin: this.filterDateRange[0],
            dateEnd: this.filterDateRange[1],
          })
          .then((response) => {
            this.resetModule();

            this.superintendents = response;
            this.loading = false;
          })
          .catch(this.errorHandler);
      }
    },

    changedSuperintendent() {
      if (this.selectedSuperintendent && this.selectedSuperintendent != "") {
        this.loading = true;

        this.contactService
          .getTotalContactsEvsBySuperintendent({
            selectedProductsIds: this.selectedProductsIds(),
            dateBegin: this.filterDateRange[0],
            dateEnd: this.filterDateRange[1],
            superintendent: this.selectedSuperintendent,
          })
          .then((response) => {
            this.tableItems = [];
            const tableItemsAux = [];
            tableItemsAux.push(response);

            const productsSelectedLength = this.getSelectedProducts.length;

            tableItemsAux.forEach((item) => {
              for (let index = 0; index < productsSelectedLength; index++) {
                const product = this.getSelectedProducts[index];
                if (index === 0) {
                  this.tableItems.push({
                    selectedProducts: product?.name,
                    superintendent: this.selectedSuperintendent,
                    ...item,
                  });
                } else {
                  this.tableItems.push({
                    selectedProducts: product?.name ? product?.name : "",
                  });
                }
              }
            });

            this.chartBarData = this.convertToChartValues(response);
            this.loading = false;
          })
          .catch(this.errorHandler);
      }
    },

    convertToChartValues(register) {
      let data = [];

      Object.keys(LABELS).forEach((key) => {
        data.push(register[key]);
      });

      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
.color-white {
  background-color: #fff;
}

.team-contacts {
  background-color: #fff;
}
</style>
