<template>
  <mobule-base-builder
    :module-data="moduleData"
    :editMode="editMode"
    :loading="loading"
    :error="error"
    :downloadImage="dataDownload"
    :tableHeaders="tableHeaders"
    :tableItems="tableItems"
    :tableHeadersExcel="tableHeaders"
    :tableItemsExcel="tableItemsExcel"
    :typePanelPDF="'TABLE'"
  >
    <template v-slot:content>
      <module-table-list-data-v-2
        :tableHeaders="tableHeaders"
        :tableItems="tableItems"
        :tableItemsExcel="tableItemsExcel"
        class="general-report"
        v-if="tableItems.length > 0"
      />
      <message-status-data
        v-else
        :selected-products="selectedProductsIds()"
        :infoLoading="loading"
      />
    </template>
  </mobule-base-builder>
</template>

<script>
import MixinModuleBase from "../../../mixins/MixinModuleBase";
import MobuleBaseBuilder from "../base/MobuleBaseBuilder.vue";
import ModuleTableListDataV2 from "../base/ModuleTableListDataV2.vue";
import MessageStatusData from "../../MessageStatusData.vue";

import ContactService from "../../../services/ContactService";
const contactService = ContactService.build();

export default {
  name: "module-general-report",
  mixins: [MixinModuleBase],
  components: {
    MobuleBaseBuilder,
    ModuleTableListDataV2,
    MessageStatusData,
  },
  data: function () {
    return {
      tableHeaders: [
        {
          text: "Empreendimento",
          align: "start",
          sortable: true,
          value: "productName",
          tooltip: "Nome do emprendimento",
        },
        {
          text: "TV",
          align: "start",
          sortable: true,
          value: "totalContacts",
          tooltip: "Total de visitas",
        },
        {
          text: "TC",
          align: "start",
          sortable: true,
          value: "totalCompletedRegisterCustomer",
          tooltip: "Total de cadastros completos",
        },
        {
          text: "TC(*)",
          align: "start",
          sortable: true,
          value: "totalCompletedRegisterCustomerRequiredFields",
          tooltip: "Total de cadastros completos (campos obrigatórios*)",
        },
        {
          text: "TPC",
          align: "start",
          sortable: true,
          value: "totalCompletedReplies",
          tooltip: "Total de pesquisas completas",
        },
        {
          text: "CAD(%)",
          align: "start",
          sortable: true,
          value: "registersPercent",
          tooltip: "Cadastros (%)",
        },
        {
          text: "CAD(*)",
          align: "start",
          sortable: true,
          value: "registersRequiredFieldsPercent",
          tooltip: "Cadastros (campos obrigatórios*) (%)",
        },
        {
          text: "PQS(%)",
          align: "start",
          sortable: true,
          value: "researchesPercent",
          tooltip: "Pesquisas (%)",
        },
        {
          text: "CC(%)",
          align: "start",
          sortable: true,
          value: "registersCapturePercent",
          tooltip: "Captação de cadastros (%)",
        },
        {
          text: "CP(%)",
          align: "start",
          sortable: true,
          value: "researchesCapturePercent",
          tooltip: "Captação de pesquisas (%)",
        },
        {
          text: "TFE",
          align: "start",
          sortable: true,
          value: "totalOutService",
          tooltip: "Total fora do expediente",
        },
      ],
      tableItems: [],
      tableItemsExcel: [],
      dataDownload: {
        class: "general-report",
        title: this.moduleData.headerTitle,
      },
    };
  },
  methods: {
    resetModule() {
      this.tableItems = [];
      this.tableItemsExcel = [];
    },
    getData() {
      this.resetModule();

      if (this.dataRetrievalOk()) {
        this.loading = true;

        contactService
          .getGeneralReport({
            selectedProductsIds: this.selectedProductsIds(),
            dateBegin: this.filterDateRange[0],
            dateEnd: this.filterDateRange[1],
          })
          .then((r) => {
            this.resetModule();
            this.tableItems = r;
            this.tableItemsExcel = r;
            this.loading = false;
          })
          .catch(this.errorHandler);
      }
    },
  },
  computed: {
    getSelectedProducts() {
      return this.$store.getters.selectedProducts;
    },
  },
};
</script>

<style>
.echarts.general-report,
#table,
.v-data-table__wrapper,
.v-data-footer {
  background-color: #fff !important;
}
</style>