<template>
  <v-map :zoom="5" :center="initialLocation" :minZoom="3">
    <v-icondefault></v-icondefault>
    <v-tilelayer :url="url" :attribution="attribution"></v-tilelayer>

    <div v-if="viewRadiusCircle">
      <v-circle
        v-for="circle in circles"
        :key="circle.circle.id"
        :lat-lng="circle.circle.center"
        :radius="circle.circle.radius"
        :color="circle.circle.color"
        :fill="false"
        :weight="6"
      >
        <v-popup
          :content="circle.circle.distance"
          :options="{ closeOnClick: false, autoClose: false, openPopup: true }"
        ></v-popup>
      </v-circle>
    </div>

    <!-- Marcadores dentro do cluster agrupam -->
    <v-marker-cluster :options="clusterOptions" v-if="viewCluster"
      ><!-- @ready="ready" -->
      <v-marker
        v-for="l in locations"
        :key="l.id"
        :lat-lng="l.latlng"
        :icon="l.icon"
      >
        <v-popup :content="l.content"></v-popup>
      </v-marker>
    </v-marker-cluster>

    <div v-if="viewPins">
      <!-- Marcadores fora do cluster não agrupam -->
      <v-marker
        v-for="l in locations"
        :key="l.id"
        :lat-lng="l.latlng"
        :icon="l.icon"
      >
        <v-popup :content="l.content"></v-popup>
      </v-marker>
    </div>

    <v-marker
      v-for="sl in standsLocations"
      :key="sl.id"
      :lat-lng="sl.latlng"
      :icon="sl.standIcon"
    >
      <v-popup :content="sl.content"></v-popup>
    </v-marker>
  </v-map>
</template>

<script>
import * as Vue2Leaflet from "vue2-leaflet";
import { latLng } from "leaflet";
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";

export default {
  name: "module-map-count-zoom",

  components: {
    "v-map": Vue2Leaflet.LMap,
    "v-tilelayer": Vue2Leaflet.LTileLayer,
    "v-icondefault": Vue2Leaflet.LIconDefault,
    "v-marker": Vue2Leaflet.LMarker,
    "v-popup": Vue2Leaflet.LPopup,
    "v-marker-cluster": Vue2LeafletMarkerCluster,
    "v-circle": Vue2Leaflet.LCircle,
    "v-tooltip": Vue2Leaflet.LTooltip,
  },

  props: {
    locations: {
      type: Array,
      default: function () {
        return [];
      },
    },
    standsLocations: {
      type: Array,
      default: function () {
        return [];
      },
    },
    circles: {
      type: Array,
      default: function () {
        return [];
      },
    },
    notViewCluster: {
      type: Boolean,
    },
    viewCluster: {
      type: Boolean,
    },
    viewRadiusCircle: {
      type: Boolean,
    },
  },

  data() {
    return {
      clusterOptions: {
        spiderLegPolylineOptions: { weight: 0, opacity: 0, color: "#fff" },
        showCoverageOnHover: true,
        animate: true,
      },
      initialLocation: latLng(-23.624963, -46.580415),
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution: `&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors`,
    };
  },

  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.clusterOptions = { disableClusteringAtZoom: 11 };
        window.dispatchEvent(new Event("resize"));
      }, 500);
    });
  },
  watch: {
    viewCluster: function (newVal, oldVal) {
      this.clusterOptions = {
        disableClusteringAtZoom: 20,
        spiderLegPolylineOptions: { weight: 0, opacity: 0, color: "#fff" },
        showCoverageOnHover: true,
        animate: true,
      };
    },
  },
};
</script>

<style lang="scss">
@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

.leaflet-popup-content {
  margin: 10px 16px 8px 10px !important;
}
</style>
