export default [
    {
        urlCircleKMLegend: require("../../../public/assets/images/circlesKMLegends/1KM.png"),
    },
    {
        urlCircleKMLegend: require("../../../public/assets/images/circlesKMLegends/2KM.png"),
    },
    {
        urlCircleKMLegend: require("../../../public/assets/images/circlesKMLegends/3KM.png"),
    },
    {
        urlCircleKMLegend: require("../../../public/assets/images/circlesKMLegends/4KM.png"),
    },
    {
        urlCircleKMLegend: require("../../../public/assets/images/circlesKMLegends/5KM.png"),
    },
    {
        urlCircleKMLegend: require("../../../public/assets/images/circlesKMLegends/6KM.png"),
    },
    {
        urlCircleKMLegend: require("../../../public/assets/images/circlesKMLegends/7KM.png"),
    },
    {
        urlCircleKMLegend: require("../../../public/assets/images/circlesKMLegends/8KM.png"),
    },
    {
        urlCircleKMLegend: require("../../../public/assets/images/circlesKMLegends/9KM.png"),
    },
    {
        urlCircleKMLegend: require("../../../public/assets/images/circlesKMLegends/10KM.png"),
    },
    {
        urlCircleKMLegend: require("../../../public/assets/images/circlesKMLegends/11KM.png"),
    },
    {
        urlCircleKMLegend: require("../../../public/assets/images/circlesKMLegends/12KM.png"),
    },
    {
        urlCircleKMLegend: require("../../../public/assets/images/circlesKMLegends/13KM.png"),
    },
    {
        urlCircleKMLegend: require("../../../public/assets/images/circlesKMLegends/14KM.png"),
    },
    {
        urlCircleKMLegend: require("../../../public/assets/images/circlesKMLegends/15KM.png"),
    },
    {
        urlCircleKMLegend: require("../../../public/assets/images/circlesKMLegends/16KM.png"),
    },
    {
        urlCircleKMLegend: require("../../../public/assets/images/circlesKMLegends/17KM.png"),
    },
    {
        urlCircleKMLegend: require("../../../public/assets/images/circlesKMLegends/18KM.png"),
    },
    {
        urlCircleKMLegend: require("../../../public/assets/images/circlesKMLegends/19KM.png"),
    },
    {
        urlCircleKMLegend: require("../../../public/assets/images/circlesKMLegends/20KM.png"),
    },
];