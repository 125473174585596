<template>
  <div>
    <div class="container bg-white col-md-10 my-6">
      <div class="row row-cols-auto align-items-center p-3">
        <div class="col col-5 input-group">
          <legend class="ml-6">Gerenciar acessos externos</legend>
        </div>

        <div class="col col-md-7">
          <template>
              <v-autocomplete
                v-model="idSelectedEnterprise"
                :items="enterprises"
                item-text="name"
                item-value="id"
                @change="getEnterpriseUsers()"
                label="Escolha uma empresa"
                id="selectEnterprise"
                class="ml-4 mt-6"
                clearable
                outlined
              ></v-autocomplete>
          </template>
        </div>
      </div>

      <div class="p-3" v-if="selectedEnterprise">
        <div
          class="row row-cols-auto align-items-end"
          v-if="!selectedEnterprise.enterpriseBI"
        >

            <v-row>
              <v-col cols="3">
                <v-menu
                  ref="menuStartDate"
                  v-model="menuStartDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startDateFormatted"
                      label="Data inicial"
                      readonly
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      outlined
                      v-bind="attrs"
                      @blur="startDate = parseDate(startDateFormatted)"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    no-title
                    @input="menuStartDate = false"
                    :min="new Date().toISOString().split('T')[0]"
                    locale="pt-br"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="3">
                <v-menu
                  ref="menuFinishDate"
                  v-model="menuFinishDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="finishDateFormatted"
                      label="Data final"
                      readonly
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      @blur="finishDate = parseDate(finishDateFormatted)"
                      v-on="on"
                      :disabled="!startDate"
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="finishDate"
                    no-title
                    @input="menuFinishDate = false"
                    :min="new Date().toISOString().split('T')[0]"
                    locale="pt-br"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="4">
                <button
                  class="btn btn-dark btn-lg py-3"
                  @click="activeOrInactiveBI"
                  :disabled="startDate === '' || finishDate === ''"
                >
                  Ativar BI
                </button>
              </v-col>
            </v-row>
        </div>
        <div v-if="selectedEnterprise.enterpriseBI">
          <div
            class="row row-cols-auto justify-content-between align-items-center active-menu"
          >
            <div class="col col-8 input-group d-flex flex-row align-items-center">
              <span>Período de Ativação: {{ formatStringDate }}</span>
              <v-switch
                v-model="selectedEnterprise.enterpriseBI.active"
                @change="activeOrInactiveBI"
                :label="selectedEnterprise.enterpriseBI.active ? 'Ativo' : 'Inativo'"
                color="#000000"
                :disabled="isBIExpired"
                class="ml-4 mt-4"
              ></v-switch>
            </div>

            <div class="col col-4 d-flex justify-content-end align-items-center">
              <button class="btn btn-custom-yellow" @click="openModal">
                <i class="fas fa-edit"></i>
                Atualizar período de Ativação
              </button>
            </div>
            <small
              class="col-md-12 d-flex justify-content-end small-info"
              style="color: red"
              v-if="isBIExpired"
              >* ATENÇÃO: Precisa atualizar o "período de ativação" para ativar o BI
            </small>
          </div>

          <div class="col-md-12" v-if="viewListUser">
            <div class="form-group col-md-12">
              <v-card>
                <v-card-title>
                  Usuário
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Pesquisar"
                    outlined
                    hide-details
                  ></v-text-field>
                </v-card-title>

                <v-data-table
                  :headers="tableHeaders"
                  :items="usersItemTable"
                  :footer-props="footerProps"
                  :loading="loading"
                  ref="dataTable"
                  :search="search"
                  :items-per-page="5"
                >
                  <template v-slot:[`item.active`]="{ item }">
                    <v-switch
                      v-model="item.active"
                      @change="addOrRemoveAccess(item)"
                      color="#000000"
                    ></v-switch>
                  </template>
                </v-data-table>
              </v-card>
            </div>

            <div class="input-group col-12 justify-content-center">
              <button
                class="btn btn-dark btn-lg btn-block col-6"
                @click="updateUsersAccess"
              >
                Atualizar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-col cols="auto">
      <v-dialog max-width="600" v-model="openActivationPeriodDialogVisible">
        <v-card>
          <v-toolbar color="#000e17" dark elevation="0">
            Deseja alterar a data de término do BI?
          </v-toolbar>
          <v-card-text class="mt-10">
            <v-row>
              <v-col cols="6">
                <v-menu
                  ref="menuStartDateUpdated"
                  v-model="menuStartDateUpdated"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startDateFormatted"
                      label="Data inicial"
                      readonly
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      @blur="startDate = parseDate(startDateFormatted)"
                      v-on="on"
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    no-title
                    @input="menuStartDateUpdated = false"
                    :min="new Date().toISOString().split('T')[0]"
                    locale="pt-br"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="6">
                <v-menu
                  ref="menuStartDate"
                  v-model="menuFinishDateUpdated"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="finishDateFormatted"
                      label="Data final"
                      readonly
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      :disabled="!startDate"
                      @blur="finishDate = parseDate(finishDateFormatted)"
                      v-on="on"
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="finishDate"
                    no-title
                    @input="menuFinishDateUpdated = false"
                    :min="new Date().toISOString().split('T')[0]"
                    locale="pt-br"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn depressed color="error" @click="openActivationPeriodDialogVisible = false"
              >Cancelar</v-btn
            >
            <v-btn depressed color="primary" :disabled="startDate === null || finishDate === null" @click="updateActivationPeriod">Editar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </div>
</template>

<script>
import DefaultMixin from "../../mixins/DefaultMixin";
import ManageMixin from "../../mixins/ManageMixin";

import EnterpriseService from "../../services/EnterpriseService";
import AccountService from "../../services/UserService";
import EnterpriseBIService from "../../services/EnterpriseBIService";
import AccountEnterpriseBIService from "../../services/AccountEnterpriseBIService";

export default {
  name: "ManageAccessBI",
  mixins: [DefaultMixin, ManageMixin],
  data() {
    return {
      enterpriseService: EnterpriseService.build(),
      accountService: AccountService.build(),
      enterpriseBIService: EnterpriseBIService.build(),
      accountEnterpriseBIService: AccountEnterpriseBIService.build(),

      enterprises: [],
      users: [],
      listUsersUpdate: [],
      usersItemTable: [],
      viewListUser: false,
      selectedEnterprise: null,
      idSelectedEnterprise: null,
      footerProps: {
        "items-per-page-options": [5, 10, 15, 30],
        "items-per-page-text": "Usuários por página",
      },
      pickerOptions: {
        disabledDate(time) {
          let today = new Date();
          today.setHours(0, 0, 0, 0);
          return time.getTime() < today.getTime();
        },
      },
      pickerOptionsDialogFinish: {
        disabledDate(time) {
          let today = new Date();
          today.setHours(0, 0, 0, 0);
          return time.getTime() < today.getTime();
        },
      },
      tableHeaders: [
        { text: "ID", align: "start", sortable: true, value: "id" },
        { text: "Email", align: "start", sortable: true, value: "email" },
        { text: "Nome", align: "start", sortable: true, value: "name" },
        {
          text: "Sobrenome",
          align: "start",
          sortable: true,
          value: "last_name",
        },
        { text: "Status", align: "start", sortable: true, value: "active" },
      ],
      search: "",
      openActivationPeriodDialogVisible: false,
      startDate: "",
      finishDate: "",
      menuStartDate: false,
      menuStartDateUpdated: false,
      menuFinishDate: false,
      menuFinishDateUpdated: false,
      dropdownItems: [
        { title: "Gerenciar acessos externos", redirect: "ManageAccessBI" },
        { title: "Gerenciar painéis", redirect: "ManageAccountPanel" },
      ],
      offset: true,
      successTitle: "",
    };
  },
  methods: {
    openModal() {
      this.startDate = this.selectedEnterprise?.enterpriseBI?.startIn?.split('T')[0];
      this.finishDate = this.selectedEnterprise?.enterpriseBI?.finishIn?.split('T')[0];
      this.openActivationPeriodDialogVisible = true;
    },
    resetVariables() {
      this.viewListUser = this.selectedEnterprise?.enterpriseBI?.active;
      this.listUsersUpdate = [];
      this.users = [];
      this.search = "";
      this.startDate = this.selectedEnterprise?.enterpriseBI?.startIn;
      this.finishDate = this.selectedEnterprise?.enterpriseBI?.finishIn;
    },
    async getAllEnterprises() {
      this.enterprises = await this.enterpriseService.findAllEnterprisesWithBI();
    },
    async getEnterpriseUsers() {
      if (this.idSelectedEnterprise) {
        this.selectedEnterprise = this.enterprises.filter(
          (enterprise) => enterprise.id === this.idSelectedEnterprise
        )[0];
        this.loading = true;
        this.resetVariables();

        this.users = await this.accountService.findUsersByEnterpriseID({
          id: this.selectedEnterprise.id,
        });
        this.usersItemTable = this.users.map((user) => {
          return {
            id: user.id,
            email: user.email,
            name: this.capitalize(user.name),
            last_name: this.capitalize(user.last_name),
            active: user?.accountEnterpriseBi?.active || false,
          };
        });
        this.loading = false;
      }
    },
    async activeOrInactiveBI() {
      let title = `Deseja liberar o BI para a empresa ${this.selectedEnterprise.name}?`;
      let text = `Ao confirmar, a empresa ${this.selectedEnterprise.name} terá acesso ao BI!`;
      this.successTitle = `BI liberado com sucesso para a empresa ${this.selectedEnterprise.name}!`;

      if (this.selectedEnterprise.enterpriseBI) {
        if (this.selectedEnterprise.enterpriseBI.active) {
          title = `Deseja liberar o BI para a empresa ${this.selectedEnterprise.name}?`;
        } else {
          title = `Deseja bloquear o BI para a empresa ${this.selectedEnterprise.name}?`;
          text = `Ao confirmar, a empresa ${this.selectedEnterprise.name} não terá acesso ao BI!`;
          this.successTitle = `BI bloqueado com sucesso para a empresa ${this.selectedEnterprise.name}!`;
        }

        this.selectedEnterprise.enterpriseBI.updatedBy = this.getUserIdLogin;
      }

      this.confirmationDialog({
        title: title,
        text: text,
      }).then((result) => {
        if (result.isConfirmed) {
          if (this.selectedEnterprise.enterpriseBI) {
            this.saveBI(this.selectedEnterprise.enterpriseBI);
          } else {
            const dateFrom = this.startDate.split('-').join('/');
            const dateTo = this.finishDate.split('-').join('/');
            const enterpriseBI = {
              enterpriseId: this.selectedEnterprise.id,
              active: true,
              startIn: dateFrom,
              finishIn: dateTo,
              createdBy: this.getUserIdLogin,
            };
            this.saveBI(enterpriseBI);
          }
        } else {
          this.selectedEnterprise.enterpriseBI.active = !this.selectedEnterprise
            .enterpriseBI.active;
        }
      });
    },
    saveBI(enterpriseBI) {
      this.enterpriseBIService
        .saveBI(enterpriseBI)
        .then(() => {
          this.viewListUser = this.selectedEnterprise?.enterpriseBI?.active;
          this.alertSuccess({ title: this.successTitle });

          this.getAllEnterprises()
            .then(() => {
              let actualEnterprise = this.enterprises.filter(
                (enterprise) => enterprise.name === this.selectedEnterprise.name
              );
              this.selectedEnterprise = actualEnterprise[0];

              this.getEnterpriseUsers().then().catch(this.errorHandler);
            })
            .catch(this.errorHandler);
        })
        .catch(this.errorHandler);
    },
    async updateUsersAccess() {
      if (this.listUsersUpdate.length > 0) {
        const result = await this.confirmationDialog({
          title: "Você tem certeza?",
          text: `Ao confirmar, a permissão de usuários com acesso ao BI da empresa ${this.selectedEnterprise.name} serão atualizados!`,
          footer: "*** Ao remover usuário este não terá mais acesso ao BI!***",
        });

        if (result.isConfirmed) {
          try {
            await this.accountEnterpriseBIService.saveAccountEnterpriseBi(
              this.listUsersUpdate
            );
            this.reloadDatasLoginUser();
            this.alertSuccess({
              title: `Usuários com acesso ao Bi da empresa ${this.selectedEnterprise.name} atualizados com sucesso!`,
            });
          } catch (err) {
            this.alertError({
              title: "Não foi possível atualizar as permissões do usuário!",
              text: "Tente novamente, se o erro persistir contate o administrador!",
            });
          }
        }
      } else {
        this.alertWarning({
          title: `Não foi identificada nenhuma mudança de usuários`,
        });
      }

      this.listUsersUpdate = [];
      this.getEnterpriseUsers();
    },
    reloadDatasLoginUser() {
      let accountIsTempUser = this.listUsersUpdate.filter(
        (account) => account.accountId === this.getTempUser.id && !account.active
      );
      if (accountIsTempUser.length > 0) {
        this.$store.dispatch("storeJwtTemp", null);
        this.$store.dispatch(
          "storeEnterpriseName",
          this.getFixedLoginDatas.enterpriseName
        );
        this.$store.dispatch(
          "storeEnterpriseLogo",
          this.getFixedLoginDatas.enterpriseLogo
        );
        this.$store.dispatch("storeEnterprise", this.getFixedLoginDatas.enterprise);
        this.$store.dispatch("storeUserTemp", this.$store.getters.account);
        this.$store.dispatch("storeClearTempValues", true);
      }
    },
    addOrRemoveAccess(item) {
      const result = this.listUsersUpdate.filter((user) => user.accountId === item.id);

      if (result.length > 0) {
        const index = this.listUsersUpdate.indexOf(result[0]);
        this.listUsersUpdate.splice(index, 1);
      } else {
        const searchEnterpriseBI = this.users.filter((user) => user.id === item.id);
        if (searchEnterpriseBI.length > 0 && searchEnterpriseBI[0].accountEnterpriseBi) {
          searchEnterpriseBI[0].accountEnterpriseBi.active = item.active;
          searchEnterpriseBI[0].accountEnterpriseBi.updatedBy = this.getUserIdLogin;
          this.listUsersUpdate.push(searchEnterpriseBI[0].accountEnterpriseBi);
        } else {
          this.listUsersUpdate.push({
            accountId: item.id,
            enterpriseBiId: this.selectedEnterprise.enterpriseBI.id,
            active: item.active,
            createdBy: this.getUserIdLogin,
          });
        }
      }
    },
    capitalize(word) {
      if (typeof word !== "string") {
        return "";
      }
      return word.charAt(0).toUpperCase() + word.substr(1);
    },
    backDashboard() {
      this.$router.push({ path: "/dashboard" });
    },
    goToManagerPanels() {
      this.$router.push({ path: "/manage-account-panel" });
    },
    updateActivationPeriod() {
      const currentDate = new Date().toISOString().split("T")[0];
      if (this.finishDate && this.finishDate >= currentDate) {
        this.selectedEnterprise.enterpriseBI.finishIn = this.finishDate
          .split("T")[0]
          .split("-")
          .join("/");
        this.selectedEnterprise.enterpriseBI.startIn =
          this.startDate.slice(0, 10) !=
            this.selectedEnterprise.enterpriseBI.startIn.slice(0, 10) &&
          this.startDate &&
          this.startDate >= currentDate
            ? this.startDate.split("T")[0].split("-").join("/")
            : this.selectedEnterprise.enterpriseBI.startIn;
        this.selectedEnterprise.enterpriseBI.updatedBy = this.getUserIdLogin;

        this.enterpriseBIService
          .saveBI(this.selectedEnterprise.enterpriseBI)
          .then(() => {
            this.alertSuccess({
              title: `Período de ativação do BI para a empresa ${this.selectedEnterprise.name} atualizado com sucesso!`,
            });
            this.getAllEnterprises().then(() => {
              let actualEnterprise = this.enterprises.filter(
                (enterprise) => enterprise.name === this.selectedEnterprise.name
              );
              this.selectedEnterprise = actualEnterprise[0];
            });

            this.openActivationPeriodDialogVisible = false;
          })
          .catch((error) => {
            this.alertError({
              title: `Não foi possível atualizar a data de expiração para a empresa ${this.selectedEnterprise.name}!`,
              text: "Se o erro persistir contate o administrador!",
            });

            this.openActivationPeriodDialogVisible = false;
          });
      } else {
        this.alertWarning({
          title: 'Data de "Término" é obrigatória e deve ser maior ou igual a hoje!',
        });
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [year, month, day]  = date.split("/");
      return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`;
    },
  },
  computed: {
    getUserIdLogin() {
      return Number(this.$store.getters.userId);
    },
    getUserSystemRole() {
      return this.$store.getters.userIsManager;
    },
    formatStringDate() {
      let startDate = this.selectedEnterprise.enterpriseBI.startIn.split("T")[0];
      let fomattedStartDate = startDate.split("-").reverse().join("/");
      let endDate = this.selectedEnterprise.enterpriseBI.finishIn.split("T")[0];
      let fomattedEndDate = endDate.split("-").reverse().join("/");

      return `${fomattedStartDate} até ${fomattedEndDate}`;
    },
    isBIExpired() {
      return this.finishDate &&
        this.finishDate.split("T")[0] < new Date().toISOString().split("T")[0]
        ? true
        : false;
    },
    getTempUser() {
      return this.$store.getters.userTemp;
    },
    getFixedLoginDatas() {
      return this.$store.getters.fixedLoginDatas;
    },
  },
  async mounted() {
    await this.inactiveEnterpriseBI();
    await this.getAllEnterprises();
  },
  watch: {
    startDate() {
      this.startDateFormatted = this.formatDate(this.startDate)
    },
    finishDate() {
      this.finishDateFormatted = this.formatDate(this.finishDate)
    },
    idSelectedEnterprise() {
      if(!this.idSelectedEnterprise) this.viewListUser = false;
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.getters.userIsManager) {
        next();
      } else {
        next({ path: "/dashboard" });
      }
    });
  },
};
</script>

<style lang="scss" scoped>
[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  background-color: #23272b;
  border-radius: 5px;
  color: #ffffff;
  font-size: 0.8rem;
  position: absolute;
  padding: 1px 5px 2px 5px;
  bottom: -2.2em;
  left: -100%;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  border: 1px solid #111111;
  z-index: 99999;
  visibility: hidden;
}

[data-title] {
  position: relative;
}

.container {
  -webkit-box-shadow: 5px 5px 14px -8px #000000;
  box-shadow: 5px 5px 14px -8px #000000;
  border-radius: 5px;
  border: 1px solid #ccc;
  border-radius: 2rem !important;
}

.active-menu {
  border-radius: 5px;
  border: 1px solid #ccc;
  max-width: 95%;
  margin: 0 auto;
}

.el-dialog__body div.d-flex {
  gap: 10px;
}

.small-info {
  margin-top: -32px;
}
</style>
