<template>
  <module-table-list-data
    :module-data="moduleData"
    :editMode="editMode"
    :loading="loading"
    :tableHeaders="tableHeaders"
    :tableItems="tableItems"
    :tableHeadersExcel="tableHeaders"
    :typePanelPDF="'TABLE'"
    :can-select-rows="true"
    @selected-rows="changedSelectedRows"
    :error="error"
    id="products"
  />
</template>

<script>
import MixinModuleBase from "../../../mixins/MixinModuleBase";
import ModuleTableListData from "../base/ModuleTableListData.vue";

import ProductService from "../../../services/ProductService";
const productService = ProductService.build();

export default {
  name: "module-products",
  mixins: [MixinModuleBase],
  components: {
    ModuleTableListData,
  },
  data: function () {
    return {
      tableHeaders: [
        {
          text: "Empreendimento",
          align: "start",
          sortable: true,
          value: "name",
          tooltip: "Nome do emprendimento",
        },
        {
          text: "1ª V",
          align: "start",
          sortable: true,
          value: "contactsFirstVisit",
          tooltip: "Total de Primeira Visita",
        },
        {
          text: "VE",
          align: "start",
          sortable: true,
          value: "contactsSpontaneous",
          tooltip: "Total de Visitas Espontâneas",
        },
        {
          text: "IC",
          align: "start",
          sortable: true,
          value: "contactsIndication",
          tooltip: "Total de Indicações",
        },
        {
          text: "R",
          align: "start",
          sortable: true,
          value: "contactsReturn",
          tooltip: "Total de Retornos",
        },
        {
          text: "TEL",
          align: "start",
          sortable: true,
          value: "contactsTelephone",
          tooltip: "Total de Telefonemas",
        },
        {
          text: "FE",
          align: "start",
          sortable: true,
          value: "contactsOutOfService",
          tooltip: "Total de Fora do Expediente",
        },
        {
          text: "CW",
          align: "start",
          sortable: true,
          value: "contactsWhatsapp",
          tooltip: "Total de Contatos WhatsApp",
        },
        {
          text: "TT",
          align: "start",
          sortable: true,
          value: "contactsTotal",
          tooltip: "Total de Contatos",
        },
      ],
      tableItems: [],
    };
  },
  computed: {
    getViewProducts() {
      return this.$store.getters.viewProducts;
    },
  },
  methods: {
    resetModule() {
      this.tableItems = [];
    },
    changedSelectedRows(payload) {
      this.$root.$emit("products-selected", payload);
    },
    getData() {
      this.resetModule();

      this.loading = true;

      productService
        .getAvailableProductsWithTotalContacts(
          this.filterDateRange && this.filterDateRange?.length == 2
            ? {
                dateBegin: this.filterDateRange[0],
                dateEnd: this.filterDateRange[1],
                choosen: this.getViewProducts,
              }
            : {}
        )
        .then((result) => {
          this.resetModule();
          this.tableItems = result;
          this.loading = false;
        })
        .catch(this.errorHandler);
    },
  },
  watch: {
    getViewProducts() {
      this.getData();
    },
  },
};
</script>

<style scoped>
#products {
  background-color: #fff;
}
</style>
