<template>
  <mobule-base-builder
    :module-data="moduleData"
    :editMode="editMode"
    :loading="loading"
    :error="error"
    :downloadImage="dataDownload"
    :tableHeaders="tableHeaders"
    :tableItems="tableItems"
    :tableHeadersExcel="tableHeaders"
    :typePanelPDF="'TABLE'"
  >
    <template v-slot:content>
      <module-table-list-data-v-2
        :tableHeaders="tableHeaders"
        :tableItems="tableItems"
        :footerPropsItemsPerPageText="footerPropsItemsPerPageText"
        class="products-selected"
        v-if="tableItems.length > 0"
      />
      <message-status-data
        v-else
        :selected-products="selectedProductsIds()"
        :infoLoading="loading"
      />
    </template>
  </mobule-base-builder>
</template>

<script>
import MixinModuleBase from "../../../mixins/MixinModuleBase";
import MobuleBaseBuilder from "../base/MobuleBaseBuilder.vue";
import ModuleTableListDataV2 from "../base/ModuleTableListDataV2.vue";
import MessageStatusData from "../../MessageStatusData.vue";

import ProductService from "../../../services/ProductService";
const productService = ProductService.build();

export default {
  name: "module-products-selected",
  mixins: [MixinModuleBase],
  components: {
    MobuleBaseBuilder,
    ModuleTableListDataV2,
    MessageStatusData,
  },
  data: function () {
    return {
      tableHeaders: [
        {
          text: "Empreendimento",
          align: "start",
          sortable: true,
          value: "name",
          tooltip: "Nome do emprendimento",
        },
        {
          text: "1ª V",
          align: "start",
          sortable: true,
          value: "contactsFirstVisit",
          tooltip: "Total de Primeira Visita",
        },
        {
          text: "VE",
          align: "start",
          sortable: true,
          value: "contactsSpontaneous",
          tooltip: "Total de Visitas Espontâneas",
        },
        {
          text: "IC",
          align: "start",
          sortable: true,
          value: "contactsIndication",
          tooltip: "Total de Indicações",
        },
        {
          text: "R",
          align: "start",
          sortable: true,
          value: "contactsReturn",
          tooltip: "Total de Retornos",
        },
        {
          text: "TEL",
          align: "start",
          sortable: true,
          value: "contactsTelephone",
          tooltip: "Total de Telefonemas",
        },
        {
          text: "FE",
          align: "start",
          sortable: true,
          value: "contactsOutOfService",
          tooltip: "Total de Fora do Expediente",
        },
        {
          text: "CW",
          align: "start",
          sortable: true,
          value: "contactsWhatsapp",
          tooltip: "Total de Contatos WhatsApp",
        },
        {
          text: "TT",
          align: "start",
          sortable: true,
          value: "contactsTotal",
          tooltip: "Total de Contatos",
        },
      ],
      tableItems: [],
      footerPropsItemsPerPageText: "Produtos por página",
      dataDownload: {
        class: "products-selected",
        title: this.moduleData.headerTitle,
      },
    };
  },
  computed: {
    getViewProducts() {
      return this.$store.getters.viewProducts;
    },
  },
  methods: {
    resetModule() {
      this.tableItems = [];
    },
    getData() {
      this.resetModule();

      if (this.dataRetrievalOk()) {
        this.loading = true;

        productService
          .getAvailableProductsWithTotalContacts(
            this.filterDateRange && this.filterDateRange.length == 2
              ? {
                  dateBegin: this.filterDateRange[0],
                  dateEnd: this.filterDateRange[1],
                  choosen: this.getViewProducts,
                  selectedProductsIds: this.selectedProductsIds(),
                }
              : {}
          )
          .then((result) => {
            this.resetModule();
            this.tableItems = result;
            this.loading = false;
          })
          .catch(this.errorHandler);
      }
    },
  },
};
</script>

<style scoped>
.products-selected {
  background-color: #fff;
}
</style>
