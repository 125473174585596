<template>
  <div id="home">
    <header class="container-header" :style="{ top: showHeader ? '0' : '-60px' }">
      <div class="system-info-container">
        <h2 class="smarts-bi-title">Smarts BI</h2>
        <span class="powered-home">&copy; Powered by Smart Staff</span>
      </div>

      <div class="enterprise-info-container">
        <div
          class="enterprise-image-container"
          :style="{
            'background-image': 'url(' + getPathImageEnterprise() + ')',
          }"
        ></div>
      </div>

      <div class="buttons-container" data-html2canvas-ignore="true">
        <div class="container-nav-options">
          <template>
            <v-btn
              @click="handleEditMode"
              type="primary"
              class="btn-custom-white mr-1"
              v-show="isAtDashboard"
            >
              <span class="font-weight-bold">
                <i
                  class="fas mr-1"
                  :class="{
                    'fa-lock-open': getEditMode,
                    'fa-lock': !getEditMode,
                  }"
                ></i>
                {{ getEditMode ? "Travar Dashboard" : "Destravar Dashboard" }}
              </span>
            </v-btn>

            <v-btn
              v-show="getEditMode && isAtDashboard"
              @click="openAddPanel"
              type="success"
              class="btn-custom-white"
            >
              <span class="font-weight-bold">
                <i class="fas fa-plus-circle mr-1"></i>
                Adicionar Painel
              </span>
            </v-btn>
          </template>
          <router-link
            :to="{ name: 'Dashboard' }"
            class="btn font-weight-bold btn-custom-white"
            tag="button"
            v-show="!isAtDashboard"
          >
            <i class="fas fa-arrow-alt-circle-left mr-1"></i>
            Voltar Para o Dashboard
          </router-link>

          <template>
            <v-btn
              @click="openManagerDashboardsModal = true"
              type="success"
              class="btn-custom-white"
              v-show="isAtDashboard && userIsManager"
            >
              <span class="font-weight-bold">
                <i class="fas fa-users-cog mr-1"></i>
                Usuário Dashboard
              </span>
            </v-btn>

            <v-menu bottom :offset-y="offset">
              <template v-slot:activator="{ on, attrs }" v-if="userIsManager">
                <v-btn
                  elevation="0"
                  v-bind="attrs"
                  v-on="on"
                  class="btn-custom-white font-weight-bold"
                  style="text-transform: none"
                >
                  <v-icon class="icon" :small="true"> fas fa-cog </v-icon>

                  <span class="px-1"> Configurações </span>

                  <v-icon class="icon mr-1" :small="true"> fas fa-angle-down </v-icon>
                </v-btn>
              </template>

              <v-list class="p-2">
                <v-list-item v-for="(item, index) in dropdownItems" :key="index" link>
                  <router-link :to="{ name: item.redirect }" tag="p" class="router-link">
                    {{ item.title }}
                  </router-link>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>

          <router-link
            :to="{ name: 'MultipleEnterprise' }"
            class="btn font-weight-bold btn-custom-white"
            tag="button"
            v-show="isAtDashboard && !userIsManager && getMultipleEnterprise?.length > 1"
          >
            <i class="fas fa-arrow-alt-circle-left mr-1"></i>
            Escolher outra empresa
          </router-link>

          <v-btn @click="loggout" type="success" class="btn-custom-white m-0">
            <span class="font-weight-bold">
              <i class="fas fa-sign-out-alt mr-1"></i>
              Sair
            </span>
          </v-btn>

          <v-tooltip bottom v-show="userIsManager && isAtDashboard">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon color="white light"> mdi mdi-account-question</v-icon>
              </v-btn>
            </template>

            <div class="infos-container">
              <span>
                <strong class="destak">Usuário Atual: </strong>
                {{ getUserTemp.name }} {{ getUserTemp.last_name }}
              </span>

              <span>
                <strong class="destak">Empresa Atual: </strong>
                {{ getEnterpriseTemp }}
              </span>
            </div>
          </v-tooltip>
        </div>
      </div>
    </header>

    <keep-alive v-if="$route.meta.keepAlive">
      <router-view
        ref="routerView"
        :reload="reload"
        :viewMenu="showHeader"
        :openAddPanel="openAddPanel"
      ></router-view>
    </keep-alive>
    <router-view ref="routerView" v-else></router-view>

    <!-- Add panel modal -->
    <v-col cols="auto">
      <v-dialog v-model="addDialogVisible" width="50%" @close="closeAddPanel" center>
        <v-card>
          <v-toolbar color="#000e17" dark elevation="0">
            <h5 class="m-0">Escolher um painel</h5>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon color="grey light"> fas fa-info-circle </v-icon>
                </v-btn>
              </template>

              <span>
                Selecione um painel para adicionar de forma específica.<br />
                E para adicionar todos, apenas não selecione nenhum painel e clique em
                "Adicionar Todos".
              </span>
            </v-tooltip>
          </v-toolbar>

          <v-autocomplete
            v-model="addPanelType"
            :items="availablePanelsByTag"
            item-text="title"
            item-value="id"
            label="Escolha um painel"
            class="ml-4 mr-4 mt-4"
            clearable
            dense
            outlined
          >
            <template v-slot:item="data">
              <template v-if="data.item?.color">
                <v-list-item :disabled="true" class="justify-start">
                  <v-badge
                    :content="data.item.name"
                    :color="data.item.color"
                    inline
                    class="justify-start"
                  ></v-badge>
                </v-list-item>
              </template>

              <template v-else>
                <v-list-item-content>
                  <v-list-item-title> {{ data.item.title }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>

          <v-card-actions class="justify-end">
            <v-btn color="error" @click="closeAddPanel">Cancelar</v-btn>

            <v-btn color="success" @click="addPanelToDashboard">{{
              addPanelType ? "Adicionar" : "Adicionar Todos"
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>

    <!-- Manager dashboards modal -->
    <ModalManageDashboard
      :centerDialogVisible="openManagerDashboardsModal"
      @closeModal="closeModal"
      @changeLogoImage="getPathImageEnterprise"
    />
  </div>
</template>

<script>
import DefaultMixin from "../mixins/DefaultMixin";

import ModalManageDashboard from "../components/manage-dashboard/ModalManageDashboard.vue";

import PanelService from "../services/PanelService";
import EnterpriseService from "../services/EnterpriseService";
import EnterpriseNameLogoMixin from "../mixins/EnterpriseNameLogoMixin";

export default {
  mixins: [DefaultMixin, EnterpriseNameLogoMixin],
  components: {
    ModalManageDashboard,
  },
  data: function () {
    return {
      panelService: PanelService.build(),
      enterpriseService: EnterpriseService.build(),

      addDialogVisible: false,
      addPanelType: [],
      openManagerDashboardsModal: false,

      availablePanels: [],

      availablePanelsByTag: [],

      reload: false,

      userId: this.$store.getters.userId,
      dropdownItems: [
        { title: "Gerenciar acessos externos", redirect: "ManageAccessBI" },
        {
          title: "Gerenciar acessos internos",
          redirect: "ManageInternalUsersAccessBI",
        },
        { title: "Gerenciar painéis", redirect: "ManageAccountPanel" },
        { title: "Gerenciar Envio de Relatórios", redirect: "ManageEmailReports" },
      ],
      offset: true,
      showHeader: true,
    };
  },
  computed: {
    userIsManager() {
      return this.$store.getters.userIsManager;
    },
    getEnterpriseTemp() {
      return this.capitalize(this.$store.getters.enterpriseName);
    },
    getUserTemp() {
      return this.$store.getters.userTemp;
    },
    isAtDashboard() {
      return this.$route.name === "Dashboard";
    },
    getEnterpriseLogo() {
      return this.$store.getters.enterpriseLogo;
    },
    getMultipleEnterprise() {
      return this.$store.getters.multipleEnterprise;
    },
    getEditMode() {
      return this.$store.getters.editMode;
    },
    getReloadDashboard() {
      return this.$store.getters.reloadDashboard;
    },
  },
  methods: {
    openAddPanel() {
      this.addDialogVisible = true;
      this.getAvailablePanels();
    },
    closeAddPanel() {
      this.addDialogVisible = false;
      this.addPanelType = "";
    },
    getAvailablePanels() {
      this.availablePanels = [];
      this.availablePanelsByTag = [];

      this.panelService
        .findAllAvailableById()
        .then((panelsById) => {
          this.$store.commit("changeUserPanelsById", panelsById);

          this.availablePanelsByTag = [];
          let tagObj = {};
          Object.values(panelsById).forEach((item) => {
            if (!tagObj[item.tagId]) {
              tagObj[item.tagId] = {
                tag: item.tag,
                panels: [],
              };
            }

            let formattedPanel = this.formatPanel(item);

            tagObj[item.tagId].panels.push(formattedPanel);
            this.availablePanels.push(formattedPanel);
          });

          Object.values(tagObj).forEach((item, index, arr) => {
            this.availablePanelsByTag.push(item.tag);
            this.availablePanelsByTag.push(...item.panels);
            if (index + 1 < arr?.length)
              this.availablePanelsByTag.push({ divider: true });
          });
        })
        .catch(this.errorHandler);
    },

    addPanelToDashboard() {
      this.addDialogVisible = false;
      if (!this.addPanelType) {
        if (this.availablePanels?.length > 0) {
          this.addPanelType = [...this.availablePanels];
        } else {
          this.alertWarning({
            title: "Atenção",
            text: "Usuário sem painéis liberados!",
          });
        }
      }
      this.$refs.routerView[this.$route.meta.addPanelToDashboard.methodName](
        this.addPanelType
      );
      this.addPanelType = "";
    },

    getFileRequired(fileName) {
      const useStaffLogo = [
        "/manage-account-panel",
        "/manage-access-bi",
        "/manage-internal-users-access-bi",
        "/manage-email-reports",
        "/manage-send-reports",
        "/manage-resend-reports",
      ];
      fileName = fileName ? fileName : "staff.png";
      if (fileName === "staff.png" || useStaffLogo.includes(this.$route.path)) {
        return require(`../../public/assets/images/${fileName}`);
      }
      return `https://s3-sa-east-1.amazonaws.com//smart-play/medias/medium-${fileName}`;
    },
    getPathImageEnterprise() {
      try {
        return this.getFileRequired(this.getEnterpriseLogo);
      } catch (error) {
        return this.getFileRequired();
      }
    },

    async loggout() {
      await this.saveAccessHistory("logout");
      localStorage.clear();
      document.cookie =
        "PLAY_BISESSION=" + "" + ";expires=Thu, 01 Jan 1970 00:00:01 GMT" + "; path=/";
      document.cookie =
        "EXT=" + "" + ";expires=Thu, 01 Jan 1970 00:00:01 GMT" + "; path=/";
      this.$router.go({ name: "Login" });
    },
    closeModal(data) {
      this.openManagerDashboardsModal = data;
    },
    capitalize(word) {
      if (typeof word !== "string") {
        return "";
      }
      return word.charAt(0).toUpperCase() + word.substr(1);
    },
    fetchMultipleEnterprise() {
      this.enterpriseService
        .findByAccountId()
        .then(async (data) => {
          if (data?.length > 1 && !this.userIsManager) {
            await this.$store.dispatch("storeMultipleEnterprise", data);
          }
        })
        .catch(this.errorHandler);
    },
    showElementOnScroll2() {
      if (this.$route.name === "Dashboard") {
        let y = window.scrollY;
        if (y >= 30) {
          this.showHeader = false;
        } else {
          this.showHeader = true;
        }
      }
    },
    handleEditMode() {
      this.$store.dispatch("storeEditMode", !this.getEditMode);
    },
  },
  async mounted() {
    this.fetchMultipleEnterprise();
    this.getAvailablePanels();
    await this.getEnterpriseByDBName();
    this.$store.dispatch("storeFixedLoginDatas", {
      enterprise: this.$store.getters.enterprise,
      enterpriseName: this.$store.getters.enterpriseName,
      enterpriseLogo: this.$store.getters.enterpriseLogo,
    });

    window.addEventListener("scroll", this.showElementOnScroll2);
  },
  watch: {
    $route(to, from) {
      if (from.name === "ManageAccountPanel" && to.name === "Dashboard") {
        this.getAvailablePanels();
      }
    },
    getReloadDashboard() {
      this.openManagerDashboardsModal = false;
      if (this.getReloadDashboard) {
        this.getAvailablePanels();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-menu__content {
  z-index: 2020 !important;
}

#home {
  background-color: inherit;

  header {
    display: grid;
    grid-template-columns: 0.8fr 0.6fr 3fr;
    grid-template-rows: 60px;
    z-index: 2020;
    min-width: 100%;

    background-color: var(--smarts-black);

    .system-info-container {
      color: var(--smarts-white);
      padding: 0.5rem;
      text-align: center;

      .smarts-bi-title {
        font-weight: bold;
        font-size: 1.6rem;
        margin: 0;
      }

      .powered-home {
        color: var(--smarts-white);
        display: flex;
        justify-content: center;
        font-size: 0.8rem;
      }
    }

    .enterprise-info-container {
      display: flex;
      background-color: var(--smarts-black);

      .enterprise-image-container {
        background-size: contain;
        background-position: 50%;
        min-width: 100%;
      }

      span {
        height: 12px;
        font-size: 0.8rem;
        padding: 2px 0 0 5px;
        font-weight: bold;
        color: var(--smarts-white);
      }

      strong {
        color: var(--smarts-white);
      }
    }

    .buttons-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      min-width: 100%;
      min-height: 100%;
      padding: 6px;

      .container-nav-options {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        min-width: 100%;
        min-height: 100%;
      }

      .btn-custom-white {
        background-color: transparent !important;
        border-style: none !important;
        color: var(--smarts-white);
        transition-duration: 0.5s !important;
        font-size: 14px;
        transition-property: inherit !important;
        transition-timing-function: inherit !important;
        padding: 0;
        outline: var(--smarts-black);

        &:hover {
          background-color: var(--smarts-white) !important;
          color: var(--smarts-gray-dark) !important;
          height: 36px;
          padding: 0 1rem;
        }
      }
    }
  }
}

.infos-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-width: 400px;

  .destak {
    font-size: 1rem;
  }
}

.v-list-item {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0 !important;

  .router-link {
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    min-width: 100%;
  }
}
</style>
