<template>
    <div class="error">
        <div>
            <div class="container-image">
                <img src="../../public/assets/images/error.png" alt=""><br/>
            </div>
            <div class="container-message">
                <span>404</span>
                <span>Página não encontrada</span>
            </div>
            <div class="container-link">
                <a href="/">Clique aqui para voltar para o inicio</a>
            </div>
        </div>
    </div>
</template>

<script >
export default {
    name: 'PageNotFound',
    data() {
        return {}
    }
}
</script>

<style lang="scss" scoped>

.error{
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
    min-height: 100%;
    background-color: rgb(212, 63, 63);
}

.container-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
        font-size: 50px;
        color: #FFFFFF;
        margin: 0;
    }   
}

.container-link{
    display: flex;
    align-items: center;
    justify-content: center;

    a {
        font-size: 20px;
        color: #FFFFFF;
    }
}


.container-image { 
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        filter: invert(100%);
        width: 100px;
        height: 100px;  
    }
}
</style>
