<template>
  <div id="public-view">
    <div id="public-view-content" class="p-2">
      <img src="../../public/assets/images/staff.png" alt="logo" />

      <router-view></router-view>
      <qrs-code-mobile-stores />
    </div>
  </div>
</template>

<script>
import QRsCodeMobileStores from "../components/QRsCodeMobileStores.vue";

export default {
  name: "Public",
  components: {
    "qrs-code-mobile-stores": QRsCodeMobileStores
  }
};
</script>

<style lang="scss" scoped>
#public-view {
  min-height: 100vh;
  max-height: 100vh;
  background-image: url("../../public/assets/images/background.jpg");
  background-size: 100% 100%;
  position: relative;
  box-sizing: border-box;
  

  #public-view-content {
    
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    max-width: 38%;
    min-width: 38%;
    background-color: var(--smarts-white);
    color: var(--smarts-gray-dark);
    display: grid;
    grid-template-rows: 0.2fr 1fr 0.8fr;
    -webkit-box-shadow: 14px 7px 40px -2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 14px 7px 40px -2px rgba(0, 0, 0, 0.75);
    box-shadow: 14px 7px 40px -2px rgba(0, 0, 0, 0.75);
    border-radius: 0 1rem 1rem 0;
    padding: 0 !important;

    img {
      margin: 0 auto;
      height: 120px;
      width: 60%;
      max-width: 250px;
      border-radius: 1rem;
    }
  }
}
</style>
