import Rest from "./base/Rest";

/**
 * @typedef {RecipientEnterpriseService}
 */
export default class RecipientEnterpriseService extends Rest {
  /**
   * @type {String}
   */
  static resource = "/recipient";

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */

  saveNewRecipient(params = {}) {
    return this.post(`/save`, params);
  }

  updateRecipients(params = []) {
    return this.post(`/updateRecipients`, params);
  }

  findRecipientByEmail(email) {
    return this.get(`/findRecipientByEmail/${email}`);
  }

  findRecipientsByEnterpriseId(enterpriseId) {
    return this.get(`/findRecipientsByEnterpriseId/${enterpriseId}`);
  }

  findRecipientsWithMailingFormatByEnterpriseId(params = {}) {
    return this.get(`findRecipientsWithMailingFormatByEnterpriseId?${this.createQueryString(params)}`)
  }

  updateNameAndEmailById(params = {}) {
    return this.post(
      `/updateNameAndEmailById?${this.createQueryString(params)}`
    );
  }
}
