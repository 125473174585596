var render = function render(){var _vm=this,_c=_vm._self._c;return _c('gmap-map',{ref:"gmap",staticStyle:{"width":"100%","height":"100%"},attrs:{"center":_vm.centerMap,"zoom":_vm.zoom,"options":_vm.options}},[_c('gmap-info-window',{attrs:{"options":_vm.infoOptions,"position":_vm.infoWindowPos,"opened":_vm.infoWinOpen},on:{"closeclick":function($event){_vm.infoWinOpen = false}}}),_vm._l((_vm.infoWindowCircle),function(lc){return _c('gmap-marker',{key:lc.id,attrs:{"position":lc.position,"clickable":false,"draggable":true,"icon":lc.image,"visible":_vm.infoWindowCircleView}})}),_vm._l((_vm.localCircles),function(circle){return _c('gmap-circle',{key:circle.circle.id,ref:"circle",refInFor:true,attrs:{"editable":_vm.editable,"draggable":_vm.draggable,"radius":circle.circle.radius,"center":circle.circle.center,"circleCenter":circle.circle.circleCenter,"clickable":true,"visible":_vm.viewCircle,"options":{
      strokeColor: circle.circle.color,
      strokeOpacity: 1,
      strokeWeight: 4,
      fillColor: circle.circle.color,
      fillOpacity: 0,
    }}})}),(_vm.viewPins)?_c('div',_vm._l((_vm.locations),function(l,index){return _c('gmap-marker',{key:l.id,attrs:{"position":l.position,"clickable":true,"draggable":false,"icon":l.image,"label":l.label},on:{"click":function($event){_vm.toggleStandInfoWindow(l, index), (_vm.center = l.position)}}})}),1):_vm._e(),(_vm.viewCluster)?_c('gmap-cluster',_vm._l((_vm.locations),function(l,index){return _c('gmap-marker',{key:l.id,attrs:{"position":l.position,"clickable":true,"draggable":false,"icon":l.image,"label":l.label},on:{"click":function($event){_vm.toggleStandInfoWindow(l, index), (_vm.center = l.position)}}})}),1):_vm._e(),_vm._l((_vm.standsLocations),function(sl,index){return _c('gmap-marker',{key:index,attrs:{"position":sl.position,"clickable":true,"draggable":false,"icon":sl.image,"title":sl.standName},on:{"click":function($event){_vm.toggleStandInfoWindow(sl, index), (_vm.center = sl.position)}}})}),(_vm.viewHeatMap)?_c('gmap-heatmap-layer',{attrs:{"data":_vm.heatMapMarkers,"options":_vm.heatmapLayerOptions}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }