import ModuleDisplayType from '../utils/enum/moduleDisplayType.enum'

export default {
    data: function () {
        return {
            loading: false,
            error: null,
            whichFunctionRequest: "",
            ModuleDisplayType,
        }
    },

    props: {
        moduleData: Object,
        editMode: {
            type: Boolean,
            default: false
        },
        selectedProducts: {
            type: Array,
            default: () => [],
        },
        filterDateRange: Array,
        typePanel: String
    },

    mounted() {
        this.getData()
    },

    created() {
        this.$root.$on('module-data-changed-' + this.moduleData.id, function (p) {
            this.moduleData = p;
            //this.$forceUpdate();
        })
    },

    watch: {
        selectedProducts: function (n, o) {
            this.$store.dispatch('storeSelectedProducts', this.selectedProducts);
            this.whichFunctionRequest = "selectedProducts"
            this.getData()
            this.whichFunctionRequest = "";
        },
        filterDateRange: function (n, o) {
            this.whichFunctionRequest = "filterDateRange"
            this.getData()
            this.whichFunctionRequest = "";
        }
    },

    methods: {
        isDraggable(value) {
            this.moduleData.isDraggable = value;
            this.$root.$emit('updated-module-data');
        },

        selectedProductsIsNotEmpty() {
            return this.selectedProducts && this.selectedProducts.length > 0;
        },

        filterDateRangeIsNotEmpty() {
            return this.filterDateRange &&
            this.filterDateRange.length == 2 &&
            this.filterDateRange[0].length > 1 &&
            this.filterDateRange[1].length > 1;
        },

        dataRetrievalOk() {
            if ((!this.filterDateRange ||
                this.filterDateRange?.length < 2 ||
                this.filterDateRange?.length == 0) && (
                    this.selectedProducts.length > 0
                )
            ) {
                this.$swal("Atenção!", 'É necessário preencher os campos de "Período dos dados" para buscar as informações!', "warning")
            }

            return this.selectedProductsIsNotEmpty() && this.filterDateRangeIsNotEmpty();
        },

        selectedProductsIds() {
            return this.selectedProducts.map((p) => {
                return parseInt(p.id);
            });
        },

        errorHandler(error) {
            this.loading = false;
            this.error = error;
        }
    }
}
