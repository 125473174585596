<template>
  <mobule-base-builder
    :module-data="moduleData"
    :editMode="editMode"
    :loading="loading"
    :error="error"
    :downloadImage="dataDownload"
    :tableHeaders="tableHeaders"
    :tableItems="tableItems"
    :tableHeadersExcel="tableHeadersExcel"
    :tableItemsExcel="tableItemsExcel"
    :typePanelPDF="moduleData.displayType.chosen"
  >
    <template v-slot:content>
      <module-table-list-data-v-2
        v-if="moduleData.displayType.chosen == 'TABLE' && tableItems.length > 0"
        :tableHeaders="tableHeaders"
        :tableItems="tableItems"
        :footerPropsItemsPerPageText="footerPropsItemsPerPageText"
        class="visit-by-sales-company echarts-default"
      />

      <module-chart-bar
        v-else-if="moduleData.displayType.chosen == 'CHART_BAR' && barSeries.length > 0"
        :legend="legend"
        :labels="labels"
        :series="barSeries"
        :showFilters="false"
        :tooltip="tooltip"
        class="visit-by-sales-company echarts-default"
      />

      <div
        class="container visit-by-sales-company"
        v-else-if="
          moduleData.displayType.chosen == 'CHART_PIZZA' && chartValues.length > 0
        "
        ref="container"
      >
        <div
          class="container-pizza"
          v-for="(chartValue, index) in chartValues"
          :key="index"
        >
          <module-chart-doughnut
            :dataTitle="salesCompaniesName[index]"
            :chartValues="chartValue"
            :dataLegend="legend"
            :ref="'chart' + index"
            class="echarts-pizza"
          />
        </div>
      </div>
      <message-status-data
        v-else
        :selected-products="selectedProductsIds()"
        :infoLoading="loading"
      />
    </template>
  </mobule-base-builder>
</template>

<script>
import SalesCompanyService from "../../../services/SalesCompanyService";
const salesCompanyService = SalesCompanyService.build();

import MobuleBaseBuilder from "../base/MobuleBaseBuilder.vue";
import MixinModuleBase from "../../../mixins/MixinModuleBase";

import ModuleChartBar from "../base/ModuleChartBar.vue";
import ModuleTableListDataV2 from "../base/ModuleTableListDataV2.vue";
import ModuleChartDoughnut from "../base/ModuleChartDoughnut.vue";
import MessageStatusData from "../../MessageStatusData.vue";

export default {
  mixins: [MixinModuleBase],
  name: "module-visits-by-sales-company",

  components: {
    MobuleBaseBuilder,
    ModuleChartBar,
    ModuleTableListDataV2,
    ModuleChartDoughnut,
    MessageStatusData,
  },

  data: function () {
    return {
      chartValues: [],

      barChartData: [],
      barSeries: [],
      salesCompaniesName: [],

      tableHeaders: [
        {
          text: "Empresa de Vendas",
          align: "start",
          sortable: true,
          value: "name",
          tooltip: "Nome da empresa de vendas",
        },
        {
          text: "1ª V",
          align: "start",
          sortable: true,
          value: "firstVisit",
          tooltip: "Total de Primeira Visita",
        },
        {
          text: "IC",
          align: "start",
          sortable: true,
          value: "indicacao",
          tooltip: "Total de Indicações",
        },
        {
          text: "VE",
          align: "start",
          sortable: true,
          value: "espontanea",
          tooltip: "Total de Visitas Espontâneas",
        },
        {
          text: "TEL",
          align: "start",
          sortable: true,
          value: "telefone",
          tooltip: "Total de Telefonemas",
        },
        {
          text: "R",
          align: "start",
          sortable: true,
          value: "retorno",
          tooltip: "Total de Retornos",
        },
        {
          text: "CW",
          align: "start",
          sortable: true,
          value: "whatsapp",
          tooltip: "Total de Contatos WhatsApp",
        },
        {
          text: "TT",
          align: "start",
          sortable: true,
          value: "total",
          tooltip: "Total de Visitas",
        },
      ],
      tableHeadersExcel: [
        {
          text: "PRODUTOS SELECIONADOS",
          align: "start",
          sortable: true,
          value: "selectedProducts",
        },
        {
          text: "EMPRESA DE VENDAS",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "1ª VISITA",
          align: "start",
          sortable: true,
          value: "firstVisit",
        },
        {
          text: "INDICAÇÃO",
          align: "start",
          sortable: true,
          value: "indicacao",
        },
        {
          text: "ESPONTÂNEA",
          align: "start",
          sortable: true,
          value: "espontanea",
        },
        { text: "TELEFONE", align: "start", sortable: true, value: "telefone" },
        { text: "RETORNO", align: "start", sortable: true, value: "retorno" },
        {
          text: "WHATSAPP",
          align: "start",
          sortable: true,
          value: "whatsapp",
        },
        { text: "TOTAL", align: "start", sortable: true, value: "total" },
      ],
      labels: [],
      legend: [],
      tableItems: [],
      tableItemsExcel: [],
      footerPropsItemsPerPageText: "Empresas de Vendas por página",
      dataDownload: {
        class: "visit-by-sales-company",
        title: this.moduleData.headerTitle,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
    };
  },
  computed: {
    getSelectedProducts() {
      return this.$store.getters.selectedProducts;
    },
  },
  methods: {
    resetModule() {
      this.tableItems = [];
      this.tableItemsExcel = [];
      this.barChartData = [];
      this.barSeries = [];
      this.chartValues = [];
      this.salesCompaniesName = [];
    },
    getData() {
      this.resetModule();

      if (this.dataRetrievalOk()) {
        this.loading = true;

        this.legend = [
          "1ª VISITA",
          "INDICAÇÃO",
          "ESPONTÂNEA",
          "TELEFONE",
          "RETORNO",
          "WHATSAPP",
          "TOTAL",
        ];

        salesCompanyService
          .contactsBySalesCompanies({
            selectedProductsIds: this.selectedProductsIds(),
            dateBegin: this.filterDateRange[0],
            dateEnd: this.filterDateRange[1],
          })
          .then((r) => {
            this.resetModule();
            this.convertToChartValues(r);
            this.loading = false;
          })
          .catch(this.errorHandler);
      }
    },

    convertToChartValues(registers) {
      let empresasVendas = [];
      let objDefault = {
        name: "",
        type: "bar",
        emphasis: {
          focus: "series",
        },
        data: [],
        label: {
          show: true,
          rotate: 0,
          position: "top",
          fontWeight: "bold",
          fontSize: 13,
          lineHeight: 20,
          overflow: "truncate",
        },
      };

      if (registers && registers.length > 0) {
        for (let i = 0; i < registers.length; i++) {
          let rawData = {
            name: registers[i]["name"],
            firstVisit: parseInt(registers[i]["contactsFirstVisit"]),
            indicacao: parseInt(registers[i]["contactsIndication"]),
            espontanea: parseInt(registers[i]["contactsSpontaneous"]),
            telefone: parseInt(registers[i]["contactsTelephone"]),
            retorno: parseInt(registers[i]["contactsReturn"]),
            whatsapp: parseInt(registers[i]["contactsWhatsapp"]),
            total: parseInt(registers[i]["contactCount"]),
          };

          let rawDataToBar = {
            firstVisit: parseInt(registers[i]["contactsFirstVisit"]),
            indicacao: parseInt(registers[i]["contactsIndication"]),
            espontanea: parseInt(registers[i]["contactsSpontaneous"]),
            telefone: parseInt(registers[i]["contactsTelephone"]),
            retorno: parseInt(registers[i]["contactsReturn"]),
            whatsapp: parseInt(registers[i]["contactsWhatsapp"]),
            total: parseInt(registers[i]["contactCount"]),
          };

          empresasVendas.push(rawData);

          this.convertToChartPizzaValues(rawData);
          if (this.barSeries.length > 0) {
            this.barSeries[0].data.push(rawDataToBar["firstVisit"]);
            this.barSeries[1].data.push(rawDataToBar["indicacao"]);
            this.barSeries[2].data.push(rawDataToBar["espontanea"]);
            this.barSeries[3].data.push(rawDataToBar["telefone"]);
            this.barSeries[4].data.push(rawDataToBar["retorno"]);
            this.barSeries[5].data.push(rawDataToBar["whatsapp"]);
            this.barSeries[6].data.push(rawDataToBar["total"]);
          } else {
            //Chart Bar
            objDefault.name = this.legend[0];
            objDefault.data.push(rawDataToBar["firstVisit"]);
            this.barSeries.push(JSON.parse(JSON.stringify(objDefault)));
            objDefault.data = [];
            objDefault.name = this.legend[1];
            objDefault.data.push(rawDataToBar["indicacao"]);
            this.barSeries.push(JSON.parse(JSON.stringify(objDefault)));
            objDefault.data = [];
            objDefault.name = this.legend[2];
            objDefault.data.push(rawDataToBar["espontanea"]);
            this.barSeries.push(JSON.parse(JSON.stringify(objDefault)));
            objDefault.data = [];
            objDefault.name = this.legend[3];
            objDefault.data.push(rawDataToBar["telefone"]);
            this.barSeries.push(JSON.parse(JSON.stringify(objDefault)));
            objDefault.data = [];
            objDefault.name = this.legend[4];
            objDefault.data.push(rawDataToBar["retorno"]);
            this.barSeries.push(JSON.parse(JSON.stringify(objDefault)));
            objDefault.data = [];
            objDefault.name = this.legend[5];
            objDefault.data.push(rawDataToBar["whatsapp"]);
            this.barSeries.push(JSON.parse(JSON.stringify(objDefault)));
            objDefault.data = [];
            objDefault.name = this.legend[6];
            objDefault.data.push(rawDataToBar["total"]);
            this.barSeries.push(JSON.parse(JSON.stringify(objDefault)));
          }

          this.labels.push(empresasVendas[i]["name"]);
        }

        const productsSelectedLength = this.getSelectedProducts.length;
        this.tableItems = [];

        if (empresasVendas.length >= productsSelectedLength) {
          empresasVendas.forEach((item, index) => {
            if (index < productsSelectedLength) {
              this.tableItems.push({ ...item });
              this.tableItemsExcel.push({
                selectedProducts: this.getSelectedProducts[index]?.name,
                ...item,
              });
            } else {
              this.tableItems.push({ ...item });
              this.tableItemsExcel.push({
                selectedProducts: "",
                ...item,
              });
            }
          });
        } else {
          this.getSelectedProducts.forEach((product, index) => {
            const item = empresasVendas[index] ? empresasVendas[index] : "";
            if (item) {
              this.tableItems.push({ ...item });
              this.tableItemsExcel.push({
                selectedProducts: product?.name,
                ...item,
              });
            } else {
              this.tableItemsExcel.push({
                selectedProducts: product?.name,
              });
            }
          });
        }
      }
    },
    convertToChartPizzaValues(data) {
      let visitsValues = [];
      for (const key in data) {
        if (key !== "name" && key !== "total" && key !== "firstVisit") {
          visitsValues.push({
            value: data[key],
            name: this.changeNameValues(key),
          });
        } else if (key === "name") {
          this.salesCompaniesName.push(
            data[key] +
              ` - ( Total de Visitas: ${data["total"]} | Total de 1º Visita: ${data["firstVisit"]} )`
          );
        }
      }
      this.chartValues.push(visitsValues);
    },
    changeNameValues(name) {
      switch (name) {
        case "firstVisit":
          name = "1ª VISITA";
          break;
        case "indicacao":
          name = "INDICAÇÃO";
          break;
        case "espontanea":
          name = "ESPONTÂNEA";
          break;
        case "retorno":
          name = "RETORNO";
          break;
        case "whatsapp":
          name = "WHATSAPP";
          break;
        case "telefone":
          name = "TELEFONE";
          break;
      }

      return name;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  min-width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  min-height: 100%;
  max-height: 100%;
  background-color: #fff !important;
  padding-bottom: 50px;

  .container-pizza {
    min-height: 300px;
    max-height: 320px;
    min-width: 550px;
    max-width: 550px;
    border: 1px solid #ccc;

    span {
      min-height: 0;
      min-width: 0;
      max-width: 0;
      border: none;
      margin: 0;
    }
  }
}

.visit-by-sales-company {
  background-color: #fff;
}

.echarts-pizza {
  width: 100% !important;
  height: 20rem !important;
  position: relative !important;
}

.echarts-default {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
