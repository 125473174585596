<template>
  <div>
    <div class="container bg-white col-md-10 my-6">
      <div class="form-group col-12 row">
        <div class="col col-12 d-flex align-items-center flex-direction-row">
          <legend class="m-0">Gerenciar reenvio de relatórios</legend>
        </div>

        <div class="col col-4 d-flex align-items-center m-0">
          <div class="custom-control">
            <v-switch
              v-model="selectUnifiedReport"
              color="#000000"
              :label="'Relatório Unificado'"
              @change="handleSelectUnifiedReport"
              class="mt-5"
            >
            </v-switch>
          </div>
        </div>

        <div
          class="col col-12 d-flex align-items-center justify-content-center flex-direction-row"
        >
          <div class="col-6">
            <template>
              <v-autocomplete
                v-model="idSelectedEnterprise"
                :items="enterprises"
                item-text="name"
                item-value="id"
                @change="getProductsByEnterpriseId()"
                label="Escolha uma empresa"
                class="ml-4 mt-6"
                clearable
                outlined
              ></v-autocomplete>
            </template>
          </div>

          <div class="col-6">
            <template>
              <v-autocomplete
                v-model="idSelectedProduct"
                :items="products"
                item-text="name"
                item-value="id"
                @change="getMailingFormatByProduct()"
                label="Escolha uma produto"
                class="ml-4 mt-6"
                clearable
                outlined
                :disabled="!selectedEnterprise || selectUnifiedReport"
              ></v-autocomplete>
            </template>
          </div>
        </div>

        <div class="col-md-12" v-if="viewListPanel">
          <div class="form-group col-md-12">
            <v-card>
              <v-card-title>
                Destinatários
                <template>
                  <v-col cols="3" lg="3">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateFormatted"
                          label="Data"
                          readonly
                          persistent-hint
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          @blur="date = parseDate(dateFormatted)"
                          v-on="on"
                          class="mt-6 ml-2"
                          outlined
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date"
                        no-title
                        :max="new Date().toISOString().split('T')[0]"
                        @input="menu = false"
                        locale="pt-br"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </template>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Pesquisar"
                  outlined
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                v-model="selectedMailingFormats"
                :headers="headers"
                :items="recipients"
                :search="search"
                item-key="id"
                show-select
                class="elevation-1"
              >
              </v-data-table>
            </v-card>
          </div>

          <div class="input-group col-12 justify-content-center">
            <button class="btn btn-dark btn-lg btn-block col-6" @click="resendReports">
              Reenviar
            </button>
          </div>
        </div>
      </div>
    </div>
    <Loading :show="showLoading" />
  </div>
</template>

<script>
import Loading from "../../views/Loading.vue";
import DefaultMixin from "../../mixins/DefaultMixin";
import ManageMixin from "../../mixins/ManageMixin";

import EnterpriseService from "../../services/EnterpriseService";
import ProductService from "../../services/ProductService";
import LoginService from "../../services/LoginService";
import MailingFormatService from "../../services/MailingFormatService";
import SendReportService from "../../services/SendReportService";

export default {
  name: "ManageResendReports",
  mixins: [DefaultMixin, ManageMixin],
  components: {
    Loading,
  },
  data() {
    return {
      date: "",
      dateFormatted: "",
      menu: false,

      enterpriseService: EnterpriseService.build(),
      productService: ProductService.build(),
      loginService: LoginService.build(),
      mailingFormatService: MailingFormatService.build(),
      sendReportService: SendReportService.build(),

      enterprises: [],
      products: [],
      recipients: [],

      idSelectedEnterprise: null,
      idSelectedProduct: null,

      footerProps: {
        "items-per-page-options": [5, 10, 15, 30],
        "items-per-page-text": "Empresas por página",
      },
      search: "",
      selectedEnterprise: null,
      viewListPanel: false,

      //table
      selectedMailingFormats: [],
      headers: [
        { text: "Nome", align: "start", value: "name" },
        { text: "E-mail", align: "center", value: "email" },
        { text: "Relatório", align: "center", value: "panelTtitle" },
      ],

      showLoading: false,
      selectUnifiedReport: false,
    };
  },
  methods: {
    resetVariables() {
      this.search = "";
      this.products = [];
      this.recipients = [];
      this.viewListPanel = false;
      this.idSelectedProduct = null;
      this.selectedMailingFormats = [];
      this.selectUnifiedReport = false;
    },
    async getAllEnterprises() {
      this.enterprises = await this.enterpriseService.findAllByAccountWithActiveBI(
        this.getUserId
      );
    },
    async getProductsByEnterpriseId() {
      this.resetVariables();
      const enterprise = this.enterprises.find(
        (enterprise) => enterprise.id === this.idSelectedEnterprise
      );

      this.selectedEnterprise = enterprise;

      if (enterprise && Object.entries(enterprise).length > 0) {
        const credencials = {
          dataBase: enterprise.dataBase,
        };

        this.loginService
          .generateJWTByDatabase(credencials)
          .then(async (data) => {
            this.$store.dispatch("storeJwtTempSendReports", data.access_token);
            if (data && data.access_token) {
              this.productService
                .getAllActiveProducts()
                .then((response) => {
                  this.products = response;
                })
                .catch(this.errorHandler);
            }
          })
          .catch(this.errorHandler);
      }
    },
    getMailingFormatByProduct() {
      this.mailingFormatService
        .findAllByProductIdAndEnterpriseId({
          selectedProductsIds: this.selectUnifiedReport ? 0 : this.idSelectedProduct,
          selectedEnterpriseId: this.idSelectedEnterprise,
        })
        .then((mailingFormats) => {
          this.recipients = mailingFormats.map((mailingFormat) => {
            return {
              id: mailingFormat.id,
              email: mailingFormat.recipientEnterprise.recipient.email,
              name: mailingFormat.recipientEnterprise.recipient.name,
              panelTtitle: mailingFormat.panel.title,
            };
          });
          this.viewListPanel = true;
        })
        .catch(this.errorHandler);
    },
    resendReports() {
      if (!this.date || this.date.length === 0) {
        this.alertWarning({
          text: "Selecione uma data para reenviar o(s) relatório(s)!",
        });
      } else if (this.selectedMailingFormats.length === 0) {
        this.alertWarning({
          text: "Selecione algum destinatário para reenviar o(s) relatório(s)!",
        });
      } else {
        this.confirmationDialog({
          text:
            "Ao confirmar, os relatórios serão reenviados para os destinatários selecionados.",
        }).then((result) => {
          if (result.isConfirmed) {
            this.showLoading = true;
            this.sendReportService
              .resendReport({
                selectedMailingFormatIds: this.selectedMailingFormats.map(
                  (mailingFormat) => mailingFormat.id
                ),
                date: this.date,
              })
              .then((result) => {
                this.showLoading = false;
                if (result && result.length > 0) {
                  let text =
                    "<p>Houve erro ao enviar os relatórios para os seguintes e-mails:</p>";
                  result.forEach((reportResult) => {
                    if (reportResult)
                      text += `<p><strong>${reportResult.email} - ${reportResult.report} - ${reportResult.error.response.message}</strong></p>`;
                  });
                  this.$swal({
                    title: "Atenção",
                    html: text,
                    icon: "warning",
                    customClass: "swal-wide-custom",
                  });
                } else {
                  this.alertSuccess({ text: "Relatórios reenviados com sucesso!" });
                }
              })
              .catch((err) => {
                this.showLoading = false;
                this.errorHandler(err);
              });
          }
        });
      }
    },
    handleSelectUnifiedReport() {
      if (this.selectUnifiedReport) {
        this.idSelectedProduct = null;
        this.getMailingFormatByProduct();
      } else {
        this.search = "";
        this.recipients = [];
        this.viewListPanel = false;
        this.idSelectedProduct = null;
        this.selectedMailingFormats = [];
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");

      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("/");
      return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`;
    },
  },
  computed: {
    getUserId() {
      return this.$store.getters.userId;
    },
  },
  async mounted(vm) {
    await this.getAllEnterprises();
    this.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10);
    this.dateFormatted = this.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    );
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.getters.userIsManager) {
        next();
      } else {
        next({ path: "/dashboard" });
      }
    });
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
    idSelectedProduct(val) {
      this.selectedMailingFormats = [];
    },
    selectedMailingFormats(val, oldVal) {
      const limitResends = 20;
      if (val.length > limitResends) {
        this.alertWarning({
          title: "Atenção!",
          text: "O máximo de reenvios são 20 por vez!",
        });
        this.$nextTick(() => {
          this.selectedMailingFormats = oldVal;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-recipient {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .container-inputs {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 1px 5px 0 rgba(0, 0, 0, 0.12);
    padding: 2rem 0.5rem;
  }

  i {
    align-self: flex-start;
    font-size: 1.4rem;
    cursor: pointer;
    margin-left: -6rem;
    margin-top: 2rem;
  }
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  background-color: #23272b;
  border-radius: 5px;
  color: #ffffff;
  font-size: 0.8rem;
  position: absolute;
  padding: 1px 5px 2px 5px;
  bottom: -2.2em;
  left: -100%;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  border: 1px solid #111111;
  z-index: 99999;
  visibility: hidden;
}

[data-title] {
  position: relative;
}

.container {
  -webkit-box-shadow: 5px 5px 14px -8px #000000;
  box-shadow: 5px 5px 14px -8px #000000;
  border-radius: 2rem;
  border: 1px solid #ccc;
}

.active-menu {
  border-radius: 5px;
  border: 1px solid #ccc;
}

.small-info {
  margin-top: -32px;
}
</style>
