<template>
  <form @submit.prevent="submit" class="form-recover-password">
    <div v-if="loading" class="d-flex justify-content-center">
      <div class="spinner-grow m-5" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <template v-else>
      <h5 class="mb-5 font-weight-bold text-secondary">
        Digite o e-mail para solicitar a alteração de senha:
      </h5>

      <form-input
        label="Email:"
        name="email"
        type="email"
        v-model="email.value"
        :error="email.error"
        @validate="validate({ email: email.value })"
        class="input-label mb-0"
      ></form-input>

      <button
        class="btn btn-block revovery-password-button"
        :disabled="email.error || !email.value"
      >
        <i class="fas fa-paper-plane"></i>
        Enviar e-mail
      </button>

      <div class="mt-2 d-flex justify-center">
        <strong class="back-to-login">
          <router-link
            :to="{ name: 'Login' }"
            class="text-secondary alert-link text-decoration-none"
          >
            <i class="fas fa-arrow-circle-left"></i>
            Voltar à tela de login
          </router-link>
        </strong>
      </div>
    </template>
  </form>
</template>

<script>
import { object, string } from "yup";
import DefaultMixin from "../../mixins/DefaultMixin";

import LoginService from "../../services/LoginService";

import FormInput from "../login/FormInput.vue";

export default {
  name: "RecoverPassword",
  mixins: [DefaultMixin],
  components: {
    FormInput,
  },
  data() {
    return {
      loginService: LoginService.build(),
      email: {
        value: "",
        error: null,
      },
      schema: object().shape({
        email: string()
          .email("E-mail inválido!")
          .required("E-mail é obrigatório!"),
      }),
      loading: false,
    };
  },
  methods: {
    validate(values = {}) {
      this.schema
        .validate(values)
        .then(() => {
          this.email.error = null;
        })
        .catch((error) => (this.email.error = error.message));
    },
    submit() {
      if (!this.email.error && this.email.value)
        this.sendRecoverEmail(this.email.value);
    },
    sendRecoverEmail(email) {
      this.loading = true;

      this.loginService
        .sendResetPasswordEmail(email)
        .then(() => {
          this.loading = false;
          this.alertSuccess({
            title: "E-mail enviado com sucesso!",
            text: "Acesse seu e-mail e siga as instruções para alterar sua senha.",
          }).then(() => this.$router.replace({ name: "Login" }));
        })
        .catch((error) => {
          this.loading = false;
          this.errorHandler(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>

.form-recover-password {
  background-color: var(--smarts-white);
  color: var(--smarts-black);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 90%;
  margin: 0 auto;
}

.input-label {
    color: var(--smarts-black);
    min-width: 100%;
  }


.spinner-border,
.spinner-grow {
  width: 3rem;
  height: 3rem;

  color: var(--smarts-wine) !important;

  background-image: url("../../../public/assets/images/background.jpg");
  background-size: 100% 100%;
}

.revovery-password-button {
  padding: 0.6rem;
  border-radius: 0.8rem;
  font-size: 1rem;
  margin-top: 2rem;
  font-weight: bold;
  background-color: var(--smarts-black);
  color: var(--smarts-white);

  transition: all 0.4s;

  &:hover {
    background-color: var(--smarts-wine);
  }
}

.back-to-login {
    font-size: 0.8rem;
  }
</style>
