import Rest from './base/Rest'

/**
 * @typedef {PanelService}
 */
export default class PanelService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/panel'

  /**
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  findById(id) {
    return this.get(`/${id}`);
  }

  /**
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
   findByIdFormatted(id) {
    return this.get(`/findByIdFormatted/${id}`);
  }

  /**
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  findAll (params = {}) {
    return this.get(`/all?${this.createQueryString(params)}`);
  }

  findAllAvailable () {
    return this.get(`/findAllAvailable`);
  }

  findAllAvailableById () {
    return this.get(`/findAllAvailableById`);
  }

  findAllAvailableWithAccountPanel (accountId) {
    return this.get(`/findAllAvailableWithAccountPanel?accountId=${accountId}`);
  }

  findAllToSendToEmail () {
    return this.get(`/findAllToSendToEmail`);
  }
}