import Rest from './base/Rest'

/**
 * @typedef {ContactService}
 */
export default class CustomerService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/customer'


  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
   getTotalCustomersByCity (params = {}) {
    return this.get(`/getTotalCustomersByCity?${this.createQueryString(params)}`)
  }


  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
   getTotalCustomersByDistrict (params = {}) {
    return this.get(`/getTotalCustomersByDistrict?${this.createQueryString(params)}`)
  }


  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
   getTotalCustomersLgpd (params = {}) {
    return this.get(`/getTotalCustomersLgpd?${this.createQueryString(params)}`)
  }

   /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
    getCustomerWithQuestionnaireAndLastContact (params = {}) {
    return this.get(`/getCustomerWithQuestionnaireAndLastContact?${this.createQueryString(params)}`)
  }

}