<template>
  <div class="echarts">
    <v-data-table
      :headers="tableHeaders"
      :items="tableItems"
      :items-per-page="itemsPerPage"
      :footer-props="showFooter ? footerProps : { 'items-per-page-options': [] }"
      :hide-default-footer="!showFooter"
      :class="panelName !== 'Questionários Completos' ? 'elevation-1' : ''"
      id="table"
      ref="dataTable"
    >
      <template
        v-for="(header, index) in tableHeaders"
        v-slot:[`item.${header.value}`]="{ item }"
      >
        <span :key="index + '' + Date.now()">{{ item[header.value] }}</span>
      </template>
      <template
        v-for="(h, index) in tableHeadersWithTootip"
        v-slot:[`header.${h.value}`]="{ tableHeadersWithTootip }"
      >
        <v-tooltip bottom :key="h.text">
          <template v-slot:activator="{ on }">
            <v-chip
              v-on="on"
              v-if="h?.color && hasVChips"
              :key="index"
              :color="h.color"
              dark
            >
              {{ h.text }}
            </v-chip>
            <span v-on="on" v-else>{{ h.text }}</span>
          </template>

          <span>{{ h.tooltip }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "module-table-list-data-v2",

  props: {
    tableHeaders: {
      type: Array,
      default: () => [],
    },
    tableItems: {
      type: Array,
      default: () => [],
    },
    showFooter: {
      type: Boolean,
      default: () => true,
    },
    footerPropsItemsPerPageText: {
      type: String,
      default: "Itens por página",
    },
    hasVChips: {
      type: Boolean,
      default: () => false,
    },
    panelName: {
      type: String,
      default: "",
    },
  },

  computed: {
    tableHeadersWithTootip() {
      return this.tableHeaders
        ? this.tableHeaders.filter((header) => header.tooltip)
        : [];
    },
  },

  data: function () {
    return {
      itemsPerPage:
        this.panelName === "Questionários Completos" ? this.tableItems.length : 0,
      optionsLength: 6,
      footerProps: {
        "items-per-page-options": [
          5,
          10,
          15,
          30,
          50,
          100,
          this.panelName === "Questionários Completos" ? this.tableItems.length : 150,
        ],
        "items-per-page-text": this.footerPropsItemsPerPageText,
      },
      newHeaders: [],
      newItens: [],
    };
  },

  mounted() {},

  watch: {
    tableItems: function (n, o) {
      this.newItens = [];
    },
  },

  methods: {
    updateDataTableHeight() {
      var tableHeight = this.$refs.dataTable.$el.offsetHeight;

      var headerHeight = this.$refs.dataTable.$children[0].$children[0].$children[0].$el
        .clientHeight;
      var offsetTop = this.$refs.tableApp.$el.offsetTop;
      if (typeof offsetTop == "undefined" || offsetTop == null || offsetTop < 0) {
        offsetTop = 0;
      }

      this.itemsPerPage = parseInt((tableHeight - headerHeight - offsetTop) / 50) - 1;
      if (this.itemsPerPage <= 0) {
        this.itemsPerPage = 1;
      }
      if (
        typeof this.tableItems.length != "undefined" &&
        this.tableItems.length < this.itemsPerPage
      ) {
        this.itemsPerPage = this.tableItems.length;
      }
      if (!this.footerProps["items-per-page-options"].includes(this.itemsPerPage)) {
        if (this.footerProps["items-per-page-options"].length == this.optionsLength) {
          this.footerProps["items-per-page-options"].unshift(this.itemsPerPage);
        } else {
          this.footerProps["items-per-page-options"].shift();
          this.footerProps["items-per-page-options"].unshift(this.itemsPerPage);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-application--wrap {
  overflow: auto;
}
</style>

<style lang="scss">
.v-application {
  position: relative;
  min-height: 100%;
  max-height: 100%;

  .v-application--wrap {
    max-height: 100%;
    min-height: 100%;
  }

  .v-data-table {
    td {
      height: 30px !important;
    }
  }

  #table {
    width: 97%;
    max-height: 100%;
    margin: 0 auto;
    padding-bottom: 10px;
  }
}

.echarts {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
