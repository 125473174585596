import { createCipheriv, createDecipheriv } from 'crypto';

/**
 * @typedef { CryptoService }
 */
export default class CryptoService {

  static alg = process.env.VUE_APP_SECRET_ALG;
  static key = process.env.VUE_APP_SECRET_KEY;
  static iv = process.env.VUE_APP_SECRET_IV.split(",");

  generateCrypto(store) {
    const cipher = createCipheriv(CryptoService.alg, CryptoService.key, CryptoService.iv);
    let encrypted = cipher.update(store, 'utf-8', 'base64');
    encrypted += cipher.final('base64');
    return encrypted;
  }

  decodeCrypto(hash){
    const decipher = createDecipheriv(CryptoService.alg, CryptoService.key, CryptoService.iv);
    let dencrypted = decipher.update(hash, 'base64', 'utf-8');
    dencrypted += decipher.final('utf-8');
    return dencrypted;
  }
}
