<template>
  <mobule-base-builder
    :module-data="moduleData"
    :editMode="editMode"
    :loading="loading"
    :error="error"
    :downloadImage="dataDownload"
    :tableHeaders="tableHeadersWithQuestionsExcel"
    :tableHeadersExcel="tableHeadersWithQuestionsExcel"
    :tableItems="tableItems"
    :tableItemsExcel="tableItems"
    :typePanelPDF="'TABLE'"
  >
    <template v-slot:content>
      <div class="pa-3" v-if="questionnaires.length > 0">
        <v-autocomplete
          v-if="getSelectedProducts.length > 0"
          v-model="selectedQuestionnaire"
          :items="questionnaires"
          item-text="title"
          item-value="id"
          @change="buildTableHeaderWithQuestions()"
          id="selectedQuestionnaire"
          label="Selecione um questionário"
          class="px-4 pt-2"
          outlined
          dense
        ></v-autocomplete>
      </div>

      <module-table-list-data-v2
        v-if="tableItems.length > 0"
        :tableHeaders="tableHeadersWithQuestions"
        :tableItems="tableItems"
        class="customer-by-questionnaire-by-contacts"
      />
      <message-status-data
        v-else
        :selected-products="selectedProductsIds()"
        :infoLoading="loading"
      />
    </template>
  </mobule-base-builder>
</template>

<script>
import MixinModuleBase from "../../../mixins/MixinModuleBase";

import MobuleBaseBuilder from "../base/MobuleBaseBuilder.vue";
import ModuleChartBar from "../base/ModuleChartBar.vue";
import ModuleChartDoughnut from "../base/ModuleChartDoughnut.vue";
import ModuleTableListDataV2 from "../base/ModuleTableListDataV2.vue";
import MessageStatusData from "../../MessageStatusData.vue";

import ContactService from "../../../services/ContactService.js";
const contactService = ContactService.build();
import QuestionnaireService from "../../../services/QuestionnaireService";
const questionnaireService = QuestionnaireService.build();

export default {
  name: "module-customer-by-questionnaire-by-contacts",
  mixins: [MixinModuleBase],
  components: {
    MobuleBaseBuilder,
    ModuleChartBar,
    ModuleChartDoughnut,
    ModuleTableListDataV2,
    MessageStatusData,
  },
  data: function () {
    return {
      labels: [],
      tableHeadersWithQuestions: [],
      tableHeadersWithQuestionsExcel: [],
      tableHeaders: [
        {
          text: "Data de Contato",
          align: "start",
          sortable: true,
          value: "created",
          width: "300px",
        },
        {
          text: "Fora do Expediente",
          align: "start",
          sortable: true,
          value: "outOfService",
          width: "300px",
        },
        {
          text: "Tipo do Contato",
          align: "start",
          sortable: true,
          value: "typeOf",
          width: "300px",
        },
        {
          text: "Produto de Interesse",
          align: "start",
          sortable: true,
          value: "productsType",
          width: "300px",
        },
        {
          text: "Indicado",
          align: "start",
          sortable: true,
          value: "indication",
          width: "300px",
        },
        {
          text: "Indicado Por",
          align: "start",
          sortable: true,
          value: "indicationBrokerName",
          width: "300px",
        },
        {
          text: "Empresa de Vendas",
          align: "start",
          sortable: true,
          value: "salesCompany",
          width: "300px",
        },
        {
          text: "Outras",
          align: "start",
          sortable: true,
          value: "another",
          width: "300px",
        },
        {
          text: "Corretor",
          align: "start",
          sortable: true,
          value: "brokerName",
          width: "300px",
        },
        {
          text: "Gerente de Vendas",
          align: "start",
          sortable: true,
          value: "managerName",
          width: "300px",
        },
        {
          text: "Superintendente de Vendas",
          align: "start",
          sortable: true,
          value: "superintendentName",
          width: "300px",
        },
        {
          text: "Nome",
          align: "start",
          sortable: true,
          value: "name",
          width: "300px",
        },
        {
          text: "Sobrenome",
          align: "start",
          sortable: true,
          value: "lastName",
          width: "300px",
        },
        {
          text: "Email",
          align: "start",
          sortable: true,
          value: "email",
          width: "300px",
        },
        {
          text: "CPF",
          align: "start",
          sortable: true,
          value: "cpf",
          width: "300px",
        },
        {
          text: "Nascimento",
          align: "start",
          sortable: true,
          value: "birthDate",
          width: "300px",
        },
        {
          text: "Sexo",
          align: "start",
          sortable: true,
          value: "gender",
          width: "300px",
        },
        {
          text: "Telefone Celular",
          align: "start",
          sortable: true,
          value: "cel",
          width: "300px",
        },
        {
          text: "Telefone Residencial",
          align: "start",
          sortable: true,
          value: "personalPhone",
          width: "300px",
        },
        {
          text: "Telefone Comercial",
          align: "start",
          sortable: true,
          value: "businessPhone",
          width: "300px",
        },
        {
          text: "Endereço",
          align: "start",
          sortable: true,
          value: "address",
          width: "300px",
        },
        {
          text: "Número",
          align: "start",
          sortable: true,
          value: "addressNumber",
          width: "300px",
        },
        {
          text: "Complemento",
          align: "start",
          sortable: true,
          value: "addressAdjunct",
          width: "300px",
        },
        {
          text: "Bairro",
          align: "start",
          sortable: true,
          value: "addressDistrict",
          width: "300px",
        },
        {
          text: "Região",
          align: "start",
          sortable: true,
          value: "regiao",
          width: "300px",
        },
        {
          text: "CEP",
          align: "start",
          sortable: true,
          value: "addressZipCode",
          width: "300px",
        },
        {
          text: "Cidade",
          align: "start",
          sortable: true,
          value: "addressCity",
          width: "300px",
        },
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "addressState",
          width: "300px",
        },
        {
          text: "Latitude",
          align: "start",
          sortable: true,
          value: "latAddress",
          width: "300px",
        },
        {
          text: "Longitude",
          align: "start",
          sortable: true,
          value: "lngAddress",
          width: "300px",
        },
        {
          text: "Endereço Comercial",
          align: "start",
          sortable: true,
          value: "businessAddress",
          width: "300px",
        },
        {
          text: "Número Comercial",
          align: "start",
          sortable: true,
          value: "businessAddressNumber",
          width: "300px",
        },
        {
          text: "Complemento Comercial",
          align: "start",
          sortable: true,
          value: "businessAddressAdjunct",
          width: "300px",
        },
        {
          text: "Bairro Comercial",
          align: "start",
          sortable: true,
          value: "businessAddressDistrict",
          width: "300px",
        },
        {
          text: "CEP Comercial",
          align: "start",
          sortable: true,
          value: "businessAddressZipCode",
          width: "300px",
        },
        {
          text: "Cidade Comercial",
          align: "start",
          sortable: true,
          value: "businessAddressCity",
          width: "300px",
        },
        {
          text: "Estado Comercial",
          align: "start",
          sortable: true,
          value: "businessAddressState",
          width: "300px",
        },
        {
          text: "Latitude Comercial",
          align: "start",
          sortable: true,
          value: "latBusiness",
          width: "300px",
        },
        {
          text: "Longitude Comercial",
          align: "start",
          sortable: true,
          value: "lngBusiness",
          width: "300px",
        },
        {
          text: "ID Contato",
          align: "start",
          sortable: true,
          value: "id",
          width: "300px",
        },
        {
          text: "ID Cliente",
          align: "start",
          sortable: true,
          value: "customerId",
          width: "300px",
        },
        {
          text: "Justificativa Fora do Expediente",
          align: "start",
          sortable: true,
          value: "justification",
          width: "300px",
        },
        {
          text: "Mídia Primária",
          align: "start",
          sortable: true,
          value: "mainMedia",
          width: "300px",
        },
        {
          text: "Mídias Secundárias",
          align: "start",
          sortable: true,
          value: "medias",
          width: "300px",
        },
        {
          text: "Criado Por",
          align: "start",
          sortable: true,
          value: "createdBy",
          width: "300px",
        },
        {
          text: "Permitiu o Uso dos Dados",
          align: "start",
          sortable: true,
          value: "personalDataAllowUse",
          width: "300px",
        },
        {
          text: "Permitiu o Compartilhamento dos Dados",
          align: "start",
          sortable: true,
          value: "personalDataAllowShare",
          width: "300px",
        },
      ],
      tableHeadersExcel: [
        {
          text: "Questionário Selecionado",
          align: "start",
          sortable: true,
          value: "selectedQuestionnaire",
        },
        {
          text: "Data de Contato",
          align: "start",
          sortable: true,
          value: "created",
        },
        {
          text: "Fora do Expediente",
          align: "start",
          sortable: true,
          value: "outOfService",
        },
        {
          text: "Tipo do Contato",
          align: "start",
          sortable: true,
          value: "typeOf",
        },
        {
          text: "Produto de Interesse",
          align: "start",
          sortable: true,
          value: "productsType",
        },
        {
          text: "Indicado",
          align: "start",
          sortable: true,
          value: "indication",
        },
        {
          text: "Indicado Por",
          align: "start",
          sortable: true,
          value: "indicationBrokerName",
        },
        {
          text: "Empresa de Vendas",
          align: "start",
          sortable: true,
          value: "salesCompany",
        },
        { text: "Outras", align: "start", sortable: true, value: "another" },
        {
          text: "Corretor",
          align: "start",
          sortable: true,
          value: "brokerName",
        },
        {
          text: "Gerente de Vendas",
          align: "start",
          sortable: true,
          value: "managerName",
        },
        {
          text: "Superintendente de Vendas",
          align: "start",
          sortable: true,
          value: "superintendentName",
        },
        { text: "Nome", align: "start", sortable: true, value: "name" },
        {
          text: "Sobrenome",
          align: "start",
          sortable: true,
          value: "lastName",
        },
        { text: "Email", align: "start", sortable: true, value: "email" },
        { text: "CPF", align: "start", sortable: true, value: "cpf" },
        {
          text: "Nascimento",
          align: "start",
          sortable: true,
          value: "birthDate",
        },
        { text: "Sexo", align: "start", sortable: true, value: "gender" },
        {
          text: "Telefone Celular",
          align: "start",
          sortable: true,
          value: "cel",
        },
        {
          text: "Telefone Residencial",
          align: "start",
          sortable: true,
          value: "personalPhone",
        },
        {
          text: "Telefone Comercial",
          align: "start",
          sortable: true,
          value: "businessPhone",
        },
        { text: "Endereço", align: "start", sortable: true, value: "address" },
        {
          text: "Número",
          align: "start",
          sortable: true,
          value: "addressNumber",
        },
        {
          text: "Complemento",
          align: "start",
          sortable: true,
          value: "addressAdjunct",
        },
        {
          text: "Bairro",
          align: "start",
          sortable: true,
          value: "addressDistrict",
        },
        { text: "Região", align: "start", sortable: true, value: "regiao" },
        {
          text: "CEP",
          align: "start",
          sortable: true,
          value: "addressZipCode",
        },
        {
          text: "Cidade",
          align: "start",
          sortable: true,
          value: "addressCity",
        },
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "addressState",
        },
        {
          text: "Latitude",
          align: "start",
          sortable: true,
          value: "latAddress",
        },
        {
          text: "Longitude",
          align: "start",
          sortable: true,
          value: "lngAddress",
        },
        {
          text: "Endereço Comercial",
          align: "start",
          sortable: true,
          value: "businessAddress",
        },
        {
          text: "Número Comercial",
          align: "start",
          sortable: true,
          value: "businessAddressNumber",
        },
        {
          text: "Complemento Comercial",
          align: "start",
          sortable: true,
          value: "businessAddressAdjunct",
        },
        {
          text: "Bairro Comercial",
          align: "start",
          sortable: true,
          value: "businessAddressDistrict",
        },
        {
          text: "CEP Comercial",
          align: "start",
          sortable: true,
          value: "businessAddressZipCode",
        },
        {
          text: "Cidade Comercial",
          align: "start",
          sortable: true,
          value: "businessAddressCity",
        },
        {
          text: "Estado Comercial",
          align: "start",
          sortable: true,
          value: "businessAddressState",
        },
        {
          text: "Latitude Comercial",
          align: "start",
          sortable: true,
          value: "latBusiness",
        },
        {
          text: "Longitude Comercial",
          align: "start",
          sortable: true,
          value: "lngBusiness",
        },
        { text: "ID Contato", align: "start", sortable: true, value: "id" },
        {
          text: "ID Cliente",
          align: "start",
          sortable: true,
          value: "customerId",
        },
        {
          text: "Justificativa Fora do Expediente",
          align: "start",
          sortable: true,
          value: "justification",
        },
        {
          text: "Mídia Primária",
          align: "start",
          sortable: true,
          value: "mainMedia",
        },
        {
          text: "Mídias Secundárias",
          align: "start",
          sortable: true,
          value: "medias",
        },
        {
          text: "Criado Por",
          align: "start",
          sortable: true,
          value: "createdBy",
        },
        {
          text: "Permitiu o Uso dos Dados",
          align: "start",
          sortable: true,
          value: "personalDataAllowUse",
          width: "300px",
        },
        {
          text: "Permitiu o Compartilhamento dos Dados",
          align: "start",
          sortable: true,
          value: "personalDataAllowShare",
          width: "300px",
        },
      ],
      tableItems: [],
      dataDownload: {
        class: "customer-by-contacts",
        title: this.moduleData.headerTitle,
      },
      questionnaires: [],
      selectedQuestionnaire: [],
      inactiveQuestionnaires: [],
    };
  },
  computed: {
    hasFilterDateRange() {
      return (
        this.filterDateRange &&
        this.filterDateRange.length == 2 &&
        this.filterDateRange[0].length > 1 &&
        this.filterDateRange[1].length > 1
      );
    },
    getSelectedProducts() {
      return this.$store.getters.selectedProducts;
    },
  },
  methods: {
    resetModule() {
      this.tableItems = [];
      this.questionnaires = [];
      this.selectedQuestionnaire = [];
    },
    formatDate(dateToFormat, key = "") {
      const date = new Date(dateToFormat);
      const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      const month =
        date.getMonth() < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      const minutes =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      return `${day}/${month}/${date.getFullYear()} ${
        key !== "birthDate" ? date.getHours() + ":" + minutes : ""
      }`;
    },

    async sanitizeContactsType(contacts) {
      if (contacts && contacts.length > 0) {
        contacts = contacts.map(async (contact) => {
          let customerDefault = {
            customerId: "-",
            name: "-",
            lastName: "-",
            email: "-",
            cpf: "-",
            birthDate: "-",
            gender: "-",
            cel: "-",
            personalPhone: "-",
            businessPhone: "-",
            address: "-",
            addressNumber: "-",
            addressAdjunct: "-",
            addressDistrict: "-",
            regiao: "-",
            addressZipCode: "-",
            addressCity: "-",
            addressState: "-",
            latAddress: "-",
            lngAddress: "-",
            businessAddress: "-",
            businessAddressNumber: "-",
            businessAddressAdjunct: "-",
            businessAddressDistrict: "-",
            businessAddressZipCode: "-",
            businessAddressCity: "-",
            businessAddressState: "-",
            latBusiness: "-",
            lngBusiness: "-",
            personalDataAllowShare: "-",
            personalDataAllowUse: "-",
          };

          if (contact["customer"]) {
            contact["customer"]["customerId"] = contact["customer"]["id"];
            delete contact["customer"]["id"];
            delete contact["customer"]["created"];
            contact = { ...contact, ...contact["customer"] };
          } else {
            contact = { ...contact, ...customerDefault };
          }
          delete contact["customer"];
          const selectedQuest = this.questionnaires.filter(
            (q) => q.id === this.selectedQuestionnaire
          )[0];

          let questionnaireIdInContact = 0;
          if (contact.replies && contact.replies.length > 0) {
            for (let reply of contact.replies) {
              if (
                reply.choice &&
                reply.choice.question &&
                reply.choice.question.questionnaire
              ) {
                questionnaireIdInContact = reply.choice.question.questionnaire.id;
                break;
              }
            }
          }

          if (
            questionnaireIdInContact !== 0 &&
            this.selectedQuestionnaire !== questionnaireIdInContact
          ) {
            const quest = await questionnaireService
              .getQuestionnaireActiveAndNotActive({
                selectedProductsIds: this.selectedProductsIds(),
                questionnaireId: questionnaireIdInContact,
              })
              .catch(this.errorHandler);

            quest.questions.forEach((question) => {
              const hasHeaderQuestion = this.tableHeadersWithQuestions.filter(
                (header) =>
                  header.text === question?.title && header.value === question?.id
              );
              if (hasHeaderQuestion.length === 0) {
                this.tableHeadersWithQuestions.push({
                  text: question?.title,
                  align: "start",
                  sortable: true,
                  value: String(question.id),
                  width: "500px",
                });
                this.tableHeadersWithQuestionsExcel.push({
                  text: question?.title,
                  align: "start",
                  sortable: true,
                  value: String(question.id),
                  width: "500px",
                });
              }

              const reply = contact?.replies
                ? contact.replies.filter(
                    (reply) =>
                      reply?.choice?.question?.id === question?.id &&
                      reply?.choice?.question?.title === question?.title
                  )[0]
                : null;

              let answer = "-";
              if (reply) {
                if (reply.answer) answer = reply.answer;
                else answer = reply.choice.textFor;
              }
              contact[question.id] = answer;
            });
          } else {
            selectedQuest.questions.forEach((question) => {
              const reply = contact?.replies
                ? contact.replies.filter(
                    (reply) =>
                      reply?.choice?.question?.id === question?.id &&
                      reply?.choice?.question?.title === question?.title
                  )[0]
                : null;
              let answer = "-";
              if (reply) {
                if (reply.answer) answer = reply.answer;
                else answer = reply.choice.textFor;
              }
              contact[question.id] = answer;
            });
          }
          Object.keys(contact).forEach((key) => {
            if (key === "mainMediaId") {
              contact["mainMedia"] =
                contact["mainMediaId"] &&
                contact["medias"] &&
                contact["medias"].length > 0
                  ? contact["medias"].filter((media) => {
                      if (media.id === contact["mainMediaId"]) {
                        return media;
                      } else {
                        return { title: "-" };
                      }
                    })[0]["title"]
                  : "-";
            } else if (key === "medias") {
              contact["medias"] =
                contact["medias"] && contact["medias"].length > 1
                  ? contact["medias"].reduce((acc, cur) => {
                      if (cur.id !== contact["mainMediaId"]) {
                        return acc ? acc + ", " + cur?.title : cur?.title;
                      }
                      return acc;
                    }, "")
                  : "-";
            } else if (key === "productsType") {
              contact["productsType"] =
                contact["productsType"] && contact["productsType"].length > 0
                  ? contact["productsType"].reduce(
                      (acc, cur) => (acc ? acc + ", " + cur.name : cur.name),
                      ""
                    )
                  : "";
            } else if (key === "indication") {
              contact["indication"] = contact["indication"] ? "SIM" : "NÃO";
            } else if (key === "indicationBrokerName") {
              if (contact["indication"] === "SIM" || contact["indication"] === true) {
                contact["indicationBrokerName"] = contact["indicationBrokerName"]
                  ? contact["indicationBrokerName"]
                  : contact["indicationBrokerEmail"];
              } else {
                contact[key] = contact[key] ? contact[key].name : "-";
              }
            } else if (key === "salesCompany" || key === "regiao") {
              contact[key] = contact[key] ? contact[key].name : "-";
            } else if (key === "justification") {
              contact["justification"] = contact["justification"]
                ? contact["justification"].description
                : "-";
            } else if (key === "outOfService") {
              contact["outOfService"] = contact["outOfService"] ? "SIM" : "NÃO";
            } else if (key === "typeOf") {
              switch (contact[key]) {
                case "FIRSTCONTACT":
                  contact[key] = "Primeira Visita";
                  break;
                case "RECURRENCE":
                  contact[key] = "Retorno";
                  break;
                case "PHONECONTACT":
                  contact[key] = "Telefonema";
                  break;
              }
            } else if (key === "created" || key === "birthDate") {
              if (contact[key] && contact[key] !== "-") {
                contact[key] = this.formatDate(contact[key]);
              } else {
                contact[key] = `-`;
              }
            } else if (
              key === "personalDataAllowShare" ||
              key === "personalDataAllowUse"
            ) {
              contact[key] = contact[key] ? "SIM" : "NÃO";
            } else {
              contact[key] = contact[key] ? contact[key] : "-";
            }
          });

          return contact;
        });
      }

      return Promise.all(contacts);
    },

    buildTableHeaderWithQuestions() {
      this.loading = true;
      const selectedQuest = this.questionnaires.filter(
        (q) => q.id === this.selectedQuestionnaire
      )[0];
      this.tableHeadersWithQuestions = [...this.tableHeaders];
      this.tableHeadersWithQuestionsExcel = [...this.tableHeadersExcel];
      selectedQuest.questions.forEach((question) => {
        this.tableHeadersWithQuestions.push({
          text: question?.title,
          align: "start",
          sortable: true,
          value: String(question.id),
          width: "500px",
        });
        this.tableHeadersWithQuestionsExcel.push({
          text: question?.title,
          align: "start",
          sortable: true,
          value: String(question.id),
          width: "500px",
        });
      });

      contactService
        .getCustomerByContactsWithQuestionnaire({
          selectedProductsIds: selectedQuest.productId,
          dateBegin: this.filterDateRange[0],
          dateEnd: this.filterDateRange[1],
        })
        .then(async (r) => {
          this.tableItems = await this.sanitizeContactsType(r);

          this.tableItems = this.tableItems.map((item, index) => {
            if (index === 0) {
              return {
                selectedQuestionnaire: selectedQuest?.title,
                ...item,
              };
            } else {
              return { selectedQuestionnaire: "", ...item };
            }
          });

          this.loading = false;
        })
        .catch(this.errorHandler);
    },

    getData() {
      this.resetModule();

      if (this.dataRetrievalOk()) {
        this.loading = true;

        questionnaireService
          .getCompletedQuestionnairesActiveAndNotActive({
            selectedProductsIds: this.selectedProductsIds(),
            dateBegin: this.filterDateRange[0],
            dateEnd: this.filterDateRange[1],
          })
          .then((q) => {
            if (q && q.length > 0) {
              this.questionnaires = q;
              this.selectedQuestionnaire = this.questionnaires[0].id;
              this.buildTableHeaderWithQuestions();
            } else {
              this.selectInPanel = {
                active: false,
                message: "Nenhum dado encontrado",
              };

              this.loading = false;
            }
          })
          .catch(this.errorHandler);
      }
    },
  },
};
</script>

<style scoped>
.echarts {
  width: 100%;
  height: 100%;
  position: relative;
}

.customer-by-questionnaire-by-contacts {
  background-color: #fff;
}

.w-100 {
  z-index: 999;
}
</style>
