import Rest from './base/Rest'

/**
 * @typedef {AccountEnterpriseBIService}
 */
export default class AccountEnterpriseBIService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/account-enterprise-bi'


  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  saveAccountEnterpriseBi (params = []) {
    return this.post(`/save`, params)
  }
}