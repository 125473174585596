<template>
  <mobule-base-builder
    :module-data="moduleData"
    :editMode="editMode"
    :loading="loading"
    :error="error"
    :downloadImage="dataDownload"
    :tableHeaders="listTableHeader"
    :tableItems="listTableItems"
    :tableHeadersExcel="listTableHeader"
    :tableItemsExcel="tableItemsExcel"
    :allTableHeaders="allTableHeaders"
    :allTableItems="allTableItems"
    :totalTables="totalTables"
    :typePanelPDF="'TABLE'"
    :selectLabelToPDF="''"
  >
    <template v-slot:content>
      <div class="pa-3">
        <span v-if="!hasFilterDateRange"
          >***É necessário preencher os campos de "Período dos dados" para
          buscar as informações!***</span
        >
        <v-autocomplete
          v-model="selectedProduct"
          :items="getSelectedProducts"
          item-text="name"
          item-value="id"
          @change="getData()"
          id="general-report-by-product"
          label="Selecione o produto"
          class="px-4 pt-2"
          :disabled="!hasFilterDateRange"
          outlined
          dense
        ></v-autocomplete>
      </div>
      <template
        v-if="
          tableItemsRegisterCapture.length > 0 ||
          tableItemsResearchesCapture.length > 0
        "
      >
        <module-table-list-data-v-2
          :tableHeaders="tableHeaders"
          :tableItems="tableItems"
          :showFooter="false"
          class="general-report-by-product"
          v-if="tableItems.length > 0"
        />
        <module-table-list-data-v-2
          :tableHeaders="tableHeadersRegisterCapture"
          :tableItems="tableItemsRegisterCapture"
          class="general-report-by-product"
          v-if="tableItemsRegisterCapture.length > 0"
        />
        <module-table-list-data-v-2
          :tableHeaders="tableHeadersResearchesCapture"
          :tableItems="tableItemsResearchesCapture"
          class="general-report-by-product"
          v-if="tableItemsResearchesCapture.length > 0"
        />
      </template>

      <message-status-data-unique-product
        v-show="
          tableItemsRegisterCapture.length === 0 &&
          tableItemsResearchesCapture.length === 0
        "
        :selected-product="hasDatasValue"
        :infoLoading="loading"
      />
    </template>
  </mobule-base-builder>
</template>

<script>
import MixinModuleBase from "../../../mixins/MixinModuleBase";
import MobuleBaseBuilder from "../base/MobuleBaseBuilder.vue";
import ModuleTableListDataV2 from "../base/ModuleTableListDataV2.vue";
import MessageStatusDataUniqueProduct from "../../MessageStatusDataUniqueProduct.vue";

import ContactService from "../../../services/ContactService";
const contactService = ContactService.build();

import ProductService from "../../../services/ProductService";
const productService = ProductService.build();

export default {
  name: "module-general-report-by-product",
  mixins: [MixinModuleBase],
  components: {
    MobuleBaseBuilder,
    ModuleTableListDataV2,
    MessageStatusDataUniqueProduct,
  },
  data: function () {
    return {
      tableHeaders: [
        {
          text: "Empreendimento",
          align: "start",
          sortable: true,
          value: "productName",
          tooltip: "Nome do emprendimento",
        },
        {
          text: "TV",
          align: "start",
          sortable: true,
          value: "totalContacts",
          tooltip: "Total de visitas",
        },
        {
          text: "TC",
          align: "start",
          sortable: true,
          value: "totalCompletedRegisterCustomer",
          tooltip: "Total de cadastros completos",
        },
        {
          text: "TC(*)",
          align: "start",
          sortable: true,
          value: "totalCompletedRegisterCustomerRequiredFields",
          tooltip: "Total de cadastros completos (campos obrigatórios*)",
        },
        {
          text: "TPC",
          align: "start",
          sortable: true,
          value: "totalCompletedReplies",
          tooltip: "Total de pesquisas completas",
        },
        {
          text: "CAD(%)",
          align: "start",
          sortable: true,
          value: "registersPercent",
          tooltip: "Cadastros (%)",
        },
        {
          text: "CAD(*)",
          align: "start",
          sortable: true,
          value: "registersRequiredFieldsPercent",
          tooltip: "Cadastros (campos obrigatórios*) (%)",
        },
        {
          text: "PQS(%)",
          align: "start",
          sortable: true,
          value: "researchesPercent",
          tooltip: "Pesquisas (%)",
        },
        {
          text: "CC(%)",
          align: "start",
          sortable: true,
          value: "registersCapturePercent",
          tooltip: "Captação de cadastros (%)",
        },
        {
          text: "CP(%)",
          align: "start",
          sortable: true,
          value: "researchesCapturePercent",
          tooltip: "Captação de pesquisas (%)",
        },
        {
          text: "TFE",
          align: "start",
          sortable: true,
          value: "totalOutService",
          tooltip: "Total fora do expediente",
        },
      ],
      tableHeadersRegisterCapture: [
        {
          text: "Pergunta do Cadastro",
          align: "start",
          sortable: true,
          value: "titleRegister",
        },
        {
          text: "Captação (%)",
          align: "start",
          sortable: true,
          value: "percentRegister",
        },
      ],
      tableHeadersResearchesCapture: [
        {
          text: "Pergunta da Pesquisa",
          align: "start",
          sortable: true,
          value: "title",
        },
        {
          text: "Captação (%)",
          align: "start",
          sortable: true,
          value: "percent",
        },
      ],
      tableItems: [],
      tableItemsExcel: [],
      tableItemsRegisterCapture: [],
      tableItemsResearchesCapture: [],
      dataDownload: {
        class: "general-report-by-product",
        title: this.moduleData.headerTitle,
      },
      products: [],
      selectedProduct: [],
      listTableHeader: [],
      listTableItems: [],
      allTableHeaders: [],
      allTableItems: [],
      totalTables: 0,
    };
  },
  computed: {
    hasFilterDateRange() {
      return (
        this.filterDateRange &&
        this.filterDateRange.length == 2 &&
        this.filterDateRange[0].length > 1 &&
        this.filterDateRange[1].length > 1
      );
    },
    hasDatasValue() {
      if (
        this.selectedProduct === "" ||
        this.selectedProduct.length === 0 ||
        (this.selectedProduct &&
          (this.tableItemsRegisterCapture.length > 0 ||
            this.tableItemsResearchesCapture > 0))
      ) {
        return true;
      }
      return false;
    },
    getSelectedProducts() {
      return this.$store.getters.selectedProducts;
    },
    getViewProducts() {
      return this.$store.getters.viewProducts;
    },
  },
  methods: {
    resetModule() {
      this.tableItems = [];
      this.tableItemsRegisterCapture = [];
      this.tableItemsResearchesCapture = [];
      this.listTableItems = [];
      this.tableItemsExcel = [];
      this.listTableHeader = [];
      this.allTableHeaders = [];
      this.allTableItems = [];
      this.totalTables = 0;
    },

    getData() {
      if (
        this.hasFilterDateRange &&
        this.selectedProduct &&
        this.selectedProduct !== "" &&
        this.selectedProduct > 0 &&
        (this.whichFunctionRequest === "" ||
          this.whichFunctionRequest === "filterDateRange")
      ) {
        this.loading = true;

        contactService
          .getGeneralReport({
            selectedProductsIds: [this.selectedProduct],
            dateBegin: this.filterDateRange[0],
            dateEnd: this.filterDateRange[1],
          })
          .then((r) => {
            this.resetModule();
            this.tableItems = r;
            this.tableItemsExcel = r;

            r[0].registersCaptureQuestions = r[0].registersCaptureQuestions.map(
              (item) => {
                switch (item.titleRegister) {
                  case "name":
                    item.titleRegister = "Nome*";
                    break;
                  case "lastName":
                    item.titleRegister = "Sobrenome*";
                    break;
                  case "birthDate":
                    item.titleRegister = "Data de Nascimento";
                    break;
                  case "personalPhone":
                    item.titleRegister = "Telefone";
                    break;
                  case "cel":
                    item.titleRegister = "Celular*";
                    break;
                  case "email":
                    item.titleRegister = "Email*";
                    break;
                  case "cpf":
                    item.titleRegister = "CPF";
                    break;
                  case "gender":
                    item.titleRegister = "Sexo*";
                    break;
                  case "addressZipCode":
                    item.titleRegister = "CEP*";
                    break;
                  case "address":
                    item.titleRegister = "Endereço (Residencial)*";
                    break;
                  case "addressDistrict":
                    item.titleRegister = "Bairro (Residencial)*";
                    break;
                  case "addressCity":
                    item.titleRegister = "Cidade (Residencial)*";
                    break;
                  case "addressState":
                    item.titleRegister = "Estado (Residencial)*";
                    break;
                }
                return item;
              }
            );
            this.tableItemsRegisterCapture = r[0].registersCaptureQuestions;
            this.tableItemsResearchesCapture = r[0].researchesCaptureQuestions;

            this.mapExcel(this.tableItems, this.tableHeaders);

            const tableItemsRegisterCaptureTemp = [];
            tableItemsRegisterCaptureTemp.push(
              ...this.tableItemsRegisterCapture
            );

            this.mapExcel(
              tableItemsRegisterCaptureTemp,
              this.tableHeadersRegisterCapture
            );

            const tableItemsResearchesCaptureTemp = [];
            tableItemsResearchesCaptureTemp.push(
              ...this.tableItemsResearchesCapture
            );

            this.mapExcel(
              tableItemsResearchesCaptureTemp,
              this.tableHeadersResearchesCapture
            );

            this.allTableHeaders = [
              this.tableHeaders,
              this.tableHeadersRegisterCapture,
              this.tableHeadersResearchesCapture,
            ];

            this.allTableItems = [
              this.tableItems,
              this.tableItemsRegisterCapture,
              this.tableItemsResearchesCapture,
            ];

            for (const itemsTable of this.allTableItems) {
              if (itemsTable.length > 0) {
                this.totalTables++;
              }
            }

            this.loading = false;
          })
          .catch(this.errorHandler);
      }
    },
    mapExcel(items, header) {
      let listAux = [];
      if (items.length > 0) {
        for (let i = 0; i < this.listTableItems.length; i++) {
          if (items.length > 0)
            listAux.push({ ...this.listTableItems[i], ...items.shift() });
          else break;
        }
        if (items.length > 0) {
          listAux = [...listAux, ...items];
        }

        this.listTableItems = listAux;
        this.tableItemsExcel = listAux;
        this.listTableHeader = [...this.listTableHeader, ...header];
      }
    },
    getProducts() {
      productService
        .getAvailableProductsWithTotalContacts(
          this.filterDateRange && this.filterDateRange.length == 2
            ? {
                dateBegin: this.filterDateRange[0],
                dateEnd: this.filterDateRange[1],
                choosen: this.getViewProducts,
              }
            : {}
        )
        .then((result) => {
          this.products = result;
        })
        .catch(this.errorHandler);
    },
  },
  mounted() {
    this.getProducts();
  },
  watch: {
    filterDateRange() {
      if (!this.hasFilterDateRange && this.selectedProduct) {
        this.resetModule();
      }
    },
    getViewProducts() {
      this.getProducts();
    },
  },
};
</script>

<style scoped>
.general-report-by-product {
  background-color: #fff;
  margin: 0 auto 1% auto;
  width: 98.1%;
  padding-bottom: 2%;
}

.w-100 {
  z-index: 999;
}

span {
  color: red;
  width: 15px;
}
</style>
