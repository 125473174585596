import IpifyRest from './ipify/IpifyRest';

/**
 * @typedef {IpifyService}
 */
export default class IpifyService extends IpifyRest {
  /**
   * @type {String}
   */
  static resource = '';

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  getIp () {
    return this.get('?format=json');
  }

}
