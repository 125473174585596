import axios from 'axios'
import responseErrorHandler from './responseErrorHandler';
import store from '../../store';
import router from '../../router'

const standard = axios.create({
    //baseURL: process.env.VUE_APP_API_BASE_URL,
    //timeout: 100000,
    transformResponse: [
        function (data) {
            return data
        }
    ],
    headers: {
        'Access-Control-Allow-Origin': '*',
        //'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Content-Type': 'application/json',
        //'Access-Control-Allow-Credentials' : 'true',
        //'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With,observe'
    }
})

standard.interceptors.request.use(async (config) => {
    const cookie = document.cookie.split(";").filter(data => data.includes("PLAY_BISESSION"));
    let jwt = null;
    const listRoutesManage = ["ManageAccountPanel", "ManageAccessBI"];
    const listRoutesManageReports = ["ManageReports", "ManageEmailReports", "ManageSendReports", "ManageResendReports"];
    if (store.getters.jwtTemp && (!listRoutesManage.includes(router.currentRoute.name)) && (!listRoutesManageReports.includes(router.currentRoute.name))) {
      jwt = store.getters.jwtTemp;
    } else if (listRoutesManageReports.includes(router.currentRoute.name) && store.getters.jwtTempSendReports) {
      jwt = store.getters.jwtTempSendReports;
    } else if (cookie.length > 0 && cookie) {
      jwt = cookie[0].split("=")[1];
    }
    config.headers.Authorization = jwt ? `Bearer ${jwt}` : '';
    return config;
});

standard.interceptors.response.use((response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;

}, responseErrorHandler);

export default standard;
