<template>
  <v-app id="app">
    <router-view v-if="pagesView || continueAnyway" />
    <screen-info-download-mobile-app
      v-else
      :handleContinueAnyway="handleContinueAnyway"
    />
  </v-app>
</template>

<script>
import ScreenInfoDownloadMobileAppVue from "./components/ScreenInfoDownloadMobileApp.vue";

export default {
  name: "App",
  components: {
    "screen-info-download-mobile-app": ScreenInfoDownloadMobileAppVue,
  },
  data() {
    return {
      pagesView: true,
      continueAnyway: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next({ path: "/dashboard" });
  },
  methods: {
    windowResize() {
      const widthPage = window.innerWidth;
      const minPageSize = 1024;

      if (widthPage < minPageSize) {
        this.pagesView = false;
      } else if (widthPage >= minPageSize) {
        this.pagesView = true;
      }
    },
    handleContinueAnyway() {
      this.continueAnyway = true;
      this.windowResize();
    },
  },
  mounted() {
    this.windowResize();
    window.addEventListener("resize", this.windowResize);
  },
};
</script>

<style lang="scss">
:root {
  --smarts-wine: #99225c;
  --smarts-black: #000e17;
  --smarts-white: #ffffff;
  --smarts-gray-dark: #14272e;

  --black: #000000;

  --foreground-gray: #5f5f5d;

  --background-light: #e6e6e6;

  --custom-green: #47a34b;
  --custom-yellow: #fea523;
  --custom-red: #ee504d;
  --custom-gray: #5a6268;

  --scrollbar-size: 10px;
  --scrollbar-background: rgb(241, 241, 241);
  --scrollbar-thumb-color: rgb(193, 193, 193);
  --scrollbar-thumb-color-hover: rgb(168, 168, 168);
  --scrollbar-thumb-color-active: rgb(120, 120, 120);
  --scrollbar-button-background-hover: rgb(210, 210, 210);
  --scrollbar-button-background-active: rgb(120, 120, 120);
}

* {
  font-family: "Inter", Helvetica, Arial;
}

/* scrollbar styling */
::-webkit-scrollbar {
  width: var(--scrollbar-size);
  height: var(--scrollbar-size);
}

::-webkit-scrollbar-corner {
  background: rgba(255, 255, 255, 0.6);
}

::-webkit-scrollbar-track {
  background: var(--scrollbar-background);
}

::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-color-hover);
}

::-webkit-scrollbar-thumb:active {
  background: var(--scrollbar-thumb-color-active);
}

::-webkit-scrollbar-button {
  height: cal(var(--scrollbar-size) + 1px);
  width: cal(var(--scrollbar-size) + 1px);

  background: no-repeat var(--scrollbar-background);
  background-size: calc(var(--scrollbar-size) / 2);
  background-position: center;
}

::-webkit-scrollbar-button:hover {
  background: no-repeat var(--scrollbar-button-background-hover);
  background-size: calc(var(--scrollbar-size) / 2);
  background-position: center;
}

::-webkit-scrollbar-button:active {
  background: no-repeat var(--scrollbar-button-background-active);
  background-size: calc(var(--scrollbar-size) / 2);
  background-position: center;
}

::-webkit-scrollbar-button:vertical:decrement {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='%23505050' viewBox='0 0 10 7'><polygon points='0,7 10,7 5,0'/></svg>");
}
::-webkit-scrollbar-button:vertical:decrement:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='white' viewBox='0 0 10 7'><polygon points='0,7 10,7 5,0'/></svg>");
}

::-webkit-scrollbar-button:vertical:increment {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='%23505050' viewBox='0 0 10 7'><polygon points='0,0 10,0 5,7'/></svg>");
}
::-webkit-scrollbar-button:vertical:increment:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='white' viewBox='0 0 10 7'><polygon points='0,0 10,0 5,7'/></svg>");
}

::-webkit-scrollbar-button:horizontal:increment {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='%23505050' viewBox='0 0 7 10'><polygon points='0,0 0,10 7,5'/></svg>");
}
::-webkit-scrollbar-button:horizontal:increment:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='white' viewBox='0 0 7 10'><polygon points='0,0 0,10 7,5'/></svg>");
}

::-webkit-scrollbar-button:horizontal:decrement {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='%23505050' viewBox='0 0 7 10'><polygon points='7,0 7,10 0,5'/></svg>");
}
::-webkit-scrollbar-button:horizontal:decrement:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='white' viewBox='0 0 7 10'><polygon points='7,0 7,10 0,5'/></svg>");
}
/* scrollbar styling end */

html {
  overflow-y: scroll !important;
}

html,
body,
#app {
  padding: 0 !important;
  margin: 0;
  background-color: var(--background-light);
}

#app {
  min-height: 100vh;
}

// Custom Buttons style
button.btn:is(.btn-custom-green, .btn-custom-yellow, .btn-custom-red, .btn-custom-gray) {
  border-radius: 20px;
}

.btn-custom-green,
.btn-custom-yellow,
.btn-custom-red,
.btn-custom-gray,
.btn-smarts-wine {
  transition: all 0.2s;
  // opacity: 0.85;
  filter: brightness(0.9);
}

:is(.btn-custom-green, .btn-custom-yellow, .btn-custom-red, .btn-custom-gray, .btn-smarts-wine):is(:not(:hover), :hover) {
  color: white;
}

:is(.btn-custom-green, .btn-custom-yellow, .btn-custom-red, .btn-custom-gray, .btn-smarts-wine):not(.outline-on-hover):hover:enabled {
  // opacity: 1;
  filter: brightness(1);
}

.btn-custom-green {
  background-color: var(--custom-green);
  border-color: var(--custom-green);
}

.btn-custom-yellow {
  background-color: var(--custom-yellow);
  border-color: var(--custom-yellow);
}

.btn-custom-red {
  background-color: var(--custom-red);
  border-color: var(--custom-red);
}

.btn-custom-gray {
  background-color: var(--custom-gray);
  border-color: var(--custom-gray);
}

.btn-smarts-wine {
  background-color: var(--smarts-wine);
  border-color: var(--smarts-wine);
}

.outline-on-hover:hover {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent !important;
}
// End of custom buttons style

select {
  cursor: pointer;
}

.swal2-container {
  z-index: 10005 !important;
}

.swal-wide-custom {
  width: auto !important;
}

label.v-label {
  margin: 0;
}

.v-list,
.v-select-list,
.v-menu__content {
  z-index: 10005 !important;
}

.v-btn {
  text-transform: none !important;
}

.v-overlay,
.v-overlay--active,
.v-overlay__scrim,
.v-overlay__content,
.v-dialog__content--active,
.v-dialog,
.v-dialog--active {
  z-index: 10004 !important;
}

.v-dialog__content {
  z-index: 10005 !important;
}

.v-overlay__scrim {
  background-color: #ccc !important;
}
</style>
