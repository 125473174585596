<template>
  <mobule-base-builder
    :module-data="moduleData"
    :editMode="editMode"
    :loading="loading"
    :error="error"
    :downloadImage="dataDownload"
    :tableHeaders="tableHeaders"
    :tableItems="tableItems"
    :tableHeadersExcel="tableHeadersExcel"
    :tableItemsExcel="tableItemsExcel"
  >
    <template v-slot:content>
      <div class="pa-3">
        <span v-if="!hasFilterDateRange"
          >***É necessário preencher os campos de "Período dos dados" para buscar as
          informações!***</span
        >
        <v-autocomplete
          v-model="selectedProduct"
          :items="products"
          item-text="name"
          item-value="id"
          @change="getData()"
          id="simplified-daily-report"
          label="Selecione o produto"
          class="px-4 pt-2"
          :disabled="!hasFilterDateRange"
          outlined
          dense
        ></v-autocomplete>
      </div>

      <div v-if="tableItems.length > 0" class="simplified-daily-report">
        <module-table-list-data-v-2
          :tableHeaders="tableHeaders"
          :tableItems="tableItems"
          :showFooter="false"
          :footerPropsItemsPerPageText="footerPropsItemsPerPageText"
        />
        <module-table-list-data-v-2
          :tableHeaders="tableHeadersContactsBySalesCompaniesInWeek"
          :tableItems="tableItemsContactsBySalesCompaniesInWeek"
          :footerPropsItemsPerPageText="footerPropsItemsPerPageText"
        />
      </div>
      <message-status-data
        v-else
        :selected-products="selectedProductsIds()"
        :infoLoading="loading"
      />
    </template>
  </mobule-base-builder>
</template>

<script>
import MixinModuleBase from "../../../mixins/MixinModuleBase";
import MobuleBaseBuilder from "../base/MobuleBaseBuilder.vue";

import ModuleChartBar from "../base/ModuleChartBar.vue";
import ModuleChartDoughnut from "../base/ModuleChartDoughnut.vue";
import ModuleTableListDataV2 from "../base/ModuleTableListDataV2.vue";
import MessageStatusData from "../../MessageStatusData.vue";

import ContactService from "../../../services/ContactService";
const contactService = ContactService.build();

import ProductService from "../../../services/ProductService";
const productService = ProductService.build();

export default {
  name: "module-simplified-daily-report",
  mixins: [MixinModuleBase],
  components: {
    MobuleBaseBuilder,
    ModuleTableListDataV2,
    MessageStatusData,
    ModuleChartDoughnut,
    ModuleChartBar,
  },
  data: function () {
    return {
      tableHeaders: [
        {
          text: "Dia da semana",
          align: "start",
          sortable: true,
          value: "weekDay",
        },
        {
          text: "Indicação",
          align: "start",
          sortable: true,
          value: "indication",
        },
        {
          text: "Espontânea",
          align: "start",
          sortable: true,
          value: "spontaneous",
        },
        {
          text: "Retorno",
          align: "start",
          sortable: true,
          value: "return",
        },
        {
          text: "Telefone",
          align: "start",
          sortable: true,
          value: "telephone",
        },
        {
          text: "Fora do Expediente",
          align: "start",
          sortable: true,
          value: "outOfService",
        },
        {
          text: "Total",
          align: "start",
          sortable: true,
          value: "total",
        },
      ],
      tableHeadersContactsBySalesCompaniesInWeek: [
        {
          text: "Empresa de Vendas (Dia da semana)",
          align: "start",
          sortable: true,
          value: "weekDay",
        },
        {
          text: "Indicação",
          align: "start",
          sortable: true,
          value: "indication",
        },
        {
          text: "Espontânea",
          align: "start",
          sortable: true,
          value: "spontaneous",
        },
        {
          text: "Retorno",
          align: "start",
          sortable: true,
          value: "return",
        },
        {
          text: "Telefone",
          align: "start",
          sortable: true,
          value: "telephone",
        },
        {
          text: "Fora do Expediente",
          align: "start",
          sortable: true,
          value: "outOfService",
        },
        {
          text: "Total",
          align: "start",
          sortable: true,
          value: "total",
        },
      ],
      tableHeadersExcel: [
        {
          text: "Produtos Selecionados",
          align: "start",
          sortable: true,
          value: "selectedProducts",
        },
        {
          text: "Dia da semana",
          align: "start",
          sortable: true,
          value: "weekDay",
        },
        {
          text: "Indicação",
          align: "start",
          sortable: true,
          value: "indication",
        },
        {
          text: "Espontânea",
          align: "start",
          sortable: true,
          value: "spontaneous",
        },
        {
          text: "Retorno",
          align: "start",
          sortable: true,
          value: "return",
        },
        {
          text: "Telefone",
          align: "start",
          sortable: true,
          value: "telephone",
        },
        {
          text: "Fora do Expediente",
          align: "start",
          sortable: true,
          value: "outOfService",
        },
        {
          text: "Total",
          align: "start",
          sortable: true,
          value: "total",
        },
      ],
      tableItems: [],
      tableItemsContactsBySalesCompaniesInWeek: [],
      tableItemsExcel: [],
      footerPropsItemsPerPageText: "Itens por página",
      dataDownload: {
        class: "simplified-daily-report",
        title: this.moduleData.headerTitle,
      },
      barChartData: [],
      labels: [],
      contactsByCity: [],
      selectedProduct: [],
      products: [],
    };
  },
  computed: {
    getViewProducts() {
      return this.$store.getters.viewProducts;
    },
    getSelectedProducts() {
      return this.$store.getters.selectedProducts;
    },
    hasFilterDateRange() {
      return (
        this.filterDateRange &&
        this.filterDateRange.length == 2 &&
        this.filterDateRange[0].length > 1 &&
        this.filterDateRange[1].length > 1
      );
    },
  },
  methods: {
    resetModule() {
      this.tableItems = [];
      this.tableItemsContactsBySalesCompaniesInWeek = [];
      this.tableItemsExcel = [];
      this.labels = [];
    },
    formatData(data, hyphen = false) {
      switch (data["weekDay"]) {
        case 0:
          data["weekDay"] = hyphen ? "- Segunda-feira" : "Segunda-feira";
          break;
        case 1:
          data["weekDay"] = hyphen ? "- Terça-feira" : "Terça-feira";
          break;
        case 2:
          data["weekDay"] = hyphen ? "- Quarta-feira" : "Quarta-feira";
          break;
        case 3:
          data["weekDay"] = hyphen ? "- Quinta-feira" : "Quinta-feira";
          break;
        case 4:
          data["weekDay"] = hyphen ? "- Sexta-feira" : "Sexta-feira";
          break;
        case 5:
          data["weekDay"] = hyphen ? "- Sábado" : "Sábado";
          break;
        case 6:
          data["weekDay"] = hyphen ? "- Domingo" : "Domingo";
          break;
      }
      return data;
    },
    getData() {
      this.resetModule();

      if (this.selectedProduct) {
        const isProdSelected = this.getSelectedProducts.filter(
          (prod) => prod.id === this.selectedProduct
        );
        if (isProdSelected.length === 0) this.selectedProduct = [];
      }
      this.products = this.getSelectedProducts;

      if (this.selectedProduct.length === 0 && this.products.length > 0) {
        this.selectedProduct = this.products[0].id;
      }

      if (this.dataRetrievalOk()) {
        this.loading = true;

        contactService
          .getContactsByWeekDayWithDateBegin({
            selectedProductsIds: this.selectedProduct,
            dateBegin: this.filterDateRange[0],
          })
          .then(async (r) => {
            this.resetModule();

            const productSelected = this.getSelectedProducts.filter(
              (prod) => prod.id === this.selectedProduct
            );

            if (r.length > 0) {
              r.forEach((item, index) => {
                if (index === 0) {
                  this.tableItems.push(this.formatData({ ...item }));
                  this.tableItemsExcel.push({
                    selectedProducts: productSelected.name,
                    ...item,
                  });
                } else {
                  this.tableItems.push(this.formatData({ ...item }));
                  this.tableItemsExcel.push({
                    selectedProducts: "",
                    ...item,
                  });
                }
              });
              const contactsBySalesCompaniesInWeek = await contactService.getContactsBySalesCompaniesInWeek(
                {
                  selectedProductsIds: this.selectedProduct,
                  dateBegin: this.filterDateRange[0],
                }
              );

              const contactsBySalesCompaniesInWeekToTable = this.contactsBySalesCompaniesInWeekToTable(
                contactsBySalesCompaniesInWeek
              );

              if (contactsBySalesCompaniesInWeekToTable.length > 0) {
                this.tableItemsExcel.push({});
                this.tableItemsExcel.push({
                  selectedProducts: "Produtos Selecionados",
                  weekDay: "Empresa de Vendas (Dia da semana)",
                  indication: "Indicação",
                  spontaneous: "Espontânea",
                  return: "Retorno",
                  telephone: "Telefone",
                  outOfService: "Fora do Expediente",
                  total: "Total",
                });

                contactsBySalesCompaniesInWeekToTable.forEach((item, index) => {
                  item = this.formatData({ ...item }, true);
                  if (index === 0) {
                    this.tableItemsContactsBySalesCompaniesInWeek.push(item);
                    this.tableItemsExcel.push({
                      selectedProducts: productSelected.name,
                      ...item,
                    });
                  } else {
                    this.tableItemsContactsBySalesCompaniesInWeek.push(item);
                    this.tableItemsExcel.push({
                      selectedProducts: "",
                      ...item,
                    });
                  }
                });
              }
            }

            this.loading = false;
          })
          .catch(this.errorHandler);
      }
    },
    contactsBySalesCompaniesInWeekToTable(contactsBySalesCompaniesInWeek) {
      let contactsBySalesCompaniesInWeekAux = [];
      contactsBySalesCompaniesInWeek.forEach((item) => {
        const hasSalesCompany = contactsBySalesCompaniesInWeekAux.filter(
          (data) => data.weekDay === item.salesCompanyName
        );
        if (hasSalesCompany.length === 0) {
          contactsBySalesCompaniesInWeekAux.push({
            weekDay: item.salesCompanyName ? item.salesCompanyName : "Outros",
          });
        }
        contactsBySalesCompaniesInWeekAux.push({ ...item });
      });

      return contactsBySalesCompaniesInWeekAux;
    },
  },
  mounted() {
    this.products = this.getSelectedProducts;
  },
  watch: {
    filterDateRange() {
      if (!this.hasFilterDateRange && this.selectedProduct) {
        this.resetModule();
      }
    },
  },
};
</script>

<style scoped>
.echarts {
  height: auto !important;
  margin-bottom: 10px;
}

.simplified-daily-report {
  background-color: #fff;
}

.w-100 {
  z-index: 999;
}

span {
  color: red;
  width: 15px;
}
</style>
