<template>
  <mobule-base-builder
    :module-data="moduleData"
    :editMode="editMode"
    :loading="loading"
    :error="error"
    :downloadImage="dataDownload"
    :typePanelPDF="''"
  >
    <template v-slot:content>
      <div class="px-6 py-3 module-whatsapp">
        <span v-if="!hasFilterDateRange"
          >***É necessário preencher os campos de "Período dos dados" para buscar as
          informações!***</span
        >
        <v-autocomplete
          v-model="selectedProduct"
          :items="products"
          item-text="name"
          item-value="id"
          @change="getData()"
          id="whatsapp"
          label="Selecione o produto"
          class="px-4 pt-2"
          :disabled="!hasFilterDateRange"
          outlined
          dense
        ></v-autocomplete>
      </div>
      <div
        class="app"
        v-if="selectedProduct.length > 0 && contactsNumberHistoryWhatsApp.length > 0"
      >
        <div class="row app-one">
          <div class="col-sm-4 side">
            <div class="side-one">
              <!-- Heading -->
              <div class="row heading">
                <div class="col-sm-3 col-xs-3 heading-avatar">
                  <div class="heading-avatar-icon">
                    <img
                      :src="require(`../../../../public/assets/images/whatsapp-logo.png`)"
                    />
                  </div>
                </div>
                <div
                  class="col-sm-2 col-xs-2 heading-compose float-right"
                  title="Atualizar lista de contatos"
                >
                  <i
                    class="fas fa-redo-alt fa-2x"
                    aria-hidden="true"
                    @click="getData"
                  ></i>
                </div>
              </div>
              <!-- Heading End -->

              <!-- SearchBox -->
              <div class="row searchBox">
                <div class="col-sm-12">
                  <v-text-field
                    label="Buscar contato"
                    prepend-inner-icon="mdi mdi-magnify"
                    outlined
                    v-model="searchContactText"
                    id="searchContactText"
                    clearable
                    dense
                    class="px-4 py-2"
                  ></v-text-field>
                </div>
              </div>

              <!-- Search Box End -->
              <!-- sideBar filtered -->
              <div class="sideBar" v-if="filteredContacts.length > 0">
                <div
                  class="row sideBar-body"
                  v-for="contact in filteredContacts"
                  :key="contact.contactNumber"
                  @click="getChatWhatsApp(contact.contactNumber)"
                  :style="{
                    backgroundColor:
                      selectedContact?.contactNumber === contact.contactNumber
                        ? '#f2f2f2'
                        : '',
                  }"
                >
                  <div class="col-sm-3 col-xs-3 sideBar-avatar">
                    <div class="avatar-icon">
                      <img :src="contact.imgURL" loading="lazy" />
                    </div>
                  </div>
                  <div class="col-sm-9 col-xs-9 sideBar-main">
                    <div class="row">
                      <div class="col-sm-8 col-xs-8 sideBar-name">
                        <span class="name-meta">{{ contact.contactName }} </span>
                      </div>
                      <div class="col-sm-4 col-xs-4 float-right sideBar-time">
                        <span class="time-meta float-right"
                          >{{ contact.createdAt }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Sidebar filtered End -->

              <div
                class="sideBar"
                v-else-if="
                  filteredContacts.length === 0 &&
                  searchContactText &&
                  searchContactText.length > 0
                "
              >
                <div class="col-sm-12 col-xs-12 sideBar-main">
                  <div class="row sideBar-body">
                    <div class="row">
                      <div class="col-sm-12 col-xs-12 sideBar-no-search-results">
                        <span class="no-search-results">Nenhum contato encontrado </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- sideBar -->
              <div class="sideBar" v-else>
                <div
                  class="row sideBar-body"
                  v-for="contact in contactsNumberHistoryWhatsApp"
                  :key="contact.contactNumber"
                  @click="getChatWhatsApp(contact.contactNumber)"
                  :style="{
                    backgroundColor:
                      selectedContact?.contactNumber === contact.contactNumber
                        ? '#f2f2f2'
                        : '',
                  }"
                >
                  <div class="col-sm-3 col-xs-3 sideBar-avatar">
                    <div class="avatar-icon">
                      <img :src="contact.imgURL" loading="lazy" />
                    </div>
                  </div>
                  <div class="col-sm-9 col-xs-9 sideBar-main">
                    <div class="row">
                      <div class="col-sm-8 col-xs-8 sideBar-name">
                        <span class="name-meta">{{ contact.contactName }} </span>
                      </div>
                      <div class="col-sm-4 col-xs-4 float-right sideBar-time">
                        <span class="time-meta float-right"
                          >{{ contact.createdAt }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Sidebar  End -->
            </div>
            <!-- Sidebar End -->
          </div>

          <!-- New Message Sidebar End -->

          <!-- Conversation Start -->
          <div class="col-sm-8 conversation">
            <!-- Heading -->
            <div class="heading">
              <div class="col-sm-2 col-md-1 col-xs-2 heading-avatar">
                <div class="heading-avatar-icon">
                  <img
                    :src="
                      hasContactSelected
                        ? selectedContact?.imgURL
                        : require(`../../../../public/assets/images/arrow-left.png`)
                    "
                  />
                </div>
              </div>
              <div
                :class="[
                  showMessageSearch ? 'col-sm-4' : 'col-sm-8',
                  showMessageSearch ? 'col-xs-7' : 'col-xs-3',
                  'heading-name',
                ]"
              >
                <a class="heading-name-meta"
                  >{{
                    hasContactSelected
                      ? selectedContact?.contactName
                      : "Selecione um contato ao lado para ver as conversas"
                  }}
                </a>
                <span class="heading-online">Online</span>
              </div>
              <div
                class="col-sm-4 col-xs-3 heading-search-message"
                v-if="showMessageSearch"
              >
                <v-text-field
                  label="Buscar mensagens"
                  prepend-inner-icon="mdi mdi-magnify"
                  outlined
                  v-model="searchMessageText"
                  id="searchMessageText"
                  clearable
                  dense
                  solo
                ></v-text-field>
              </div>
              <label
                class="col-sm-1 col-xs-1 heading-search float-right"
                @click="handleShowSearchMessage"
                title="Buscar mensagens"
              >
                <i class="fas fa-search fa-2x float-right" aria-hidden="false"></i>
              </label>
            </div>
            <!-- Heading End -->

            <!-- Filtered Message Box -->
            <div
              class="message"
              id="conversation"
              v-if="hasContactSelected && filteredMessages.length > 0"
            >
              <div class="row message-previous">
                <div class="col-sm-12 previous">
                  <strong>Período das mensagens: </strong>
                  <span>
                    {{ formatFilterRangeDate() }}
                  </span>
                </div>
              </div>

              <div
                class="row message-body"
                v-for="message in filteredMessages"
                :key="message.id"
              >
                <div
                  :class="[
                    'col-sm-12',
                    message.fromMe ? 'message-main-sender' : 'message-main-receiver',
                  ]"
                >
                  <div :class="[message.fromMe ? 'sender' : 'receiver']">
                    <div class="message-isForwarded-text" v-if="message.isForwarded">
                      <div class="container-icon-text">
                        <i class="fas fa-share"></i>
                        <p>Encaminhada</p>
                      </div>
                    </div>
                    <div class="message-text" v-if="message.typeMessage === 'chat'">
                      {{ message.content }}
                    </div>
                    <span class="message-time float-right">
                      {{ message.createdAt }}
                    </span>
                  </div>
                  <div
                    class="message-revoked-text"
                    v-if="message.typeMessage === 'revoked'"
                  >
                    <div class="container-icon-text">
                      <i class="far fa-times-circle"></i>
                      <p>{{ message.content }}</p>
                    </div>

                    <span class="message-time-revoked float-right">
                      {{ message.createdAt }}
                    </span>
                  </div>
                </div>
              </div>

              <label
                class="down-conversation-container"
                @click="handleGoToLastMessages"
                v-if="showGoToLastMessages"
                title="Ver mensagem mais recente"
              >
                <i class="fa fa-angle-double-down" aria-hidden="true"></i>
              </label>
            </div>
            <!-- Filtered Message Box End -->

            <!-- Message Box not search messages -->
            <div
              class="message"
              id="conversation"
              v-else-if="
                hasContactSelected &&
                filteredMessages.length === 0 &&
                searchMessageText &&
                searchMessageText.length > 0
              "
            >
              <div class="row message-previous-no-message">
                <div class="col-sm-12 previous">
                  <strong
                    >Nenhuma mensagem encontrada para os termos pesquisados:
                  </strong>
                  <span>
                    {{ searchMessageText }}
                  </span>
                </div>
              </div>
            </div>
            <!-- Message Box not search messages End -->

            <!-- Message Box -->
            <div class="message" id="conversation" v-else>
              <div class="row message-previous">
                <div class="col-sm-12 previous">
                  <strong>Período das mensagens: </strong>
                  <span>
                    {{ formatFilterRangeDate() }}
                  </span>
                </div>
              </div>

              <div
                class="container-period-messages"
                v-for="(messages, index) in messagesSelectedContact"
                :key="index"
                :style="{ marginTop: index === 0 ? '10px' : '0' }"
              >
                <strong>{{ messages.period }}</strong>
                <div
                  class="row message-body"
                  v-for="message in messages.messages"
                  :key="message.id"
                >
                  <div
                    :class="[
                      'col-sm-12',
                      message.fromMe ? 'message-main-sender' : 'message-main-receiver',
                    ]"
                  >
                    <div :class="[message.fromMe ? 'sender' : 'receiver']">
                      <div class="message-isForwarded-text" v-if="message.isForwarded">
                        <div class="container-icon-text">
                          <i class="fas fa-share"></i>
                          <p>Encaminhada</p>
                        </div>
                      </div>
                      <div class="message-text" v-if="message.typeMessage === 'chat'">
                        {{ message.content }}
                        <span class="message-time float-right">
                          {{ message.createdAt }}
                        </span>
                      </div>

                      <div
                        class="message-revoked-text"
                        v-if="message.typeMessage === 'revoked'"
                      >
                        <div class="container-icon-text">
                          <i class="far fa-times-circle"></i>
                          <p>{{ message.content }}</p>
                        </div>

                        <span class="message-time-revoked float-right">
                          {{ message.createdAt }}
                        </span>
                      </div>
                      <div
                        class="message-image"
                        v-else-if="message.typeMessage === 'image'"
                      >
                        <img
                          :src="`https://msg-whats-api-bi.s3.sa-east-1.amazonaws.com/${message.content}`"
                          v-if="message.content"
                        />
                        <img src="https://placehold.co/600x400.png" v-else />
                        <div class="caption-text" v-if="message.caption !== ''">
                          {{ message.caption }}
                        </div>
                        <div class="image-download-time-container">
                          <a
                            :href="`https://msg-whats-api-bi.s3.sa-east-1.amazonaws.com/${message.content}`"
                            v-if="message.content"
                          >
                            <i
                              class="far fa-arrow-alt-circle-down"
                              aria-hidden="true"
                            ></i>
                          </a>
                          <span class="message-time">
                            {{ message.createdAt }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="message-sticker"
                        v-else-if="message.typeMessage === 'sticker'"
                      >
                        <img
                          :src="`https://msg-whats-api-bi.s3.sa-east-1.amazonaws.com/${message.content}`"
                          v-if="message.content"
                        />
                        <img src="https://placehold.co/50x50.png" v-else />
                        <span class="message-time float-right">
                          {{ message.createdAt }}
                        </span>
                      </div>
                      <div
                        class="message-video"
                        v-else-if="message.typeMessage === 'video'"
                      >
                        <video width="400" controls v-if="message.content">
                          <source
                            :src="`https://msg-whats-api-bi.s3.sa-east-1.amazonaws.com/${message.content}`"
                          />
                        </video>
                        <img src="https://placehold.co/1920x1080.mp4" v-else />
                        <div class="caption-text" v-if="message.caption !== ''">
                          {{ message.caption }}
                        </div>
                        <span class="message-time float-right">
                          {{ message.createdAt }}
                        </span>
                      </div>
                      <div
                        class="message-audio"
                        v-else-if="['ptt', 'audio'].includes(message.typeMessage)"
                      >
                        <div
                          class="container-img-and-audio"
                          :style="{
                            flexDirection: message.fromMe ? 'row-reverse' : 'row',
                          }"
                        >
                          <div
                            class="heading-avatar-audio-icon"
                            :style="{
                              margin: message.fromMe ? '0 0 0 1rem' : '0 1rem 0 0',
                            }"
                          >
                            <img
                              :src="
                                message.fromMe
                                  ? require(`../../../../public/assets/images/whatsapp-no-perfil-image.png`)
                                  : hasContactSelected
                                  ? selectedContact?.imgURL
                                  : require(`../../../../public/assets/images/arrow-left.png`)
                              "
                            />
                            <i class="fa fa-microphone fa-2x" aria-hidden="true"></i>
                          </div>
                          <audio
                            width="400"
                            controls
                            :style="{
                              backgroundColor: message.fromMe ? '#DCF8C6' : '#Efefee',
                              borderRadius: message.fromMe ? '0' : '2rem',
                            }"
                            v-if="message.content"
                          >
                            <source
                              :src="`https://msg-whats-api-bi.s3.sa-east-1.amazonaws.com/${message.content}`"
                            />
                          </audio>
                          <div v-else id="container-no-audio">
                            <img
                              id="image-no-audio"
                              :src="
                                require(`../../../../public/assets/images/no-audio.png`)
                              "
                              alt=""
                            />
                          </div>
                        </div>
                        <span class="message-time float-right">
                          {{ message.createdAt }}
                        </span>
                      </div>
                      <!--
                        Caso queira utilizar o PDF com pré visualizador é só descomentar esse código e incluir:
                        &&
                          message.fileType === 'pdf' no v-if da próxima div
                      <div
                        class="message-document"
                        v-else-if="
                          message.typeMessage === 'document' &&
                          message.fileType === 'pdf'
                        "
                      >
                        <iframe
                          :src="`https://msg-whats-api-bi.s3.sa-east-1.amazonaws.com/${message.content}`"
                          width="500"
                          height="678"
                          ><p>This browser does not support PDF!</p></iframe
                        >
                        <span class="message-time float-right">
                          {{ message.createdAt }}
                        </span>
                      </div>
                      -->
                      <div
                        class="message-document"
                        v-else-if="message.typeMessage === 'document'"
                      >
                        <div
                          class="message-document-docs"
                          :style="{
                            backgroundColor: message.fromMe ? '#D1F4CC' : '#F5F6F6',
                          }"
                        >
                          <img
                            :src="
                              message.fileType
                                ? require(`../../../../public/assets/images/${message.fileType}-image.png`)
                                : require(`../../../../public/assets/images/doc-not-identified-image.png`)
                            "
                            alt=""
                          />
                          <span>
                            {{ message.docName }}
                            <small v-if="message.content"
                              >{{ message.fileSize }} -
                              {{ message.fileType.toUpperCase() }}</small
                            >
                          </span>
                          <a
                            :href="`https://msg-whats-api-bi.s3.sa-east-1.amazonaws.com/${message.content}`"
                            v-if="
                              message.docName !==
                                'Tipo de arquivo não suportado, contate o administrador!' ||
                              message.content
                            "
                          >
                            <i
                              class="far fa-arrow-alt-circle-down"
                              aria-hidden="true"
                              v-if="message.content"
                            ></i>
                          </a>
                        </div>
                        <div class="caption-text" v-if="message.caption !== ''">
                          {{ message.caption }}
                        </div>
                        <span class="message-time float-right">
                          {{ message.createdAt }}
                        </span>
                      </div>
                      <div
                        class="message-location"
                        v-else-if="message.typeMessage === 'location'"
                      >
                        <a
                          :href="`https://www.google.com/maps/dir/${message?.latitude},${message?.longitude}`"
                          target="_blank"
                        >
                          <img
                            :src="`https://maps.googleapis.com/maps/api/staticmap?center=${message?.latitude},${message?.longitude}&zoom=15&size=400x200&maptype=roadmap&markers=${message?.latitude},${message?.longitude}&key=${VUE_APP_APIKEY_GOOGLE_MAPS}`"
                          />
                        </a>
                        <span class="message-time float-right">
                          {{ message.createdAt }}
                        </span>
                      </div>
                      <div
                        class="message-poll-creation"
                        v-else-if="message.typeMessage === 'poll_creation'"
                      >
                        <h6 class="m-0">{{ message.pollName }}</h6>

                        <hr />

                        <ul>
                          <li
                            v-for="option in getJsonObject(message.pollOptions)"
                            :key="`${message.chatId}-${message.pollName}-${option.localId}`"
                          >
                            <div class="poll-creation-container">
                              <div class="input-label-container">
                                <input
                                  :id="`${option.localId}-${message.pollName}`"
                                  type="checkbox"
                                  :checked="option.total > 0"
                                  :value="option.total"
                                />
                                <label></label>
                                <p>{{ option.name }}</p>
                              </div>

                              <div
                                v-if="option.total > 0"
                                class="col-sm-4 col-md-2 col-xs-4 heading-avatar-poll-creation"
                              >
                                <div class="heading-avatar-icon-poll-creation">
                                  <img
                                    :src="
                                      hasContactSelected
                                        ? selectedContact?.imgURL
                                        : require(`../../../../public/assets/images/whatsapp-no-perfil-image.png`)
                                    "
                                  />
                                </div>
                              </div>
                              <p>{{ option.total }}</p>
                            </div>

                            <v-progress-linear
                              :background-color="option.total > 0 ? 'success' : '#ccc'"
                              color="success"
                              :model-value="option.total"
                              :max="message.pollMaxTotal"
                              height="8"
                              rounded
                            ></v-progress-linear>
                          </li>
                        </ul>
                        <span class="message-time float-right">
                          {{ message.createdAt }}
                        </span>
                      </div>
                      <div
                        class="message-call-log-text"
                        v-if="message.typeMessage === 'call_log'"
                      >
                        <div class="container-icon-text">
                          <i
                            v-if="message.subtype === 'call_audio'"
                            class="fas fa-phone-slash"
                          ></i>
                          <i
                            v-if="message.subtype === 'call_video'"
                            class="fas fa-video-slash"
                          ></i>
                          <p>
                            {{
                              message.subtype === "call_audio"
                                ? `Chamada de voz perdida às ${message.createdAt}`
                                : `Chamada de video perdida às ${message.createdAt}`
                            }}
                          </p>
                        </div>
                      </div>
                      <div
                        v-else-if="message.typeMessage === 'vcard'"
                        class="message-vcard"
                      >
                        <template>
                          <v-banner lines="two" icon="mdi-account-box">
                            <h6>Contato:</h6>
                            <span> {{ message.vcardFormattedName }} : </span>
                            <span>
                              {{ message.vCardContactNumber }}
                            </span>
                          </v-banner>
                        </template>
                      </div>

                      <div
                        :class="[
                          message.fromMe
                            ? 'reaction-container-sender'
                            : 'reaction-container-receiver',
                        ]"
                        v-if="message.hasReaction && message.reactionText !== ''"
                      >
                        {{ message.reactionText }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <label
                class="down-conversation-container"
                @click="handleGoToLastMessages"
                v-if="showGoToLastMessages"
                title="Ver mensagem mais recente"
              >
                <i class="fa fa-angle-double-down" aria-hidden="true"></i>
              </label>
            </div>
            <!-- Message Box End -->

            <!-- Reply Box -->
            <div class="row reply" v-if="hasContactSelected">
              <div class="col-sm-1 col-xs-1 reply-emojis">
                <i class="far fa-smile fa-2x" aria-hidden="true"></i>
              </div>
              <div class="col-sm-9 col-xs-9 reply-main">
                <textarea class="form-control" rows="1" id="comment" disabled></textarea>
              </div>
              <div class="col-sm-1 col-xs-1 reply-recording">
                <i class="fa fa-microphone fa-2x" aria-hidden="true"></i>
              </div>
              <div class="col-sm-1 col-xs-1 reply-send">
                <i class="far fa-paper-plane fa-2x" aria-hidden="true"></i>
              </div>
            </div>
            <!-- Reply Box End -->
          </div>
          <!-- Conversation End -->
        </div>
        <!-- App One End -->
      </div>
      <message-status-data-unique-product
        v-show="contactsNumberHistoryWhatsApp.length === 0"
        :selected-product="hasDatasValue"
        :infoLoading="loading"
      />
    </template>
  </mobule-base-builder>
</template>

<script>
import MixinModuleBase from "../../../mixins/MixinModuleBase";
import MobuleBaseBuilder from "../base/MobuleBaseBuilder.vue";
import MessageStatusDataUniqueProduct from "../../MessageStatusDataUniqueProduct.vue";

import ProductService from "../../../services/ProductService";
const productService = ProductService.build();

import HistoryWhatsAppService from "../../../services/HistoryWhatsAppService";
const historyWhatsAppService = HistoryWhatsAppService.build();

export default {
  name: "module-whatsapp",
  mixins: [MixinModuleBase],

  components: {
    MobuleBaseBuilder,
    MessageStatusDataUniqueProduct,
  },

  data: function () {
    return {
      VUE_APP_APIKEY_GOOGLE_MAPS: process.env.VUE_APP_APIKEY_GOOGLE_MAPS,
      dataDownload: {
        class: "module-whatsapp",
        title: this.moduleData.headerTitle,
      },
      products: [],
      selectedProduct: [],
      contactsNumberHistoryWhatsApp: [],
      selectedContact: {},
      hasContactSelected: false,
      showMessageSearch: false,
      showGoToLastMessages: false,
      searchContactText: "",
      searchMessageText: "",
      filteredContacts: [],
      filteredMessages: [],
      messagesSelectedContact: [],
    };
  },
  methods: {
    getJsonObject(jsonString) {
      return JSON.parse(jsonString);
    },
    resetModule() {
      this.contactsNumberHistoryWhatsApp = [];
      this.selectedContact = {};
      this.hasContactSelected = false;
      this.showMessageSearch = false;
      this.showGoToLastMessages = false;
      this.filteredContacts = [];
      this.filteredMessages = [];
      this.searchContactText = "";
      this.searchMessageText = "";
      this.messagesSelectedContact = [];
    },
    getData() {
      if (
        this.hasFilterDateRange &&
        this.selectedProduct &&
        this.selectedProduct !== "" &&
        this.selectedProduct > 0
      ) {
        this.resetModule();
        this.loading = true;

        historyWhatsAppService
          .getContactsNumberHistoryWhatsAppByProduct({
            selectedProductsIds: [this.selectedProduct],
            dateBegin: this.filterDateRange[0],
            dateEnd: this.filterDateRange[1],
          })
          .then((result) => {
            let firstContactNumber = "";
            if (result && result.length > 0) {
              const formattedMessageData = result.map((contact, index) => {
                if (index === 0) {
                  firstContactNumber = contact.contactNumber;
                }
                const messageJsonFormatted = JSON.parse(contact.messageJson);
                const time = contact.createdAt.slice(-13, -8);
                let imgURL = require(`../../../../public/assets/images/whatsapp-no-perfil-image.png`);

                if (messageJsonFormatted?.sender?.profilePicThumbObj?.img) {
                  if (
                    !messageJsonFormatted.sender.profilePicThumbObj.img.includes(
                      "whatsapp-no-perfil-image"
                    )
                  ) {
                    this.checkIfImageExists(
                      messageJsonFormatted.sender.profilePicThumbObj.img,
                      (exists) => {
                        if (exists) {
                          imgURL = messageJsonFormatted.sender.profilePicThumbObj.img;
                        }
                      }
                    );
                  }
                }
                return {
                  contactNumber: contact.contactNumber,
                  contactName: messageJsonFormatted?.sender?.formattedName
                    ? messageJsonFormatted.sender.formattedName
                    : contact.contactNumber,
                  imgURL,
                  chatId: messageJsonFormatted?.chatId ? messageJsonFormatted.chatId : "",
                  fromMe: messageJsonFormatted?.fromMe
                    ? messageJsonFormatted.fromMe
                    : false,
                  createdAt: time,
                };
              });

              this.getChatWhatsApp(firstContactNumber);
              this.contactsNumberHistoryWhatsApp = formattedMessageData;
            } else {
              this.loading = false;
            }
          })
          .catch(this.errorHandler);
      }
    },
    getChatWhatsApp(contactNumberSelected) {
      this.loading = true;
      this.searchMessageText = "";
      this.filteredMessages = [];
      this.showMessageSearch = false;

      historyWhatsAppService
        .getMessagesFromHistoryWhatsAppByContactAndProduct({
          selectedProductsIds: [this.selectedProduct],
          dateBegin: this.filterDateRange[0],
          dateEnd: this.filterDateRange[1],
          contactNumber: contactNumberSelected,
        })
        .then((result) => {
          const totalPeriods = Object.entries(result).length;
          const validsDocuments = [
            "docx",
            "ppt",
            "xlsx",
            "xlsm",
            "xls",
            "ods",
            "odt",
            "csv",
            "pdf",
            "doc",
            "vnd",
          ];

          if (result && totalPeriods > 0) {
            let formattedMessageData = [];
            const groupedFormattedMessageData = [];

            for (let index = 0; index < totalPeriods; index++) {
              const periodData = Object.values(result)[index];

              if (periodData.length > 0) {
                let periodName = Object.keys(result)[index];

                formattedMessageData = periodData.map((message) => {
                  const messageJsonFormatted = JSON.parse(message.messageJson);

                  let caption = "",
                    latitude,
                    longitude,
                    pollName = "",
                    pollOptions = "",
                    pollMaxTotal = 1,
                    subtype = "",
                    hasReaction = false,
                    reactionText = "";

                  switch (messageJsonFormatted?.type) {
                    case "image":
                    case "video":
                    case "document":
                      if (
                        Object.keys(messageJsonFormatted).includes("caption") &&
                        messageJsonFormatted?.caption !== message?.fileName
                      ) {
                        caption = messageJsonFormatted?.caption;
                      }
                      break;

                    case "location":
                      latitude = messageJsonFormatted?.lat;
                      longitude = messageJsonFormatted?.lng;
                      break;

                    case "poll_creation":
                      pollName = messageJsonFormatted?.pollName;

                      pollOptions = messageJsonFormatted?.pollOptions.map(
                        (selected, index) => {
                          let total = 0;

                          if (
                            messageJsonFormatted?.selectedOptions &&
                            messageJsonFormatted?.selectedOptions.length > 0
                          ) {
                            const selectedOptionsLength =
                              messageJsonFormatted?.selectedOptions.length;

                            if (
                              selectedOptionsLength > 0 &&
                              selectedOptionsLength > index
                            ) {
                              if (
                                messageJsonFormatted?.selectedOptions[index]?.name ===
                                selected.name
                              ) {
                                total = 1;
                              }
                            }
                            return {
                              ...selected,
                              total,
                            };
                          } else {
                            return {
                              ...selected,
                              total,
                            };
                          }
                        }
                      );

                      pollOptions = JSON.stringify(pollOptions);
                      break;

                    case "call_log":
                      subtype =
                        messageJsonFormatted?.subtype &&
                        messageJsonFormatted.subtype === "miss"
                          ? "call_audio"
                          : "call_video";
                      break;
                  }

                  const time = message.createdAt.slice(-13, -8);

                  if (Object.keys(messageJsonFormatted).includes("hasReaction")) {
                    hasReaction = messageJsonFormatted.hasReaction;
                    reactionText = messageJsonFormatted?.reactionText
                      ? messageJsonFormatted.reactionText
                      : "";
                  }

                  let fileType = "";
                  let fileSize = 0;

                  if (messageJsonFormatted?.filename) {
                    fileType =
                      messageJsonFormatted?.filename.split(".")[1].toLowerCase() || "";
                    fileSize = this.formatBytes(messageJsonFormatted.size);
                  } else if (message?.fileName) {
                    fileType = message?.fileName.split(".")[1].toLowerCase() || "";
                    fileSize = this.formatBytes(messageJsonFormatted.size);
                  }

                  let vCardContactNumber = "";
                  if (messageJsonFormatted?.type === "vcard") {
                    vCardContactNumber = messageJsonFormatted?.body
                      .split("waid")[1]
                      .split(":")[1]
                      .split("\n")[0];
                  }

                  let imgURL = require(`../../../../public/assets/images/whatsapp-no-perfil-image.png`);

                  if (messageJsonFormatted?.sender?.profilePicThumbObj?.img) {
                    if (
                      !messageJsonFormatted.sender.profilePicThumbObj.img.includes(
                        "whatsapp-no-perfil-image"
                      )
                    ) {
                      this.checkIfImageExists(
                        messageJsonFormatted.sender.profilePicThumbObj.img,
                        (exists) => {
                          if (exists) {
                            imgURL = messageJsonFormatted.sender.profilePicThumbObj.img;
                          }
                        }
                      );
                    }
                  }

                  return {
                    contactNumber: message.contactNumber,
                    contactName: messageJsonFormatted?.sender?.formattedName
                      ? messageJsonFormatted.sender.formattedName
                      : message.contactNumber,
                    imgURL,
                    chatId: messageJsonFormatted?.chatId
                      ? messageJsonFormatted.chatId
                      : "",
                    fromMe: messageJsonFormatted?.fromMe
                      ? messageJsonFormatted.fromMe
                      : false,
                    createdAt: time,
                    content:
                      messageJsonFormatted.type === "revoked"
                        ? "Mensagem apagada"
                        : messageJsonFormatted.type === "chat"
                        ? messageJsonFormatted?.body
                        : message?.fileNameS3,
                    docName:
                      messageJsonFormatted.type === "document" &&
                      validsDocuments.includes(fileType)
                        ? message?.fileName
                          ? message.fileName
                          : messageJsonFormatted?.filename
                          ? messageJsonFormatted.filename
                          : ""
                        : "Arquivo em processamento... Caso o arquivo não apareça em até 5 minutos, não foi possível recuperar o arquivo devido ao tempo que foi enviado!",
                    typeMessage: messageJsonFormatted?.type
                      ? messageJsonFormatted.type
                      : "chat",
                    fileType:
                      messageJsonFormatted.type === "document" &&
                      validsDocuments.includes(fileType)
                        ? fileType
                        : "",
                    fileSize,
                    caption: caption,
                    latitude: latitude,
                    longitude: longitude,
                    pollName: pollName,
                    pollOptions: pollOptions,
                    pollMaxTotal: pollMaxTotal,
                    isForwarded: messageJsonFormatted.isForwarded,
                    subtype,
                    hasReaction,
                    reactionText,
                    vCardContactNumber,
                    vcardFormattedName: messageJsonFormatted?.vcardFormattedName
                      ? messageJsonFormatted.vcardFormattedName
                      : "",
                  };
                });

                let formatKey = "";
                if (periodName === "today") {
                  formatKey = "Hoje";
                } else if (periodName === "yesterday") {
                  formatKey = "Ontem";
                } else {
                  formatKey = periodName.replaceAll("_", " ");
                }

                groupedFormattedMessageData.push({
                  period: formatKey,
                  messages: formattedMessageData,
                });
              }
            }

            const reverseGroupedFormattedMessageData = groupedFormattedMessageData.reverse();
            const reverseGroupedFormattedMessages = [];

            for (
              let index = 0;
              index < reverseGroupedFormattedMessageData.length;
              index++
            ) {
              const element = reverseGroupedFormattedMessageData[index];

              reverseGroupedFormattedMessages.push({
                ...element,
                messages: element.messages.reverse(),
              });
            }

            this.messagesSelectedContact = reverseGroupedFormattedMessages;

            this.selectedContact = this.contactsNumberHistoryWhatsApp.filter(
              (contact) => contact.contactNumber === contactNumberSelected
            )[0];

            if (this.selectedContact) {
              this.hasContactSelected = true;
              this.alterTimeContactToLastMessage();
            } else {
              this.hasContactSelected = false;
            }
          }

          setTimeout(() => {
            this.handleGoToLastMessages();
            this.loading = false;
          }, 500);
        })
        .catch(this.errorHandler);
    },
    getProducts() {
      productService
        .getAvailableProductsWithTotalContacts(
          this.filterDateRange && this.filterDateRange.length == 2
            ? {
                dateBegin: this.filterDateRange[0],
                dateEnd: this.filterDateRange[1],
                choosen: this.getViewProducts,
              }
            : {}
        )
        .then((result) => {
          this.products = result;
        })
        .catch(this.errorHandler);
    },
    searchContact() {
      const filteredContacts = this.contactsNumberHistoryWhatsApp.filter((contact) => {
        if (
          contact.contactName
            .toLowerCase()
            .includes(String(this.searchContactText).toLowerCase()) ||
          contact.contactNumber.includes(this.searchContactText)
        ) {
          return contact;
        }
      });

      this.filteredContacts = filteredContacts;
    },
    searchMessages() {
      let filteredMessages = [];
      for (let index = 0; index < this.messagesSelectedContact.length; index++) {
        this.messagesSelectedContact[index].messages.forEach((message) => {
          if (
            message.content !== null &&
            (message.typeMessage === "chat" || message.typeMessage === "revoked")
          ) {
            if (
              (message.content &&
                message.content
                  .toLowerCase()
                  .includes(String(this.searchMessageText).toLowerCase())) ||
              message.content.includes(this.searchMessageText)
            ) {
              filteredMessages.push(message);
            }
          }
        });
      }

      if (filteredMessages.length > 10) {
        this.showGoToLastMessages = true;
      } else {
        this.showGoToLastMessages = false;
      }

      this.filteredMessages = filteredMessages;
    },
    formatFilterRangeDate() {
      let formatDate = "";
      if (this.filterDateRange && this.filterDateRange.length > 1) {
        formatDate =
          this.filterDateRange[0].split("T")[0].split("-").reverse().join("/") +
          " até " +
          this.filterDateRange[1].split("T")[0].split("-").reverse().join("/");
      }
      return formatDate;
    },
    handleShowSearchMessage() {
      this.showMessageSearch = !this.showMessageSearch;
    },
    handleGoToLastMessages() {
      setTimeout(() => {
        const containerMessage = document.querySelector(".message");
        if (containerMessage) {
          containerMessage.addEventListener(
            "scroll",
            () => {
              if (containerMessage.scrollHeight - containerMessage.scrollTop > 1000) {
                this.showGoToLastMessages = true;
              } else {
                this.showGoToLastMessages = false;
              }
            },
            { passive: true }
          );

          containerMessage.scrollTop = containerMessage.scrollHeight;
        }
      }, 100);
    },
    formatBytes(bytes, decimals = 0) {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    alterTimeContactToLastMessage() {
      if (
        this.messagesSelectedContact.length > 0 &&
        this.messagesSelectedContact[0].messages.length > 0
      ) {
        this.contactsNumberHistoryWhatsApp = this.contactsNumberHistoryWhatsApp.map(
          (contact) => {
            const message = this.messagesSelectedContact[0].messages[
              this.messagesSelectedContact[0].messages.length - 1
            ];
            if (message && message.chatId === contact.chatId) {
              contact.createdAt = message.createdAt;
              return contact;
            }
            return contact;
          }
        );
      }
    },
    checkIfImageExists(url, callback) {
      const img = new Image();
      img.src = url;
      if (img.complete) {
        callback(true);
      } else {
        img.onload = () => {
          callback(true);
        };

        img.onerror = () => {
          callback(false);
        };
      }
    },
  },
  computed: {
    hasFilterDateRange() {
      return (
        this.filterDateRange &&
        this.filterDateRange.length == 2 &&
        this.filterDateRange[0].length > 1 &&
        this.filterDateRange[1].length > 1
      );
    },
    hasDatasValue() {
      if (
        this.selectedProduct === "" ||
        this.selectedProduct.length === 0 ||
        (this.selectedProduct && this.contactsNumberHistoryWhatsApp.length > 0)
      ) {
        return true;
      }
      return false;
    },
    getViewProducts() {
      return this.$store.getters.viewProducts;
    },
    getEnterpriseLogo() {
      return this.$store.getters.enterpriseLogo;
    },
  },
  mounted() {
    this.getProducts();
  },
  watch: {
    filterDateRange() {
      if (!this.hasFilterDateRange && this.selectedProduct) {
        this.resetModule();
      }
    },
    getViewProducts() {
      this.getProducts();
    },
    searchContactText(value) {
      if (value && value.trim().length > 0) {
        this.searchContact();
      } else {
        this.filteredContacts = [];
      }
    },
    searchMessageText(value) {
      if (value && value.trim().length > 0) {
        this.searchMessages();
      } else {
        this.filteredMessages = [];
      }
    },
  },
};
</script>

<style scoped lang="scss" media="print">
.module-whatsapp {
  background-color: #fff !important;
}

div,
span {
  width: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.fa-2x {
  font-size: 1.5em !important;
}

.app {
  position: relative;
  overflow: hidden;
  top: 0;
  height: calc(100% - 38px);
  margin: auto !important;
  padding: 0 0 40px 0 !important;
  max-width: 100%;
  background-color: #fff !important;
}

.app-one {
  background-color: #fff !important;
  height: 100%;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
}

.side {
  padding: 0 !important;
  margin: 0 !important;
  height: 100%;

  ::-webkit-scrollbar {
    background-color: #fff;
  }
  ::-webkit-scrollbar-button {
    background-color: #fff;
    color: #009688;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #009688;
  }
  ::-webkit-scrollbar-track {
    background-color: #fff;
  }
  ::-webkit-scrollbar-track-piece {
    background-color: #fff;
  }
  ::-webkit-scrollbar-corner {
    background-color: #fff;
  }
}
.side-one {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  position: relative;
  display: block;
  top: 0;
  background-color: #fff;
}

.heading {
  padding: 10px 16px 10px 15px;
  margin: 0 !important;
  height: 60px;
  width: 100%;
  background-color: #009688;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
}

.heading-avatar {
  padding: 0 !important;
  cursor: default;
}

.heading-avatar-poll-creation {
  padding: 0 !important;
  cursor: default;
}

.heading-avatar-icon img {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background-color: #ffffff;
}

.heading-avatar-icon-poll-creation img {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  background-color: #ffffff;
}

.heading-name {
  padding: 0 !important;
  cursor: default;
}

.heading-search-message {
  padding: 0 !important;
  cursor: pointer;
}

.heading-name-meta {
  font-weight: 700;
  font-size: 100%;
  padding: 5px;
  padding-bottom: 0;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
  display: block;
  text-decoration: none;
  cursor: default;
}
.heading-online {
  display: none;
  padding: 0 5px;
  font-size: 12px;
  color: #fff;
}
.heading-compose {
  padding: 0;

  :hover {
    background-color: var(--smarts-white);
    color: #009688;
    border-radius: 50%;
  }
}

.heading-compose i {
  text-align: center;
  margin-left: 1.4rem;
  padding: 5px;
  color: #fff;
  cursor: pointer;
}

.heading-dot {
  padding: 0;
  margin-left: 10px;
}

.heading-dot i {
  text-align: right;
  padding: 5px;
  color: #fff;
  cursor: default;
}

.heading-search {
  padding: 0;
  margin-left: 10px;
  cursor: pointer;

  :hover {
    background-color: var(--smarts-white);
    color: #009688;
    border-radius: 50%;
  }
}

.heading-search i {
  text-align: right;
  padding: 5px;
  color: #fff;
  cursor: pointer;
}

.searchBox {
  padding: 0 !important;
  margin: 0 !important;
  height: 60px;
  width: 100%;
}

.searchBox-inner {
  height: 100%;
  width: 100%;
  padding: 10px !important;
  background-color: #fbfbfb;
}

.clear-input {
  cursor: pointer;
}

.sideBar {
  padding: 0 !important;
  margin: 0 !important;
  background-color: #fff;
  overflow-y: auto;
  border: 1px solid #ffffff;
  height: calc(100% - 120px);
}

.sideBar-body {
  position: relative;
  padding: 10px !important;
  border-bottom: 1px solid #ffffff;
  height: 72px;
  margin: 0 !important;
  cursor: pointer;
}

.sideBar-body:hover {
  background-color: #f2f2f2;
}

.sideBar-avatar {
  text-align: center;
  padding: 0 !important;
}

.avatar-icon img {
  border-radius: 50%;
  height: 49px;
  width: 49px;
}

.sideBar-main {
  padding: 0 !important;
}

.sideBar-main .row {
  padding: 0 !important;
  margin: 0 !important;
}

.sideBar-name {
  padding: 10px !important;
}

.name-meta {
  font-size: 100%;
  padding: 1% !important;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
}

.sideBar-no-search-results {
  padding: 10px !important;
  text-align: center;
}
.no-search-results {
  font-size: 100%;
  padding: 1% !important;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
  font-weight: bold;
}

.sideBar-time {
  padding: 10px !important;
}

.time-meta {
  text-align: right;
  font-size: 12px;
  padding: 1% !important;
  color: rgba(0, 0, 0, 0.4);
  vertical-align: baseline;
}

/*Conversation*/

.conversation {
  padding: 0 !important;
  margin: 0 !important;
  height: 100%;
  /*width: 100%;*/
  border-left: 1px solid rgba(0, 0, 0, 0.08);
  /*overflow-y: auto;*/
  position: relative;

  ::-webkit-scrollbar {
    background-color: #fff;
  }
  ::-webkit-scrollbar-button {
    background-color: #fff;
    color: #009688;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #009688;
  }
  ::-webkit-scrollbar-track {
    background-color: #fff;
  }
  ::-webkit-scrollbar-track-piece {
    background-color: #fff;
  }
  ::-webkit-scrollbar-corner {
    background-color: #fff;
  }
}

.message {
  padding: 0 !important;
  margin: 0 !important;
  background: url("../../../../public/assets/images/conversation-bg.png") no-repeat fixed
    center;
  background-size: cover;
  overflow-y: auto;
  border: 1px solid #ffffff;
  height: calc(100% - 120px);
}

.message-previous {
  margin: 0.5rem auto 0 !important;
  padding: 0 !important;
  height: 60px;
  width: 26%;
  background-color: #9de1ff;
  opacity: 0.6;
  border-radius: 0.8rem;
  position: absolute;
  top: 60px;
  left: 36%;
  /*Para mostrar o message previous é só remover o código abaixo */
  display: none;
}

.message-previous-no-message {
  margin: 0.5rem auto 0 !important;
  padding: 0 !important;
  height: 80px;
  width: 60%;
  background-color: #9de1ff !important;
  opacity: 0.6;
  border-radius: 0.8rem;
  position: absolute;
  top: 60px;
  left: 20%;
}

.previous {
  font-size: 15px;
  text-align: center;
  padding: 10px !important;
  opacity: 1;
}

.previous strong {
  font-weight: 700;
  opacity: 1;
  font-size: 0.8rem;
}

.previous span {
  font-weight: 700;
  opacity: 1;
  display: block;
  font-size: 0.7rem;
  margin-top: 0.2rem !important;
}

.container-period-messages {
  /*margin: 0 !important;*/
  padding: 0 !important;
  width: auto;
  height: auto;
  text-align: center;

  strong {
    background-color: #fff;
    padding: 0.6rem;
    border-radius: 0.5rem;
    display: block;
    width: auto;
    max-width: 26%;
    margin: 1rem 0;
    margin-left: 36.5%;
    opacity: 0.6;
    font-size: 0.8rem;
  }
}

.message-body {
  /*margin: 0 !important;*/
  padding: 0 !important;
  width: auto;
  height: auto;
  text-align: left;
}

.message-main-receiver {
  padding: 10px 20px;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
}

.message-main-sender {
  padding: 10px 20px !important;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin-left: 10%;
  position: relative;
}

.message-text {
  margin: 0 !important;
  padding: 5px !important;
  word-wrap: break-word;
  font-weight: 200;
  font-size: 14px;
  padding-bottom: 0 !important;
  color: #000000;
  opacity: 1 !important;
}

.message-revoked-text {
  margin: 0 !important;
  padding: 5px !important;
  font-weight: 200;
  font-size: 14px;
  padding-bottom: 0 !important;
  color: #000000;
  opacity: 1 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  .container-icon-text {
    display: flex;
    align-items: center;
    justify-content: space-around;

    p {
      margin: 0;
      margin-left: 0.5rem;
    }

    i {
      color: #93918f;
      font-size: 18px;
    }
  }
}

.message-call-log-text {
  margin: 0 !important;
  padding: 5px !important;
  font-weight: 200;
  font-size: 14px;
  padding-bottom: 0 !important;
  color: #000000;
  opacity: 1 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  .container-icon-text {
    display: flex;
    align-items: center;
    justify-content: space-around;

    p {
      margin: 0;
      margin-left: 0.5rem;
    }

    i {
      color: red;
      font-size: 14px;
    }
  }
}

.message-vcard {
  h6 {
    margin: 0;
  }
}

.message-isForwarded-text {
  margin: 0 0 0.5rem 0 !important;
  padding: 5px !important;
  font-weight: 200;
  font-size: 14px;
  padding-bottom: 0 !important;
  color: #000000;
  opacity: 1 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .container-icon-text {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    opacity: 0.7;

    p {
      margin: 0;
      margin-left: 0.5rem;
      color: #93918f;
    }

    i {
      color: #93918f;
      font-size: 18px;
    }
  }
}

.message-image {
  margin: 0 !important;
  padding: 5px !important;
  word-wrap: break-word;
  font-weight: 200;
  font-size: 14px;
  padding-bottom: 0 !important;
  color: #000000;
  opacity: 1 !important;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
  }

  .caption-text {
    margin: 1rem 0 0 0 !important;
    padding: 5px !important;
    word-wrap: break-word;
    font-weight: 400;
    font-size: 14px !important;
    padding-bottom: 0 !important;
    color: #000000;
    opacity: 1 !important;
  }

  .image-download-time-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    a {
      text-decoration: none;

      i {
        padding: 1rem 0 0 0;
        color: #93918f;
        font-size: 1.6rem;
      }
    }
  }
}

.message-sticker {
  margin: 0 !important;
  padding: 5px !important;
  word-wrap: break-word;
  font-weight: 200;
  font-size: 14px;
  padding-bottom: 0 !important;
  color: #000000;
  opacity: 1 !important;
  width: 120px;

  img {
    width: 110px;
    height: 110px;
  }
}

.message-video {
  margin: 0 !important;
  padding: 5px !important;
  word-wrap: break-word;
  font-weight: 200;
  font-size: 14px;
  padding-bottom: 0 !important;
  color: #000000;
  opacity: 1 !important;

  .caption-text {
    margin: 1rem 0 0 0 !important;
    padding: 5px !important;
    word-wrap: break-word;
    font-weight: 400;
    font-size: 14px !important;
    padding-bottom: 0 !important;
    color: #000000;
    opacity: 1 !important;
  }

  video {
    width: 100%;
  }
}

.container-img-and-audio {
  display: flex;

  .heading-avatar-audio-icon {
    max-width: 60px;
    margin-right: 1rem;
    position: relative;

    i {
      color: #20abf3 !important;
      position: absolute;
      bottom: 0;
      right: 10px;
    }
  }
  .heading-avatar-audio-icon img {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    background-color: #dcf8c6 !important;
  }
}

.message-audio {
  margin: 0 !important;
  padding: 5px !important;
  padding-bottom: 0 !important;
  color: #000000;
  opacity: 1 !important;
  width: 400px;

  .container-img-and-audio {
    max-height: 55px;
  }

  audio {
    height: 50px;
    width: 100%;
    border-radius: 0;
    background-color: #fff;

    source {
      background-color: #009688 !important;
    }
  }

  #container-no-audio {
    #image-no-audio {
      height: 70px;
      width: 100%;
    }
  }
}

.message-document {
  margin: 0 !important;
  padding: 5px !important;
  word-wrap: break-word;
  font-weight: 200;
  font-size: 14px;
  padding-bottom: 0 !important;
  color: #000000;
  opacity: 1 !important;
  width: 500px;

  .caption-text {
    margin: 1rem 0 0 0 !important;
    padding: 5px !important;
    word-wrap: break-word;
    font-weight: 400;
    font-size: 14px !important;
    padding-bottom: 0 !important;
    color: #000000;
    opacity: 1 !important;
  }

  iframe {
    width: 100%;
  }
}

.message-document-docs {
  margin: 0 !important;
  padding: 10px !important;
  word-wrap: break-word;
  font-weight: 200;
  font-size: 14px;
  color: #000000;
  opacity: 1 !important;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  width: 500px;
  border-radius: 0.5rem;

  img {
    width: 40px;
    height: 40px;
  }

  span {
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;

    small {
      font-size: 0.8rem;
      text-align: left;
      margin-left: 0.2rem;
      opacity: 0.7;
    }
  }

  a {
    display: flex !important;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem 0;
    i {
      color: #93918f;
      font-size: 1.8rem;
    }
  }
}

.message-location {
  margin: 0 !important;
  padding: 5px !important;
  word-wrap: break-word;
  font-weight: 200;
  font-size: 14px;
  padding-bottom: 0 !important;
  color: #000000;
  opacity: 1 !important;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
  }
}

.message-poll-creation {
  margin: 0 !important;
  padding: 5px !important;
  word-wrap: break-word;
  font-weight: 200;
  font-size: 14px;
  padding-bottom: 0 !important;
  color: #000000;
  opacity: 1 !important;
  width: auto;
  min-width: 300px;
  max-width: 650px;

  ul {
    list-style-type: none;
    padding-left: 0;

    li {
      margin: 1rem 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .poll-creation-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;

        .input-label-container {
          display: flex;
          align-items: center;

          p {
            margin: 0 0 0.6rem 0.5rem;
          }
        }

        p {
          margin: 0 0 0.6rem 0;
        }
      }

      input[type="checkbox"] {
        display: none;
      }

      label:before {
        background: #eee;
        border: 2px solid #009688;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        display: block;
      }

      input[type="checkbox"] + label:before {
        content: "";
        background: #eee;
        border: 1px solid #009688;
        color: #eee;
        font-size: 15px;
        line-height: 15px;
        text-align: center;
        font-weight: bold;
      }

      input[type="checkbox"]:checked + label:before {
        border-color: #009688;
        background: #009688;
        color: #fff;
        content: "✓";
      }
    }
  }
}

.message-time {
  margin: 0.5rem 0 0 0 !important;
  margin-left: 50px !important;
  font-size: 12px;
  text-align: right;
  color: #9a9a9a;
}

.message-time-revoked {
  margin: 0.5rem 0 0 0 !important;
  font-size: 12px;
  text-align: right;
  color: #9a9a9a;
}

.receiver {
  width: auto !important;
  padding: 4px 10px 7px;
  border-radius: 10px 10px 10px 0;
  background-color: #ffffff;
  font-size: 12px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  word-wrap: break-word;
  display: inline-block;
  opacity: 0.8;
  user-select: text;
  cursor: text;
}

.sender {
  width: auto !important;
  padding: 4px 10px 7px;
  border-radius: 10px 10px 0 10px;
  background-color: #dcf8c6;
  font-size: 12px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  word-wrap: break-word;
  display: inline-block;
  user-select: text;
  cursor: text;
}

.reaction-container-receiver {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 0;
  right: 5;
  background-color: #fff;
  border-radius: 50%;
  -webkit-box-shadow: 10px 10px 40px -7px rgba(0, 0, 0, 0.61);
  -moz-box-shadow: 10px 10px 40px -7px rgba(0, 0, 0, 0.61);
  box-shadow: 10px 10px 40px -7px rgba(0, 0, 0, 0.61);
}

.reaction-container-sender {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 0;
  background-color: #fff;
  border-radius: 50%;
  -webkit-box-shadow: 10px 10px 40px -7px rgba(0, 0, 0, 0.61);
  -moz-box-shadow: 10px 10px 40px -7px rgba(0, 0, 0, 0.61);
  box-shadow: 10px 10px 40px -7px rgba(0, 0, 0, 0.61);
}

.down-conversation-container {
  background-color: #ffffff;
  opacity: 0.8;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  bottom: 60px;
  right: 10px;
}

.down-conversation-container i {
  color: #9a9a9a;
}

/*Reply*/

.reply {
  height: 60px;
  width: 100%;
  background-color: #f5f1ee;
  padding: 10px 5px 10px 5px !important;
  margin: 0 !important;
  z-index: 1000;
}

.reply-emojis {
  padding: 5px !important;
}

.reply-emojis i {
  text-align: center;
  padding: 5px 5px 5px 5px !important;
  color: #93918f;
  cursor: default;
}

.reply-recording {
  padding: 5px !important;
}

.reply-recording i {
  text-align: center;
  padding: 5px !important;
  color: #93918f;
  cursor: default;
}

.reply-send {
  padding: 5px !important;
}

.reply-send i {
  text-align: center;
  padding: 5px !important;
  color: #93918f;
  cursor: default;
}

.reply-main {
  padding: 2px 5px !important;
}

.reply-main textarea {
  width: 100%;
  resize: none;
  overflow: hidden;
  padding: 5px !important;
  outline: none;
  border: none;
  text-indent: 5px;
  box-shadow: none;
  height: 100%;
  font-size: 16px;
}

.reply-main textarea:focus {
  outline: none;
  border: none;
  text-indent: 5px;
  box-shadow: none;
}

@media screen and (max-width: 700px) {
  .app {
    top: 0;
    height: 100%;
  }
  .heading {
    height: 70px;
    background-color: #009688;
  }
  .fa-2x {
    font-size: 2.3em !important;
  }
  .heading-avatar {
    padding: 0 !important;
  }
  .heading-avatar-icon img {
    height: 40px;
    width: 40px;
  }
  .heading-compose {
    padding: 5px !important;
  }
  .heading-compose i {
    color: #fff;
    cursor: pointer;
  }
  .heading-dot {
    padding: 5px !important;
    margin-left: 10px !important;
  }
  .heading-dot i {
    color: #fff;
    cursor: pointer;
  }
  .sideBar {
    height: calc(100% - 130px);
  }
  .sideBar-body {
    height: 80px;
  }
  .sideBar-avatar {
    text-align: left;
    padding: 0 8px !important;
  }
  .avatar-icon img {
    height: 55px;
    width: 55px;
  }
  .sideBar-main {
    padding: 0 !important;
  }
  .sideBar-main .row {
    padding: 0 !important;
    margin: 0 !important;
  }
  .sideBar-name {
    padding: 10px 5px !important;
  }
  .name-meta {
    font-size: 16px;
    padding: 5% !important;
  }
  .sideBar-time {
    padding: 10px !important;
  }
  .time-meta {
    text-align: right;
    font-size: 14px;
    padding: 4% !important;
    color: rgba(0, 0, 0, 0.4);
    vertical-align: baseline;
  }
  /*Conversation*/
  .conversation {
    padding: 0 !important;
    margin: 0 !important;
    height: 100%;
    /*width: 100%;*/
    border-left: 1px solid rgba(0, 0, 0, 0.08);
    /*overflow-y: auto;*/
  }
  .message {
    height: calc(100% - 140px);
  }
  .reply {
    height: 70px;
  }
  .reply-emojis {
    padding: 5px 0 !important;
  }
  .reply-emojis i {
    padding: 5px 2px !important;
    font-size: 1.8em !important;
  }
  .reply-main {
    padding: 2px 8px !important;
  }
  .reply-main textarea {
    padding: 8px !important;
    font-size: 18px;
  }
  .reply-recording {
    padding: 5px 0 !important;
  }
  .reply-recording i {
    padding: 5px 0 !important;
    font-size: 1.8em !important;
  }
  .reply-send {
    padding: 5px 0 !important;
  }
  .reply-send i {
    padding: 5px 2px 5px 0 !important;
    font-size: 1.8em !important;
  }
}
</style>
