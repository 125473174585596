<template>
  <mobule-base-builder
    :module-data="moduleData"
    :editMode="editMode"
    :loading="loading"
    :error="error"
    :downloadImage="dataDownload"
    :viewRadiusCircle="viewRadiusCircle"
    :viewPins="viewPins"
    :viewLegendRadiusCircle="viewLegendRadiusCircle"
    :totalCustomersMapContactsTypes="totalCustomersMapContactsTypes"
    @getData="getData(true, true)"
    @activeRadiusCircleView="activeRadiusCircleView"
    @activeRadiusCircleLegendView="activeRadiusCircleLegendView"
  >
    <template v-slot:content>
      <module-google-map
        :loading="loading"
        :locations="locations"
        :standsLocations="standsLocations"
        :circles="circles"
        :viewRadiusCircle="viewRadiusCircle"
        :qtdRadiusCircle="qtdRadiusCircle"
        :viewPins="viewPins"
        :viewLegendRadiusCircle="viewLegendRadiusCircle"
        :error="error"
        class="module-contacts-types-map"
      />

      <div class="legends-container" v-if="locations.length > 0 && !loading">
        <div class="info-container">
          <h6>Legenda - Visitas por tipo</h6>
          <div>
            <strong>Contato Indicado: Sim</strong>
            <strong>Contato Indicado: Não</strong>

            <div class="indication-container" v-if="selectedProducts.length > 0">
              <v-img
                lazy-src="../../../../public/assets/images/customersPins/02-pin.png"
                max-height="30"
                max-width="30"
                src="../../../../public/assets/images/customersPins/02-pin.png"
              ></v-img>
              <strong>Total: </strong>
              <span> {{ totalIndication }} </span>
            </div>
            <div v-if="selectedProducts.length > 0">
              <v-img
                lazy-src="../../../../public/assets/images/customersPins/01-pin.png"
                max-height="30"
                max-width="30"
                src="../../../../public/assets/images/customersPins/01-pin.png"
              ></v-img>
              <strong>Total: </strong>
              <span>{{ totalSpontaneous }}</span>
            </div>
          </div>
          <section class="sub-legend">
            <span v-if="totalCustomersMapContactsTypes !== 0">
              {{ totalCustomersMapContactsTypes }}
              {{
                totalCustomersMapContactsTypes > 1
                  ? "visitantes informaram"
                  : "visitante informou"
              }}
              endereço completo para mapeamento.
            </span>
          </section>
        </div>

        <div
          class="total-customers-by-client-container"
          v-if="viewRadiusCircle && selectedProducts.length === 1"
        >
          <h6>Legenda - Total de visitas por tipo e quilometragem</h6>
          <div>
            <strong>Contato Indicado: Sim</strong>
            <strong>Contato Indicado: Não</strong>
            <div
              v-for="(item, index) in useStandardDistancesByNumberOfCircles"
              :key="index"
            >
              <span>{{ replaceLastThreeZerosWithKM(String(item.km)) }}</span>
              <span
                :style="{
                  color: item.contactType === 'Indicação' ? '#E60023' : '#007dbe',
                }"
                >{{ item.quantity }} {{ item.quantity > 1 ? "visitas" : "visita" }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </template>
  </mobule-base-builder>
</template>

<script>
import MobuleBaseBuilder from "../base/MobuleBaseBuilder.vue";
import MixinModuleBase from "../../../mixins/MixinModuleBase";
import ModuleGoogleMap from "../base/ModuleGoogleMap.vue";
import colorsAndImagesPinsMaps from "../../../utils/maps/colorsAndImagesPinsMaps";
import { getGoogleMapsAPI } from "gmap-vue";

import circlesKMLegends from "../../../utils/maps/circlesKMLegends";

import ContactService from "../../../services/ContactService";
const contactService = ContactService.build();

export default {
  name: "module-contacts-types-map",
  components: { ModuleGoogleMap, MobuleBaseBuilder },
  mixins: [MixinModuleBase],
  data() {
    return {
      locations: [],
      standsLocations: [],
      circles: [],
      totalCustomersMapContactsTypes: 0,
      totalIndication: 0,
      totalSpontaneous: 0,
      quantityCustomersByRadius: [],
      standardDistancesByNumberOfCirclesDefault: [],
      standardDistancesByNumberOfCircles: [],
      useStandardDistancesByNumberOfCircles: [],
      dataDownload: {
        class: "module-contacts-types-map",
        title: this.moduleData.headerTitle,
      },
      viewPins: true,
      viewRadiusCircle: false,
      viewLegendRadiusCircle: false,
      qtdRadiusCircle: 1,
    };
  },
  computed: {
    google() {
      return getGoogleMapsAPI();
    },
  },
  methods: {
    resetModule(resetCircle) {
      this.locations = [];
      this.standsLocations = [];
      this.circles = [];
      this.viewLegendRadiusCircle = false;

      if (resetCircle) {
        this.qtdRadiusCircle = 1;
        this.viewRadiusCircle = false;
      }
    },
    getData(isNotLimit = false, resetCircle = true) {
      if (resetCircle) {
        this.resetModule(resetCircle);
      } else {
        this.resetModule(resetCircle);
      }

      if (this.dataRetrievalOk()) {
        this.loading = true;

        contactService
          .getLatLongContacts({
            selectedProductsIds: this.selectedProductsIds(),
            dateBegin: this.filterDateRange[0],
            dateEnd: this.filterDateRange[1],
            limit: isNotLimit,
          })
          .then((results) => {
            this.resetModule(resetCircle);
            const standsLocations = this.getStandsPinsMap();
            const customersContactsLocations = this.getCustomersPinsMap(
              results,
              standsLocations
            );

            this.standsLocations = standsLocations;
            this.locations = customersContactsLocations;
            this.loading = false;
          })
          .catch(this.errorHandler);
      }
    },
    getStandsPinsMap() {
      const customStandsLocations = [];
      if (this.selectedProducts.length > 0 && this.google) {
        const scaledSize = new this.google.maps.Size(52, 52);
        for (let index = 0; index < this.selectedProducts.length; index++) {
          let standIcon = {};
          let standColor = "#E60023";

          standIcon = {
            url: colorsAndImagesPinsMaps[9].urlStandPin,
            scaledSize: scaledSize,
          };

          customStandsLocations.push({
            id: this.selectedProducts[index].id,
            position: {
              lat: Number(this.selectedProducts[index].latProduct),
              lng: Number(this.selectedProducts[index].lngProduct),
            },
            infoText: `
                    <h6 style="font-weight: bold; color: #000000; margin: 10px;">
                      ${this.selectedProducts[index].name}
                    </h6>
                    `,
            image: standIcon,
            standName: this.selectedProducts[index].name,
          });

          this.generateKilometerRaysPerStand(
            this.selectedProducts[index].name,
            this.selectedProducts[index].id,
            this.selectedProducts[index].latProduct,
            this.selectedProducts[index].lngProduct,
            standColor
          );
        }

        return customStandsLocations;
      }

      return customStandsLocations;
    },
    getCustomersPinsMap(contacts, standsLocations) {
      const customCustomersContactsLocations = [];
      this.totalIndication = 0;
      this.totalSpontaneous = 0;
      this.totalCustomersMapContactsTypes = contacts.length;
      const scaledSize = this.google ? new this.google.maps.Size(32, 32) : null;

      //Separa as latitudes repetidas para definir a Label no marcador
      const groupSameLat = contacts.reduce(function (r, a) {
        r[a.lat_address] = r[a.lat_address] || [];
        r[a.lat_address].push(a);
        return r;
      }, Object.create(null));

      const formatLatAndQtd = [];
      Object.entries(groupSameLat).forEach((item) => {
        if (item[1].length > 1) {
          return formatLatAndQtd.push({
            lat: item[0],
            total: item[1].length,
          });
        }
      });

      if (this.totalCustomersMapContactsTypes > 0 && standsLocations.length > 0) {
        let customerIcon = {};
        let customerPin = "";
        let contactType = "";

        for (
          let indexContact = 0;
          indexContact < this.totalCustomersMapContactsTypes;
          indexContact++
        ) {
          for (let indexStand = 0; indexStand < standsLocations.length; indexStand++) {
            if (contacts[indexContact].product_id === standsLocations[indexStand].id) {
              if (contacts[indexContact].is_indication) {
                contactType = "Indicação";
                customerPin = colorsAndImagesPinsMaps[1].urlCustomerPin;
                customerIcon = {
                  url: customerPin, // url
                  scaledSize: scaledSize, // scaled size
                };
                this.totalIndication++;
              } else {
                contactType = "Espontânea";
                customerPin = colorsAndImagesPinsMaps[0].urlCustomerPin;
                customerIcon = {
                  url: customerPin, // url
                  scaledSize: scaledSize, // scaled size
                };
                this.totalSpontaneous++;
              }

              const visitDate = new Date(
                contacts[indexContact].visit_date
              ).toLocaleDateString("pt-br", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
              });

              //Defini marcadores com a mesma posição que recebem a label no marcador
              const labelFind = formatLatAndQtd.find((element) => {
                return element.lat === contacts[indexContact].lat_address;
              });
              let label = "";
              if (labelFind) {
                label = String(labelFind.total);
              }

              customCustomersContactsLocations.push({
                id: contacts[indexContact].id,
                position: {
                  lat: Number(contacts[indexContact].lat_address),
                  lng: Number(contacts[indexContact].lng_address),
                },
                infoText: `
                          <div style="margin: 5px 5px 20px 5px;">                 
                            <div style="display: flex; flex-direction: row; align-items: center;">
                              <span style="font-weight: bold; font-size: 16px; margin: 0 5px;">ID contato:</span> 
                              <span>${contacts[indexContact].id}</span>
                            </div>
                  
                            <div style="display: flex; flex-direction: row; align-items: center;">
                              <span style="font-weight: bold; font-size: 16px; margin: 0 5px;">Cliente:</span> 
                              <span>${contacts[indexContact].name} ${contacts[indexContact].last_name}</span> 
                            </div>
                            <div style="display: flex; flex-direction: row; align-items: center;">
                              <span style="font-weight: bold; font-size: 16px; margin: 0 5px;">Produto:</span> 
                              <span>${standsLocations[indexStand].standName}</span>
                            </div>
                            <div style="display: flex; flex-direction: row; align-items: center;">
                              <span style="font-weight: bold; font-size: 16px; margin: 0 5px;">Tipo de Visita:</span> 
                              <span>${contactType}</span>
                            </div>
                            <div style="display: flex; flex-direction: row; align-items: center;">
                              <span style="font-weight: bold; font-size: 16px; margin: 0 5px;">Data da visita:</span> 
                              <span>${visitDate}</span>
                            </div>
                          </div>
                          `,

                image: customerIcon,
                label,
              });

              if (standsLocations.length === 1) {
                this.getNumberOfCustomersPerKilometer(
                  standsLocations,
                  indexStand,
                  contacts,
                  indexContact,
                  contactType
                );
              }
            }
          }
        }
        return customCustomersContactsLocations;
      }

      return customCustomersContactsLocations;
    },
    generateKilometerRaysPerStand(standName, standId, standLat, standLng, standColor) {
      const limitRadius = 21;
      this.standardDistancesByNumberOfCirclesDefault = [];
      const standardDataAndDistancesByNumberOfCirclesTemp = [];
      for (let index = 1; index < limitRadius; index++) {
        const element = {
          circle: {
            id: standId + "-" + Math.random(),
            center: { lat: Number(standLat), lng: Number(standLng) },
            circleCenter: { lat: Number(standLat), lng: Number(standLng) },
            radius: Number(`${index}000`),
            color: "#E60023",
            infoText: `<span style="background-color: ${standColor}; padding: 8px 16px; color: white; font-weight: bold; border-radius: 5px;">${index} KM</span>`,
          },
        };

        for (let i = 0; i < 2; i++) {
          const standardDataAndDistancesByNumberOfCircles = {
            standName,
            km: Number(`${index}000`),
            quantity: 0,
            image: circlesKMLegends[index - 1].urlCircleKMLegend,
            contactType: i === 0 ? "Indicação" : "Espontânea",
          };

          standardDataAndDistancesByNumberOfCirclesTemp.push(
            standardDataAndDistancesByNumberOfCircles
          );
        }

        this.circles.push(element);
      }

      this.standardDistancesByNumberOfCirclesDefault.push(
        standardDataAndDistancesByNumberOfCirclesTemp
      );
    },
    getNumberOfCustomersPerKilometer(
      standsLocations,
      indexStand,
      contacts,
      indexContact,
      contactType
    ) {
      const limitDistanceRadius = 40000;

      const distance = this.getDistanceFromLatLonInKm(
        standsLocations[indexStand].position,
        {
          lat: contacts[indexContact].lat_address,
          lng: contacts[indexContact].lng_address,
        }
      );

      if (distance <= limitDistanceRadius && indexStand === 0) {
        this.standardDistancesByNumberOfCircles = [];
        const distanceCharsQuantity = distance.length;
        let actualDistance = 1000;
        switch (distanceCharsQuantity) {
          case 4:
            actualDistance = Number(`${Number(distance.substring(0, 1)) + 1}000`);
            break;
          case 5:
            actualDistance = Number(`${Number(distance.substring(0, 2)) + 1}000`);
            break;
          default:
            actualDistance = 1000;
        }

        this.standardDistancesByNumberOfCircles = this.standardDistancesByNumberOfCirclesDefault[
          indexStand
        ].map((item) => {
          if (item.km === actualDistance && contactType === item.contactType) {
            return {
              ...item,
              quantity: item.quantity++,
            };
          } else {
            return {
              ...item,
            };
          }
        });
      }
    },
    getDistanceFromLatLonInKm(standPosition, customerPosition) {
      const deg2rad = function (deg) {
          return deg * (Math.PI / 180);
        },
        R = 6371,
        dLat = deg2rad(customerPosition.lat - standPosition.lat),
        dLng = deg2rad(customerPosition.lng - standPosition.lng),
        a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(deg2rad(standPosition.lat)) *
            Math.cos(deg2rad(standPosition.lat)) *
            Math.sin(dLng / 2) *
            Math.sin(dLng / 2),
        c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return (R * c * 1000).toFixed();
    },
    activeRadiusCircleView(qtd) {
      this.qtdRadiusCircle = Number(qtd);
      this.viewLegendRadiusCircle = false;

      if (qtd > 1) {
        this.viewRadiusCircle = true;
        this.useStandardDistancesByNumberOfCircles = [];
        this.standardDistancesByNumberOfCirclesDefault[0].forEach((item, index) => {
          if (index < qtd * 2 - 2) {
            this.useStandardDistancesByNumberOfCircles.push(item);
          }
        });
      } else {
        this.useStandardDistancesByNumberOfCircles = [];
        this.viewRadiusCircle = false;
      }
    },
    activeRadiusCircleLegendView() {
      this.viewLegendRadiusCircle = !this.viewLegendRadiusCircle;
    },
    replaceLastThreeZerosWithKM(str) {
      const stringWithKM = str.replace(/0{3}$/, " KM:");
      return stringWithKM;
    },
  },
};
</script>

<style scoped lang="scss">
.module-contacts-map {
  background-color: #fff;
}

.legends-container {
  padding-top: 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  justify-items: center;
  max-height: 100px;
  height: 100px;
  position: relative;
  .info-container {
    width: 100%;
    border: 2px solid var(--smarts-black);
    border-radius: 0 0 1rem 1rem;

    h6 {
      width: 100%;
      background-color: var(--smarts-black);
      color: var(--smarts-white);
      font-weight: bold;
      padding: 1rem;
    }

    div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0.5rem;
      align-items: center;
      padding: 0.5rem;

      strong:first-of-type {
        color: #e60023;
        text-align: center;
      }

      strong:last-of-type {
        color: #007dbe;
        text-align: center;
      }

      .indication-container {
        strong {
          color: #e60023;
          text-align: center;
        }

        span {
          font-weight: bold;
          color: #e60023;
          font-size: 1rem;
        }
      }

      div {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-weight: bold;
          color: #007dbe;
          font-size: 1rem;
        }
      }
    }

    .sub-legend {
      max-width: 90%;
      text-align: center;
      margin: 1rem auto;
      padding: 0.5rem;

      span {
        font-weight: bold;
        color: var(--smarts-black);
        font-size: 1rem;
      }
    }
  }

  .total-customers-by-client-container {
    width: 100%;
    border: 2px solid var(--smarts-black);
    border-radius: 0 0 1rem 1rem;

    h6 {
      background-color: var(--smarts-black);
      color: var(--smarts-white);
      font-weight: bold;
      padding: 1rem;
    }

    div {
      width: 90%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0.5rem;
      align-items: center;
      padding: 0.5rem;

      strong:first-of-type {
        color: #e60023;
        text-align: center;
      }

      strong:last-of-type {
        color: #007dbe;
        text-align: center;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: center;

        border: 2px solid var(--smarts-black);

        span {
          font-weight: bold;
          color: var(--smarts-black);
          font-size: 0.8rem;
        }
      }
    }
  }
}
</style>
