<template>
  <mobule-base-builder
    :module-data="moduleData"
    :editMode="editMode"
    :loading="loading"
    :error="error"
    :downloadImage="dataDownload"
    :tableHeaders="tableHeaders"
    :tableItems="tableItems"
    :tableHeadersExcel="tableHeadersExcel"
    :tableItemsExcel="tableItemsExcel"
  >
    <template v-slot:content>
      <div class="container-labels-color">
        <div class="label">
          <span class="label-color" style="background-color: #2196f3"></span>
          <p class="label-text font-weight-bold">Segunda a sexta</p>
        </div>
        <div class="label">
          <span class="label-color" style="background-color: #f44336"></span>
          <p class="label-text font-weight-bold">Final de semana</p>
        </div>
      </div>
      <module-table-list-data-v-2
        v-if="tableItems.length > 0"
        :tableHeaders="tableHeaders"
        :tableItems="tableItems"
        :footerPropsItemsPerPageText="footerPropsItemsPerPageText"
        :hasVChips="true"
        class="unified-report"
      />
      <message-status-data
        v-else
        :selected-products="selectedProductsIds()"
        :infoLoading="loading"
      />
    </template>
  </mobule-base-builder>
</template>

<script>
import MixinModuleBase from "../../../mixins/MixinModuleBase";
import MobuleBaseBuilder from "../base/MobuleBaseBuilder.vue";

import ModuleChartBar from "../base/ModuleChartBar.vue";
import ModuleChartDoughnut from "../base/ModuleChartDoughnut.vue";
import ModuleTableListDataV2 from "../base/ModuleTableListDataV2.vue";
import MessageStatusData from "../../MessageStatusData.vue";

import ContactService from "../../../services/ContactService";
const contactService = ContactService.build();

import ProductService from "../../../services/ProductService";
const productService = ProductService.build();

export default {
  name: "module-unified-report",
  mixins: [MixinModuleBase],
  components: {
    MobuleBaseBuilder,
    ModuleTableListDataV2,
    MessageStatusData,
    ModuleChartDoughnut,
    ModuleChartBar,
  },
  data: function () {
    return {
      tableHeaders: [
        {
          text: "Produto",
          align: "start",
          sortable: true,
          value: "productName",
          tooltip: "Nome do Produto",
        },
        {
          text: "VE",
          align: "start",
          sortable: true,
          value: "spontaneousWeek",
          color: "blue",
          tooltip: "Total de Visita Espontânea (Segunda a sexta)",
        },
        {
          text: "IC",
          align: "start",
          sortable: true,
          value: "indicationWeek",
          color: "blue",
          tooltip: "Total de Indicação (Segunda a sexta)",
        },
        {
          text: "R",
          align: "start",
          sortable: true,
          value: "returnWeek",
          color: "blue",
          tooltip: "Total de Retorno (Segunda a sexta)",
        },
        {
          text: "TEL",
          align: "start",
          sortable: true,
          value: "telephoneWeek",
          color: "blue",
          tooltip: "Total de Telefonema (Segunda a sexta)",
        },
        {
          text: "FE",
          align: "start",
          sortable: true,
          value: "outOfServiceWeek",
          color: "blue",
          tooltip: "Total Fora do Expediente (Segunda a sexta)",
        },
        {
          text: "TT",
          align: "start",
          sortable: true,
          value: "totalWeek",
          color: "blue",
          tooltip: "Total de Contatos (Segunda a sexta)",
        },
        {
          text: "VE",
          align: "start",
          sortable: true,
          value: "spontaneousWeekend",
          color: "red",
          tooltip: "Total de Visitas Expontânea (Final de Semana)",
        },
        {
          text: "IC",
          align: "start",
          sortable: true,
          value: "indicationWeekend",
          color: "red",
          tooltip: "Total de Indicação (Final de Semana)",
        },
        {
          text: "R",
          align: "start",
          sortable: true,
          value: "returnWeekend",
          color: "red",
          tooltip: "Total de Retorno (Final de Semana)",
        },
        {
          text: "TEL",
          align: "start",
          sortable: true,
          value: "telephoneWeekend",
          color: "red",
          tooltip: "Total de Telefonema (Final de Semana)",
        },
        {
          text: "FE",
          align: "start",
          sortable: true,
          value: "outOfServiceWeekend",
          color: "red",
          tooltip: "Total Fora do Expediente (Final de Semana)",
        },
        {
          text: "TT",
          align: "start",
          sortable: true,
          value: "totalWeekend",
          color: "red",
          tooltip: "Total de Contatos (Final de Semana)",
          cellClass: "py-2",
        },
        {
          text: "TT (Período)",
          align: "start",
          sortable: true,
          value: "totalPeriod",
          tooltip: "Total de Contatos no Período Escolhido",
        },
        {
          text: "TT (Ano)",
          align: "start",
          sortable: true,
          value: "totalYear",
          tooltip: "Total de Contatos no Ano (conforme a data de início escolhida)",
        },
        {
          text: "TT (Desde o início)",
          align: "start",
          sortable: true,
          value: "totalFull",
          tooltip: "Total de Contatos Desde o Início do Produto",
        },
        {
          text: "Abertura",
          align: "start",
          sortable: true,
          value: "createdWhen",
          tooltip: "Data de Abertura do Stand",
        },
      ],
      tableHeadersExcel: [
        {
          text: "Produto",
          align: "start",
          sortable: true,
          value: "productName",
        },
        {
          text: "VE (Segunda a sexta)",
          align: "start",
          sortable: true,
          value: "spontaneousWeek",
          color: "blue",
        },
        {
          text: "IC (Segunda a sexta)",
          align: "start",
          sortable: true,
          value: "indicationWeek",
          color: "blue",
        },
        {
          text: "R (Segunda a sexta)",
          align: "start",
          sortable: true,
          value: "returnWeek",
          color: "blue",
        },
        {
          text: "TEL (Segunda a sexta)",
          align: "start",
          sortable: true,
          value: "telephoneWeek",
          color: "blue",
        },
        {
          text: "FE (Segunda a sexta)",
          align: "start",
          sortable: true,
          value: "outOfServiceWeek",
          color: "blue",
        },
        {
          text: "TT (Segunda a sexta)",
          align: "start",
          sortable: true,
          value: "totalWeek",
          color: "blue",
        },
        {
          text: "VE (Final de Semana)",
          align: "start",
          sortable: true,
          value: "spontaneousWeekend",
          color: "red",
        },
        {
          text: "IC (Final de Semana)",
          align: "start",
          sortable: true,
          value: "indicationWeekend",
          color: "red",
        },
        {
          text: "R (Final de Semana)",
          align: "start",
          sortable: true,
          value: "returnWeekend",
          color: "red",
        },
        {
          text: "TEL (Final de Semana)",
          align: "start",
          sortable: true,
          value: "telephoneWeekend",
          color: "red",
        },
        {
          text: "FE (Final de Semana)",
          align: "start",
          sortable: true,
          value: "outOfServiceWeekend",
          color: "red",
        },
        {
          text: "TT (Final de Semana)",
          align: "start",
          sortable: true,
          value: "totalWeekend",
          color: "red",
          cellClass: "py-2",
        },
        {
          text: "TT (Período)",
          align: "start",
          sortable: true,
          value: "totalPeriod",
        },
        {
          text: "TT (Ano)",
          align: "start",
          sortable: true,
          value: "totalYear",
        },
        {
          text: "TT (Desde o início)",
          align: "start",
          sortable: true,
          value: "totalFull",
        },
        {
          text: "Abertura",
          align: "start",
          sortable: true,
          value: "createdWhen",
        },
      ],
      tableItems: [],
      tableItemsExcel: [],
      footerPropsItemsPerPageText: "Cidades por página",
      dataDownload: { class: "cities", title: this.moduleData.headerTitle },
      barChartData: [],
      labels: [],
      options: ["Residencial", "Comercial"],
      selectedOption: "",
      productsWithTotalContactsInYear: [],
      productsWithTotalContactsFull: [],
    };
  },
  computed: {
    getSelectedProducts() {
      return this.$store.getters.selectedProducts;
    },
    getViewProducts() {
      return this.$store.getters.viewProducts;
    },
  },
  methods: {
    resetModule() {
      this.tableItems = [];
      this.tableItemsExcel = [];
      this.barChartData = [];
      this.labels = [];
    },
    convertToTable(contactsDatas) {
      let contactsDatasLinkedMoments = [];

      contactsDatas.forEach(async (contactData) => {
        let hasContactDataIndex = contactsDatasLinkedMoments.findIndex(
          (item) => item.productName === contactData.productName
        );
        let momentString =
          contactData.moment.charAt(0).toUpperCase() + contactData.moment.slice(1);
        let contactDataKeyWithMoment = {};
        Object.entries(contactData).forEach(([key, value]) => {
          if (key !== "productName") contactDataKeyWithMoment[key + momentString] = value;
          else {
            const product = this.getSelectedProducts.filter(
              (prod) => prod.name === value
            )[0];
            const dateSplit = product.createdWhen.split("T")[0];
            const dateFormatted = dateSplit.split("-").reverse().join("/");
            contactDataKeyWithMoment["createdWhen"] = dateFormatted;
            contactDataKeyWithMoment[key] = value;
          }
        });

        if (hasContactDataIndex !== -1) {
          contactsDatasLinkedMoments[hasContactDataIndex] = {
            ...contactsDatasLinkedMoments[hasContactDataIndex],
            ...contactDataKeyWithMoment,
          };
        } else {
          const productsWithTotalContactsFull = this.productsWithTotalContactsFull.filter(
            (prod) => prod.name === contactDataKeyWithMoment.productName
          )[0];
          const prodWithTotalContactsInYear = this.productsWithTotalContactsInYear.filter(
            (prod) => prod.name === contactDataKeyWithMoment.productName
          )[0];
          const prodWithTotalContactsInPeriod = this.getSelectedProducts.filter(
            (prod) => prod.name === contactDataKeyWithMoment.productName
          )[0];
          if (prodWithTotalContactsInPeriod)
            contactDataKeyWithMoment["totalPeriod"] =
              prodWithTotalContactsInPeriod.contactsTotal;
          if (prodWithTotalContactsInYear)
            contactDataKeyWithMoment["totalYear"] =
              prodWithTotalContactsInYear.contactsTotal;
          if (productsWithTotalContactsFull)
            contactDataKeyWithMoment["totalFull"] =
              productsWithTotalContactsFull.contactsTotal;
          contactsDatasLinkedMoments.push(contactDataKeyWithMoment);
        }
      });

      return contactsDatasLinkedMoments;
    },
    getData() {
      this.resetModule();

      if (this.dataRetrievalOk()) {
        this.loading = true;

        contactService
          .getContactsByWeekAndWeekend({
            selectedProductsIds: this.selectedProductsIds(),
            dateBegin: this.filterDateRange[0],
            dateEnd: this.filterDateRange[1],
          })
          .then(async (r) => {
            this.resetModule();

            const yearStart = Number(this.filterDateRange[0].split("-")[0]);
            const dateBeginOfYear = new Date(yearStart, 0, 1);
            const dateBeginToFullData = new Date(1922, 0, 1);
            const dateNow = new Date();

            this.productsWithTotalContactsInYear = await productService.getAvailableProductsWithTotalContacts(
              this.filterDateRange && this.filterDateRange.length == 2
                ? {
                    dateBegin: dateBeginOfYear.toISOString(),
                    dateEnd: dateNow.toISOString(),
                    choosen: this.getViewProducts,
                    selectedProductsIds: this.selectedProductsIds(),
                  }
                : {}
            );

            this.productsWithTotalContactsFull = await productService.getAvailableProductsWithTotalContacts(
              this.filterDateRange && this.filterDateRange.length == 2
                ? {
                    dateBegin: dateBeginToFullData.toISOString(),
                    dateEnd: dateNow.toISOString(),
                    choosen: this.getViewProducts,
                    selectedProductsIds: this.selectedProductsIds(),
                  }
                : {}
            );

            r = this.convertToTable(r);

            const productsSelectedLength = this.getSelectedProducts.length;

            if (r.length >= productsSelectedLength) {
              r.forEach((item, index) => {
                if (index < productsSelectedLength) {
                  this.tableItems.push({ ...item });
                  this.tableItemsExcel.push({
                    selectedProducts: this.getSelectedProducts[index]?.name,
                    ...item,
                  });
                } else {
                  this.tableItems.push({ ...item });
                  this.tableItemsExcel.push({
                    selectedProducts: "",
                    ...item,
                  });
                }
              });
            } else {
              this.getSelectedProducts.forEach((product, index) => {
                const item = r[index] ? r[index] : "";
                if (item) {
                  this.tableItems.push({ ...item });
                  this.tableItemsExcel.push({
                    selectedProducts: product?.name,
                    ...item,
                  });
                } else {
                  this.tableItemsExcel.push({
                    selectedProducts: product?.name,
                  });
                }
              });
            }

            this.loading = false;
          })
          .catch(this.errorHandler);
      }
    },
  },
};
</script>

<style scoped>
.unified-report {
  background-color: #fff;
}

.w-100 {
  z-index: 999;
}

.container-labels-color {
  display: flex;
  justify-content: center;
  align-items: center;
}

.label {
  display: flex;
  margin-right: 5px;
}

.label-text {
  font-size: 15px;
  margin-left: 5px;
}

.label-color {
  margin-left: 2px;
  border-radius: 10px;
  width: 50px;
  height: 20px;
}
</style>
