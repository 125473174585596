<template>
  <mobule-base-builder
    :module-data="moduleData"
    :editMode="editMode"
    :loading="loading"
    :error="error"
    :downloadImage="dataDownload"
    :viewRadiusCircle="viewRadiusCircle"
    :viewPins="viewPins"
    :viewLegendRadiusCircle="viewLegendRadiusCircle"
    :totalCustomersPurchaseMotivation="totalCustomersPurchaseMotivation"
    @getData="getData(true, true)"
    @activeRadiusCircleView="activeRadiusCircleView"
    @activeRadiusCircleLegendView="activeRadiusCircleLegendView"
  >
    <template v-slot:content>
      <module-google-map
        :loading="loading"
        :locations="locations"
        :standsLocations="standsLocations"
        :circles="circles"
        :viewRadiusCircle="viewRadiusCircle"
        :qtdRadiusCircle="qtdRadiusCircle"
        :viewPins="viewPins"
        :viewLegendRadiusCircle="viewLegendRadiusCircle"
        :error="error"
        class="module-contacts-with-purchase-motivation"
      />

      <div
        class="legends-container"
        v-if="
          selectedProducts.length > 0 &&
          legendsPurchaseMotivations.length > 0 &&
          !loading
        "
      >
        <div class="info-container">
          <h6>Legenda - Visitas por motivadores de compra</h6>
          <div class="container-purchase-motivation">
            <div
              class="container-purchase-motivation2"
              v-for="(lpm, index) in legendsPurchaseMotivations"
              :key="index"
            >
              <v-img
                :lazy-src="lpm.icon"
                max-height="30"
                max-width="30"
                :src="lpm.icon"
              ></v-img>
              <strong :style="{ color: lpm.color }"
                >{{ lpm.legend }} - Total: {{ lpm.total }}</strong
              >
            </div>
          </div>

          <div class="sub-legend">
            <span v-if="totalCustomersPurchaseMotivation !== 0">
              {{ totalCustomersPurchaseMotivation }}
              {{
                totalCustomersPurchaseMotivation > 1
                  ? "visitantes informaram"
                  : "visitante informou"
              }}
              endereço completo e responderam a questão motivação de compra para
              mapeamento.
            </span>
          </div>
        </div>
      </div>
    </template>
  </mobule-base-builder>
</template>
  
  <script>
import MobuleBaseBuilder from "../base/MobuleBaseBuilder.vue";
import MixinModuleBase from "../../../mixins/MixinModuleBase";
import ModuleGoogleMap from "../base/ModuleGoogleMap.vue";
import colorsAndImagesPinsMaps from "../../../utils/maps/colorsAndImagesPinsMaps";
import { getGoogleMapsAPI } from "gmap-vue";

import ContactService from "../../../services/ContactService";
const contactService = ContactService.build();

export default {
  name: "module-contacts-with-purchase-motivation",
  components: { ModuleGoogleMap, MobuleBaseBuilder },
  mixins: [MixinModuleBase],
  data() {
    return {
      locations: [],
      standsLocations: [],
      circles: [],
      legendsPurchaseMotivations: [],
      totalCustomersPurchaseMotivation: 0,
      dataDownload: {
        class: "module-contacts-with-purchase-motivation",
        title: this.moduleData.headerTitle,
      },
      viewPins: true,
      viewRadiusCircle: false,
      viewLegendRadiusCircle: false,
      qtdRadiusCircle: 1,
    };
  },
  computed: {
    google() {
      return getGoogleMapsAPI();
    },
  },
  methods: {
    resetModule(resetCircle) {
      this.locations = [];
      this.standsLocations = [];
      this.circles = [];
      this.viewLegendRadiusCircle = false;

      if (resetCircle) {
        this.qtdRadiusCircle = 1;
        this.viewRadiusCircle = false;
      }
    },
    getData(isNotLimit = false, resetCircle = true) {
      if (resetCircle) {
        this.resetModule(resetCircle);
      } else {
        this.resetModule(resetCircle);
      }

      if (this.dataRetrievalOk()) {
        this.loading = true;

        contactService
          .getLatLongContactsWithPurchaseMotivation({
            selectedProductsIds: this.selectedProductsIds(),
            dateBegin: this.filterDateRange[0],
            dateEnd: this.filterDateRange[1],
            limit: isNotLimit,
          })
          .then((results) => {
            this.resetModule(resetCircle);
            const standsLocations = this.getStandsPinsMap();
            const customersContactsLocations =
              this.getCustomersPinsMap(results);

            this.standsLocations = standsLocations;
            this.locations = customersContactsLocations;
            this.loading = false;
          })
          .catch(this.errorHandler);
      }
    },
    getStandsPinsMap() {
      const customStandsLocations = [];
      if (this.selectedProducts.length > 0 && this.google) {
        const scaledSize = new this.google.maps.Size(52, 52);
        for (let i = 0; i < this.selectedProducts.length; i++) {
          let standIcon = {};
          let standColor = "#E60023";

          standIcon = {
            url: colorsAndImagesPinsMaps[9].urlStandPin,
            scaledSize: scaledSize,
          };

          customStandsLocations.push({
            id: this.selectedProducts[i].id,
            position: {
              lat: Number(this.selectedProducts[i].latProduct),
              lng: Number(this.selectedProducts[i].lngProduct),
            },
            infoText: `
                    <h6 style="font-weight: bold; color: #000000; margin: 10px;">
                      ${this.selectedProducts[i].name}
                    </h6>
                    `,
            image: standIcon,
            standName: this.selectedProducts[i].name,
          });

          this.generateKilometerRaysPerStand(
            this.selectedProducts[i].id,
            this.selectedProducts[i].latProduct,
            this.selectedProducts[i].lngProduct,
            standColor
          );
        }

        return customStandsLocations;
      }

      return customStandsLocations;
    },
    getCustomersPinsMap(data) {
      let customCustomersContactsLocations = [];
      this.legendsPurchaseMotivations = [];
      let customersSameAddress = [];
      this.totalCustomersPurchaseMotivation = 0;
      const items = Object.entries(data);

      if (Object.entries(data).length > 0) {
        let customerIcon = {};
        const scaledSize = this.google
          ? new this.google.maps.Size(32, 32)
          : null;

        //Cálcula o total de clientes com endereço completo
        this.totalCustomersPurchaseMotivation = Object.entries(data).reduce(
          (acc, item) => {
            return (acc += item[1].length);
          },
          0
        );

        //Defini o array de dados dos clientes e seus marcadores no mapa
        items.forEach((item, index) => {
          //Defini as legendas de acordo com a cor do PIN
          this.legendsPurchaseMotivations.push({
            legend: item[0],
            icon: colorsAndImagesPinsMaps[index].urlCustomerPin,
            color: colorsAndImagesPinsMaps[index].color,
            total: item[1].length,
          });

          //Separa as latitudes repetidas para definir a Label no marcador
          const groupSameLat = item[1].reduce(function (r, a) {
            r[a.lat_address] = r[a.lat_address] || [];
            r[a.lat_address].push(a);
            return r;
          }, Object.create(null));

          customersSameAddress.push(groupSameLat);

          const formatLatAndQtd = [];
          customersSameAddress.forEach((csa) => {
            Object.entries(csa).forEach((item) => {
              if (item[1].length > 1) {
                return formatLatAndQtd.push({
                  lat: item[0],
                  total: item[1].length,
                });
              }
            });
          });

          //Monta cada marcador com seus respectivos dados
          item[1].forEach((contact) => {
            //Define o ícone por motivador de compra
            customerIcon = {
              url: colorsAndImagesPinsMaps[index].urlCustomerPin, // url
              scaledSize: scaledSize, // scaled size
            };

            //Formata data da visita
            const visitDate = new Date(contact.visit_date).toLocaleDateString(
              "pt-br",
              {
                year: "numeric",
                month: "numeric",
                day: "numeric",
              }
            );

            //Defini marcadores com a mesma posição que recebem a label no marcador
            const labelFind = formatLatAndQtd.find((element) => {
              return element.lat === contact.lat_address;
            });
            let label = "";
            if (labelFind) {
              label = String(labelFind.total);
            }

            customCustomersContactsLocations.push({
              id: contact.id,
              position: {
                lat: Number(contact.lat_address),
                lng: Number(contact.lng_address),
              },
              infoText: `<div style="margin: 5px 5px 20px 5px;">                 
                          <div style="display: flex; flex-direction: row; align-items: center;">
                            <span style="font-weight: bold; font-size: 16px; margin: 0 5px;">ID contato:</span> 
                            <span>${contact.id}</span>
                          </div>
                          <div style="display: flex; flex-direction: row; align-items: center;">
                            <span style="font-weight: bold; font-size: 16px; margin: 0 5px;">Cliente:</span> 
                            <span>${contact.name} ${contact.last_name}</span> 
                          </div>
                          <div style="display: flex; flex-direction: row; align-items: center;">
                            <span style="font-weight: bold; font-size: 16px; margin: 0 5px;">Produto:</span> 
                            <span>${contact.product_name}</span>
                          </div>
                          <div style="display: flex; flex-direction: row; align-items: center;">
                            <span style="font-weight: bold; font-size: 16px; margin: 0 5px;">Tipo de Visita:</span> 
                            <span>${
                              contact.is_indication ? "Indicação" : "Espontânea"
                            }</span>
                          </div>
                          <div style="display: flex; flex-direction: row; align-items: center;">
                            <span style="font-weight: bold; font-size: 16px; margin: 0 5px;">Motivadores de Compra:</span> 
                            <span>${contact.reply}</span>
                          </div>
                          <div style="display: flex; flex-direction: row; align-items: center;">
                            <span style="font-weight: bold; font-size: 16px; margin: 0 5px;">Data da visita:</span> 
                            <span>${visitDate}</span>
                          </div>
                      </div>
                              `,

              image: customerIcon,
              label,
            });
          });
        });

        return customCustomersContactsLocations;
      }

      return customCustomersContactsLocations;
    },
    generateKilometerRaysPerStand(standId, standLat, standLng, standColor) {
      const limitRadius = 21;
      for (let index = 1; index < limitRadius; index++) {
        const element = {
          circle: {
            id: standId + "-" + Math.random(),
            center: { lat: Number(standLat), lng: Number(standLng) },
            circleCenter: { lat: Number(standLat), lng: Number(standLng) },
            radius: Number(`${index}000`),
            color: standColor,
            infoText: `<span style="background-color: ${standColor}; padding: 8px 16px; color: white; font-weight: bold; border-radius: 5px;">${index} KM</span>`,
          },
        };

        this.circles.push(element);
      }
    },
    activeRadiusCircleView(qtd) {
      this.qtdRadiusCircle = Number(qtd);
      this.viewLegendRadiusCircle = false;

      if (qtd > 1) {
        this.viewRadiusCircle = true;
      } else {
        this.viewRadiusCircle = false;
      }
    },
    activeRadiusCircleLegendView() {
      this.viewLegendRadiusCircle = !this.viewLegendRadiusCircle;
    },
  },
};
</script>
  
  <style scoped lang="scss">
.module-contacts-map {
  background-color: #fff;
}

.legends-container {
  display: grid;
  padding-top: 1rem;
  grid-template-columns: 1fr;
  justify-items: center;
  max-height: 100px;
  height: 100px;
  position: relative;
  .info-container {
    width: 100%;
    border: 2px solid var(--smarts-black);
    border-radius: 0 0 1rem 1rem;

    h6 {
      width: 100%;
      background-color: var(--smarts-black);
      color: var(--smarts-white);
      font-weight: bold;
      padding: 1rem;
    }

    .container-purchase-motivation {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
      max-width: 90%;
      margin: 0 auto;
      align-items: center;

      .container-purchase-motivation2 {
        display: grid;
        grid-template-columns: 40px 1fr;
        align-items: center;
        padding: 0.5rem;
        border: 2px solid var(--smarts-black);

        strong {
          font-size: 1rem;
        }
      }
    }

    .sub-legend {
      max-width: 98%;
      margin: 1rem auto;
      padding: 0.5rem;
      font-weight: bold;
      text-align: center;
    }
    .container-contact-type {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      padding: 1rem;
    }
  }
}
</style>