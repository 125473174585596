<template>
  <form @submit.prevent="submit">
    <h5 class="mb-5 font-weight-bold">Digite sua nova senha:</h5>

    <form-input
      label="Senha"
      name="password"
      type="password"
      v-model="form.password.value"
      :error="form.password.error"
      @input="validate()"
      @validate="validate()"
    ></form-input>

    <form-input
      label="Confirme a senha"
      name="passwordConfirmation"
      type="password"
      v-model="form.passwordConfirmation.value"
      :error="form.passwordConfirmation.error"
      @input="validate()"
      @validate="validate()"
    ></form-input>

    <button class="btn btn-smarts-black btn-block" :disabled="!formIsValid">
      <i class="fas fa-key"></i>
      Alterar Senha
    </button>

    <div class="mt-2 d-flex justify-center">
      <small>
        <router-link :to="{ name: 'Login' }" replace class="text-secondary alert-link text-decoration-none">
          <i class="fas fa-sign-in-alt"></i>
          Ir à tela de login
        </router-link>
      </small>
    </div>
  </form>
</template>

<script>
import { object, string } from "yup";
import DefaultMixin from '../../mixins/DefaultMixin';

import UserService from '../../services/UserService';

import FormInput from "../login/FormInput.vue";

export default {
  name: 'ChangePassword',
  mixins: [DefaultMixin],
  components: {
    FormInput,
  },
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      userService: UserService.build(),
      form: {
        password: {
          value: '',
          error: null,
        },
        passwordConfirmation: {
          value: '',
          error: null,
        },
      },
    }
  },
  computed: {
    formIsValid() {
      return Object.values(this.form).findIndex(value => value.error || !value.value) === -1
    },
    schema() {
      return object().shape({
        password: string().required('Digite a senha para continuar!'),
        passwordConfirmation: string().required('Confirme sua senha!')
        .matches(new RegExp(`^${this.form.password.value}$`), { message: 'As duas senhas diferem!', excludeEmptyString: true }),
      });
    },
  },
  methods: {
    validate() {
      Object.entries(this.form).forEach(([key, control]) => {
        this.schema.validateAt(key, { [key]: control.value }).then(() => {
          control.error = null;

        }).catch(error => control.error = error.message);
      });
    },
    submit() {
      if (this.formIsValid) this.changePassword();
    },
    changePassword() {
      this.userService.changePassword({ token: this.token, password: this.form.password.value }).then(() => {
        this.alertSuccess({
          title: 'Senha Alterada com sucesso!',
        }).then(() => this.$router.replace({ name: 'Login' }));

      }).catch(this.errorHandler);
    },
  },
  mounted() {
    if (!this.token) this.$router.replace({ name: 'Login' });
  },
}
</script>

<style lang="scss" scoped>

.btn-smarts-black {
  background-color: var(--smarts-black);
  color: var(--smarts-white);
  width: 94%;
  padding: 0.8rem 0.6rem;
  border-radius: 0.8rem;
  font-size: 1rem;
  margin: 2rem auto 0 auto;
  font-weight: bold;
  transition: all 0.4s;

  &:hover {
    background-color: var(--smarts-wine);
  }
}
</style>
