<template>
  <mobule-base-builder
    :module-data="moduleData"
    :editMode="editMode"
    :loading="loading"
    :error="error"
    :downloadImage="dataDownload"
    :tableHeaders="tableHeaders"
    :tableItems="tableItems"
    :tableHeadersExcel="tableHeadersExcel"
    :tableItemsExcel="tableItemsExcel"
    :typePanelPDF="moduleData.displayType.chosen"
    :selectLabelToPDF="selectedOption"
  >
    <template v-slot:content>
      <div class="pa-3" v-if="getSelectedProducts.length > 0">
        <v-autocomplete
          v-model="selectedOption"
          :items="options"
          @change="getData()"
          id="mediaByProductOfInterest"
          label="Selecione um produto de interesse"
          class="px-4 pt-2"
          outlined
          dense
        ></v-autocomplete>
      </div>

      <module-chart-bar
        v-if="
          moduleData.displayType.chosen == 'CHART_BAR' &&
          barChartData.length > 0
        "
        :data="barChartData"
        :labels="labels"
        class="mediaByProductOfInterest"
      />
      <div
        class="echarts"
        v-else-if="
          moduleData.displayType.chosen == 'CHART_PIZZA' &&
          mediaByProductOfInterest.length > 0
        "
      >
        <module-chart-doughnut
          :dataTitle="''"
          :dataLegend="labels"
          :chartValues="mediaByProductOfInterest"
          class="mediaByProductOfInterest"
        />
      </div>
      <module-table-list-data-v-2
        v-else-if="
          moduleData.displayType.chosen == 'TABLE' && tableItems.length > 0
        "
        :tableHeaders="tableHeaders"
        :tableItems="tableItems"
        :footerPropsItemsPerPageText="footerPropsItemsPerPageText"
        class="mediaByProductOfInterest"
      />
      <message-status-data
        v-else
        :selected-products="selectedProductsIds()"
        :infoLoading="loading"
        :selectInPanel="selectInPanel"
      />
    </template>
  </mobule-base-builder>
</template>

<script>
import MixinModuleBase from "../../../mixins/MixinModuleBase";
import MobuleBaseBuilder from "../base/MobuleBaseBuilder.vue";

import ModuleChartBar from "../base/ModuleChartBar.vue";
import ModuleChartDoughnut from "../base/ModuleChartDoughnut.vue";
import ModuleTableListDataV2 from "../base/ModuleTableListDataV2.vue";
import MessageStatusData from "../../MessageStatusData.vue";

import ContactService from "../../../services/ContactService";
const contactService = ContactService.build();

export default {
  name: "module-media-by-product-of-interest",
  mixins: [MixinModuleBase],
  components: {
    MobuleBaseBuilder,
    ModuleTableListDataV2,
    MessageStatusData,
    ModuleChartDoughnut,
    ModuleChartBar,
  },
  data: function () {
    return {
      tableHeaders: [
        { text: "Mídia", align: "start", sortable: true, value: "mainMedia" },
        {
          text: "Contagem",
          align: "start",
          sortable: true,
          value: "totalMedia",
        },
      ],
      tableHeadersExcel: [
        {
          text: "Produtos Selecionados",
          align: "start",
          sortable: true,
          value: "selectedProducts",
        },
        {
          text: "Produto de interesse",
          align: "start",
          sortable: true,
          value: "interestProduct",
        },
        { text: "Mídia", align: "start", sortable: true, value: "mainMedia" },
        {
          text: "Contagem",
          align: "start",
          sortable: true,
          value: "totalMedia",
        },
      ],
      tableItems: [],
      tableItemsExcel: [],
      footerPropsItemsPerPageText: "Mídias por página",
      dataDownload: {
        class: "mediaByProductOfInterest",
        title: this.moduleData.headerTitle,
      },
      barChartData: [],
      labels: [],
      mediaByProductOfInterest: [],
      options: [],
      selectedOption: '',
      selectInPanel: {
        active: true,
        message: "Selecione algum produto de interesse",
      },
    };
  },
  computed: {
    getSelectedProducts() {
      return this.$store.getters.selectedProducts;
    },
  },
  methods: {
    resetModule() {
      this.mediaByProductOfInterest = [];
      this.tableItems = [];
      this.tableItemsExcel = [];
      this.barChartData = [];
      this.labels = [];
      this.selectInPanel = {
        active: true,
        message: "Selecione algum produto de interesse",
      };
    },
    getData() {
      this.resetModule();

      if (this.dataRetrievalOk() && this.selectedOption) {
        this.loading = true;

        contactService
          .getContactsWithMediaByProductOfInterest({
            selectedProductsIds: this.selectedProductsIds(),
            dateBegin: this.filterDateRange[0],
            dateEnd: this.filterDateRange[1],
          })
          .then((r) => {
            this.resetModule();

            const productsSelectedLength = this.getSelectedProducts.length;

            if (r.length >= productsSelectedLength) {
              r.forEach((item) => {
                if (item.productTypeName === this.selectedOption) {
                  item.medias.forEach((media, index) => {
                    if (index < productsSelectedLength) {
                      this.tableItems.push({
                        ...media,
                      });

                      this.tableItemsExcel.push({
                        selectedProducts: this.getSelectedProducts[index]?.name,
                        interestProduct: index === 0 ? this.selectedOption : "",
                        ...media,
                      });
                    } else {
                      this.tableItems.push({
                        ...media,
                      });

                      this.tableItemsExcel.push({
                        selectedProducts: "",
                        interestProduct: "",
                        ...media,
                      });
                    }
                  });
                }
              });
            } else {
              let filterSameProductType = [];
              r.forEach((item) => {
                if (item.productTypeName === this.selectedOption) {
                  filterSameProductType = item.medias.filter((media) => media);
                }
              });

              this.getSelectedProducts.forEach((product, index) => {
                const media = filterSameProductType[index];
                if (media) {
                  this.tableItems.push({
                    ...media,
                  });

                  this.tableItemsExcel.push({
                    selectedProducts: product?.name,
                    interestProduct: index === 0 ? this.selectedOption : "",
                    ...media,
                  });
                } else {
                  this.tableItemsExcel.push({
                    selectedProducts: product?.name,
                  });
                }
              });
            }

            r.map((item) => {
              if (item.productTypeName === this.selectedOption) {
                item.medias.forEach((media) => {
                  this.barChartData.push(media.totalMedia);
                  this.labels.push(media.mainMedia);

                  this.mediaByProductOfInterest.push({
                    value: media.totalMedia,
                    name: media.mainMedia,
                  });
                });
              }
            });
            this.loading = false;

            this.selectInPanel = {
              active: false,
              message: "Selecione algum produto de interesse",
            };
          })
          .catch(this.errorHandler);
      }
    },
  },
  watch: {
    getSelectedProducts() {
      this.options = [];
      this.resetModule();
      this.selectInPanel = {
        active: true,
        message: "Selecione algum produto de interesse",
      };
      contactService
        .getContactsWithMediaByProductOfInterest({
          selectedProductsIds: this.selectedProductsIds(),
          dateBegin: this.filterDateRange[0],
          dateEnd: this.filterDateRange[1],
        })
        .then((response) => {
          if (response.length > 0) {
            this.options = response.map(
              (productType) => productType.productTypeName
            );
          }
        })
        .catch(this.errorHandler);

      this.selectedOption = null;
    },
  },
};
</script>

<style scoped>
.mediaByProductOfInterest {
  background-color: #fff;
}

.w-100 {
  z-index: 999;
}
</style>
