import Rest from './base/Rest'

/**
 * @typedef {ContactService}
 */
export default class MediaService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/media'


  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  getCountMainMedia(params = {}) {
    return this.get(`/getCountMainMedia?${this.createQueryString(params)}`)
  }


  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
   getCountSecondaryMedia (params = {}) {
    return this.get(`/getCountSecondaryMedia?${this.createQueryString(params)}`)
  }

}