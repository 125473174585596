var render = function render(){var _vm=this,_c=_vm._self._c;return _c('mobule-base-builder',{attrs:{"module-data":_vm.moduleData,"editMode":_vm.editMode,"loading":_vm.loading,"error":_vm.error,"downloadImage":_vm.dataDownload,"tableHeaders":_vm.tableHeaders,"tableItems":_vm.tableItems,"tableHeadersExcel":_vm.tableHeadersExcel,"tableItemsExcel":_vm.tableItemsExcel,"typePanelPDF":_vm.moduleData.displayType.chosen},scopedSlots:_vm._u([{key:"content",fn:function(){return [(
        _vm.moduleData.displayType.chosen == 'CHART_BAR' &&
        _vm.barChartData.length > 0
      )?_c('module-chart-bar',{class:_vm.dataDownloadNameClass,attrs:{"data":_vm.barChartData,"labels":_vm.labels}}):(
        _vm.moduleData.displayType.chosen == 'CHART_PIZZA' &&
        _vm.contactTypes.length > 0
      )?_c('div',{staticClass:"echarts"},[_c('module-chart-doughnut',{class:_vm.dataDownloadNameClass,attrs:{"dataTitle":"","dataLegend":_vm.labels,"legendOrientation":'top',"chartValues":_vm.contactTypes}})],1):(
        _vm.moduleData.displayType.chosen == 'TABLE' && _vm.tableItems.length > 0
      )?_c('module-table-list-data-v2',{class:_vm.dataDownloadNameClass,attrs:{"tableHeaders":_vm.tableHeaders,"tableItems":_vm.tableItems}}):_c('message-status-data',{attrs:{"selected-products":_vm.selectedProductsIds(),"infoLoading":_vm.loading}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }