import Http from '../base/Http';
import telephony from './telephony'

/**
 * @typedef {TelephonyHttp}
 */
export default class TelephonyHttp extends Http {
  /**
   * @param {String} path
   * @param {Object} options
   * @param {Object} http
   */
  constructor (path, options = {}, http = null) {
    super(options);
    this.path = path;
    this.http = http || telephony;
  }
}
