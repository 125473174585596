var render = function render(){var _vm=this,_c=_vm._self._c;return _c('mobule-base-builder',{ref:"tableParent",attrs:{"module-data":_vm.moduleData,"editMode":_vm.editMode,"loading":_vm.loading,"error":_vm.error,"downloadImage":_vm.dataDownload,"tableHeaders":_vm.tableHeaders,"tableItems":_vm.tableItems,"tableHeadersExcel":_vm.tableHeadersExcel,"tableItemsExcel":_vm.tableItemsExcel,"typePanelPDF":_vm.moduleData.displayType.chosen},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-app',{ref:"tableApp",attrs:{"id":""}},[_c('v-layout',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.updateDataTableHeight),expression:"updateDataTableHeight"}],staticStyle:{"max-height":"100%"},attrs:{"fluid":""}},[(
            _vm.moduleData.displayType.chosen == 'CHART_BAR' &&
            _vm.barChartData.length > 0
          )?_c('module-chart-bar',{staticClass:"main-media",attrs:{"data":_vm.barChartData,"labels":_vm.labels}}):(
            _vm.moduleData.displayType.chosen == 'CHART_PIZZA' &&
            _vm.chartValues.length > 0
          )?_c('div',{staticClass:"echarts"},[_c('module-chart-doughnut',{staticClass:"main-media",attrs:{"dataTitle":"","dataLegend":_vm.labels,"legendOrientation":'top',"chartValues":_vm.chartValues}})],1):(
            _vm.moduleData.displayType.chosen == 'TABLE' && _vm.tableItems.length > 0
          )?_c('module-table-list-data-v2',{staticClass:"main-media",attrs:{"tableHeaders":_vm.tableHeaders,"tableItems":_vm.tableItems,"footerPropsItemsPerPageText":_vm.footerPropsItemsPerPageText}}):_c('message-status-data',{attrs:{"selected-products":_vm.selectedProductsIds(),"infoLoading":_vm.loading}})],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }