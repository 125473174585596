<template>
  <v-dialog
    v-model="centerDialogVisible"
    width="50%"
    center
    @close="closeModal(false)"
    :align-center="true"
  >
    <v-card>
      <v-toolbar color="#000e17" dark elevation="0">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="grey lighten-1"> fas fa-info-circle </v-icon>
            </v-btn>
          </template>
          <span
            >Selecione uma empresa e usuário para visualizar e <br />
            gerenciar seus Dashboards.</span
          >
        </v-tooltip>
        Gerenciar Dashboard
      </v-toolbar>

      <available-users-input
        :key="centerDialogVisible"
        :selectedEnterpriseValue="selectedEnterprise"
        @update:selectedEnterprise="selectedEnterprise = $event"
        :selectedUserValue="selectedUser"
        @update:selectedUser="selectedUser = $event"
        :isMultiple="false"
      ></available-users-input>

      <v-card-actions class="justify-end">
        <v-btn color="error" @click="closeModal(false)">Cancelar</v-btn>
        <v-btn color="success" @click="fetchJWTTemp">Gerenciar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import LoginService from "../../services/LoginService";

import DefaultMixin from "../../mixins/DefaultMixin";
import EnterpriseNameLogoMixin from "../../mixins/EnterpriseNameLogoMixin";
import AvailableUsersInput from "./AvailableUsersInput.vue";

export default {
  name: "ModalManageDashboard",
  mixins: [DefaultMixin, EnterpriseNameLogoMixin],
  components: {
    AvailableUsersInput,
  },
  props: {
    centerDialogVisible: { type: Boolean, default: false },
  },
  data() {
    return {
      loginService: LoginService.build(),
      selectedEnterprise: null,
      selectedUser: null,
    };
  },
  methods: {
    closeModal(reload = false) {
      this.resetVariables();
      this.$emit("closeModal", false);
      this.$store.dispatch("storeReloadDashboard", reload);
    },
    fetchJWTTemp() {
      if (this.selectedEnterprise && this.selectedUser) {
        const credencials = {
          id: this.selectedUser.id,
          dataBase: this.selectedEnterprise[0].dataBase,
        };

        this.$store.dispatch("storeJwtTemp", null).then(() => {
          this.loginService
            .generateJWT(credencials)
            .then((res) => {
              this.$store.dispatch("storeJwtTemp", res[`access_token`]).then(async () => {
                this.$store.dispatch(
                  "storeEnterprise",
                  this.selectedEnterprise[0].dataBase
                );
                this.$store.dispatch("storeUserTemp", this.selectedUser);
                await this.getEnterpriseByDBName();
                this.changeLogoImage();
                this.closeModal(true);
              });
            })
            .catch(this.errorHandler);
        });
      } else this.alertWarning({ title: `Empresa e usuário são obrigatórios!` });
    },
    changeLogoImage() {
      this.$emit("changeLogoImage");
    },
    resetVariables() {
      this.selectedEnterprise = null;
      this.selectedUser = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-title {
  font-size: 32px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
</style>
