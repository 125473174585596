import Http from './Http'

/**
 * @type Api
 */
export default class Api extends Http {
    /**
     * @type {String}
     */
    static base = process.env.VUE_APP_API_BASE_URL+'/'

    /**
     * @param {String} path
     * @param {Object} options
     * @param {Object} http
     * @return {this}
     */
    static build (path = '', options = {}, http = null) {
        return new this(Api.normalize(Api.base, path), options, http)
    }

    createQueryString(params = {}) {
        const usp = new URLSearchParams();
        for (var [key, value] of Object.entries(params)) {
            if (Array.isArray(value)) {
                value.forEach(function (v, k) {
                    usp.append(key+'[]', v);
                })
            } else {
                usp.append(key, value);
            }
        }
        return usp.toString();
    }
}