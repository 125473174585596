<template>
  <div>
    <div class="container bg-white col-md-10 my-6">
      <div class="row row-cols-auto align-items-center p-4">
        <div class="col input-group">
          <legend class="m-0">Gerenciar e-mails</legend>
        </div>
        <div class="col col-md-7">
          <template>
              <v-autocomplete
                v-model="idSelectedEnterprise"
                :items="enterprises"
                item-text="name"
                item-value="id"
                @change="getRecipientsByEnterpriseId(idSelectedEnterprise)"
                label="Escolha uma empresa"
                id="selectEnterprise"
                class="ml-4 mt-6"
                clearable
                outlined
              ></v-autocomplete>
          </template>
        </div>

        <div class="col col-md-12 mt-4" v-if="idSelectedEnterprise">
          <div class="col input-group col col-md-12">
            <legend class="m-0">Adicionar endereços de e-mail</legend>
          </div>
          <form @submit.prevent="addNewRecipient" class="form-recipient">
            <div class="container-inputs">
              <form-input
                label="Nome do destinário:"
                type="text"
                name="recipientName"
                v-model="values.recipientName"
                :placeholder="'Ex: João da Silva'"
                :error="errors.recipientName"
                @validate="validate('recipientName')"
                class="input-label mb-1"
              ></form-input>
              <i class="far fa-trash-alt" title="Limpar Nome" @click="clearNameInput">
              </i>

              <form-input
                label="Endereço de e-mail para envio:"
                type="email"
                name="recipientEmail"
                v-model="values.recipientEmail"
                :error="errors.recipientEmail"
                :placeholder="'Ex: joao.silva@enterprise.com'"
                @validate="validate('recipientEmail')"
                class="input-label mb-1 ml-5"
              ></form-input>
              <i class="far fa-trash-alt" title="Limpar E-mail" @click="clearEmailInput">
              </i>
            </div>

            <div class="input-group col-12 justify-content-center mt-2">
              <button class="btn btn-dark btn-lg btn-block col-6">
                Adicionar novo e-mail
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="container bg-white form-group col-md-10" v-if="idSelectedEnterprise">
      <div class="col col-12 d-flex align-items-center flex-direction-row">
        <legend class="m-0 col col-4">Gerenciar status de e-mail</legend>
      </div>

      <div class="col col-12 d-flex align-items-center flex-direction-row">
        <div class="col col-4 d-flex justify-content-start align-items-center">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon color="grey lighten-1"> fas fa-info-circle </v-icon>
              </v-btn>
            </template>
            <span
              >Só demonstra os e-mails ativos e inativos antes de clicar em atualizar.<br />
              Após atualizar, sempre trará os status dos e-mails atualizados.</span
            >
          </v-tooltip>

          <button
            class="btn btn-custom-yellow"
            @click="getRecipientsByEnterpriseId(idSelectedEnterprise)"
          >
            <i class="fas fa-eye"></i>
            Rever status atuais
          </button>
        </div>

        <div class="col col-4 d-flex align-items-center m-0" v-if="recipients.length > 1">
          <div class="custom-control">
            <v-switch
              v-model="selectAll"
              color="#000000"
              :label="selectAllLabel"
              @change="handleSelectAllRecipients"
              class="mt-5"
            ></v-switch>
          </div>
        </div>

        <div
          class="col col-4 d-flex align-items-center m-0"
          v-if="recipients.length > 1 && viewhideOrShowInactiveButton"
        >
          <div class="custom-control">
            <v-switch
              v-model="hideOrShowInactive"
              color="#000000"
              :label="hideOrShowInactiveLabel"
              @change="handleHideOrShowInactiveRecipients"
              class="mt-5"
            ></v-switch>
          </div>
        </div>
      </div>
      <v-card>
        <v-card-title>
          {{ recipients.length }}
          {{
            recipentsLessThanOrEqualToOne
              ? "Endereço encontrado"
              : "Endereços encontrados"
          }}
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            outlined
            hide-details
          ></v-text-field>
        </v-card-title>

        <v-data-table
          :headers="tableHeaders"
          :items="filterRecipients.length > 0 ? filterRecipients : recipients"
          :footer-props="footerProps"
          ref="dataTable"
          :search="search"
          :items-per-page="10"
        >
          <template v-slot:[`item.editName`]="{ item }">
            <i
              @click="handleEditName(item)"
              class="fas fa-edit fa-lg ml-8"
              :style="{ cursor: 'pointer' }"
            ></i>
          </template>

          <template v-slot:[`item.editEmail`]="{ item }">
            <i
              @click="handleEditEmail(item)"
              class="fas fa-edit fa-lg ml-8"
              :style="{ cursor: 'pointer' }"
            ></i>
          </template>

          <template v-slot:[`item.active`]="{ item }">
            <v-switch
              v-model="item.recipientEnterprise.active"
              @change="activeOrInactiveRecipient(item)"
              color="#000000"
              class="mt-5"
            ></v-switch>
          </template>
        </v-data-table>
      </v-card>

      <div class="input-group col-12 justify-content-center">
        <button
          class="btn btn-dark btn-lg btn-block col-6"
          @click="updateRecipients"
          :disabled="recipients.length === 0"
        >
          Atualizar status dos e-mails
        </button>
      </div>
    </div>

    <!-- Edit nome do destinatário -->
    <v-col cols="auto">
      <v-dialog max-width="600" v-model="values.dialogEditNameVisible">
        <v-card>
          <v-toolbar color="#000e17" dark elevation="0">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon color="grey light"> fas fa-info-circle </v-icon>
                </v-btn>
              </template>

              <span> Após essa ação o nome do destinatário será atualizado! </span>
            </v-tooltip>
            Digite o nome do destinatário para editar!
          </v-toolbar>
          <v-card-text>
            <div class="input-group p-2 container-input mt-6">
              <form-input
                label="Nome do destinatário:"
                type="text"
                name="recipientNameEdit"
                v-model="values.recipientNameEdit"
                :error="errors.recipientNameEdit"
                :placeholder="'Ex: João da Silva'"
                @validate="validate('recipientNameEdit')"
                class="input-label mb-1 ml-5"
              >
              </form-input>
              <i class="far fa-trash-alt" title="Limpar Nome" @click="clearEditNameInput">
              </i>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn depressed color="error" @click="values.dialogEditNameVisible = false"
              >Cancelar</v-btn
            >
            <v-btn depressed color="primary" @click="saveEditName">Editar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>

    <!-- Edit e-mail -->
    <v-col cols="auto">
      <v-dialog max-width="600" v-model="values.dialogEditEmailVisible">
        <v-card>
          <v-toolbar color="#000e17" dark elevation="0">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon color="grey light"> fas fa-info-circle </v-icon>
                </v-btn>
              </template>

              <span> Após essa ação o e-mail será atualizado! </span>
            </v-tooltip>
            Digite o e-mail correto para editar!
          </v-toolbar>
          <v-card-text>
            <div class="input-group p-2 container-input mt-6">
              <form-input
                label="Endereço de e-mail para envio:"
                type="email"
                name="recipientEmailEdit"
                v-model="values.recipientEmailEdit"
                :error="errors.recipientEmailEdit"
                :placeholder="'Ex: joao.silva@enterprise.com'"
                @validate="validate('recipientEmailEdit')"
                class="input-label mb-1 ml-5"
              ></form-input>
              <i
                class="far fa-trash-alt"
                title="Limpar Nome"
                @click="clearEditEmailInput"
              >
              </i>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn depressed color="error" @click="values.dialogEditEmailVisible = false"
              >Cancelar</v-btn
            >
            <v-btn depressed color="primary" @click="saveEditEmail">Editar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </div>
</template>

<script>
import DefaultMixin from "../../mixins/DefaultMixin";
import ManageMixin from "../../mixins/ManageMixin";

import EnterpriseService from "../../services/EnterpriseService";
import RecipientService from "../../services/RecipientService";
import RecipientEnterpriseService from "../../services/RecipientEnterpriseService";

import { object, string } from "yup";

import FormInput from "../login/FormInput.vue";

const loginFormSchema = object().shape({
  recipientName: string().required("Nome precisa ter no minímo 3 caracteres."),
  recipientEmail: string()
    .email("Formato de e-mail inválido!")
    .required("E-mail é obrigatório!"),
  recipientNameEdit: string().required("Nome precisa ter no minímo 3 caracteres."),
  recipientEmailEdit: string()
    .email("Formato de e-mail inválido!")
    .required("E-mail é obrigatório!"),
});

export default {
  name: "ManageEmailReports",
  mixins: [DefaultMixin, ManageMixin],
  components: {
    "form-input": FormInput,
  },
  data() {
    return {
      enterpriseService: EnterpriseService.build(),
      recipientService: RecipientService.build(),
      recipientEnterpriseService: RecipientEnterpriseService.build(),

      values: {
        recipientName: "",
        recipientEmail: "",
        recipientNameEdit: "",
        recipientEmailEdit: "",
        dialogEditEmailVisible: false,
        dialogEditNameVisible: false,
      },

      errors: {
        recipientName: "",
        recipientEmail: "",
        recipientNameEdit: "",
        recipientEmailEdit: "",
        dialogEditEmailVisible: false,
        dialogEditNameVisible: false,
      },

      recipientName: "",
      recipientEmail: "",
      recipientNameEdit: "",
      recipientEmailEdit: "",

      enterprises: [],
      recipients: [],
      recipientEdit: {},
      filterRecipients: [],
      users: [],
      recipentsLessThanOrEqualToOne: true,
      idSelectedEnterprise: null,
      idSelectedUser: null,
      listRecipientsUpdate: [],
      enterprisesItemTable: [],
      viewListEnterprises: false,
      selectAll: false,
      selectAllLabel: "Marcar Todas",
      hideOrShowInactive: false,
      hideOrShowInactiveLabel: "Ocultar Inativos",
      viewhideOrShowInactiveButton: false,
      footerProps: {
        "items-per-page-options": [5, 10, 15, 30],
        "items-per-page-text": "Empresas por página",
      },
      tableHeaders: [
        {
          text: "Nome do destinatário",
          align: "start",
          sortable: true,
          value: "name",
        },
        { text: "E-mail", align: "start", sortable: true, value: "email" },
        {
          text: "Editar nome",
          align: "start",
          sortable: false,
          value: "editName",
        },
        {
          text: "Editar e-mail",
          align: "start",
          sortable: false,
          value: "editEmail",
        },
        { text: "Status", align: "start", sortable: false, value: "active" },
      ],
      search: "",
      successTitle: "",
      tab: null,
    };
  },
  methods: {
    resetVariables() {
      this.listRecipientsUpdate = [];
      this.search = "";
      this.enterprisesItemTable = [];
      this.recipients = [];
      this.filterRecipients = [];
      this.recipientName = "";
      this.recipientEmail = "";
      this.recipientNameEdit = "";
      this.recipientEmailEdit = "";
      this.values.recipientName = "";
      this.values.recipientEmail = "";
      this.values.recipientNameEdit = "";
      this.values.recipientEmailEdit = "";
      this.errors.recipientName = "";
      this.errors.recipientEmail = "";
      this.errors.recipientNameEdit = "";
      this.errors.recipientEmailEdit = "";
    },
    resetEditName() {
      this.values.dialogEditNameVisible = false;
      this.values.recipientNameEdit = "";
      this.errors.recipientNameEdit = "";
    },
    resetEditEmail() {
      this.values.dialogEditEmailVisible = false;
      this.values.recipientEmailEdit = "";
      this.errors.recipientEmailEdit = "";
    },
    async getAllEnterprises() {
      this.enterprises = await this.enterpriseService.findAllByAccountWithActiveBI(
        this.getUserIdLogin
      );
    },
    async getRecipientsByEnterpriseId(idSelectedEnterprise) {
      this.resetVariables();

      this.loading = true;

      this.recipientService
        .findRecipientsByEnterpriseId(idSelectedEnterprise)
        .then((response) => {
          if (response && response.length > 0) {
            this.checkSelectAllLabel(response);
            this.recipients = response;

            if (this.recipients.length > 1) {
              this.recipentsLessThanOrEqualToOne = false;
            }

            this.loading = false;
          }
        })
        .catch(this.errorHandler);
    },
    async updateRecipients() {
      if (this.listRecipientsUpdate.length > 0) {
        const result = await this.confirmationDialog({
          title: "Você tem certeza?",
          text: `Ao confirmar, a lista de e-mails ativos para envio de relatórios será atualizada!`,
        });

        if (result.isConfirmed) {
          try {
            await this.recipientService.updateRecipients(this.listRecipientsUpdate);
            this.alertSuccess({
              title: `A lista de e-mails ativos e inativos foi atualizada com sucesso!`,
            });
          } catch (err) {
            this.alertError({
              title: "Não foi possível atualizar a lista de e-mails!",
              text: "Tente novamente, se o erro persistir contate o administrador!",
            });
          }
        }
        this.getRecipientsByEnterpriseId(this.idSelectedEnterprise);
      } else {
        this.alertWarning({
          title: `Não foi identificada nenhuma mudança na lista de destinatários e e-mails!`,
        });
      }

      this.listRecipientsUpdate = [];
    },
    activeOrInactiveRecipient(recipient) {
      const result = this.listRecipientsUpdate.filter(
        (item) =>
          item.recipientEnterprise.recipientId ===
          recipient.recipientEnterprise.recipientId
      );

      if (result.length > 0) {
        const index = this.listRecipientsUpdate.indexOf(result[0]);
        this.listRecipientsUpdate.splice(index, 1);

        this.listRecipientsUpdate.push({
          id: recipient.id,
          name: recipient.name,
          email: recipient.email,
          updatedBy: this.getUserIdLogin,
          recipientEnterprise: [
            {
              id: recipient.recipientEnterprise.id,
              enterpriseId: recipient.recipientEnterprise.enterpriseId,
              active: recipient.recipientEnterprise.active,
              recipientId: recipient.id,
              updatedBy: this.getUserIdLogin,
            },
          ],
        });
      } else {
        this.listRecipientsUpdate.push({
          id: recipient.id,
          name: recipient.name,
          email: recipient.email,
          updatedBy: this.getUserIdLogin,
          recipientEnterprise: [
            {
              id: recipient.recipientEnterprise.id,
              enterpriseId: recipient.recipientEnterprise.enterpriseId,
              active: recipient.recipientEnterprise.active,
              recipientId: recipient.id,
              updatedBy: this.getUserIdLogin,
            },
          ],
        });
      }

      if (recipient.recipientEnterprise.active) {
        this.selectAllLabel = "Desmarcar Todas";
        this.selectAll = true;
      } else {
        this.selectAllLabel = "Marcar Todas";
        this.selectAll = false;
      }
    },
    handleEditName(recipient) {
      this.values.recipientNameEdit = recipient.name;
      this.recipientNameEdit = recipient.name;
      this.recipientEdit = recipient;
      this.values.dialogEditNameVisible = true;
    },
    handleEditEmail(recipient) {
      this.values.recipientEmailEdit = recipient.email;
      this.recipientEmailEdit = recipient.email;
      this.recipientEdit = recipient;
      this.values.dialogEditEmailVisible = true;
    },
    async saveEditName() {
      if (!this.values.recipientNameEdit && !this.errors.recipientNameEdit) {
        this.alertWarning({
          title: `Nome do destinatário é obrigatório!`,
        });
      } else {
        const recipientAlreadyExists = await this.recipientService.findRecipientByEmail(
          this.recipientEdit.email.toLocaleLowerCase()
        );

        if (Object.entries(recipientAlreadyExists).length > 0) {
          await this.recipientService
            .updateNameAndEmailById({
              recipientId: this.recipientEdit.id,
              email: String(this.recipientEdit.email.toLowerCase()),
              name: this.values.recipientNameEdit,
            })
            .then(() => {
              this.values.dialogEditNameVisible = false;
              this.alertSuccess({
                title: `Nome do destinatário editado com sucesso!`,
              });
            })
            .catch(this.errorHandler);

          await this.getRecipientsByEnterpriseId(this.idSelectedEnterprise);
        } else {
          this.alertWarning({
            title: `Não foi possível atualizar o nome do destinatário!`,
          });
        }
      }
    },
    async saveEditEmail() {
      if (!this.values.recipientEmailEdit && !this.errors.recipientEmailEdit) {
        this.alertWarning({
          title: `E-mail é obrigatório!`,
        });
      } else {
        const recipientAlreadyExists = await this.recipientService.findRecipientByEmail(
          this.values.recipientEmailEdit.toLocaleLowerCase()
        );

        if (Object.entries(recipientAlreadyExists).length > 0) {
          this.values.dialogEditEmailVisible = false;

          this.alertWarning({
            title: "Atenção!",
            text: `O ${recipientAlreadyExists.email} já está cadastrado!`,
          });
        } else {
          await this.recipientService
            .updateNameAndEmailById({
              recipientId: this.recipientEdit.id,
              email: String(this.values.recipientEmailEdit.toLowerCase()),
              name: this.recipientEdit.name,
            })
            .then(() => {
              this.values.dialogEditEmailVisible = false;
              this.alertSuccess({
                title: `E-mail editado com sucesso!`,
              });
            })
            .catch(this.errorHandler);
        }

        await this.getRecipientsByEnterpriseId(this.idSelectedEnterprise);
      }
    },
    checkSelectAllLabel(recipients) {
      const findActive = recipients.filter(
        (recipient) => recipient.recipientEnterprise.active
      );
      const findInactive = recipients.filter(
        (recipient) => !recipient.recipientEnterprise.active
      );

      if (findActive && findActive.length === recipients.length) {
        this.selectAllLabel = "Desmarcar Todas";
        this.selectAll = true;
      } else {
        this.selectAllLabel = "Marcar Todas";
        this.selectAll = false;
      }

      if (
        (findActive && findActive.length === recipients.length) ||
        (findInactive && findInactive.length === recipients.length)
      ) {
        this.viewhideOrShowInactiveButton = false;
        this.hideOrShowInactive = false;
      } else {
        this.viewhideOrShowInactiveButton = true;
        this.hideOrShowInactive = false;
      }
    },
    handleSelectAllRecipients() {
      if (this.filterRecipients.length > 0) {
        for (let recipient of this.filterRecipients) {
          if (this.selectAll) {
            recipient.recipientEnterprise.active = true;
            this.activeOrInactiveRecipient(recipient);
          } else {
            recipient.recipientEnterprise.active = false;
            this.activeOrInactiveRecipient(recipient);
          }
        }
      } else {
        for (let recipient of this.recipients) {
          if (this.selectAll) {
            recipient.recipientEnterprise.active = true;
            this.activeOrInactiveRecipient(recipient);
          } else {
            recipient.recipientEnterprise.active = false;
            this.activeOrInactiveRecipient(recipient);
          }
        }
      }
    },
    handleHideOrShowInactiveRecipients() {
      if (this.hideOrShowInactive) {
        const tempRecipients = [...this.recipients];
        this.filterRecipients = tempRecipients.filter(
          (recipient) => recipient.recipientEnterprise.active
        );
        this.checkSelectAllLabel(this.filterRecipients);
      } else {
        this.filterRecipients = [];
        const tempRecipients = [...this.recipients];
        this.checkSelectAllLabel(tempRecipients);
      }
    },
    async addNewRecipient() {
      this.loading = true;
      if (!this.recipientName || !this.recipientEmail) {
        this.alertWarning({
          title: `Nome do destinatário e e-mail são obrigatórios`,
        });
      }

      if (
        !this.errors.recipientEmail &&
        this.recipientEmail &&
        !this.errors.recipientName &&
        this.recipientName
      ) {
        const recipientAlreadyExists = await this.recipientService.findRecipientByEmail(
          this.recipientEmail.toLocaleLowerCase()
        );
        const enterprise = this.enterprises.find(
          (enterprise) => enterprise.id === this.idSelectedEnterprise
        );

        if (Object.entries(recipientAlreadyExists).length > 0) {
          const recipientEnterprise = {
            enterpriseId: this.idSelectedEnterprise,
            recipientId: recipientAlreadyExists.id,
            createdBy: this.getUserIdLogin,
            active: true,
          };

          const recipientEnterpriseAlreadyExists = await this.recipientEnterpriseService.findByRecipientIdAndEnterpriseId(
            {
              enterpriseId: this.idSelectedEnterprise,
              recipientId: recipientAlreadyExists.id,
            }
          );

          if (
            recipientEnterpriseAlreadyExists &&
            recipientEnterpriseAlreadyExists.length > 0
          ) {
            this.alertWarning({
              title: `E-mail já consta vinculado a empresa ${enterprise.name}, verifique na lista abaixo!`,
            });
          } else {
            await this.recipientEnterpriseService
              .saveNewRecipientEnterprise(recipientEnterprise)
              .then(() => {
                this.alertSuccess({
                  title: `E-mail cadastrado e vinculado a empresa ${enterprise.name}, com sucesso!`,
                });
              })
              .catch(this.errorHandler);
          }
        } else {
          const recipient = {
            name: this.recipientName,
            email: this.recipientEmail.toLocaleLowerCase(),
            createdBy: this.getUserIdLogin,
          };

          await this.recipientService
            .saveNewRecipient(recipient)
            .then(async (recipientSave) => {
              const recipientEnterprise = {
                enterpriseId: this.idSelectedEnterprise,
                recipientId: recipientSave.id,
                createdBy: this.getUserIdLogin,
                active: true,
              };

              await this.recipientEnterpriseService
                .saveNewRecipientEnterprise(recipientEnterprise)
                .then(() => {
                  this.alertSuccess({
                    title: `E-mail cadastrado e vinculado a empresa ${enterprise.name}, com sucesso!`,
                  });
                })
                .catch(this.errorHandler);
            })
            .catch(this.errorHandler);
        }
        await this.getRecipientsByEnterpriseId(this.idSelectedEnterprise);
      }
    },
    validate(field) {
      loginFormSchema
        .validateAt(field, this.values)
        .then(() => {
          this.recipientName = this.values.recipientName;
          this.recipientEmail = this.values.recipientEmail;

          this.errors[field] = "";
        })
        .catch((err) => {
          this.errors[err.path] = err.message;
        });
    },
    clearNameInput() {
      this.recipientName = "";
      this.values.recipientName = "";
      this.errors.recipientName = "";
    },
    clearEmailInput() {
      this.recipientEmail = "";
      this.values.recipientEmail = "";
      this.errors.recipientEmail = "";
    },
    clearEditNameInput() {
      this.recipientNameEdit = "";
      this.values.recipientNameEdit = "";
      this.errors.recipientNameEdit = "";
    },
    clearEditEmailInput() {
      this.recipientEmailEdit = "";
      this.values.recipientEmailEdit = "";
      this.errors.recipientEmailEdit = "";
    },
  },
  computed: {
    getUserIdLogin() {
      return Number(this.$store.getters.userId);
    },
    getUserSystemRole() {
      return this.$store.getters.userIsManager;
    },
    getTempUser() {
      return this.$store.getters.userTemp;
    },
    getFixedLoginDatas() {
      return this.$store.getters.fixedLoginDatas;
    },
  },
  async mounted() {
    await this.getAllEnterprises();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.getters.userIsManager) {
        next();
      } else {
        next({ path: "/dashboard" });
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.container {
  border-radius: 2rem !important;
}
.form-recipient {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .container-inputs {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 1px 5px 0 rgba(0, 0, 0, 0.12);
    padding: 2rem 0.5rem;
  }

  i {
    align-self: flex-start;
    font-size: 1.4rem;
    cursor: pointer;
    margin-left: -6rem;
    margin-top: 2rem;
  }
}

.container-input {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  i {
    align-self: flex-start;
    font-size: 1.4rem;
    cursor: pointer;
    margin-left: -2rem;
    margin-top: 2rem;
  }
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  background-color: #23272b;
  border-radius: 5px;
  color: #ffffff;
  font-size: 0.8rem;
  position: absolute;
  padding: 1px 5px 2px 5px;
  bottom: -2.2em;
  left: -100%;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  border: 1px solid #111111;
  z-index: 99999;
  visibility: hidden;
}

[data-title] {
  position: relative;
}

.container {
  -webkit-box-shadow: 5px 5px 14px -8px #000000;
  box-shadow: 5px 5px 14px -8px #000000;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.active-menu {
  border-radius: 5px;
  border: 1px solid #ccc;
}

.small-info {
  margin-top: -32px;
}

.input-group-prepend {
  min-width: 80%;
}
</style>
