<template>
  <mobule-base-builder :module-data="moduleData" :editMode="editMode" :loading="loading" :error="error" :downloadImage="dataDownload">
    <template v-slot:content>
      <div class="mobule-number-total total-number-of-contacts echarts">
        <p class="total">{{ totalContacts }}</p>
        <p>{{ moduleData.contentMainTitle }}</p>
      </div>
    </template>
  </mobule-base-builder>
</template>

<script>
import MobuleBaseBuilder from '../base/MobuleBaseBuilder.vue'
import MixinModuleBase from "../../../mixins/MixinModuleBase";

import ContactService from '../../../services/ContactService.js'
const contactService = ContactService.build()

export default {
  mixins: [MixinModuleBase],
  name: 'module-total-number-of-contacts',

  components: {
    MobuleBaseBuilder
  },

  data: function () {
    return {
      totalContacts: 0,
      dataDownload: {class: "total-number-of-contacts", title: this.moduleData.headerTitle}
    }
  },

  methods: {
    resetModule() {
      this.totalContacts = 0
    },
    getData() {
      this.resetModule();

      if (this.dataRetrievalOk()) {
        this.loading = true
        contactService.getTotalNumberOfContacts({
          selectedProductsIds: this.selectedProductsIds(),
          dateBegin: this.filterDateRange[0],
          dateEnd: this.filterDateRange[1]
        }).then((r) => {
          this.resetModule();
          this.totalContacts = r.total;
          this.loading = false;

        }).catch(this.errorHandler);
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.mobule-number-total{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
  background-color: #fff;

  p{
    margin: 5px;
    font-weight: bold;
    font-size: 16px;

    &.total{
      font-size: 28px;
    }
  }
}

.echarts {
  width: 100%;
  height: 100%;
}
</style>
