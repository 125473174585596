import Rest from './base/Rest';

/**
 * @typedef {ProductService}
 */
export default class AccessHistoryService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/access-history'


  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  getLoginByPeriod (params = {}) {
    return this.get(`login-by-period?${this.createQueryString(params)}`);
  }

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  async loginSave (ip) {
    return await this.post(`/login/${ip}/web`);
  }


  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  async logoutSave (ip) {
    return await this.post(`/logout/${ip}/web`);
  }
}