<template>
  <mobule-base-builder
    :module-data="moduleData"
    :editMode="editMode"
    :loading="loading"
    :error="error"
    :downloadImage="dataDownload"
    :tableHeaders="tableHeaders"
    :tableItems="accessHistory"
    :tableHeadersExcel="tableHeaders"
    :tableItemsExcel="accessHistory"
    :typePanelPDF="moduleData.displayType.chosen"
  >
    <template v-slot:content>
      <message-status-data
        v-if="accessHistory.length <= 0"
        :selected-products="selectedProductsIds()"
        :infoLoading="loading"
      />
      <div
        v-else-if="moduleData.displayType.chosen == ModuleDisplayType.TABLE"
        class="echarts system-usage"
      >
        <v-data-table
          id="table"
          :headers="tableHeaders"
          :items="accessHistory"
          :items-per-page="5"
          :search="tableSearchQuery"
          :footer-props="{ 'items-per-page-options': [5, 10, 15, 30, 50, 100] }"
        >
          <template v-slot:top>
            <v-row class="m-0">
              <v-col class="m-0" cols="8">
                <v-text-field
                  v-model="tableSearchQuery"
                  label="Pesquise por Usuário, E-mail, IP ou Data"
                ></v-text-field>
              </v-col>

              <v-col class="m-0" cols="4">
                <v-select
                  v-model="tableFromFilter"
                  :items="tableFromValues"
                  item-text="text"
                  item-value="value"
                  label="Filtre por interface"
                  multiple
                  outlined
                >
                  <template #selection="{ item, index }">
                    <v-chip
                      close
                      @click:close="tableFromFilter.splice(index, 1)"
                    >
                      <span>{{ item.text }}</span>
                    </v-chip>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </template>

          <template v-slot:[`item.from`]="{ item }">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  :color="accessHistoryFromStyle[item.from].color"
                  dark
                >
                  <v-icon>{{ accessHistoryFromStyle[item.from].icon }}</v-icon>
                </v-chip>
              </template>

              <span>{{ accessHistoryFromStyle[item.from].text }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.createdAt`]="{ item }">
            {{
              new Date(item.createdAt).toLocaleDateString("pt-br", {
                hour: "2-digit",
                minute: "2-digit",
              })
            }}
          </template>
        </v-data-table>
      </div>
      <v-row
        v-else-if="
          [ModuleDisplayType.PIZZA, ModuleDisplayType.BAR].includes(
            moduleData.displayType.chosen
          )
        "
        class="h-100 m-0 position-relative"
      >
        <v-col
          v-if="moduleData.displayType.chosen == ModuleDisplayType.PIZZA"
          cols="12"
          class="m-0"
        >
          <div class="echarts">
            <module-chart-doughnut
              :dataTitle="`Accessos por ${propGroupData[groupByProp].text}`"
              :legendOptions="legendOption"
              :dataLegend="accessHistoryPropLabels"
              :chartValues="accessHistoryAmountByPropPizza"
              renderer="canvas"
              class="system-usage"
            />
          </div>
        </v-col>
        <v-col
          v-else-if="moduleData.displayType.chosen == ModuleDisplayType.BAR"
          cols="9"
          class="m-0"
        >
          <module-chart-bar
            :dataTitle="`Accessos por ${propGroupData[groupByProp].text}`"
            :data="accessHistoryAmountByPropBar"
            :labels="accessHistoryPropLabels"
            class="system-usage"
          />
        </v-col>

        <v-col
          cols="3"
          class="m-0"
          :class="{
            'group-by-select-absolute':
              moduleData.displayType.chosen == ModuleDisplayType.PIZZA,
          }"
        >
          <v-select
            v-model="groupByProp"
            :items="propGroupValues"
            item-text="text"
            item-value="value"
            label="Agrupar por"
            outlined
          ></v-select>
        </v-col>
      </v-row>
    </template>
  </mobule-base-builder>
</template>

<script>
import MixinModuleBase from "../../../mixins/MixinModuleBase";
import MobuleBaseBuilder from "../base/MobuleBaseBuilder.vue";
import MessageStatusData from "../../MessageStatusData.vue";
import ModuleChartDoughnut from "../base/ModuleChartDoughnut.vue";
import ModuleChartBar from "../base/ModuleChartBar.vue";
import AccessHistoryFromEnum from "../../../utils/enum/accessHistoryFrom.enum";

import AccessHistoryService from "../../../services/AccessHistoryService";
const accessHistoryService = AccessHistoryService.build();

const propGroup = {
  FROM: "FROM",
  SYSTEM_ROLE: "SYSTEM_ROLE",
  ACCOUNT: "Account",
};

const accessHistoryFromStyle = {
  [AccessHistoryFromEnum.WEB]: {
    icon: "mdi-web",
    color: "blue",
    text: "Web",
  },
  [AccessHistoryFromEnum.MOBILE]: {
    icon: "mdi-cellphone",
    color: "green",
    text: "Mobile",
  },
  [null]: {
    icon: "mdi-minus",
    color: "gray",
    text: "Não registrado",
  },
  get [undefined]() {
    return this[null];
  },
};

const propGroupData = {
  [propGroup.FROM]: {
    text: "Interface",
    getValue: (accessHistory) =>
      accessHistoryFromStyle[accessHistory.from].text,
  },
  [propGroup.SYSTEM_ROLE]: {
    text: "Perfil",
    getValue: (accessHistory) => accessHistory.account.systemRole[0].name,
  },
  [propGroup.ACCOUNT]: {
    text: "Usuário",
    getValue: (accessHistory) => accessHistory.account.fullName,
  },
};

export default {
  name: "module-system-usage",
  mixins: [MixinModuleBase],
  components: {
    MobuleBaseBuilder,
    MessageStatusData,
    ModuleChartDoughnut,
    ModuleChartBar,
  },
  data: function () {
    return {
      legendOption: {
        type: "scroll",
        orient: "vertical",
        left: "1rem",
        top: "5%",
        bottom: "5%",
      },
      accessHistoryFromStyle,
      propGroupData,
      propGroupValues: Object.values(propGroup).map((item) => ({
        value: item,
        text: propGroupData[item].text,
      })),
      tableSearchQuery: "",
      tableFromFilter: [],
      tableFromValues: Object.values(AccessHistoryFromEnum)
        .concat(null)
        .map((item) => ({
          value: item,
          text: accessHistoryFromStyle[item].text,
        })),
      groupByProp: propGroup.FROM,
      accessHistory: [],
      accessHistoryPropLabels: [],
      accessHistoryAmountByPropPizza: [],
      accessHistoryAmountByPropBar: [],
      dataDownload: {
        class: "system-usage",
        title: this.moduleData.headerTitle,
      },
    };
  },
  computed: {
    tableHeaders() {
      return [
        { text: "Usuário", value: "account.fullName" },
        { text: "E-mail", value: "account.email" },
        { text: "Endereço de IP", value: "ip" },
        {
          text: "Interface",
          value: "from",
          cellClass: "py-1",
          filter: (value) => {
            return (
              !this.tableFromFilter ||
              this.tableFromFilter.length <= 0 ||
              this.tableFromFilter.includes(value)
            );
          },
        },
        { text: "Data", value: "createdAt" },
      ];
    },
  },
  methods: {
    getPizzaChartSvgG() {
      return document.querySelector(".system-usage svg > g:last-child");
    },
    dataRetrievalOk() {
      return this.filterDateRangeIsNotEmpty();
    },
    resetModule() {
      this.accessHistory = [];
      this.resetGroupedData();
    },
    resetGroupedData() {
      this.accessHistoryPropLabels = [];
      this.accessHistoryAmountByPropPizza = [];
      this.accessHistoryAmountByPropBar = [];
    },
    getData() {
      this.resetModule();

      if (this.dataRetrievalOk()) {
        this.loading = true;

        accessHistoryService
          .getLoginByPeriod({
            dateBegin: this.filterDateRange[0],
            dateEnd: this.filterDateRange[1],
          })
          .then((r) => {
            this.resetModule();
            this.accessHistory = r;
            this.groupDataByProp();
            this.loading = false;
          })
          .catch(this.errorHandler);
      }
    },
    groupDataByProp() {
      this.resetGroupedData();

      let accessHistoryByPropValue = this.accessHistory.reduce((acc, item) => {
        let propValue = propGroupData[this.groupByProp].getValue(item);

        if (!acc[propValue]) acc[propValue] = [];
        acc[propValue].push(item);
        return acc;
      }, {});

      for (let i in accessHistoryByPropValue) {
        this.accessHistoryPropLabels.push(i);

        this.accessHistoryAmountByPropBar.push(
          accessHistoryByPropValue[i].length
        );

        this.accessHistoryAmountByPropPizza.push({
          value: accessHistoryByPropValue[i].length,
          name: i,
        });
      }
    },
  },
  watch: {
    groupByProp() {
      this.groupDataByProp();
    },
  },
};
</script>

<style scoped>
.v-application--wrap {
  overflow: auto;
}

.system-usage {
  background-color: #fff;
}

.group-by-select-absolute {
  position: absolute;
  right: 0;
  top: 5%;
}
</style>
