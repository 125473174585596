<template>
  <mobule-base-builder
    :module-data="moduleData"
    :editMode="editMode"
    :loading="loading"
    :error="error"
    :downloadImage="dataDownload"
    :tableHeaders="tableHeaders"
    :tableItems="tableItems"
    :tableHeadersExcel="tableHeadersExcel"
    :tableItemsExcel="tableItemsExcel"
    :typePanelPDF="moduleData.displayType.chosen"
  >
    <template v-slot:content>
      <module-chart-bar
        v-if="
          moduleData.displayType.chosen == 'CHART_BAR' &&
          barChartData.length > 0
        "
        :data="barChartData"
        :labels="labels"
        class="total-contacts-created-by"
      />
      <div
        class="echarts"
        v-else-if="
          moduleData.displayType.chosen == 'CHART_PIZZA' &&
          contactTypes.length > 0
        "
      >
        <module-chart-doughnut
          dataTitle=""
          :dataLegend="labels"
          :chartValues="contactTypes"
          class="total-contacts-created-by"
        />
      </div>
      <module-table-list-data-v2
        v-else-if="
          moduleData.displayType.chosen == 'TABLE' && tableItems.length > 0
        "
        :tableHeaders="tableHeaders"
        :tableItems="tableItems"
        class="total-contacts-created-by"
      />
      <message-status-data
        v-else
        :selected-products="selectedProductsIds()"
        :infoLoading="loading"
      />
    </template>
  </mobule-base-builder>
</template>

<script>
import ContactService from "../../../services/ContactService";
const contactService = ContactService.build();

import percentageSort from "../../../utils/sorting/percentage.sort";

import MixinModuleBase from "../../../mixins/MixinModuleBase";
import MobuleBaseBuilder from "../base/MobuleBaseBuilder.vue";
import ModuleChartBar from "../base/ModuleChartBar.vue";
import ModuleChartDoughnut from "../base/ModuleChartDoughnut.vue";
import ModuleTableListDataV2 from "../base/ModuleTableListDataV2.vue";
import MessageStatusData from "../../MessageStatusData.vue";

export default {
  name: "module-total-contacts-created-by",
  mixins: [MixinModuleBase],
  components: {
    MobuleBaseBuilder,
    ModuleChartBar,
    ModuleChartDoughnut,
    ModuleTableListDataV2,
    MessageStatusData,
  },
  data: function () {
    return {
      contactTypes: [],
      barChartData: [],
      labels: [],
      tableHeaders: [
        { text: "Criador", align: "start", sortable: true, value: "createdBy" },
        {
          text: "Quantidade",
          align: "start",
          sortable: true,
          value: "contactCount",
        },
        {
          text: "%",
          align: "start",
          sortable: true,
          value: "percentage",
          sort: percentageSort,
        },
      ],
      tableHeadersExcel: [
        {
          text: "Produtos Selecionados",
          align: "start",
          sortable: true,
          value: "selectedProducts",
        },
        { text: "Criador", align: "start", sortable: true, value: "createdBy" },
        {
          text: "Quantidade",
          align: "start",
          sortable: true,
          value: "contactCount",
        },
        {
          text: "%",
          align: "start",
          sortable: true,
          value: "percentage",
          sort: percentageSort,
        },
      ],
      tableItems: [],
      tableItemsExcel: [],
      dataDownload: {
        class: "total-contacts-created-by",
        title: this.moduleData.headerTitle,
      },
    };
  },
  computed: {
    getSelectedProducts() {
      return this.$store.getters.selectedProducts;
    },
  },
  methods: {
    resetModule() {
      this.tableItems = [];
      this.tableItemsExcel = [];
      this.contactTypes = [];
      this.barChartData = [];
      this.labels = [];
    },

    convertToChartValues(contacts) {
      let ret = [];
      if (typeof contacts !== "undefined" && contacts.length > 0) {
        for (let i = 0; i < contacts.length; i++) {
          //Chart Pizza
          ret.push({
            value: contacts[i]["contactCount"],
            name: contacts[i]["createdBy"],
          });

          //Chart Bar
          this.barChartData.push(contacts[i]["contactCount"]);
          this.labels.push(contacts[i]["createdBy"]);
        }
      }
      return ret;
    },

    getData() {
      this.resetModule();

      if (this.dataRetrievalOk()) {
        this.loading = true;

        contactService
          .getTotalContactsCreatedBy({
            selectedProductsIds: this.selectedProductsIds(),
            dateBegin: this.filterDateRange[0],
            dateEnd: this.filterDateRange[1],
          })
          .then((r) => {
            this.resetModule();

            let tot = 0;
            for (let i = 0; i < r.length; i++) {
              tot += parseInt(r[i]["contactCount"]);
            }
            for (let i = 0; i < r.length; i++) {
              r[i]["percentage"] =
                ((r[i]["contactCount"] / tot) * 100).toFixed(2) + " %";
            }

            const productsSelectedLength = this.getSelectedProducts.length;

            if (r.length >= productsSelectedLength) {
              r.forEach((item, index) => {
                if (index < productsSelectedLength) {
                  this.tableItems.push({ ...item });
                  this.tableItemsExcel.push({
                    selectedProducts: this.getSelectedProducts[index]?.name,
                    ...item,
                  });
                } else {
                  this.tableItems.push({ ...item });
                  this.tableItemsExcel.push({
                    selectedProducts: "",
                    ...item,
                  });
                }
              });
            } else {
              this.getSelectedProducts.forEach((product, index) => {
                const item = r[index] ? r[index] : "";
                if (item) {
                  this.tableItems.push({ ...item });
                  this.tableItemsExcel.push({
                    selectedProducts: product?.name,
                    ...item,
                  });
                } else {
                  this.tableItemsExcel.push({
                    selectedProducts: product?.name,
                  });
                }
              });
            }

            this.contactTypes = this.convertToChartValues(r);
            this.loading = false;
          })
          .catch(this.errorHandler);
      }
    },
  },
};
</script>

<style scoped>
.echarts {
  width: 100%;
  height: 100%;
  position: relative;
}

.total-contacts-created-by {
  background-color: #fff;
}
</style>
