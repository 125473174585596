import TelephonyAPI from './TelephonyAPI'

/**
 * @typedef {TelephonyRest}
 */
export default class TelephonyRest extends TelephonyAPI {
  /**
   * @type {String}
   */
  static resource = '';

  /**
   * @param {String} resource
   * @param {Object} options
   * @param {Object} http
   */
  constructor (resource, options = {}, http = null) {
    super(TelephonyRest.normalize(`${TelephonyRest.base}/cdr/${TelephonyRest.token}/${TelephonyRest.key}/`, resource), options, http);
  }

  /**
   * @return {this}
   */
  static build () {
    return new this(this.resource);
  }
}
