<template>
  <form @submit.prevent="loginUserValidate" class="form-login">
    <h3 class="mb-4 mt-1 font-weight-bold text-secondary">
      Bem-vindo ao
      <strong class="destak">Smarts BI</strong>
    </h3>

    <form-input
      label="Email:"
      type="email"
      name="email"
      v-model="values.email"
      :error="errors.email"
      @validate="validate('email')"
      class="input-label mb-1"
    ></form-input>

    <form-input
      label="Senha:"
      type="password"
      name="password"
      v-model="values.password"
      :error="errors.password"
      @validate="validate('password')"
      class="input-label mb-1"
    ></form-input>

    <button class="btn btn-block login-button">Entrar</button>

    <div class="mt-2 d-flex justify-center ">
      <strong class="forgot-password-text">
        <router-link
          :to="{ name: 'RecoverPassword' }"
          class="text-secondary alert-link text-decoration-none"
        >
          <i class="fas fa-key"></i>
          Esqueceu a senha?
        </router-link>
      </strong>
    </div>
  </form>
</template>

<script>
import { object, string } from "yup";

import FormInput from "../login/FormInput.vue";

import LoginService from "../../services/LoginService";
const loginService = LoginService.build();

import UserService from "../../services/UserService";
const userService = UserService.build();

import EnterpriseService from "../../services/EnterpriseService";
const enterpriseService = EnterpriseService.build();

import DefaultMixin from "../../mixins/DefaultMixin";

const loginFormSchema = object().shape({
  email: string()
    .email("Formato de e-mail inválido!")
    .required("E-mail é obrigatório!"),
  password: string().required("Senha é obrigatória!"),
});
export default {
  name: "Login",
  mixins: [DefaultMixin],
  components: {
    "form-input": FormInput,
  },
  data() {
    return {
      values: {
        email: "",
        password: "",
      },
      errors: {
        email: "",
        password: "",
      },
      user: {
        username: "",
        password: "",
      },
    };
  },
  computed: {
    userIsManager() {
      return this.$store.getters.userIsManager;
    },
  },
  methods: {
    fetchUserSystemRole() {
      userService
        .getLoggedUser()
        .then((loggedUser) => {
          this.$store.dispatch("storeAccount", loggedUser);
          this.$store.dispatch("storeUserTemp", loggedUser);
          this.getMultipleEnterprise();
        })
        .catch(this.errorHandler);
    },
    login() {
      const user = {
        username: this.user.username,
        password: this.user.password,
      };

      loginService
        .login(user)
        .then(async (data) => {
          if (data?.access_token) {
            document.cookie = `PLAY_BISESSION=${data.access_token};`;

            await this.saveAccessHistory("login");

            const { dataBase } = this.$jwtDec.decode(data.access_token);
            this.$store.dispatch("storeEnterprise", dataBase);

            this.fetchUserSystemRole();
          }
        })
        .catch(this.errorHandler);
    },
    loginUserValidate() {
      loginFormSchema
        .validate(this.values, { abortEarly: false })
        .then(() => {
          this.user.username = this.values.email;
          this.user.password = this.values.password;
          this.login();
        })
        .catch((err) => {
          err.inner.forEach((error) => {
            this.errors = { ...this.errors, [error.path]: error.message };
          });
        });
    },
    validate(field) {
      loginFormSchema
        .validateAt(field, this.values)
        .then(() => {
          this.errors[field] = "";
        })
        .catch((err) => {
          this.errors[err.path] = err.message;
        });
    },
    getMultipleEnterprise() {
      enterpriseService
        .findByAccountId()
        .then((data) => {
          if (data.length > 1 && !this.userIsManager) {
            this.$store.dispatch("storeMultipleEnterprise", data);
            this.redirect("/multiple-enterprise");
          } else {
            this.redirect();
          }
        })
        .catch(this.errorHandler);
    },
    redirect(path = "/dashboard") {
      this.$router.push({ path: path });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-login {
  background-color: var(--smarts-white);
  color: var(--smarts-black);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 90%;
  margin: 0 auto;

  .destak {
    color: var(--smarts-wine);
    border-bottom: 2px solid var(--smarts-wine);
  }

  .input-label {
    color: var(--smarts-black);
    min-width: 100%;
    font-size: 1rem;
    margin-bottom: 2px !important;
  }

  .login-button {
    background-color: var(--smarts-black);
    color: var(--smarts-white);
    padding: 0.6rem;
    border-radius: 0.8rem;
    font-size: 1rem;
    margin-top: 0.6rem;
    font-weight: bold;
    transition: all 0.4s;

    &:hover {
      background-color: var(--smarts-wine);
    }
  }

  .forgot-password-text {
    font-size: 0.8rem;
  }
}
</style>
