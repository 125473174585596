import Rest from './base/Rest'

/**
 * @typedef {LogWhatsAppService}
 */
export default class LogWhatsAppService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/log-whatsapp'
  /**
 * @param {Object} parameters
 * @returns {*|PromiseLike<T | never>|Promise<T | never>}
 */
  getLatestStatusWhatsAppConnectByDateAndProduct(params = {}) {
    return this.get(`/getLatestStatusWhatsAppConnectByDateAndProduct?${this.createQueryString(params)}`)
  }

}