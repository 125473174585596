import Rest from "./base/Rest";

/**
 * @typedef {RecipientEnterpriseService}
 */
export default class RecipientEnterpriseService extends Rest {
  /**
   * @type {String}
   */
  static resource = "/recipient-enterprise";

  /**
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */

  saveNewRecipientEnterprise(params = {}) {
    return this.post(`/save`, params);
  }

  findByRecipientIdAndEnterpriseId(params = {}) {
    return this.get(
      `/findByRecipientIdAndEnterpriseId?${this.createQueryString(params)}`
    );
  }
}
