<template>
  <div>
    <div class="container bg-white col-md-10 my-6">
      <div class="row row-cols-auto align-items-center p-3">
        <div class="col input-group">
          <legend class="m-0">Gerenciar acessos internos</legend>
        </div>

        <div class="col col-md-7">
          <template>
            <v-autocomplete
              v-model="idSelectedUser"
              :items="users"
              :item-text="(item) => `${item.fullName} - ${item.email}`"
              item-value="id"
              @change="handleUsers()"
              label="Escolha um usuário"
              id="userSelect"
              class="ml-4 mt-6"
              clearable
              outlined
            ></v-autocomplete>
          </template>
        </div>
      </div>
      <div class="col-md-12" v-if="viewListEnterprises">
        <div class="form-group col-md-12">
          <v-card>
            <v-card-title>
              Empresas
              <v-spacer></v-spacer>
              <div class="col col-3 mt-4 d-flex align-items-center">
                <div class="custom-control">
                  <v-switch
                    v-model="selectAll"
                    color="#000000"
                    :label="allSelectTitle"
                    @change="handleSelectAllEnterprises"
                  ></v-switch>
                </div>
              </div>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Pesquisar"
                outlined
                hide-details
              ></v-text-field>
            </v-card-title>

            <v-data-table
              :headers="tableHeaders"
              :items="enterprisesItemTable"
              :footer-props="footerProps"
              ref="dataTable"
              :loading="loading"
              :search="search"
              :items-per-page="10"
            >
              <template v-slot:[`item.active`]="{ item }">
                <v-switch
                  v-model="item.active"
                  @change="addOrRemoveAccess(item)"
                  color="#000000"
                ></v-switch>
              </template>
            </v-data-table>
          </v-card>
        </div>

        <div class="input-group col-12 justify-content-center">
          <button
            class="btn btn-dark btn-lg btn-block col-6"
            @click="updateEnterprisesAccess"
          >
            Atualizar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultMixin from "../../mixins/DefaultMixin";
import ManageMixin from "../../mixins/ManageMixin";

import EnterpriseService from "../../services/EnterpriseService";
import AccountService from "../../services/UserService";
import EnterpriseBIService from "../../services/EnterpriseBIService";
import AccountEnterpriseBIService from "../../services/AccountEnterpriseBIService";

export default {
  name: "ManageAccessBI",
  mixins: [DefaultMixin, ManageMixin],
  data() {
    return {
      enterpriseService: EnterpriseService.build(),
      accountService: AccountService.build(),
      enterpriseBIService: EnterpriseBIService.build(),
      accountEnterpriseBIService: AccountEnterpriseBIService.build(),

      enterprises: [],
      users: [],
      idSelectedUser: null,
      listEnterprisesUpdate: [],
      enterprisesItemTable: [],
      viewListEnterprises: false,
      selectAll: false,
      allSelectTitle: "Marcar Todas",
      footerProps: {
        "items-per-page-options": [5, 10, 15, 30],
        "items-per-page-text": "Empresas por página",
      },
      tableHeaders: [
        { text: "Nome", align: "start", sortable: true, value: "name" },
        { text: "Status", align: "start", sortable: false, value: "active" },
      ],
      search: "",
      successTitle: "",
    };
  },
  methods: {
    resetVariables() {
      this.listEnterprisesUpdate = [];
      this.search = "";
      this.enterprisesItemTable = [];
    },
    async getAllEnterprises() {
      this.enterprises = await this.enterpriseService.findAllEnterprisesIsActiveBI();
    },
    async getAllInternalActiveUsers() {
      this.users = await this.accountService.getAllInternalActiveUsers();
    },
    getUsersWithEnterprises() {
      if (this.idSelectedUser) {
        this.loading = true;
        this.resetVariables();

        const accountId = this.idSelectedUser;
        this.enterpriseService
          .findAllByAccountWithActiveBI(accountId)
          .then((response) => {
            if (response && response.length > 0) {
              this.enterprises.forEach((enterprise) => {
                const hasEnterprise = response.filter(
                  (item) => item.id === enterprise.id
                )[0];
                this.enterprisesItemTable.push({
                  ...enterprise,
                  active: hasEnterprise ? true : false,
                });
              });
            } else {
              if (this.enterprises.length > 0) {
                this.enterprises.map((enterprise) => {
                  this.enterprisesItemTable.push({
                    ...enterprise,
                    active: false,
                  });
                });
              }
            }

            if (this.enterprises.length === response.length) {
              this.selectAll = true;
              this.allSelectTitle = "Desmarcar Todas";
            } else {
              this.selectAll = false;
              this.allSelectTitle = "Marcar Todas";
            }
          })
          .catch(this.errorHandler);

        this.loading = false;
        this.viewListEnterprises = true;
      }
    },
    handleUsers() {
      this.loading = true;
      if (this.idSelectedUser) {
        this.selectedUser = this.users.filter(
          (user) => user.id === this.idSelectedUser
        )[0];
        this.getUsersWithEnterprises(this.selectedUser);
      }
    },
    async updateEnterprisesAccess() {
      if (this.listEnterprisesUpdate.length > 0) {
        const result = await this.confirmationDialog({
          title: "Você tem certeza?",
          text: `Ao confirmar, os acessos as empresas do usuário: ${this.selectedUser?.fullName} serão atualizados!`,
          footer: `*** Ao remover empresa(s) o usuário: ${this.selectedUser?.fullName} não poderá mais acessar o BI das empresas removidas!***`,
        });

        if (result.isConfirmed) {
          try {
            await this.accountEnterpriseBIService.saveAccountEnterpriseBi(
              this.listEnterprisesUpdate
            );
            this.alertSuccess({
              title: `Os acessos a(s) empresa(s) do usuário: ${this.selectedUser?.fullName} foram atualizados com sucesso!`,
            });
          } catch (err) {
            this.alertError({
              title: "Não foi possível atualizar as empresas que o usuário terá acesso!",
              text: "Tente novamente, se o erro persistir contate o administrador!",
            });
          }
        }
      } else {
        this.alertWarning({
          title: `Não foi identificada nenhuma mudança de empresas para o usuário ${this.selectedUser?.fullName}`,
        });
      }

      this.listEnterprisesUpdate = [];
      this.getUsersWithEnterprises(this.idSelectedUser);
    },
    async addOrRemoveAccess(enterprise) {
      const result = this.listEnterprisesUpdate.filter(
        (item) => item.enterpriseBiId === enterprise.enterpriseBI.id
      );

      if (result.length > 0) {
        const index = this.listEnterprisesUpdate.indexOf(result[0]);
        this.listEnterprisesUpdate.splice(index, 1);

        this.listEnterprisesUpdate.push({
          accountId: this.idSelectedUser,
          enterpriseBiId: enterprise.enterpriseBI.id,
          active: enterprise.active,
          createdBy: this.getUserIdLogin,
        });
      } else {
        this.listEnterprisesUpdate.push({
          accountId: this.idSelectedUser,
          enterpriseBiId: enterprise.enterpriseBI.id,
          active: enterprise.active,
          createdBy: this.getUserIdLogin,
        });
      }
    },
    handleSelectAllEnterprises() {
      for (let enterprise of this.enterprisesItemTable) {
        if (this.selectAll) {
          enterprise.active = true;
          this.addOrRemoveAccess(enterprise);
        } else {
          enterprise.active = false;
          this.addOrRemoveAccess(enterprise);
        }
      }
      this.allSelectTitle = this.selectAll ? "Desmarcar Todas" : "Marcar Todas";
    },
  },
  computed: {
    getUserIdLogin() {
      return Number(this.$store.getters.userId);
    },
    getUserSystemRole() {
      return this.$store.getters.userIsManager;
    },
    getTempUser() {
      return this.$store.getters.userTemp;
    },
    getFixedLoginDatas() {
      return this.$store.getters.fixedLoginDatas;
    },
  },
  async mounted() {
    await this.getAllEnterprises();
    await this.getAllInternalActiveUsers();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.getters.userIsManager) {
        next();
      } else {
        next({ path: "/dashboard" });
      }
    });
  },
  watch: {
    idSelectedUser() {
      if (!this.idSelectedUser) {
        this.viewListEnterprises = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  background-color: #23272b;
  border-radius: 5px;
  color: #ffffff;
  font-size: 0.8rem;
  position: absolute;
  padding: 1px 5px 2px 5px;
  bottom: -2.2em;
  left: -100%;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  border: 1px solid #111111;
  z-index: 99999;
  visibility: hidden;
}

[data-title] {
  position: relative;
}

.container {
  -webkit-box-shadow: 5px 5px 14px -8px #000000;
  box-shadow: 5px 5px 14px -8px #000000;
  border-radius: 5px;
  border: 1px solid #ccc;
  border-radius: 2rem !important;
}

.active-menu {
  border-radius: 5px;
  border: 1px solid #ccc;
}

.small-info {
  margin-top: -32px;
}

.input-group-prepend {
  min-width: 80%;
}
</style>
