<template>
  <div :class="['form-group', !!error && 'has-error']">
    <label class="form-label font-weight-bold" :for="name">{{ label }}</label>
    <input
      :id="name"
      :name="name"
      :type="type"
      :value="value"
      :placeholder="placeholder"
      class="form-input"
      @input="$emit('input', $event.target.value)"
      @blur="$emit('validate')"
      @keypress="$emit('validate')"
    />
    <p class="form-input-hint" v-if="!!error">{{ error }}</p>
  </div>
</template>

<script>
export default {
  name: "form-input",
  emits: ["input", "validate"],
  props: {
    type: { required: true },
    label: { required: true },
    name: { required: true },
    value: { required: true },
    error: { required: true },
    placeholder: { type: String, default: ''}
  },
};
</script>

<style scoped>
input {
  min-width: 100%;
  outline: transparent;
  border-bottom: 2px solid var(--smarts-gray-dark) !important;
  margin-bottom: 0.5rem;
  height: 2.4rem;
}

.form-input {
  color: var(--smarts-wine);
  font-weight: bold;
}
.form-input-hint {
  color: var(--smarts-wine);
  font-size: 0.8rem;
  font-weight: bold;
}
</style>
