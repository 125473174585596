<template>
  <mobule-base-builder
    :module-data="moduleData"
    :editMode="editMode"
    :loading="loading"
    :error="error"
    :downloadImage="dataDownload"
    :tableHeaders="tableHeaders"
    :tableItems="tableItems"
    :tableHeadersExcel="tableHeadersExcel"
    :typePanelPDF="'TABLE'"
  >
    <template v-slot:content>
      <div class="pa-3">
        <span v-if="!hasFilterDateRange"
          >***É necessário preencher os campos de "Período dos dados" para
          buscar as informações!***</span
        >
        <v-autocomplete
          v-model="selectedProduct"
          :items="products"
          item-text="name"
          item-value="id"
          @change="getData()"
          id="visits-by-superintendent-and-sales-company"
          label="Selecione o produto"
          class="px-4 pt-2"
          :disabled="!hasFilterDateRange"
          outlined
          dense
        ></v-autocomplete>
      </div>
      <module-table-list-data-v-2
        :tableHeaders="tableHeaders"
        :tableItems="tableItems"
        class="visits-by-superintendent-and-sales-company"
        v-if="tableItems.length > 0"
      />
      <message-status-data
        v-else
        :selected-products="selectedProductsIds()"
        :infoLoading="loading"
      />
    </template>
  </mobule-base-builder>
</template>

<script>
import MixinModuleBase from "../../../mixins/MixinModuleBase";
import MobuleBaseBuilder from "../base/MobuleBaseBuilder.vue";
import ModuleTableListDataV2 from "../base/ModuleTableListDataV2.vue";
import MessageStatusData from "../../MessageStatusData.vue";

import ContactService from "../../../services/ContactService";
const contactService = ContactService.build();

import ProductService from "../../../services/ProductService";
const productService = ProductService.build();

export default {
  name: "module-visits-by-superintendents-and-sales-company",
  mixins: [MixinModuleBase],
  components: {
    MobuleBaseBuilder,
    ModuleTableListDataV2,
    MessageStatusData,
  },
  data: function () {
    return {
      tableHeaders: [],
      tableHeadersExcel: [
        {
          text: "Produto Selecionado",
          align: "start",
          sortable: true,
          value: "selectedProducts",
        },
      ],
      tableItems: [],
      dataDownload: { class: "cities", title: this.moduleData.headerTitle },
      selectedProduct: [],
      products: [],
    };
  },
  computed: {
    getSelectedProducts() {
      return this.$store.getters.selectedProducts;
    },
    getViewProducts() {
      return this.$store.getters.viewProducts;
    },
    hasFilterDateRange() {
      return (
        this.filterDateRange &&
        this.filterDateRange.length == 2 &&
        this.filterDateRange[0].length > 1 &&
        this.filterDateRange[1].length > 1
      );
    },
  },
  methods: {
    resetModule() {
      this.tableItems = [];
      this.tableHeaders = [];
    },
    getData() {
      this.resetModule();

      if (
        this.hasFilterDateRange &&
        this.selectedProduct &&
        this.selectedProduct !== "" &&
        this.selectedProduct > 0 &&
        (this.whichFunctionRequest === "" ||
          this.whichFunctionRequest === "filterDateRange")
      ) {
        this.loading = true;

        contactService
          .getVisitsBySuperintendentsAndSalesCompany({
            selectedProductsIds: [this.selectedProduct],
            dateBegin: this.filterDateRange[0],
            dateEnd: this.filterDateRange[1],
          })
          .then((r) => {
            this.resetModule();

            if (r.length > 0) {
              this.tableHeaders.push({
                text: "Superintendente",
                align: "start",
                sortable: true,
                value: "superintendent",
              });
              let defaultDynamicObjectItems = {};
              defaultDynamicObjectItems["superintendent"] = "";

              r.forEach((item) => {
                const hasColumnSalesCompany = this.tableHeaders.filter(
                  (column) => column.text === item.salescompanyName
                );
                if (hasColumnSalesCompany.length === 0) {
                  this.tableHeaders.push({
                    text: item.salescompanyName,
                    align: "start",
                    sortable: true,
                    value: item.salescompanyName,
                  });
                  defaultDynamicObjectItems[item.salescompanyName] = "";
                }
              });
              this.tableHeaders.push({
                text: "Total Geral",
                align: "start",
                sortable: true,
                value: "totalGeneral",
              });
              defaultDynamicObjectItems["totalGeneral"] = 0;

              let obj = JSON.parse(JSON.stringify(defaultDynamicObjectItems));
              let objTotalGeneral = JSON.parse(
                JSON.stringify(defaultDynamicObjectItems)
              );
              this.tableHeadersExcel = [
                ...this.tableHeadersExcel,
                ...this.tableHeaders,
              ];

              r.forEach((item, index) => {
                let hasSuperintendent = this.tableItems.filter(
                  (object) =>
                    object["superintendent"].toLowerCase() ===
                    item.superintendentName.toLowerCase()
                );

                if (hasSuperintendent.length > 0) {
                  let indexMain = this.tableItems.indexOf(hasSuperintendent[0]);

                  this.tableItems[indexMain][item.salescompanyName] =
                    item.total;
                  this.tableItems[indexMain]["totalGeneral"] += Number(
                    item.total
                  );

                  this.tableItems[indexMain + 1][item.salescompanyName] =
                    item.contactsFirstVisit;
                  this.tableItems[indexMain + 1]["totalGeneral"] += Number(
                    item.contactsFirstVisit
                  );

                  this.tableItems[indexMain + 2][item.salescompanyName] =
                    item.contactsIndication;
                  this.tableItems[indexMain + 2]["totalGeneral"] += Number(
                    item.contactsIndication
                  );

                  this.tableItems[indexMain + 3][item.salescompanyName] =
                    item.contactsSpontaneous;
                  this.tableItems[indexMain + 3]["totalGeneral"] += Number(
                    item.contactsSpontaneous
                  );

                  this.tableItems[indexMain + 4][item.salescompanyName] =
                    item.contactsReturn;
                  this.tableItems[indexMain + 4]["totalGeneral"] += Number(
                    item.contactsReturn
                  );

                  this.tableItems[indexMain + 5][item.salescompanyName] =
                    item.contactsTelephone;
                  this.tableItems[indexMain + 5]["totalGeneral"] += Number(
                    item.contactsTelephone
                  );

                  this.tableItems[indexMain + 6][item.salescompanyName] =
                    item.contactsOutOfService;
                  this.tableItems[indexMain + 6]["totalGeneral"] += Number(
                    item.contactsOutOfService
                  );
                } else {
                  defaultDynamicObjectItems["superintendent"] =
                    item.superintendentName;
                  defaultDynamicObjectItems[item.salescompanyName] = item.total;
                  defaultDynamicObjectItems["totalGeneral"] += Number(
                    item.total
                  );

                  //Insere produto selecionado na cabeçalho do Excel
                  if (index === 0) {
                    defaultDynamicObjectItems["selectedProducts"] =
                      this.products.filter(
                        (prod) => prod.id === this.selectedProduct
                      )[0].name;
                  }

                  this.tableItems.push(
                    JSON.parse(JSON.stringify(defaultDynamicObjectItems))
                  );
                  defaultDynamicObjectItems["selectedProducts"] = "";
                  defaultDynamicObjectItems["totalGeneral"] = 0;

                  defaultDynamicObjectItems["superintendent"] = " - 1º Visita";
                  defaultDynamicObjectItems[item.salescompanyName] =
                    item.contactsFirstVisit;
                  defaultDynamicObjectItems["totalGeneral"] += Number(
                    item.contactsFirstVisit
                  );
                  this.tableItems.push(
                    JSON.parse(JSON.stringify(defaultDynamicObjectItems))
                  );
                  defaultDynamicObjectItems["totalGeneral"] = 0;

                  defaultDynamicObjectItems["superintendent"] = " - Indicação";
                  defaultDynamicObjectItems[item.salescompanyName] =
                    item.contactsIndication;
                  defaultDynamicObjectItems["totalGeneral"] += Number(
                    item.contactsIndication
                  );
                  this.tableItems.push(
                    JSON.parse(JSON.stringify(defaultDynamicObjectItems))
                  );
                  defaultDynamicObjectItems["totalGeneral"] = 0;

                  defaultDynamicObjectItems["superintendent"] = " - Espontâneo";
                  defaultDynamicObjectItems[item.salescompanyName] =
                    item.contactsSpontaneous;
                  defaultDynamicObjectItems["totalGeneral"] += Number(
                    item.contactsSpontaneous
                  );
                  this.tableItems.push(
                    JSON.parse(JSON.stringify(defaultDynamicObjectItems))
                  );
                  defaultDynamicObjectItems["totalGeneral"] = 0;

                  defaultDynamicObjectItems["superintendent"] = " - Retorno";
                  defaultDynamicObjectItems[item.salescompanyName] =
                    item.contactsReturn;
                  defaultDynamicObjectItems["totalGeneral"] += Number(
                    item.contactsReturn
                  );
                  this.tableItems.push(
                    JSON.parse(JSON.stringify(defaultDynamicObjectItems))
                  );
                  defaultDynamicObjectItems["totalGeneral"] = 0;

                  defaultDynamicObjectItems["superintendent"] = " - Telefone";
                  defaultDynamicObjectItems[item.salescompanyName] =
                    item.contactsTelephone;
                  defaultDynamicObjectItems["totalGeneral"] += Number(
                    item.contactsTelephone
                  );
                  this.tableItems.push(
                    JSON.parse(JSON.stringify(defaultDynamicObjectItems))
                  );
                  defaultDynamicObjectItems["totalGeneral"] = 0;

                  defaultDynamicObjectItems["superintendent"] =
                    " - Fora do Expediente";
                  defaultDynamicObjectItems[item.salescompanyName] =
                    item.contactsOutOfService;
                  defaultDynamicObjectItems["totalGeneral"] += Number(
                    item.contactsOutOfService
                  );
                  this.tableItems.push(
                    JSON.parse(JSON.stringify(defaultDynamicObjectItems))
                  );
                  defaultDynamicObjectItems["totalGeneral"] = 0;
                }

                defaultDynamicObjectItems = JSON.parse(JSON.stringify(obj));
                objTotalGeneral[item.salescompanyName] =
                  typeof objTotalGeneral[item.salescompanyName] === "string"
                    ? 0 + Number(item.total)
                    : Number(objTotalGeneral[item.salescompanyName]) +
                      Number(item.total);
              });
              objTotalGeneral["superintendent"] = "Total Geral";
              this.tableItems.push(objTotalGeneral);
            }
            this.loading = false;
          })
          .catch(this.errorHandler);
      }
    },
    getProducts() {
      productService
        .getAvailableProductsWithTotalContacts(
          this.filterDateRange && this.filterDateRange.length == 2
            ? {
                dateBegin: this.filterDateRange[0],
                dateEnd: this.filterDateRange[1],
                choosen: this.getViewProducts,
              }
            : {}
        )
        .then((result) => {
          this.products = result;
        })
        .catch(this.errorHandler);
    },
  },
  mounted() {
    this.getProducts();
  },
  watch: {
    filterDateRange() {
      if (!this.hasFilterDateRange && this.selectedProduct) {
        this.resetModule();
      }
    },
    getViewProducts() {
      this.getProducts();
    },
  },
};
</script>

<style scoped>
.visits-by-superintendent-and-sales-company {
  background-color: #fff;
}

.w-100 {
  z-index: 999;
}

span {
  color: red;
  width: 15px;
}
</style>
