<template>
  <div>
    <div class="container bg-white col-md-10 my-6">
      <div class="form-group col-12 row">
        <div class="col col-12 d-flex align-items-center flex-direction-row">
          <legend class="m-0">Gerenciar envio de relatórios</legend>
        </div>

        <div
          class="col col-12 d-flex align-items-center justify-content-center flex-direction-row p-4"
        >
          <div class="col-4">
            <v-autocomplete
              v-model="idSelectedEnterprise"
              :items="enterprises"
              item-text="name"
              item-value="id"
              @change="getProductsByEnterpriseId()"
              label="Escolha uma empresa"
              id="selectEnterprise"
              class="ml-4 mt-6"
              clearable
              outlined
            ></v-autocomplete>
          </div>

          <div class="col-4">
            <v-autocomplete
              v-model="idSelectedReport"
              :items="reports"
              item-text="title"
              item-value="id"
              @change="changeReports()"
              label="Escolha um relatório"
              class="ml-4 mt-6"
              clearable
              outlined
              :disabled="!selectedEnterprise"
            ></v-autocomplete>
          </div>

          <div class="col-4">
            <v-autocomplete
              v-model="idSelectedProduct"
              :items="products"
              item-text="name"
              item-value="id"
              @change="getRecipients()"
              label="Escolha um produto"
              class="ml-4 mt-6"
              clearable
              outlined
              :disabled="
                !selectedEnterprise || !idSelectedReport || idSelectedReport === 40
              "
            ></v-autocomplete>
          </div>
        </div>

        <div class="col-md-12" v-if="viewList">
          <div class="form-group col-md-12">
            <v-card>
              <v-card-title>
                Destinatários
                <div>
                  <v-autocomplete
                    v-model="selectedSendingType"
                    :items="sendingTypes"
                    item-text="text"
                    item-value="value"
                    label="Escolha um tipo de envio"
                    class="ml-4 mt-8"
                    clearable
                    outlined
                  ></v-autocomplete>
                </div>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Pesquisar"
                  hide-details
                  outlined
                ></v-text-field>
              </v-card-title>

              <v-data-table
                v-model="selectedMailingFormats"
                :headers="headers"
                :items="recipients"
                :search="search"
                ref="dataTable"
                class="elevation-1"
              >
                <template v-slot:[`item.activeSwitch`]="{ item }">
                  <v-switch
                    v-model="item.activeSwitch"
                    @change="activeOrInactiveSwitch(item)"
                    color="#000000"
                    class="mt-5"
                  ></v-switch>
                </template>

                <template v-slot:[`item.editSendingType`]="{ item }">
                  <i
                    @click="handleEditSendingType(item)"
                    class="fas fa-edit fa-lg ml-8"
                    :style="{ cursor: 'pointer' }"
                  ></i>
                </template>
              </v-data-table>
            </v-card>
          </div>

          <div class="input-group col-12 justify-content-center">
            <button
              class="btn btn-dark btn-lg btn-block col-6"
              @click="updateRecipientsAndMailingFormats"
            >
              Atualizar
            </button>
          </div>
        </div>
      </div>
    </div>

    <v-col cols="auto">
      <v-dialog max-width="600" v-model="values.dialogEditSendingTypeVisible">
        <v-card>
          <v-toolbar color="#000e17" dark elevation="0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon color="grey light"> fas fa-info-circle </v-icon>
                </v-btn>
              </template>

              <span>
                Após essa ação o tipo de envio do destinatário será atualizado!
              </span>
            </v-tooltip>
            Escolha o tipo de envio para o destinatário!
          </v-toolbar>
          <v-card-text>
            <v-autocomplete
              v-model="values.selectEditSendingType"
              :items="sendingTypes"
              item-text="text"
              item-value="value"
              label="Escolha o tipo de envio"
              class="ml-4 mt-12"
              clearable
              dense
              filled
              solo
            ></v-autocomplete>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              depressed
              color="error"
              @click="values.dialogEditSendingTypeVisible = false"
              >Cancelar</v-btn
            >
            <v-btn depressed color="primary" @click="saveEditSendingType">Editar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </div>
</template>

<script>
import DefaultMixin from "../../mixins/DefaultMixin";
import ManageMixin from "../../mixins/ManageMixin";

import EnterpriseService from "../../services/EnterpriseService";
import ProductService from "../../services/ProductService";
import LoginService from "../../services/LoginService";
import MailingFormatService from "../../services/MailingFormatService";
import SendReportService from "../../services/SendReportService";
import PanelService from "../../services/PanelService";
import RecipientService from "../../services/RecipientService";

export default {
  name: "ManageSendReports",
  mixins: [DefaultMixin, ManageMixin],
  data() {
    return {
      enterpriseService: EnterpriseService.build(),
      productService: ProductService.build(),
      loginService: LoginService.build(),
      mailingFormatService: MailingFormatService.build(),
      sendReportService: SendReportService.build(),
      panelService: PanelService.build(),
      recipientService: RecipientService.build(),

      enterprises: [],
      products: [],
      recipients: [],
      reports: [],

      listToUpdate: [],

      idSelectedEnterprise: null,
      idSelectedProduct: null,
      idSelectedReport: null,

      footerProps: {
        "items-per-page-options": [5, 10, 15, 30],
        "items-per-page-text": "Empresas por página",
      },
      search: "",
      selectedEnterprise: null,
      viewList: false,

      //table
      selectedMailingFormats: [],
      headers: [
        { text: "Nome", align: "start", value: "name" },
        { text: "E-mail", align: "start", value: "email" },
        {
          text: "Tipo de envio",
          align: "start",
          value: "recipientEnterprise.mailingFormats.sendingType",
        },
        {
          text: "Editar Tipo de envio",
          align: "start",
          sortable: false,
          value: "editSendingType",
        },
        { text: "Status", align: "start", value: "activeSwitch" },
      ],
      sendingTypes: [
        { text: "Para", value: "TO" },
        { text: "Com cópia", value: "CC" },
        { text: "Com cópia oculta", value: "BCC" },
      ],
      selectedSendingType: null,

      values: {
        dialogEditSendingTypeVisible: false,
        selectEditSendingType: null,
        idMailingFormat: null,
      },
    };
  },
  computed: {
    getUserId() {
      return this.$store.getters.userId;
    },
    setSwitchRecipient(hasMailingFormat) {
      return hasMailingFormat ? true : false;
    },
  },
  methods: {
    resetVariables() {
      this.search = "";
      this.selectedMailingFormats = [];
      this.listToUpdate = [];
      this.viewList = false;
      this.selectedSendingType = null;
    },
    resetAllVariables() {
      this.products = [];
      this.recipients = [];
      this.idSelectedProduct = null;
      this.idSelectedReport = null;
      this.resetVariables();
    },
    resetVariablesWhenChangeProduct() {
      this.idSelectedProduct = null;
      this.resetVariables();
    },
    resetVariablesWhenChangeReports() {
      this.resetVariables();
    },
    async getAllEnterprises() {
      this.enterprises = await this.enterpriseService.findAllByAccountWithActiveBI(
        this.getUserId
      );
    },
    async getProductsByEnterpriseId() {
      this.resetAllVariables();
      const enterprise = this.enterprises.find(
        (enterprise) => enterprise.id === this.idSelectedEnterprise
      );

      this.selectedEnterprise = enterprise;

      if (enterprise && Object.entries(enterprise).length > 0) {
        const credencials = {
          dataBase: enterprise.dataBase,
        };

        this.loginService
          .generateJWTByDatabase(credencials)
          .then(async (data) => {
            this.$store.dispatch("storeJwtTempSendReports", data.access_token);
            if (data && data.access_token) {
              this.productService
                .getAllActiveProducts()
                .then((response) => {
                  this.products = response;
                })
                .catch(this.errorHandler);
            }
          })
          .catch(this.errorHandler);
      }

      this.getReports();
    },
    changeReports() {
      this.resetVariablesWhenChangeProduct();
      if (this.idSelectedReport === 40) {
        this.getRecipients();
      }
    },
    getReports() {
      this.resetVariablesWhenChangeReports();
      this.panelService
        .findAllToSendToEmail()
        .then((panels) => {
          this.reports = panels;
        })
        .catch(this.errorHandler);
    },
    getRecipients() {
      this.resetVariablesWhenChangeReports();
      //this.resetVariablesWhenChangeProduct();
      this.recipientService
        .findRecipientsWithMailingFormatByEnterpriseId({
          enterpriseId: this.idSelectedEnterprise,
          productId: this.idSelectedProduct,
          panelId: this.idSelectedReport,
        })
        .then((recipients) => {
          this.recipients = recipients.map((recipient) => {
            recipient.activeSwitch =
              recipient.recipientEnterprise.mailingFormats &&
              !recipient.recipientEnterprise.mailingFormats.deleted
                ? true
                : false;
            return recipient;
          });
          this.viewList = true;
        })
        .catch(this.errorHandler);
    },
    activeOrInactiveSwitch(recipient) {
      const foundRecipientInList = this.listToUpdate.filter((r) => r.id === recipient.id);
      if (foundRecipientInList.length > 0) {
        this.listToUpdate = this.listToUpdate.filter((r) => r.id !== recipient.id);
      } else {
        this.listToUpdate.push(recipient);
      }
    },
    updateRecipientsAndMailingFormats() {
      const noError = this.validationBeforeUpdate();
      if (noError) {
        this.confirmationDialog({
          text:
            "Ao confirmar, o(s) destinatário(s) ativado(s) receberá/receberão o relatório da incorporadora e stands devidamente selecionados. Caso esteja desativando, o(s) destinatário(s) NÃO receberá/receberão o relatório da incorporadora e stands devidamente selecionados.",
        }).then((result) => {
          if (result.isConfirmed) {
            const definitiveListToUpdate = this.listToUpdate.map((recipient) => {
              if (recipient.recipientEnterprise?.mailingFormats) {
                recipient.recipientEnterprise.mailingFormats.updatedBy = this.getUserId;
                recipient.recipientEnterprise.mailingFormats.deleted = !recipient.activeSwitch;
                recipient.recipientEnterprise.mailingFormats.deletedBy = this.getUserId;
                if (recipient.activeSwitch) {
                  recipient.recipientEnterprise.mailingFormats.deletedAt = null;
                  recipient.recipientEnterprise.mailingFormats.deletedBy = 0;
                  recipient.recipientEnterprise.mailingFormats.sendingType = this.selectedSendingType;
                }
                return recipient.recipientEnterprise.mailingFormats;
              } else {
                return {
                  panelId: this.idSelectedReport,
                  recipientEnterpriseId: recipient.recipientEnterprise.id,
                  sendingType: this.selectedSendingType,
                  productId: this.idSelectedProduct ? this.idSelectedProduct : 0,
                  createdBy: this.getUserId,
                };
              }
            });
            console.log(definitiveListToUpdate);
            this.saveMailingFormat(definitiveListToUpdate);
          }
        });
      }
    },
    saveMailingFormat(listToUpdate) {
      const listToDelete = listToUpdate.filter((recipient) => recipient.deleted);
      if (listToDelete.length > 0) this.mailingFormatService.softDelete(listToDelete);

      this.mailingFormatService
        .save(listToUpdate)
        .then(() => {
          this.alertSuccess({ text: "Destinatário(s) atualizado(s) com sucesso!" });
          this.getRecipients();
        })
        .catch(this.errorHandler);
    },
    validationBeforeUpdate() {
      const hasNewMailingFormat = this.listToUpdate.filter((r) => r.activeSwitch);
      if (this.listToUpdate.length === 0) {
        this.alertWarning({
          text:
            "Não houve nenhuma mudança! Ative ou desative algum destinatário no campo status.",
        });
        return false;
      } else if (!this.selectedSendingType && hasNewMailingFormat.length > 0) {
        this.alertWarning({
          text: "Selecione um tipo de envio!",
        });
        return false;
      }
      return true;
    },
    handleEditSendingType(recipient) {
      if (recipient.recipientEnterprise.mailingFormats && recipient.activeSwitch) {
        this.values.recipient = recipient;
        this.values.idMailingFormat = recipient.recipientEnterprise.mailingFormats.id;
        this.values.selectEditSendingType =
          recipient.recipientEnterprise.mailingFormats.sendingType;
        this.values.dialogEditSendingTypeVisible = true;
      } else {
        this.alertWarning({
          text:
            "Não é possível editar destinatário! Ative o destinatário para editar o tipo de envio.",
        });
      }
    },
    saveEditSendingType() {
      if (
        this.values.selectEditSendingType !==
        this.values.recipient.recipientEnterprise.mailingFormats.sendingType
      ) {
        this.values.recipient.recipientEnterprise.mailingFormats.sendingType = this.values.selectEditSendingType;
        this.values.recipient.recipientEnterprise.mailingFormats.updatedBy = this.getUserId;
        this.confirmationDialog({
          text: `Ao confirmar, o tipo de envio do destinatário ${this.values.recipient.email} será atualizado.`,
        }).then((result) => {
          if (result.isConfirmed) {
            this.saveMailingFormat([
              this.values.recipient.recipientEnterprise.mailingFormats,
            ]);
            this.values.dialogEditSendingTypeVisible = false;
          }
        });
      } else {
        this.alertWarning({
          text:
            "Tipo de envio já está cadastratado no destinatário! Escolha um novo tipo de envio.",
        });
      }
    },
  },
  async mounted() {
    await this.getAllEnterprises();
  },
};
</script>

<style lang="scss" scoped>
.form-recipient {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .container-inputs {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 1px 5px 0 rgba(0, 0, 0, 0.12);
    padding: 2rem 0.5rem;
  }

  i {
    align-self: flex-start;
    font-size: 1.4rem;
    cursor: pointer;
    margin-left: -6rem;
    margin-top: 2rem;
  }
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  background-color: #23272b;
  border-radius: 5px;
  color: #ffffff;
  font-size: 0.8rem;
  position: absolute;
  padding: 1px 5px 2px 5px;
  bottom: -2.2em;
  left: -100%;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  border: 1px solid #111111;
  z-index: 99999;
  visibility: hidden;
}

[data-title] {
  position: relative;
}

.container {
  -webkit-box-shadow: 5px 5px 14px -8px #000000;
  box-shadow: 5px 5px 14px -8px #000000;
  border-radius: 2rem;
  border: 1px solid #ccc;
}

.active-menu {
  border-radius: 5px;
  border: 1px solid #ccc;
}

.small-info {
  margin-top: -32px;
}

.v-icon {
  cursor: pointer !important;
}
</style>
