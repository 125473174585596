import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import VueApexCharts from 'vue-apexcharts'
import VueJwtDecode from 'vue-jwt-decode'
import VueSweetalert2 from 'vue-sweetalert2'
import CryptoService from './services/CryptoService'
import VueExcelXlsx from "vue-excel-xlsx";
import GmapVue from 'gmap-vue';
import { components } from 'gmap-vue';
const cryptoService = new CryptoService();

import 'sweetalert2/dist/sweetalert2.min.css';

store.subscribe((mutation, state) => {
  // Store the state object as a JSON string
  let store = {
    userId: state.userId,
    enterprise: state.enterprise,
    enterpriseName: state.enterpriseName,
    enterpriseLogo: state.enterpriseLogo,
    currentDashboard: state.currentDashboard,
    selectedProducts: state.selectedProducts,
    viewProducts: state.viewProducts,
    filterDateRange: state.filterDateRange,
    isDownloadMap: state.isDownloadMap
  };
  const hashStore = cryptoService.generateCrypto(JSON.stringify(store));
  localStorage.setItem('store', hashStore);
});

const options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
};

Vue.use(VueApexCharts)
Vue.use(VueJwtDecode)
Vue.use(VueSweetalert2, options);
Vue.use(VueExcelXlsx);
Vue.component('apexchart', VueApexCharts)
Vue.component('gmap-cluster', components.Cluster);

Vue.use(GmapVue, {
  load: {
    // [REQUIRED] This is the unique required value by Google Maps API
    key: process.env.VUE_APP_APIKEY_GOOGLE_MAPS,
    // [OPTIONAL] This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
    libraries: 'visualization',

    // [OPTIONAL] If you want to set the version, you can do so:
    v: '3.5.4',

    // This option was added on v3.0.0 but will be removed in the next major release.
    // If you already have an script tag that loads Google Maps API and you want to use it set you callback
    // here and our callback `GoogleMapsCallback` will execute your custom callback at the end; it must attached
    // to the `window` object, is the only requirement.
    customCallback: 'MyCustomCallback',
  },

  // [OPTIONAL] If you intend to programmatically custom event listener code
  // (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  // instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  // you might need to turn this on.
  autoBindAllEvents: false,

  // [OPTIONAL] If you want to manually install components, e.g.
  // import {GmapMarker} from 'gmap-vue/src/components/marker'
  // Vue.component('GmapMarker', GmapMarker)
  // then set installComponents to 'false'.
  // If you want to automatically install all the components this property must be set to 'true':
  installComponents: true,

  // Load the Google Maps API dynamically, if you set this to `true` the plugin doesn't load the Google Maps API
  dynamicLoad: false,
})


import 'leaflet/dist/leaflet.css'

import vuetify from '@/plugins/vuetify'

Vue.config.productionTip = false

new Vue({
  vuetify,
  store,
  beforeCreate() { this.$store.commit('initialiseStore'); },
  router,
  render: h => h(App)
}).$mount('#app')
