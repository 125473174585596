import IpifyAPI from './IpifyAPI'

/**
 * @typedef {IpifyRest}
 */
export default class IpifyRest extends IpifyAPI {
  /**
   * @type {String}
   */
  static resource = '';

  /**
   * @param {String} resource
   * @param {Object} options
   * @param {Object} http
   */
  constructor (resource, options = {}, http = null) {
    super(IpifyRest.normalize(IpifyRest.base, resource), options, http);
  }

  /**
   * @return {this}
   */
  static build () {
    return new this(this.resource);
  }
}
