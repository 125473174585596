const MODULE_TOTAL_NUMBER_OF_CONTACTS = 'MODULE_TOTAL_NUMBER_OF_CONTACTS';
const MODULE_TOTAL_CONTACTS_CREATED_BY = 'MODULE_TOTAL_CONTACTS_CREATED_BY';
const MODULE_CONTACT_TYPES = 'MODULE_CONTACT_TYPES';
const MODULE_TOTAL_CUSTOMERS_LGPD = 'MODULE_TOTAL_CUSTOMERS_LGPD';
const MODULE_CONTACTS_EVS = 'MODULE_CONTACTS_EVS';
const MODULE_TEAM_CONTACTS = 'MODULE_TEAM_CONTACTS';
const MODULE_PRODUCTS = 'MODULE_PRODUCTS';
const MODULE_PRODUCTS_SELECTED = 'MODULE_PRODUCTS_SELECTED';

const MODULE_SALES_COMPANY_BY_CONTACTS = 'MODULE_SALES_COMPANY_BY_CONTACTS';

const MODULE_CONTACTS_MAP = 'MODULE_CONTACTS_MAP';
const MODULE_CONTACTS_TYPES_MAP = 'MODULE_CONTACTS_TYPES_MAP';
const MODULE_CONTACTS_WITH_PURCHASE_MOTIVATION_MAP = 'MODULE_CONTACTS_WITH_PURCHASE_MOTIVATION_MAP';

const MODULE_CITIES = 'MODULE_CITIES';
const MODULE_DISTRICTS = 'MODULE_DISTRICTS';
const MODULE_MAIN_MEDIA = 'MODULE_MAIN_MEDIA';
const MODULE_SECONDARY_MEDIA = 'MODULE_SECONDARY_MEDIA';
const MODULE_PURCHASE_GOAL = 'MODULE_PURCHASE_GOAL';
const MODULE_TABLE_LIST_DATA = 'MODULE_TABLE_LIST_DATA';

const MODULE_QUESTIONNAIRES = 'MODULE_QUESTIONNAIRES';

const MODULE_TEMP_CAMERA = 'MODULE_TEMP_CAMERA';
const MODULE_TEMP_TELEFONIA = 'MODULE_TEMP_TELEFONIA';
const MODULE_TEMP_ENERGIA = 'MODULE_TEMP_ENERGIA';

const MODULE_ROULETTE_AMOUNT = 'MODULE_ROULETTE_AMOUNT';
const MODULE_ROULETTE_AVERAGE_BY_BROKER = 'MODULE_ROULETTE_AVERAGE_BY_BROKER';
const MODULE_ROULETTE_AVERAGE_BY_ROULETTE = 'MODULE_ROULETTE_AVERAGE_BY_ROULETTE';

const MODULE_VISITS__BY_SALES_COMPANY = 'MODULE_VISITS__BY_SALES_COMPANY';

const MODULE_GENERAL_REPORT = 'MODULE_GENERAL_REPORT';
const MODULE_GENERAL_REPORT_BY_PRODUCT = 'MODULE_GENERAL_REPORT_BY_PRODUCT';

const MODULE_VISITS_BY_PRODUCTS_TYPE = 'MODULE_VISITS_BY_PRODUCTS_TYPE';

const MODULE_SYSTEM_USAGE = 'MODULE_SYSTEM_USAGE';
const MODULE_VISITS_BY_SUPERINTENDENTS_AND_SALES_COMPANY = 'MODULE_VISITS_BY_SUPERINTENDENTS_AND_SALES_COMPANY';

const MODULE_CONTACTS_BY_WEEK = 'MODULE_CONTACTS_BY_WEEK';
const MODULE_MEDIA_BY_PRODUCT_OF_INTEREST = 'MODULE_MEDIA_BY_PRODUCT_OF_INTEREST';

const MODULE_CUSTOMER_BY_QUESTIONNAIRE_BY_CONTACTS = 'MODULE_CUSTOMER_BY_QUESTIONNAIRE_BY_CONTACTS';

const MODULE_COMPLETED_QUESTIONNAIRES = 'MODULE_COMPLETED_QUESTIONNAIRES';

const MODULE_CLIENT_QUESTIONNAIRE_LAST_CONTACT = 'MODULE_CLIENT_QUESTIONNAIRE_LAST_CONTACT';

const MODULE_SIMPLIFIED_DAILY_REPORT = 'MODULE_SIMPLIFIED_DAILY_REPORT';

const MODULE_UNIFIED_REPORT = 'MODULE_UNIFIED_REPORT';

const MODULE_DAILY_REPORT = 'MODULE_DAILY_REPORT';

const MODULE_HISTORY_WHATSAPP = 'MODULE_HISTORY_WHATSAPP';

const MODULE_WEEKLY_REPORT = 'MODULE_WEEKLY_REPORT';

const MODULE_PARTIAL_REPORT = 'MODULE_PARTIAL_REPORT';

const MODULE_WHATSAPP_STATUS = 'MODULE_WHATSAPP_STATUS';

const MODULE_CUSTOMER_BY_CONTACTS = 'MODULE_CUSTOMER_BY_CONTACTS';

const MODULE_REPORT_LOGS_INTEGRATION = 'MODULE_REPORT_LOGS_INTEGRATION';

export {
    MODULE_TOTAL_NUMBER_OF_CONTACTS,
    MODULE_TOTAL_CONTACTS_CREATED_BY,
    MODULE_CONTACT_TYPES,
    MODULE_TOTAL_CUSTOMERS_LGPD,
    MODULE_CONTACTS_EVS,
    MODULE_TEAM_CONTACTS,
    MODULE_PRODUCTS,
    MODULE_PRODUCTS_SELECTED,

    MODULE_SALES_COMPANY_BY_CONTACTS,

    MODULE_CONTACTS_MAP,
    MODULE_CONTACTS_TYPES_MAP,
    MODULE_CONTACTS_WITH_PURCHASE_MOTIVATION_MAP,

    MODULE_CITIES,
    MODULE_DISTRICTS,
    MODULE_MAIN_MEDIA,
    MODULE_SECONDARY_MEDIA,
    MODULE_PURCHASE_GOAL,
    MODULE_TABLE_LIST_DATA,

    MODULE_QUESTIONNAIRES,

    MODULE_TEMP_CAMERA,
    MODULE_TEMP_TELEFONIA,
    MODULE_TEMP_ENERGIA,
    MODULE_ROULETTE_AMOUNT,
    MODULE_ROULETTE_AVERAGE_BY_BROKER,
    MODULE_ROULETTE_AVERAGE_BY_ROULETTE,

    MODULE_VISITS__BY_SALES_COMPANY,

    MODULE_GENERAL_REPORT,
    MODULE_GENERAL_REPORT_BY_PRODUCT,

    MODULE_VISITS_BY_PRODUCTS_TYPE,

    MODULE_SYSTEM_USAGE,

    MODULE_VISITS_BY_SUPERINTENDENTS_AND_SALES_COMPANY,

    MODULE_CONTACTS_BY_WEEK,

    MODULE_MEDIA_BY_PRODUCT_OF_INTEREST,

    MODULE_CUSTOMER_BY_QUESTIONNAIRE_BY_CONTACTS,

    MODULE_COMPLETED_QUESTIONNAIRES,

    MODULE_CLIENT_QUESTIONNAIRE_LAST_CONTACT,

    MODULE_SIMPLIFIED_DAILY_REPORT,

    MODULE_UNIFIED_REPORT,

    MODULE_DAILY_REPORT,

    MODULE_HISTORY_WHATSAPP,

    MODULE_WEEKLY_REPORT,

    MODULE_PARTIAL_REPORT,

    MODULE_WHATSAPP_STATUS,

    MODULE_CUSTOMER_BY_CONTACTS,

    MODULE_REPORT_LOGS_INTEGRATION
}
