<template>
  <mobule-base-builder
    :module-data="moduleData"
    :editMode="editMode"
    :loading="loading"
    :error="error"
    :downloadImage="dataDownload"
    :typePanelPDF="''"
  >
    <template v-slot:content>
      <div class="mobule-number-total roulette-average-by-roulette echarts">
        <p class="total">{{ total }}</p>
        <p>média de visitas por Roleta</p>
      </div>
    </template>
  </mobule-base-builder>
</template>

<script>
import MobuleBaseBuilder from "../base/MobuleBaseBuilder.vue";
import MixinModuleBase from "../../../mixins/MixinModuleBase";

import BrokerRotationBrokerActivityService from "../../../services/BrokerRotationBrokerActivityService";

export default {
  mixins: [MixinModuleBase],
  name: "module-roulette-average-by-roulette",

  components: {
    MobuleBaseBuilder,
  },

  data: function () {
    return {
      brokerRotationBrokerActivityService:
        BrokerRotationBrokerActivityService.build(),

      total: 0,

      dataDownload: {
        class: "roulette-average-by-roulette",
        title: this.moduleData.headerTitle,
      },
    };
  },

  methods: {
    resetModule() {
      this.total = 0;
    },
    getData() {
      this.resetModule();

      if (this.dataRetrievalOk()) {
        this.loading = true;

        this.brokerRotationBrokerActivityService
          .getAverageByRoulette({
            selectedProductsIds: this.selectedProductsIds(),
            dateBegin: this.filterDateRange[0],
            dateEnd: this.filterDateRange[1],
          })
          .then((response) => {
            this.resetModule();
            this.total = response.average;
            this.loading = false;
          })
          .catch(this.errorHandler);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mobule-number-total {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
  background-color: #fff;

  p {
    margin: 5px;
    font-weight: bold;
    font-size: 16px;

    &.total {
      font-size: 28px;
    }
  }
}

.echarts {
  width: 100%;
  height: 100%;
}
</style>
