import axios from 'axios'
import responseErrorHandler from '../base/responseErrorHandler';

const ipify = axios.create({
  timeout: 100000,
  transformResponse: [
    (data) => {
      return data
    }
  ],
})

ipify.interceptors.response.use(response => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;

}, responseErrorHandler);

export default ipify;
