import Rest from './base/Rest'

/**
 * @typedef {EnterpriseService}
 */
export default class EnterpriseService extends Rest {
  /**
   * @type {String}
   */
  static resource = "/enterprise";

  /**
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */

  findAllEnterprises() {
    return this.get(`/getAllEnterprises`);
  }

  findAllEnterprisesWithBI() {
    return this.get(`/getAllEnterprisesWithBI`);
  }

  findAllEnterprisesIsActiveBI() {
    return this.get(`/findAllEnterprisesActiveBI`);
  }

  findAllByAccountWithActiveBI(accountId) {
    return this.get(`/findAllByAccountWithActiveBI?accountId=${accountId}`);
  }

  findOneActiveByDBName(dbName) {
    return this.get(`findOneActiveByDBName?dbName=${dbName}`);
  }

  findByAccountId() {
    return this.get(`findByAccountId`);
  }
}