<template>
  <div>
    <div class="multiple-enterprise">
      <div class="multiple-enterprise-container">
        <div class="multiple-enterprise-content">
          <h4>
            <strong class="text-secondary"
              >Escolha uma empresa para acessar os seus Dashboards:
            </strong>
          </h4>
          <div class="container bg-white col-md-12 my-6">
            <div class="col-12">
              <div class="input-group">
                <div class="input-group-prepend">
                  <label class="input-group-text" for="inputGroupSelect01"
                    >Empresa</label
                  >
                </div>
                <select
                  class="custom-select"
                  id="inputGroupSelect02"
                  v-model="selectedEnterprise"
                >
                  <option selected :value="null" disabled>Selecione</option>
                  <option
                    v-for="enterprise in getMultipleEnterprise"
                    :key="enterprise.name"
                    :value="enterprise"
                    :id="enterprise.name"
                  >
                    {{ enterprise.name }}
                  </option>
                </select>
              </div>
            </div>
            <button
              class="btn btn-block btn-smarts-black"
              @click="accessToSelectedEnterprise"
            >
              Acessar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginService from "../services/LoginService";
const loginService = LoginService.build();

import EnterpriseService from "../services/EnterpriseService";
const enterpriseService = EnterpriseService.build();

import DefaultMixin from "../mixins/DefaultMixin";

export default {
  name: "MultipleEnterprise",
  mixins: [DefaultMixin],
  data() {
    return {
      selectedEnterprise: null,
      enterpriseDefault: null,
    };
  },
  computed: {
    getMultipleEnterprise() {
      return this.$store.getters.multipleEnterprise;
    },
    getUserId() {
      return this.$store.getters.userId;
    },
    getDataBaseEnterprise() {
      return this.$store.getters.enterprise;
    },
    userIsManager() {
      return this.$store.getters.userIsManager;
    },
  },
  methods: {
    accessToSelectedEnterprise() {
      if (
        this.selectedEnterprise &&
        this.selectedEnterprise !== this.enterpriseDefault
      ) {
        const credencials = {
          dataBase: this.selectedEnterprise.dataBase,
        };

        loginService
          .generateJWTByDatabase(credencials)
          .then(async (data) => {
            await this.saveAccessHistory("login");

            document.cookie = `PLAY_BISESSION=${data.access_token};`;
            const { dataBase } = this.$jwtDec.decode(data.access_token);
            this.$store.dispatch("storeEnterprise", dataBase);
            this.$router.push({ path: "/dashboard" });
          })
          .catch(this.errorHandler);
      } else {
        this.$router.push({ path: "/dashboard" });
      }
    },
    async fetchMultipleEnterprise() {
      try {
        const data = await enterpriseService.findByAccountId();
        if (data.length > 1 && !this.userIsManager) {
          await this.$store.dispatch("storeMultipleEnterprise", data);
        }
      } catch (err) {
        this.errorHandler(err);
      }
    },
  },
  async mounted() {
    await this.fetchMultipleEnterprise();
    const enterpDefault = this.getMultipleEnterprise.filter(
      (enterprise) => enterprise.dataBase === this.getDataBaseEnterprise
    );
    this.enterpriseDefault = enterpDefault[0];
    this.selectedEnterprise = this.enterpriseDefault;
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.getters.multipleEnterprise.length > 1) next();
      else next({ path: "/" });
    });
  },
};
</script>

<style lang="scss" scoped>
.multiple-enterprise {
  min-height: 100vh;
  background-image: url("../../public/assets/images/background.jpg");
  background-size: 100% 100%;
  position: relative;
}

.multiple-enterprise-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  min-height: 100%;
  max-width: 38%;
  min-width: 38%;
  background-color: var(--smarts-white);
  color: var(--smarts-gray-dark);
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-box-shadow: 14px 7px 40px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 14px 7px 40px -2px rgba(0, 0, 0, 0.75);
  box-shadow: 14px 7px 40px -2px rgba(0, 0, 0, 0.75);
  border-radius: 0 1rem 1rem 0;
}

.multiple-enterprise-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 90%;
  margin: 0 auto;

  h4 {
    margin-left: 2rem;
  }
}

.btn-smarts-black {
  background-color: var(--smarts-black);
  color: var(--smarts-white);
  width: 94%;
  padding: 0.8rem 0.6rem;
  border-radius: 0.8rem;
  font-size: 1rem;
  margin: 2rem auto 0 auto;
  font-weight: bold;
  transition: all 0.4s;

  &:hover {
    background-color: var(--smarts-wine);
  }
}
</style>