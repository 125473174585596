import { render, staticRenderFns } from "./ModuleWhatsApp.vue?vue&type=template&id=377bd300&scoped=true&"
import script from "./ModuleWhatsApp.vue?vue&type=script&lang=js&"
export * from "./ModuleWhatsApp.vue?vue&type=script&lang=js&"
import style0 from "./ModuleWhatsApp.vue?vue&type=style&index=0&id=377bd300&prod&scoped=true&lang=scss&media=print&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "377bd300",
  null
  
)

export default component.exports