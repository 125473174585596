import Rest from './base/Rest'

/**
 * @typedef {ReplyService}
 */
export default class ReplyService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/reply'


  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  getPurchaseGoals(params = {}) {
    return this.get(`/getPurchaseGoals?${this.createQueryString(params)}`)
  }
}