<template>
  <mobule-base-builder
    :module-data="moduleData"
    :editMode="editMode"
    :loading="loading"
    :error="error"
    :downloadImage="dataDownload"
    :tableHeaders="tableHeaders"
    :tableItems="tableItems"
    :typePanelPDF="''"
  >
    <template v-slot:content>
      <div class="echarts" v-if="barChartData.length > 0">
        <module-chart-bar
          v-if="barChartData.length > 0"
          :data="barChartData"
          :labels="labels"
          class="sales-company-by-contacts"
        />
      </div>
      <message-status-data
        v-else
        :selected-products="selectedProductsIds()"
        :infoLoading="loading"
      />
    </template>
  </mobule-base-builder>
</template>

<script>
import MobuleBaseBuilder from "../base/MobuleBaseBuilder.vue";
import ModuleChartBar from "../base/ModuleChartBar.vue";
import MixinModuleBase from "../../../mixins/MixinModuleBase";
import MessageStatusData from "../../MessageStatusData.vue";

import ECharts from "vue-echarts";
import getBar from "../../../data/bar";

import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/component/title";
import "echarts/lib/component/dataset";
import "zrender/lib/svg/svg";

import theme from "../theme/theme.json";
ECharts.registerTheme("ovilia-green", theme);

import SalesCompanyService from "../../../services/SalesCompanyService";
const salesCompanyService = SalesCompanyService.build();

export default {
  mixins: [MixinModuleBase],
  name: "module-sales-company-by-contacts",

  components: {
    MobuleBaseBuilder,
    "v-chart": ECharts,
    MessageStatusData,
    ModuleChartBar,
  },

  data: function () {
    let data = [];

    for (let i = 0; i <= 360; i++) {
      let t = (i / 180) * Math.PI;
      let r = Math.sin(2 * t) * Math.cos(2 * t);
      data.push([r, i]);
    }

    return {
      initOptions: {
        renderer: "svg",
      },
      dataDownload: {
        class: "sales-company-by-contacts",
        title: this.moduleData.headerTitle,
      },
      tableHeaders: [],
      tableItems: [],
      barChartData: [],
      labels: [],
    };
  },
  computed: {
    getSelectedProducts() {
      return this.$store.getters.selectedProducts;
    },
  },
  methods: {
    resetModule() {
      this.tableItems = [];
      this.barChartData = [];
      this.labels = [];
    },
    getData() {
      this.resetModule();

      if (this.dataRetrievalOk()) {
        this.loading = true;
        salesCompanyService
          .getSalesCompaniesGroupContact({
            selectedProductsIds: this.selectedProductsIds(),
            dateBegin: this.filterDateRange[0],
            dateEnd: this.filterDateRange[1],
          })
          .then((r) => {
            this.resetModule();

            if (r && r.length > 0) {
              for (let i = 0; i < r.length; i++) {
                this.barChartData.push(r[i].contactCount);
                this.labels.push(r[i].name);
              }
            }

            this.generatedExcelData(r);
            this.loading = false;
          })
          .catch(this.errorHandler);
      }
    },
    generatedExcelData(data) {
      this.tableHeaders = [];
      this.tableItems = [];
      if (data) {
        this.tableHeaders = data.map((header, index) => {
          return {
            text: header.name,
            value: `contactCount${index}`,
          };
        });

        this.tableHeaders.unshift({
          text: "Produtos Selecionados",
          value: "selectedProducts",
        });

        if (this.tableHeaders.length > 0) {
          let itens = {};
          data.forEach((obj, index) => {
            delete obj.name;
            let itemAux = {};
            itemAux[`contactCount${index}`] = obj.contactCount;
            itens = {
              ...itens,
              ...itemAux,
            };
          });

          this.tableItems = [];
          const tableItemsAux = [];
          tableItemsAux.push(itens);

          const productsSelectedLength = this.getSelectedProducts.length;

          tableItemsAux.forEach((item) => {
            for (let index = 0; index < productsSelectedLength; index++) {
              const product = this.getSelectedProducts[index];
              if (index === 0) {
                this.tableItems.push({
                  selectedProducts: product?.name,
                  ...item,
                });
              } else {
                this.tableItems.push({
                  selectedProducts: product?.name ? product?.name : "",
                });
              }
            }
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.echarts {
  width: 100%;
  height: 100%;
  position: relative;
}

.sales-company-by-contacts {
  background-color: #fff;
}
</style>
