<template>
  <mobule-base-builder :module-data="moduleData" :editMode="editMode" :loading="loading" ref="tableParent" :downloadImage="dataDownload">
      <template v-slot:content class="camera">
        <img :src="imgSelecionada" style="width:100%; height:100%;" />
      </template>
  </mobule-base-builder>
</template>

<script>
import MixinModuleBase from "../../../mixins/MixinModuleBase";

import MobuleBaseBuilder from '../base/MobuleBaseBuilder.vue';

export default {
  name: "module-tmp-camera",
  mixins: [MixinModuleBase],
  components: {
    MobuleBaseBuilder,
  },
  data: function () {
    return {
      imagens: [
        'https://cs01.smartsigma.com.br:7443/api/2.0/snapshot/camera/607588078f08130d32e7eafe?apiKey=2gBEP34JWIqvyd4OexDFxvPDGnjSezDR',
        'https://cs01.smartsigma.com.br:7443/api/2.0/snapshot/camera/607587478f08130d32e7de28?apiKey=2gBEP34JWIqvyd4OexDFxvPDGnjSezDR',
        'https://cs01.smartsigma.com.br:7443/api/2.0/snapshot/camera/607586af8f08130d32e7d471?apiKey=2gBEP34JWIqvyd4OexDFxvPDGnjSezDR',
        'https://cs01.smartsigma.com.br:7443/api/2.0/snapshot/camera/607586a48f08130d32e7d3ad?apiKey=2gBEP34JWIqvyd4OexDFxvPDGnjSezDR',
        'https://cs01.smartsigma.com.br:7443/api/2.0/snapshot/camera/607586aa8f08130d32e7d41a?apiKey=2gBEP34JWIqvyd4OexDFxvPDGnjSezDR',
        'https://cs01.smartsigma.com.br:7443/api/2.0/snapshot/camera/6075881f8f08130d32e7ec44?apiKey=2gBEP34JWIqvyd4OexDFxvPDGnjSezDR',
        'https://cs01.smartsigma.com.br:7443/api/2.0/snapshot/camera/607586b68f08130d32e7d4e3?apiKey=2gBEP34JWIqvyd4OexDFxvPDGnjSezDR',
        'https://cs01.smartsigma.com.br:7443/api/2.0/snapshot/camera/6075882c8f08130d32e7ecda?apiKey=2gBEP34JWIqvyd4OexDFxvPDGnjSezDR',
        'https://cs01.smartsigma.com.br:7443/api/2.0/snapshot/camera/6075881a8f08130d32e7ec12?apiKey=2gBEP34JWIqvyd4OexDFxvPDGnjSezDR',
        'https://cs01.smartsigma.com.br:7443/api/2.0/snapshot/camera/607588148f08130d32e7ebc8?apiKey=2gBEP34JWIqvyd4OexDFxvPDGnjSezDR',
        'https://cs01.smartsigma.com.br:7443/api/2.0/snapshot/camera/607588298f08130d32e7ecbc?apiKey=2gBEP34JWIqvyd4OexDFxvPDGnjSezDR',
        'https://cs01.smartsigma.com.br:7443/api/2.0/snapshot/camera/6075869d8f08130d32e7d322?apiKey=2gBEP34JWIqvyd4OexDFxvPDGnjSezDR',
      ],
      imgSelecionada: '',
      dataDownload: {class: "camera", title: this.moduleData.headerTitle}
    }
  },
  methods: {
    resetModule() {
    },
    getData() {
    }
  },
  mounted() {
    this.imgSelecionada = this.imagens[Math.floor(Math.random() * this.imagens.length)]
  }
};
</script>

<style scoped>
.camera {
  background-color: #fff;
}
</style>
