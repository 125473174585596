import Rest from './base/Rest'

/**
 * @typedef {DashboardService}
 */
export default class DashboardService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/dashboard'


  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  save (params = {}) {
    return this.post(`/save`, params)
  }

    /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
    deleteDashboard (params = {}) {
      return this.delete(`/delete`, { data: params })
    }
  
  /**
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
   findAllAvailable () {
    return this.get('/findAllAvailable')
  }
  
  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
   getById (id) {
    return this.get(`/getById/${id}`)
  }

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  deletePanelsDeniedInDashboard(params = {}, user = {}){
    return this.post(`/deletePanelsDeniedInDashboard`, params, user)
  }
}