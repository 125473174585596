<template>
  <mobule-base-builder
    :module-data="moduleData"
    :editMode="editMode"
    :loading="loading"
    :error="error"
    :downloadImage="dataDownload"
    :tableHeaders="tableHeaders"
    :tableItems="tableItems"
    :tableHeadersExcel="tableHeadersExcel"
    :tableItemsExcel="tableItemsExcel"
    :typePanelPDF="''"
  >
    <template v-slot:content>
      <div class="px-6 py-3 bg-white">
        <v-row>
          <template>
            <v-col cols="8" lg="8">
              <v-autocomplete
                v-model="selectedProduct"
                :items="products"
                item-text="name"
                item-value="id"
                id="whatsapp"
                label="Selecione o produto"
                class="px-4 pt-2"
                outlined
                dense
                @change="resetModule"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2" lg="2">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormatted"
                    label="Data"
                    readonly
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    @blur="date = parseDate(dateFormatted)"
                    v-on="on"
                    class="mt-2"
                    dense
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  no-title
                  :max="new Date().toISOString().split('T')[0]"
                  @input="menu = false"
                  locale="pt-br"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2" lg="2" align-self="center" class="mb-5">
              <v-row align="center" justify="space-around">
                <v-btn
                  depressed
                  color="#000E17"
                  dark
                  elevation="2"
                  @click="getStatus"
                  class="px-4 py-5"
                >
                  <v-icon left>fas fa-search</v-icon>
                  Buscar
                </v-btn>
              </v-row>
            </v-col>
          </template>
        </v-row>

        <div v-if="tableItems.length > 0">
          <v-alert :type="typeAlert" class="alert" dark elevation="2">
            {{ textAlert }}
          </v-alert>
          <module-table-list-data-v-2
            :tableHeaders="tableHeaders"
            :tableItems="tableItems"
            :footerPropsItemsPerPageText="footerPropsItemsPerPageText"
            class="echarts-table"
          />
          <module-table-list-data-v-2
            :tableHeaders="tableHeadersSessions"
            :tableItems="quantityMessagesBySession"
            :footerPropsItemsPerPageText="'Sessões por página'"
            class="echarts-table"
          />
        </div>
        <v-alert
          v-else-if="
            tableItems.length === 0 &&
            selectedProduct.length !== 0 &&
            date !== '' &&
            firstSearch
          "
          type="error"
          class="alert"
          dark
          elevation="2"
        >
          Baseado nas informações o WhatsApp não esteve conectado na data selecionada!
        </v-alert>
        <message-status-data-unique-product v-else :infoLoading="loading" />
      </div>
    </template>
  </mobule-base-builder>
</template>

<script>
import MixinModuleBase from "../../../mixins/MixinModuleBase";
import DefaultMixin from "../../../mixins/DefaultMixin";
import MobuleBaseBuilder from "../base/MobuleBaseBuilder.vue";
import MessageStatusDataUniqueProduct from "../../MessageStatusDataUniqueProduct.vue";
import ModuleTableListDataV2 from "../base/ModuleTableListDataV2.vue";

import ProductService from "../../../services/ProductService";
const productService = ProductService.build();

import HistoryWhatsAppService from "../../../services/HistoryWhatsAppService";
const historyWhatsAppService = HistoryWhatsAppService.build();
import LogWhatsAppService from "../../../services/LogWhatsAppService";
const logWhatsAppService = LogWhatsAppService.build();

export default {
  name: "module-whatsapp-status",
  mixins: [MixinModuleBase, DefaultMixin],

  components: {
    MobuleBaseBuilder,
    MessageStatusDataUniqueProduct,
    ModuleTableListDataV2,
  },

  data: function () {
    return {
      dataDownload: {
        class: "module-whatsapp-status",
        title: this.moduleData.headerTitle,
      },
      tableHeaders: [
        {
          text: "Status",
          align: "start",
          sortable: true,
          value: "status",
          tooltip: "Status registrado",
        },
        {
          text: "Sessão",
          align: "start",
          sortable: true,
          value: "session",
          tooltip: "Número de sessão",
        },
        {
          text: "Número WhatsApp",
          align: "start",
          sortable: false,
          value: "cellphone",
          tooltip: "Número WhatsApp",
        },
        {
          text: "Data e Hora de Registro do Status",
          align: "start",
          sortable: false,
          value: "createdAt",
          tooltip: "Data e Hora de Registro do Status",
        },
      ],
      tableHeadersSessions: [
        {
          text: "Sessão",
          align: "start",
          sortable: true,
          value: "sessionName",
          tooltip: "Número de sessão",
        },
        {
          text: "Total de Mensagens",
          align: "start",
          sortable: true,
          value: "total",
          tooltip: "Total de Mensagens",
        },
        {
          text: "Data e Hora de Registro da Última Mensagem",
          align: "start",
          sortable: false,
          value: "createdAtSession",
          tooltip: "Data e Hora de Registro da Última Mensagem",
        },
      ],
      tableItems: [],
      tableHeadersExcel: [],
      tableItemsExcel: [],
      footerPropsItemsPerPageText: "Status por página",
      products: [],
      selectedProduct: [],
      quantityMessagesBySession: [],
      menu: false,
      dateFormatted: "",
      date: "",
      textAlert:
        "Baseado nas informações obtidas até o momento o WhatsApp não está conectado!",
      typeAlert: "error",
      firstSearch: false,
    };
  },
  methods: {
    getJsonObject(jsonString) {
      return JSON.parse(jsonString);
    },
    resetModule() {
      this.tableItems = [];
      this.quantityMessagesBySession = [];
      this.tableHeadersExcel = [];
      this.tableHeadersExcel.push({
        text: "Produto Selecionado",
        value: "selectedProduct",
      }),
        (this.tableItemsExcel = []);
      const product = this.products.filter(
        (product) => product.id === this.selectedProduct
      );
      this.tableItemsExcel.push({
        selectedProduct: product[0]?.name,
      });
    },
    getData() {
      if (
        this.date &&
        this.date !== "" &&
        this.selectedProduct &&
        this.selectedProduct !== "" &&
        this.selectedProduct > 0
      ) {
        this.resetModule();
        this.loading = true;
        this.firstSearch = true;

        logWhatsAppService
          .getLatestStatusWhatsAppConnectByDateAndProduct({
            selectedProductsIds: [this.selectedProduct],
            date: this.date,
          })
          .then(async (result) => {
            if (result && result.length > 0) {
              this.tableItems = result.map((status) => {
                const date = status.createdAt
                  .split("T")[0]
                  .split("-")
                  .reverse()
                  .join("/");
                const time = status.createdAt.split("T")[1].split(".")[0];
                const dateAndHour = `${date} - ${time}`;
                const statusTitle = this.translateStatus(status.status);
                return {
                  ...status,
                  status: statusTitle,
                  createdAt: dateAndHour,
                };
              });

              const sessionsRemoveDuplicateArray = this.removeDuplicatesBySession(result);

              const sessionsArray = sessionsRemoveDuplicateArray.map(
                (item) => item.session
              );

              await this.getQuantityMessagesBySession(sessionsArray, result);
            }

            this.loading = false;
          })
          .catch(this.errorHandler);
      }
    },
    mapExcel(items, header) {
      let listAux = [];
      if (items.length > 0) {
        for (let i = 0; i < this.tableItemsExcel.length; i++) {
          if (items.length > 0)
            listAux.push({ ...this.tableItemsExcel[i], ...items.shift() });
          else break;
        }
        if (items.length > 0) {
          listAux = [...listAux, ...items];
        }

        this.tableItemsExcel = listAux;
        this.tableHeadersExcel = [...this.tableHeadersExcel, ...header];
      }
    },
    getStatus() {
      if (
        this.date &&
        this.date !== "" &&
        this.selectedProduct &&
        this.selectedProduct !== "" &&
        this.selectedProduct > 0
      ) {
        this.getData();
      } else {
        this.alertWarning({ title: "Atenção", text: "Produto e data são obrigatórios!" });
      }
    },
    checkConnection(status, sessoes) {
      if (status.length > 0) {
        const today = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10);

        let findConnected = [];
        let findTotalMessagesBySessionConnected = [];
        findConnected = status.filter((item) => item.status === "inChat");

        if (sessoes.length > 0) {
          findTotalMessagesBySessionConnected = sessoes.filter(
            (item) => item.session === findConnected[0].session
          );
        }

        if (
          findConnected &&
          findConnected.length > 0 &&
          findTotalMessagesBySessionConnected &&
          findTotalMessagesBySessionConnected.length > 0
        ) {
          const date = findTotalMessagesBySessionConnected[0].createdAt
            .split("T")[0]
            .split("-")
            .reverse()
            .join("/");
          const time = findTotalMessagesBySessionConnected[0].createdAt
            .split("T")[1]
            .split(".")[0];
          const dateAndHour = `${date} - ${time}`;
          this.typeAlert = "success";

          if (
            today === findTotalMessagesBySessionConnected[0]?.createdAt?.split("T")[0]
          ) {
            this.textAlert = `Baseado na sessão: ${
              findConnected[0].session
            } com status: ${this.translateStatus(findConnected[0].status)},
             que contém: ${
               findTotalMessagesBySessionConnected[0].total
             } mensagens registradas, com a última registrada em ${dateAndHour},
             no momento o WhatsApp está conectado`;
          } else {
            this.textAlert = `Baseado na sessão: ${
              findConnected[0].session
            } com status: ${this.translateStatus(findConnected[0].status)},
             que contém: ${
               findTotalMessagesBySessionConnected[0].total
             } mensagens registradas, com a última registrada em ${dateAndHour},
            o WhatsApp esteve conectado em: ${date}`;
          }
        } else if (
          findConnected &&
          findConnected.length > 0 &&
          findTotalMessagesBySessionConnected &&
          findTotalMessagesBySessionConnected.length === 0
        ) {
          this.typeAlert = "warning";

          if (today === findConnected[0]?.createdAt?.split("T")[0]) {
            this.textAlert = `Baseado na sessão: ${
              findConnected[0].session
            } com status: ${this.translateStatus(findConnected[0].status)},
              com 0 mensagens registradas, no momento o WhatsApp pode não estar conectado.
              Envie uma mensagem para o WhatsApp desse Stand e efetue uma nova busca para ter certeza!`;
          } else {
            this.textAlert = `Baseado na sessão: ${
              findConnected[0].session
            } com status: ${this.translateStatus(findConnected[0].status)},
              com 0 mensagens registradas, o WhatsApp pode ter sido conectado mas não houve troca de mensagens,
              em: ${this.date.split("-").reverse().join("/")}`;
          }
        } else {
          this.typeAlert = "error";
          this.textAlert =
            "Baseado nas informações obtidas até o momento o WhatsApp não está conectado!";
        }
      }
    },
    async getQuantityMessagesBySession(sessions, status) {
      this.loading = true;

      historyWhatsAppService
        .getMessagesBySessionAndProductAndDate({
          selectedProductsIds: [this.selectedProduct],
          date: this.date,
          session: sessions,
        })
        .then((result) => {
          if (result && result.length > 0) {
            this.quantityMessagesBySession = result.map((session) => {
              const date = session.createdAt.split("T")[0].split("-").reverse().join("/");
              const time = session.createdAt.split("T")[1].split(".")[0];
              const dateAndHour = `${date} - ${time}`;
              return {
                total: session.total,
                sessionName: session.session,
                createdAtSession: dateAndHour,
              };
            });

            const temp = [];
            temp.push(...this.quantityMessagesBySession);

            this.mapExcel(temp, this.tableHeadersSessions);
            this.mapExcel(this.tableItems, this.tableHeaders);

            this.checkConnection(status, result);
          } else {
            this.checkConnection(status, []);
            this.mapExcel(
              [{ total: "", sessionName: "", createdAtSession: "" }],
              this.tableHeadersSessions
            );
            this.mapExcel(this.tableItems, this.tableHeaders);
          }
        })
        .catch(this.errorHandler);
    },
    getProducts() {
      productService
        .getAvailableProductsWithTotalContacts({
          dateBegin: null,
          dateEnd: null,
          choosen: "activies",
        })
        .then((result) => {
          this.products = result;
        })
        .catch(this.errorHandler);
    },
    removeDuplicatesBySession(status) {
      const uniqueArray = status.reduce((accumulator, current) => {
        // Verifica se já existe um objeto no novo array com a mesma session
        const isDuplicate = accumulator.some((item) => item.session === current.session);

        // Se não houver objetos com a mesma session, adiciona o objeto ao novo array
        if (!isDuplicate) {
          accumulator.push(current);
        }

        return accumulator;
      }, []);

      return uniqueArray;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");

      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("/");
      return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`;
    },
    translateStatus(status) {
      switch (status) {
        case "qrReadError":
          return "Erro ao ler QRCode";
        case "browserClose":
          return "Navegador foi fechado";
        case "autocloseCalled":
          return "Fechamento automático foi chamado";
        case "inChat":
          return "Conectado";
        case "notLogged":
          return "Não está logado";
        case "desconnectedMobile":
          return "celular desconectado";
        default:
          return status;
      }
    },
  },
  computed: {
    hasFilterDateRange() {
      return this.date && this.date !== "";
    },
    hasDatasValue() {
      if (
        this.selectedProduct === "" ||
        this.selectedProduct.length === 0 ||
        (this.selectedProduct && this.tableItems.length > 0)
      ) {
        return true;
      }
      return false;
    },
    getEnterpriseLogo() {
      return this.$store.getters.enterpriseLogo;
    },
  },
  mounted() {
    this.getProducts();
    this.dateFormatted = this.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    );
    this.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10);
  },
  watch: {
    filterDateRange() {
      if (!this.hasFilterDateRange && this.selectedProduct) {
        this.resetModule();
      }
    },
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
};
</script>

<style scoped lang="scss">
.echarts-table {
  height: auto !important;
  margin-bottom: 32px;
}

.alert {
  max-width: 97%;
  margin: 0 auto 1rem auto;
}
</style>
