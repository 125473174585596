import { v5 as uuid5 } from 'uuid';

/**
 * @typedef { UUIDService }
 */
export default class UUIDService {

  /**
   * UUID namespace for milliseconds
   *  @type { string }
   */
  static MILLIS = '896f9f8c-5bae-4174-8aa4-2836a00d93e2';

  /**
   * generate an RFC version 5 UUID based on the current time
   * @returns { string } the generated UUID
   */
  generateCurrentMillisUUID() {
    return uuid5(String(Date.now()), UUIDService.MILLIS);
  }
}
