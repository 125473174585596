<template>
  <mobule-base-builder :module-data="moduleData" :editMode="editMode" :loading="loading" :downloadImage="dataDownload">
    <template v-slot:content>
      <div class="mobule-number-total tmp-energia">
        <p class="total">{{ totalKw }}</p>
        <p>kWh no período</p>
      </div>
    </template>
  </mobule-base-builder>
</template>

<script>
import MobuleBaseBuilder from '../base/MobuleBaseBuilder.vue'
import MixinModuleBase from "../../../mixins/MixinModuleBase";

export default {
  mixins: [MixinModuleBase],
  name: 'module-tmp-energia',

  components: {
    MobuleBaseBuilder
  },

  data: function () {
    return {
      totalKw: 0,
      dataDownload: {class: "tmp-energia", title: this.moduleData.headerTitle}
    }
  },

  methods: {
    resetModule() {
      this.totalKw = 0;
    },
    getData() {
      this.resetModule();

      this.totalKw = Math.floor(Math.random() * 1000);
    }
  },
}
</script>

<style lang="scss" scoped>
.mobule-number-total{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
  background-color: #fff;

  p{
    margin: 5px;
    font-weight: bold;
    font-size: 16px;

    &.total{
      font-size: 28px;
    }
  }
}
</style>
