<template>
  <div class="dashboard-layout">
    <div
      class="container-sub-header"
      :style="{
        position: viewMenu ? 'absolute' : 'fixed',
        top: viewMenu ? '60px' : '0',
      }"
    >
      <div class="container-period labels">
        <v-menu
          ref="menuStartDate"
          v-model="menuStartDate"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startDateFormatted"
              label="Data inicial"
              readonly
              prepend-icon="mdi-calendar"
              outlined
              dense
              v-bind="attrs"
              @blur="startDate = parseDate(startDateFormatted)"
              v-on="on"
              class="mt-4"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="startDate"
            no-title
            @input="menuStartDate = false"
            :max="new Date().toISOString().split('T')[0]"
            locale="pt-br"
          ></v-date-picker>
        </v-menu>

        <v-menu
          ref="menuFinishDate"
          v-model="menuFinishDate"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="finishDateFormatted"
              label="Data final"
              readonly
              prepend-icon="mdi-calendar"
              dense
              outlined
              v-bind="attrs"
              @blur="finishDate = parseDate(finishDateFormatted)"
              v-on="on"
              :disabled="!startDate"
              class="mt-4"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="finishDate"
            no-title
            @input="menuFinishDate = false"
            :max="new Date().toISOString().split('T')[0]"
            locale="pt-br"
            :disabled="!startDate"
          ></v-date-picker>
        </v-menu>

        <v-autocomplete
          v-model="dashboardDateRange"
          :items="pickerOptions.shortcuts"
          item-text="text"
          item-value="value"
          @change="handleDateChange()"
          label="Escolha um Período"
          class="mt-4"
          dense
          outlined
        ></v-autocomplete>
      </div>

      <template v-if="getEditMode">
        <div class="container-manage-dashboard labels">
          <v-autocomplete
            v-model="chosenDashboard"
            :items="availableDashboards"
            item-text="name"
            item-value="id"
            @change="loadDashboardPanels()"
            label="Escolha um Dashboard"
            class="mt-4"
            dense
            outlined
          ></v-autocomplete>
        </div>

        <div data-html2canvas-ignore="true">
          <button
            @click="newDashboard.dialogVisible = true"
            class="btn btn-custom-white mb-3"
            :disabled="this.dashboardLayout[0].items?.length === 0"
          >
            <i class="fas fa-plus-circle mr-1"></i>
            Salvar novo
          </button>
        </div>

        <template
          v-if="
            (currentDashboard &&
              Number(currentDashboard.createdBy) === Number(currentDashboard.userId)) ||
            (userIsManager && currentDashboard)
          "
        >
          <div data-html2canvas-ignore="true">
            <button
              @click="saveCurrentDashboardDialogVisible = true"
              class="btn btn-custom-white mb-3"
            >
              <i class="fas fa-save mr-1"></i>
              Salvar atual
            </button>
          </div>

          <div data-html2canvas-ignore="true">
            <button @click="deleteDashboard" class="btn btn-custom-white mb-3">
              <i class="fas fa-trash-alt mr-1"></i>
              Apagar
            </button>
          </div>
        </template>
      </template>

      <div v-if="currentDashboard" data-html2canvas-ignore="true">
        <button class="btn btn-custom-white mb-3 javascript:void(0)" @click="download">
          <i class="fas fa-download mr-1"></i> Download
        </button>
      </div>
    </div>

    <template>
      <v-card id="create" class="item-menu-fixed hide" data-html2canvas-ignore="true">
        <v-speed-dial
          v-model="fab"
          :bottom="bottom"
          :left="left"
          :direction="direction"
          :open-on-hover="hover"
          :transition="transition"
        >
          <template v-slot:activator>
            <v-btn v-model="fab" color="#000E17" dark fab>
              <v-icon v-if="fab"> mdi-close </v-icon>
              <v-icon v-else> mdi-wrench</v-icon>
            </v-btn>
          </template>
          <v-btn
            fab
            dark
            small
            :color="getEditMode ? 'green' : 'red'"
            @click="handleEditMode"
          >
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon v-if="!getEditMode"> mdi-lock-outline </v-icon>
                  <v-icon v-else> mdi-lock-open-outline </v-icon>
                </v-btn>
              </template>
              {{ getEditMode ? "Travar Painéis" : "Destravar Painéis" }}
            </v-tooltip>
          </v-btn>
          <v-btn fab dark small color="blue">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-information-variant</v-icon>
                </v-btn>
              </template>

              <span>
                <div>
                  Período dos dados:
                  <strong>
                    {{ formatFilterRangeDate }}
                  </strong>
                </div>
                <div>
                  <div>Produtos selecionados:</div>
                  <div
                    v-for="selectedProduct in getSelectedProducts"
                    :key="selectedProduct.id"
                  >
                    <strong>{{ selectedProduct.name }}</strong>
                  </div>
                </div>
              </span>
            </v-tooltip>
          </v-btn>
          <v-btn fab dark small color="indigo" @click="openAddPanel" v-show="getEditMode">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-server-plus</v-icon>
                </v-btn>
              </template>
              Adicionar Painel
            </v-tooltip>
          </v-btn>
        </v-speed-dial>
      </v-card>
    </template>

    <div class="item-scroll-top hide" @click="upTopPage" data-html2canvas-ignore="true">
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon color="white light"> mdi-arrow-up </v-icon>
          </v-btn>
        </template>
        Voltar ao topo
      </v-tooltip>
      <!-- <i class="fas fa-plus"></i> -->
    </div>

    <dashboard-layout
      :dashboard-layout="dashboardLayout"
      :dashboard-created="dashboardCreated"
      :edit-mode="getEditMode"
      :filter-date-range="filterDateRange"
    />

    <!-- Save new dashboard modal -->
    <v-col cols="auto">
      <v-dialog
        v-model="newDashboard.dialogVisible"
        @close="resetNewDashboard"
        max-width="600"
      >
        <v-card>
          <v-toolbar color="#000e17" dark elevation="0" v-if="userIsManager">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon color="grey light"> fas fa-info-circle </v-icon>
                </v-btn>
              </template>

              <span>
                Os paineis aos quais o usuário de destino não possui acesso não serão
                copiados!
              </span>
            </v-tooltip>
            Qual o nome e destino desse Dashboard?
          </v-toolbar>

          <v-toolbar color="#000e17" dark elevation="0" v-else>
            Qual o nome desse Dashboard?
          </v-toolbar>

          <v-form label-width="120px" @submit.native.prevent>
            <div class="container py-0">
              <v-text-field
                v-model="newDashboard.name"
                label="Nome"
                class="ml-4 mr-4 mt-4"
                outlined
                dense
              ></v-text-field>
            </div>

            <available-users-input
              v-if="userIsManager"
              class="py-0"
              :selectedEnterpriseValue="newDashboard.targetEnterprise"
              @update:selectedEnterprise="newDashboard.targetEnterprise = $event"
              :selectedUserValue="newDashboard.targetUser"
              @update:selectedUser="newDashboard.targetUser = $event"
              :isMultiple="true"
              :dialogVisible="newDashboard.dialogVisible"
            ></available-users-input>
          </v-form>

          <v-card-actions class="justify-end">
            <v-btn color="error" @click="newDashboard.dialogVisible = false">
              Cancelar
            </v-btn>

            <v-btn color="success" @click="saveNewDashboard">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>

    <!-- Save current dashboard modal -->
    <v-col cols="auto">
      <v-dialog
        title="Deseja trocar o nome do Dashboard?"
        v-model="saveCurrentDashboardDialogVisible"
        max-width="600"
      >
        <v-card>
          <v-toolbar color="#000e17" dark elevation="0">
            Deseja trocar o nome do Dashboard?
          </v-toolbar>
          <v-form label-width="120px" @submit.native.prevent>
            <v-text-field
              class="ml-4 mr-4 mt-4"
              v-model="newCurrentDashboardName"
              label="Nome:"
              outlined
              dense
            ></v-text-field>
          </v-form>

          <v-card-actions class="justify-end">
            <v-btn color="error" @click="saveCurrentDashboardDialogVisible = false"
              >Cancelar</v-btn
            >

            <v-btn color="success" @click="saveCurrentDashboard">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
    <Loading :show="showLoading" />
  </div>
</template>

<script>
import DefaultMixin from "../../mixins/DefaultMixin";

import DateRangeEnum from "../../utils/enum/dateRange.enum";

import PanelService from "../../services/PanelService";
import DashboardService from "../../services/DashboardService";
import ProductService from "../../services/ProductService";
import UUIDService from "../../services/UUIDService";
import UserService from "../../services/UserService";

import DashboardLayout from "../charts/DashboardLayout.vue";
import AvailableUsersInput from "../manage-dashboard/AvailableUsersInput.vue";
import Loading from "../../views/Loading.vue";
import dateRangeEnum from "../../utils/enum/dateRange.enum";

import html2canvas from "html2canvas";

import * as htmlToImage from "html-to-image";

export default {
  name: "Dashboards",
  props: {
    reload: { type: Boolean, default: false },
    viewMenu: { type: Boolean, default: true },
    openAddPanel: {
      type: Function,
      default: () => {},
    },
  },
  mixins: [DefaultMixin],
  components: { DashboardLayout, AvailableUsersInput, Loading },
  data() {
    return {
      panelService: PanelService.build(),
      dashboardService: DashboardService.build(),
      productService: ProductService.build(),
      userService: UserService.build(),
      uuidService: new UUIDService(),

      // Vue-Responsive-Dash Grid Config
      dashboardLayout: [
        {
          breakpoint: "xxs",
          breakpointWidth: 0,
          numberOfCols: 20,
          items: [],
        },
      ],

      availableProducts: [],
      availableDashboards: [
        {
          name: "Novo dashboard em branco",
          id: "Em Branco",
        },
      ],

      dashboardCreated: true,

      saveCurrentDashboardDialogVisible: false,
      newCurrentDashboardName: "",

      dashboardDateRange: DateRangeEnum.CUSTOM,

      newDashboard: {
        name: "",
        dialogVisible: false,
        targetEnterprise: null,
        targetUser: null,
      },

      chosenDashboard: null,
      currentDashboard: null,

      filterDateRange: [],

      startDate: "",
      finishDate: "",
      startDateFormatted: "",
      finishDateFormatted: "",
      menuStartDate: false,
      menuFinishDate: false,
      selectedDateRange: false,

      dateShortcuts: {},
      pickerOptions: {
        shortcuts: [],
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },

      showLoading: false,

      direction: "top",
      fab: false,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: false,
      bottom: true,
      left: true,
      transition: "slide-y-reverse-transition",
    };
  },
  computed: {
    getEnterpriseName() {
      return this.$store.getters.enterprise;
    },
    filterDateRangeSelected() {
      return (
        this.dashboardDateRange === DateRangeEnum.CUSTOM &&
        this.filterDateRange?.length > 0
      );
    },
    userIsManager() {
      return this.$store.getters.userIsManager;
    },
    getUserTemp() {
      return this.$store.getters.userTemp;
    },
    getLoggedUserId() {
      return this.$store.getters.userId;
    },
    getSelectedProducts() {
      return this.$store.getters.selectedProducts;
    },
    formatFilterRangeDate() {
      let formatDate = "";
      if (this.filterDateRange && this.filterDateRange[0] && this.filterDateRange[1]) {
        formatDate =
          this.filterDateRange[0].split("T")[0].split("-").reverse().join("/") +
          " - " +
          this.filterDateRange[1].split("T")[0].split("-").reverse().join("/");
      }
      return formatDate;
    },
    getClearTempValues() {
      return this.$store.getters.clearTempValues;
    },
    getCurrentDashboard() {
      return this.$store.getters.currentDashboard;
    },
    getIsDownloadMap() {
      return this.$store.getters.isDownloadMap;
    },
    getFilterDateRange() {
      return this.$store.getters.filterDateRange;
    },
    getEditMode() {
      return this.$store.getters.editMode;
    },
    getReloadDashboard() {
      return this.$store.getters.reloadDashboard;
    },
  },
  methods: {
    handleDateChange() {
      this.filterDateRange = this.dateShortcuts[this.dashboardDateRange].range;
      this.startDate = this.filterDateRange[0];
      this.finishDate = this.filterDateRange[1];
    },

    getWeekRange({ weeksAway = 1, weeksAmount = 1 } = {}) {
      let lastSunday = new Date();
      lastSunday.setDate(lastSunday.getDate() - lastSunday.getDay());

      let weeksRange = weeksAway - weeksAmount;
      let startDate = new Date(lastSunday.getTime());
      let endDate = new Date(lastSunday.getTime());

      startDate.setDate(startDate.getDate() - 7 * weeksAway + 1);
      endDate.setDate(endDate.getDate() - 7 * (weeksRange >= 0 ? weeksRange : 0));

      const startDateDay =
        startDate.getDate() < 10 ? "0" + startDate.getDate() : startDate.getDate();
      const startDateMonth =
        startDate.getMonth() + 1 < 10
          ? "0" + (startDate.getMonth() + 1)
          : startDate.getMonth() + 1;
      const startDateYear = startDate.getFullYear();

      const endDateDay =
        endDate.getDate() < 10 ? "0" + endDate.getDate() : endDate.getDate();
      const endDateMonth =
        endDate.getMonth() + 1 < 10
          ? "0" + (endDate.getMonth() + 1)
          : endDate.getMonth() + 1;
      const endDateYear = endDate.getFullYear();

      return [
        `${startDateYear}-${startDateMonth}-${startDateDay}`,
        `${endDateYear}-${endDateMonth}-${endDateDay}`,
      ];
    },
    getMonthRange({ monthsAway = 1, monthsAmount = 1 } = {}) {
      let lastDayOfLastMonth = new Date();
      lastDayOfLastMonth.setDate(0);

      let monthsRange = monthsAway - monthsAmount;
      let startDate = new Date(lastDayOfLastMonth.getTime());
      startDate.setDate(1);
      let endDate = new Date(lastDayOfLastMonth.getTime());

      startDate.setMonth(startDate.getMonth() - monthsAway + 1);
      endDate.setMonth(endDate.getMonth() - (monthsRange >= 0 ? monthsRange : 0));

      const startDateDay =
        startDate.getDate() < 10 ? "0" + startDate.getDate() : startDate.getDate();
      const startDateMonth =
        startDate.getMonth() + 1 < 10
          ? "0" + (startDate.getMonth() + 1)
          : startDate.getMonth() + 1;
      const startDateYear = startDate.getFullYear();

      const endDateDay =
        endDate.getDate() < 10 ? "0" + endDate.getDate() : endDate.getDate();
      const endDateMonth =
        endDate.getMonth() + 1 < 10
          ? "0" + (endDate.getMonth() + 1)
          : endDate.getMonth() + 1;
      const endDateYear = endDate.getFullYear();

      return [
        `${startDateYear}-${startDateMonth}-${startDateDay}`,
        `${endDateYear}-${endDateMonth}-${endDateDay}`,
      ];
    },
    getYearRange({ yearsAway = 1, yearsAmount = 1 } = {}) {
      let lastDayOfLastYear = new Date();
      lastDayOfLastYear.setMonth(0);
      lastDayOfLastYear.setDate(0);

      let yearsRange = yearsAway - yearsAmount;
      let startDate = new Date(lastDayOfLastYear.getTime());
      startDate.setMonth(0);
      startDate.setDate(1);
      let endDate = new Date(lastDayOfLastYear.getTime());

      startDate.setFullYear(startDate.getFullYear() - yearsAway + 1);
      endDate.setFullYear(endDate.getFullYear() - (yearsRange >= 0 ? yearsRange : 0));

      const startDateDay =
        startDate.getDate() < 10 ? "0" + startDate.getDate() : startDate.getDate();
      const startDateMonth =
        startDate.getMonth() + 1 < 10
          ? "0" + (startDate.getMonth() + 1)
          : startDate.getMonth() + 1;
      const startDateYear = startDate.getFullYear();

      const endDateDay =
        endDate.getDate() < 10 ? "0" + endDate.getDate() : endDate.getDate();
      const endDateMonth =
        endDate.getMonth() + 1 < 10
          ? "0" + (endDate.getMonth() + 1)
          : endDate.getMonth() + 1;
      const endDateYear = endDate.getFullYear();

      return [
        `${startDateYear}-${startDateMonth}-${startDateDay}`,
        `${endDateYear}-${endDateMonth}-${endDateDay}`,
      ];
    },

    getSelectedDateFormatted(index = 0) {
      return this.filterDateRangeSelected
        ? this.filterDateRange[index].split("-").join("/")
        : null;
    },

    async resetCurrentDashboard() {
      this.chosenDashboard = null;
      this.currentDashboard = null;
      this.filterDateRange = [];
      this.startDate = "";
      this.finishDate = "";
      this.startDateFormatted = "";
      this.finishDateFormatted = "";
      this.dashboardDateRange = "custom";
      this.dashboardLayout[0].items = [];
      this.dashboardCreated = true;

      await this.fetchPanelByIdFormatted(18);
    },

    resetNewDashboard() {
      this.newDashboard.dialogVisible = false;
      this.newDashboard.name = "";
      this.newDashboard.targetEnterprise = null;
      this.newDashboard.targetUser = null;
    },

    getAvailableDashboards() {
      this.dashboardService
        .findAllAvailable()
        .then((response) => {
          this.availableDashboards = [
            {
              name: "Novo dashboard em branco",
              id: "Em Branco",
            },
            ...response,
          ];
          if (this.availableDashboards?.length > 0) {
            let foundInList = this.availableDashboards.filter(
              (data) =>
                data.id === this.chosenDashboard ||
                (data.id === this.getCurrentDashboard?.id &&
                  data.name === this.getCurrentDashboard?.name)
            )[0];

            this.chosenDashboard = foundInList
              ? foundInList.id
              : this.availableDashboards[1]?.id;

            this.loadDashboardPanels();
          }
        })
        .catch(this.errorHandler);
    },
    getAvailableProducts() {
      this.productService
        .getAvailableProducts()
        .then((response) => {
          this.availableProducts = response;
        })
        .catch(this.errorHandler);
    },

    async addPanelToDashboard(panels) {
      if (panels) {
        if (Array.isArray(panels) && panels?.length > 0) {
          this.showLoading = true;
          for (const panel of panels) {
            await this.fetchPanelByIdFormatted(panel.id);
          }
          this.showLoading = false;
        } else {
          await this.fetchPanelByIdFormatted(panels);
        }
      }
    },

    async fetchPanelByIdFormatted(panelId) {
      await this.panelService
        .findByIdFormatted(panelId)
        .then((panel) => {
          if (panel) {
            for (let lBreakpoint in this.dashboardLayout) {
              let uid = this.uuidService.generateCurrentMillisUUID();
              let y = 0;
              if (panelId !== 18) {
                y = 8;

                if (this.dashboardLayout[lBreakpoint]?.items) {
                  if (this.dashboardLayout[lBreakpoint].items?.length !== 0) {
                    y = y * this.dashboardLayout[lBreakpoint].items?.length;
                  }
                }
                setTimeout(() => {
                  this.downBottomPage();
                }, 200);
              } else {
                this.upTopPage();
              }

              this.dashboardLayout[lBreakpoint].items.push({
                id: uid,
                width: 20,
                height: 8,
                x: 0,
                y: y,
                type_base: panel.type_base,
                type_panel: panel.type_panel,
                data: {
                  id: uid,
                  ...panel.data,
                },
              });
            }
          }
        })
        .catch(this.errorHandler);
    },

    loadDashboardPanels() {
      this.dashboardCreated = false;
      this.$emit("clear-products-selected");
      if (this.chosenDashboard === "Em Branco" || !this.chosenDashboard) {
        this.resetCurrentDashboard();
      } else {
        this.getDashboardById();
      }
    },

    getDashboardById() {
      this.dashboardService
        .getById(this.chosenDashboard)
        .then((dashboard) => {
          this.currentDashboard = dashboard;
          this.dashboardDateRange = dashboard.filterDateRange;
          if (this.getIsDownloadMap) {
            const filterDateRangeArray = this.getFilterDateRange.split(" - ");
            this.filterDateRange = [
              filterDateRangeArray[0].split("/").reverse().join("-"),
              filterDateRangeArray[1].split("/").reverse().join("-"),
            ];
            this.startDate = this.filterDateRange[0].split("T")[0];
            this.finishDate = this.filterDateRange[1].split("T")[0];
          } else if (
            dashboard.filterDateRange == dateRangeEnum.CUSTOM &&
            dashboard.filterDateFrom !== null &&
            dashboard.filterDateTo !== null
          ) {
            this.filterDateRange = [
              this.currentDashboard.filterDateFrom,
              this.currentDashboard.filterDateTo,
            ];
            this.startDate = this.filterDateRange[0].split("T")[0];
            this.finishDate = this.filterDateRange[1].split("T")[0];
          } else {
            let dateRange =
              dashboard.filterDateRange != dateRangeEnum.CUSTOM
                ? dashboard.filterDateRange
                : dateRangeEnum.WEEK;
            this.dashboardDateRange = dateRange;
            this.filterDateRange = this.dateShortcuts[dateRange].range;
            this.startDate = this.filterDateRange[0].split("T")[0];
            this.finishDate = this.filterDateRange[1].split("T")[0];
          }

          this.dashboardLayout[0].items = dashboard.panels;
          this.newCurrentDashboardName = dashboard.name;
          this.dashboardCreated = true;
        })
        .catch(this.errorHandler);
    },

    saveNewDashboard() {
      if (
        this.newDashboard.name &&
        this.newDashboard.name != "" &&
        ((this.newDashboard.targetEnterprise &&
          this.newDashboard.targetUser &&
          this.newDashboard.targetUser?.length > 0) ||
          !this.userIsManager)
      ) {
        this.newDashboard.name = this.newDashboard.name.trim();

        if (this.dashboardLayout[0].items?.length === 0) {
          this.alertWarning({
            title: "É necessário ter pelo menos um painel no dashboard para salvar!",
          });
        } else {
          let enterpriseName = this.getEnterpriseName;
          let userId = this.getLoggedUserId;
          let users = [];
          let enterprises = [];
          let lastEnterprise = false;

          const panelsInDashboard = this.dashboardLayout[0].items.map(
            (panel) => panel.data.panelId
          );

          if (this.userIsManager) {
            if (this.newDashboard.targetEnterprise?.length > 1) {
              enterprises = this.newDashboard.targetEnterprise;
            } else {
              enterpriseName = this.newDashboard.targetEnterprise[0].dataBase;
            }
            users = this.newDashboard.targetUser;
          }

          const filterUserWithoutPanelReleased = users.filter((user) => {
            let containAtLeastOnePanel = [];
            if (user.panelIds?.length > 0) {
              containAtLeastOnePanel = user.panelIds.filter((panel) => {
                if (panelsInDashboard.includes(panel.panelId)) {
                  return panel;
                }
              });

              if (containAtLeastOnePanel) {
                return user;
              }
            }
          });

          const usersLength = filterUserWithoutPanelReleased?.length;
          const enterprisesLength = enterprises?.length;

          if (enterprisesLength > 0 && usersLength > 0) {
            let quantityEnterprises = enterprisesLength;

            enterprises.forEach((enterprise, index) => {
              let quantityUsers = usersLength;

              if (quantityEnterprises === index + 1) {
                lastEnterprise = true;
              }

              filterUserWithoutPanelReleased.forEach((user) => {
                const userHasAccessToEnterprise = user.enterprises.includes(
                  enterprise.dataBase
                );

                if (userHasAccessToEnterprise) {
                  this.saveNewDashboardService(
                    user.id,
                    enterprise.dataBase,
                    quantityUsers,
                    lastEnterprise
                  );
                }

                quantityUsers--;
              });
            });
          } else {
            if (usersLength > 0) {
              let quantityUsers = usersLength;

              filterUserWithoutPanelReleased.forEach((user) => {
                this.saveNewDashboardService(
                  user.id,
                  enterpriseName,
                  quantityUsers,
                  !lastEnterprise
                );
                quantityUsers--;
              });
            } else {
              this.saveNewDashboardService(
                userId,
                enterpriseName,
                usersLength,
                !lastEnterprise
              );
            }
          }
        }
      } else this.alertWarning({ title: "Preencha todos os campos!" });
    },
    saveNewDashboardService(userId, enterpriseName, quantityUsers, lastEnterprise) {
      this.dashboardService
        .save({
          name: this.newDashboard.name,
          filterDateFrom: this.getSelectedDateFormatted(0),
          filterDateTo: this.getSelectedDateFormatted(1),
          filterDateRange: this.dashboardDateRange,
          panels: this.dashboardLayout[0].items,
          userId: userId,
          enterpriseName: enterpriseName,
          createdBy: this.getLoggedUserId,
        })
        .then((savedDashboard) => {
          if (quantityUsers <= 1 && lastEnterprise) {
            this.getAvailableDashboards();
            this.loadDashboardPanels();

            this.newDashboard.name = "";
            this.newDashboard.dialogVisible = false;
            this.newDashboard.targetEnterprise = null;
            this.newDashboard.targetUser = null;

            this.alertSuccess({ text: "Dashboard salvo com sucesso!" });
          }
        })
        .catch(this.errorHandler);
    },
    saveCurrentDashboard() {
      const dateFrom = this.filterDateRangeSelected
        ? this.filterDateRange[0].split("-").join("/")
        : null;
      const dateTo = this.filterDateRangeSelected
        ? this.filterDateRange[1].split("-").join("/")
        : null;
      this.saveCurrentDashboardDialogVisible = false;
      this.currentDashboard.name = this.newCurrentDashboardName;
      this.currentDashboard.panels = this.dashboardLayout[0].items;
      this.currentDashboard.filterDateFrom = dateFrom;
      this.currentDashboard.filterDateTo = dateTo;
      this.currentDashboard.filterDateRange = this.dashboardDateRange;
      this.currentDashboard.updatedBy = this.getLoggedUserId;

      this.dashboardService
        .save(this.currentDashboard)
        .then(() => {
          this.getAvailableDashboards();
          this.alertSuccess({ text: "Dashboard atualizado com sucesso!" });
        })
        .catch(this.errorHandler);
    },
    deleteDashboard() {
      this.confirmationDialog({
        title: "Tem certeza que deseja apagar esse Dashboard?",
        text: "Se confirmar a exclusão esse Dashboard não poderá ser recuperado!",
        confirmButtonText: "Sim",
      }).then((result) => {
        if (result.isConfirmed) {
          this.currentDashboard.updatedBy = this.getLoggedUserId;
          this.currentDashboard.deleted = true;
          const removeDashboard = JSON.parse(JSON.stringify(this.currentDashboard));

          this.dashboardService
            .save(removeDashboard)
            .then(() => {
              this.resetCurrentDashboard();
              this.getAvailableDashboards();
              this.alertSuccess({ text: "Dashboard apagado com sucesso!" });
            })
            .catch(this.errorHandler);
        }
      });
    },

    async download() {
      const loading = (value) => (this.showLoading = value);
      const loadRouter = () => this.$router.go();
      const storeIsDownloadMap = async () => {
        await this.$store.dispatch("storeIsDownloadMap", true);
      };
      loading(true);
      const dashboard = {
        name: this.currentDashboard.name,
        dateRange: this.filterDateRange,
      };
      let elementDownloadData = document.querySelector("body");
      let mapSearchContainer = document.querySelector("#search-map-container");
      let className = "";
      if (mapSearchContainer) {
        mapSearchContainer.style.display = "none";
        className = mapSearchContainer.className;
        mapSearchContainer.className = "";
      }

      await this.buildImageToDownload(elementDownloadData);
      const imgTempToDownload = document.querySelector("#temp-image-to-download");

      html2canvas(imgTempToDownload, {
        allowTaint: false,
        logging: true,
        taintTest: false,
        useCORS: true,
      }).then(async function (canvas) {
        var d = canvas.toDataURL("image/png", 1.0);
        var anchar = document.createElement("a");
        anchar.href = d;
        anchar.setAttribute(
          "download",
          `${dashboard.name}_${dashboard.dateRange[0].slice(
            0,
            10
          )}_${dashboard.dateRange[1].slice(0, 10)}`
        );
        anchar.click();
        document.body.removeChild(imgTempToDownload);

        if (
          document.querySelector(".module-contacts-map") ||
          document.querySelector(".module-contacts-types-map") ||
          document.querySelector(".module-contacts-with-purchase-motivation") ||
          document.querySelector(".leaflet-container")
        ) {
          loadRouter();
          await storeIsDownloadMap();
        }

        if (mapSearchContainer) {
          mapSearchContainer.style.display = "inheric";
          mapSearchContainer.className = className;
        }
        loading(false);
      });
    },
    async buildImageToDownload() {
      const dataURL = await htmlToImage.toPng(document.querySelector("body"), {
        cacheBust: true,
        filter: (node) =>
          !node?.dataset?.html2canvasIgnore &&
          node !== document.querySelector(".loading"),
      });
      const imgElement = document.createElement("img");
      imgElement.id = "temp-image-to-download";
      imgElement.src = dataURL;
      document.body.appendChild(imgElement);
    },
    showElementOnScroll() {
      if (this.$route.name === "Dashboard") {
        let itemMenuFixed = document.querySelector(".item-menu-fixed");
        let itemScrollTop = document.querySelector(".item-scroll-top");

        let y = window.scrollY;
        if (y >= 200) {
          itemMenuFixed.className = "item-menu-fixed show";
        } else {
          itemMenuFixed.className = "item-menu-fixed hide";
        }

        if (y >= 500) {
          itemScrollTop.className = "item-scroll-top show";
        } else {
          itemScrollTop.className = "item-scroll-top hide";
        }
      }
    },
    upTopPage() {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: "smooth",
      });
    },
    downBottomPage() {
      window.scrollTo({
        left: 0,
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    },
    handleEditMode() {
      this.$store.dispatch("storeEditMode", !this.getEditMode);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("/");
      this.dashboardDateRange = DateRangeEnum.CUSTOM;
      return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`;
    },
  },
  watch: {
    getReloadDashboard() {
      if (this.getReloadDashboard) {
        this.resetCurrentDashboard();
        this.getAvailableDashboards();
        this.getAvailableProducts();
        this.$store.dispatch("storeReloadDashboard", false);
      }
    },
    filterDateRange() {
      this.$store.dispatch("storeFilterDateRange", this.formatFilterRangeDate);
    },
    currentDashboard() {
      this.$store.dispatch("storeCurrentDashboard", this.currentDashboard);
    },
    startDate(newStartDate, oldStartDate) {
      if (this.selectedDateRange) {
        this.dashboardDateRange = DateRangeEnum.CUSTOM;
      }
      this.startDateFormatted = this.formatDate(this.startDate);

      if (this.finishDate && this.finishDate?.length > 0) {
        const finish = new Date(this.finishDate);
        const start = new Date(newStartDate);

        if (finish < start) {
          this.finishDate = newStartDate;
        }
      }

      if (new Date(this.startDate) > new Date(this.finishDate)) {
        this.finishDate = newStartDate;
      }

      this.filterDateRange = [];
      this.filterDateRange[0] = newStartDate;
      this.filterDateRange[1] = this.finishDate;
    },
    finishDate(newFinishDate, oldFinishDate) {
      if (this.selectedDateRange) {
        this.dashboardDateRange = DateRangeEnum.CUSTOM;
      }
      this.finishDateFormatted = this.formatDate(this.finishDate);

      if (this.finishDate && this.finishDate?.length > 0) {
        const finish = new Date(newFinishDate);
        const start = new Date(this.startDate);

        if (finish < start) {
          this.finishDate = newFinishDate;
          this.startDate = newFinishDate;
        }
      }

      this.filterDateRange = [];
      this.filterDateRange[0] = this.startDate;
      this.filterDateRange[1] = newFinishDate;
    },
    $route(to, from) {
      if (
        from.name === "ManageAccessBI" &&
        to.name === "Dashboard" &&
        this.getClearTempValues
      ) {
        this.resetCurrentDashboard();
        this.getAvailableDashboards();
        this.getAvailableProducts();
        this.$store.dispatch("storeClearTempValues", false);
      }
    },
    top(val) {
      this.bottom = !val;
    },
    right(val) {
      this.left = !val;
    },
    bottom(val) {
      this.top = !val;
    },
    left(val) {
      this.right = !val;
    },
  },
  mounted() {
    this.$root.$on(
      "remove-panel",
      (({ moduleData }) => {
        this.dashboardLayout.forEach((dashboard) => {
          let moduleIndex = dashboard.items.findIndex(
            (item) => item.data.id === moduleData.id
          );

          if (moduleIndex > -1) {
            dashboard.items.splice(moduleIndex, 1);
          }
        });
      }).bind(this)
    );

    this.dateShortcuts = {
      [DateRangeEnum.CUSTOM]: {
        label: "Período Personalizado",
        range: "",
      },
      [DateRangeEnum.WEEK]: {
        label: "Última Semana",
        range: this.getWeekRange(),
      },
      [DateRangeEnum.TWO_WEEKS]: {
        label: "Últimas 2 semanas",
        range: this.getWeekRange({ weeksAway: 2, weeksAmount: 2 }),
      },
      [DateRangeEnum.MONTH]: {
        label: "Último Mês",
        range: this.getMonthRange(),
      },
      [DateRangeEnum.QUARTER]: {
        label: "Último Trimestre",
        range: this.getMonthRange({ monthsAway: 3, monthsAmount: 3 }),
      },
      [DateRangeEnum.SEMESTER]: {
        label: "Último Semestre",
        range: this.getMonthRange({ monthsAway: 6, monthsAmount: 6 }),
      },
      [DateRangeEnum.YEAR]: {
        label: "Último Ano",
        range: this.getYearRange(),
      },
    };

    this.pickerOptions.shortcuts = Object.entries(this.dateShortcuts).map(
      ([dateRange, item]) => {
        return {
          text: item.label,
          value: dateRange,
          range: item.range,
          disabled: dateRange === "custom" ? true : false,
        };
      }
    );

    this.getAvailableDashboards();
    this.getAvailableProducts();

    setTimeout(() => {
      window.addEventListener("scroll", this.showElementOnScroll);
    }, 1000);
  },
};
</script>

<style lang="scss">
.dashboard-layout {
  margin: 70px auto 0 auto;
  padding-right: 0.6rem;
}

.container-sub-header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: var(--smarts-white);
  min-width: 100%;
  top: 0;
  z-index: 2020;
  -webkit-box-shadow: 0px 10px 26px -15px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 26px -15px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 26px -15px rgba(0, 0, 0, 0.75);
  border-radius: 0 0 1rem 1rem;
}

.container-period {
  max-width: 42%;
  display: grid;
  gap: 1rem;
  grid-template-columns: 29% 29% 37%;
  grid-template-rows: 1fr;
  align-items: center;
}

.container-manage-dashboard {
  max-width: 16%;
  display: grid;
  align-items: center;
}

.input-group > .custom-file,
.input-group > .custom-select,
.input-group > .form-control,
.input-group > .form-control-plaintext {
  width: 80% !important;
}

.v-tooltip__content {
  opacity: 1 !important;
  background-color: #121d24 !important;
}

.el-picker-panel.has-sidebar {
  width: 789px !important;
}

.el-picker-panel.has-sidebar .el-picker-panel__sidebar {
  width: 145px !important;
}

.el-picker-panel.has-sidebar .el-picker-panel__body {
  margin-left: 145px !important;
}

.item-menu-fixed {
  z-index: 1000;
  position: fixed;
  bottom: 0;
  left: 0;
}

.item-scroll-top {
  width: 56px;
  height: 56px;
  border-radius: 100%;
  z-index: 1000;
  position: fixed;
  bottom: 10px;
  right: 5px;
  background-color: #121d24;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.hide {
  display: none;
  transition: 1s;
}

.show {
  display: flex;
  transition: 1s;
}

.labels {
  font-size: 0.8rem;
  color: var(--smarts-black);
  font-weight: bold;
}

.btn-custom-white {
  background-color: transparent !important;
  border-style: none !important;
  color: var(--smarts-black);
  font-weight: bold;
  transition-duration: 0.5s !important;
  font-size: 14px;
  transition-property: inherit !important;
  transition-timing-function: inherit !important;
  padding: 0;
  outline: var(--smarts-black);

  &:hover {
    background-color: var(--smarts-black) !important;
    color: var(--smarts-white) !important;
    height: 40px;
    padding: 0 1rem;
  }
}

#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}

.v-speed-dial--left,
.v-speed-dial--bottom {
  left: 5px !important;
  bottom: 5px !important;
}
</style>
