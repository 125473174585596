import router from '../../router'
import store from '../../store';

const responseErrorHandler = error => {
  let errorFromJson = (error?.response?.data) ? JSON.parse(error.response.data) : null;

  const err = {
    status: errorFromJson?.status || error?.response?.status || error?.status || 500,
    message: errorFromJson?.message || error?.response?.message || error?.message || 'Erro ao realizar requisição ao servidor',
  };
  
  if (err.status === 401 && router.currentRoute.matched[0].name != 'Public') {
    if((store.getters.userTemp && (store.getters.userTemp.id === store.getters.userId)) || store.getters.userTemp === null){
      localStorage.clear();  
      document.cookie = "PLAY_BISESSION=" + "" + ";expires=Thu, 01 Jan 1970 00:00:01 GMT" + "; path=/";
      document.cookie = "EXT=" + "" + ";expires=Thu, 01 Jan 1970 00:00:01 GMT" + "; path=/";
      window.location.href = "/erro";
    }
  }

  return Promise.reject(err);
}

export default responseErrorHandler;
