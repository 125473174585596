<template>
  <mobule-base-builder
    :module-data="moduleData"
    :editMode="editMode"
    :loading="loading"
    :error="error"
    :downloadImage="dataDownload"
    :tableHeaders="tableHeaders"
    :tableHeadersExcel="tableHeadersExcel"
    :tableItems="tableItems"
    :tableItemsExcel="tableItems"
    :typePanelPDF="'TABLE'"
  >
    <template v-slot:content>
      <div class="pa-3">
        <span v-if="!hasFilterDateRange"
          >***É necessário preencher os campos de "Período dos dados" para buscar as
          informações!***</span
        >
        <v-autocomplete
          v-model="selectedProduct"
          :items="getSelectedProducts"
          item-text="name"
          item-value="id"
          @change="getData()"
          id="report-logs-integration"
          label="Selecione o produto"
          class="px-4 pt-2"
          :disabled="!hasFilterDateRange"
          outlined
          dense
        ></v-autocomplete>
      </div>

      <module-table-list-data-v-2
        v-if="selectedProduct.length > 0 && tableItems.length > 0"
        :tableHeaders="tableHeaders"
        :tableItems="tableItems"
        class="report-logs-integration"
      />

      <message-status-data-unique-product
        v-else
        :selected-products="selectedProductsIds()"
        :infoLoading="loading"
      />
    </template>
  </mobule-base-builder>
</template>

<script>
import MixinModuleBase from "../../../mixins/MixinModuleBase";
import MobuleBaseBuilder from "../base/MobuleBaseBuilder.vue";
import ModuleTableListDataV2 from "../base/ModuleTableListDataV2.vue";
import MessageStatusDataUniqueProduct from "../../MessageStatusDataUniqueProduct.vue";

import ContactService from "../../../services/ContactService";
const contactService = ContactService.build();

import ProductService from "../../../services/ProductService";
const productService = ProductService.build();

export default {
  name: "module-report-logs-integration",
  mixins: [MixinModuleBase],
  components: {
    MobuleBaseBuilder,
    ModuleTableListDataV2,
    MessageStatusDataUniqueProduct,
  },
  data: function () {
    return {
      tableHeaders: [
        {
          text: "ID do Contato",
          align: "start",
          sortable: true,
          value: "contactId",
          tooltip: "ID do contato Extend",
          width: "300px",
        },
        {
          text: "Data do Contato",
          align: "start",
          sortable: true,
          value: "contact_created",
          tooltip: "Data da criação do contato no Extend",
          width: "300px",
        },
        {
          text: "Tipo de Contato",
          align: "start",
          sortable: true,
          value: "contactType",
          tooltip: "Tipo do contato no Extend",
          width: "300px",
        },
        {
          text: "Nome do Corretor",
          align: "start",
          sortable: true,
          value: "brokerName",
          tooltip: "Nome do corretor",
          width: "300px",
        },
        {
          text: "Email do Corretor",
          align: "start",
          sortable: true,
          value: "brokerEmail",
          tooltip: "Email do corretor",
          width: "300px",
        },
        {
          text: "ID Corretor CRM",
          align: "start",
          sortable: true,
          value: "brokerAccountIdCrm",
          tooltip: "ID do corretor no CRM",
          width: "300px",
        },
        {
          text: "Empresa de Vendas",
          align: "start",
          sortable: true,
          value: "salesCompany",
          tooltip: "Nome da empresa de vendas",
          width: "300px",
        },
        {
          text: "ID do Cliente",
          align: "start",
          sortable: true,
          value: "customerId",
          tooltip: "ID do cliente no Extend",
          width: "300px",
        },
        {
          text: "Nome do Cliente",
          align: "start",
          sortable: true,
          value: "name",
          tooltip: "Nome do cliente",
          width: "300px",
        },
        {
          text: "Email do Cliente",
          align: "start",
          sortable: true,
          value: "customer_email",
          tooltip: "Email do cliente",
          width: "300px",
        },
        {
          text: "Permite o Compartilhamento dos Dados Pessoais",
          align: "start",
          sortable: true,
          value: "personalDataAllowShare",
          tooltip: "Cliente permitiu compartilhar dados pessoais",
          width: "300px",
        },
        {
          text: "Integrado",
          align: "start",
          sortable: true,
          value: "integrated",
          tooltip: "Se houve sucesso na integração",
          width: "300px",
        },
        {
          text: "Detalhes Sobre o Status da Integração",
          align: "start",
          sortable: true,
          value: "detailsAboutIntegrationStatus",
          tooltip: "Detalhes sobre o status da integração",
          width: "300px",
        },
        {
          text: "Observações Sobre o Status da Integração",
          align: "start",
          sortable: true,
          value: "observationsAboutIntegrationStatus",
          tooltip: "Observações sobre o status da integração",
          width: "300px",
        },
        {
          text: "ID Contato do CRM",
          align: "start",
          sortable: true,
          value: "thirdPartyId",
          tooltip: "ID do contato do CRM integrado com o Extend",
          width: "300px",
        },
        {
          text: "Requisição",
          align: "start",
          sortable: true,
          value: "logHypnobox_payload",
          tooltip: "Requisição enviada",
          width: "300px",
        },
        {
          text: "Retorno",
          align: "start",
          sortable: true,
          value: "logHypnobox_log",
          tooltip: "Resposta da requisição",
          width: "300px",
        },
        {
          text: "Data de Envio da Visita",
          align: "start",
          sortable: true,
          value: "sendVisitMoment",
          tooltip: "Data do envio da visita para o CRM",
          width: "300px",
        },
      ],
      tableHeadersExcel: [
        {
          text: "Produto Selecionado",
          align: "start",
          sortable: true,
          value: "selectedProducts",
        },
        {
          text: "ID do Contato",
          align: "start",
          sortable: true,
          value: "contactId",
          tooltip: "ID do contato Extend",
        },
        {
          text: "Data do Contato",
          align: "start",
          sortable: true,
          value: "contact_created",
          tooltip: "Data da criação do contato no Extend",
        },
        {
          text: "Tipo de Contato",
          align: "start",
          sortable: true,
          value: "contactType",
          tooltip: "Tipo do contato no Extend",
        },
        {
          text: "Nome do Corretor",
          align: "start",
          sortable: true,
          value: "brokerName",
          tooltip: "Nome do corretor",
        },
        {
          text: "Email do Corretor",
          align: "start",
          sortable: true,
          value: "brokerEmail",
          tooltip: "Email do corretor",
        },
        {
          text: "ID Corretor CRM",
          align: "start",
          sortable: true,
          value: "brokerAccountIdCrm",
          tooltip: "ID do corretor no CRM",
        },
        {
          text: "Empresa de Vendas",
          align: "start",
          sortable: true,
          value: "salesCompany",
          tooltip: "Nome da empresa de vendas",
        },
        {
          text: "ID do Cliente",
          align: "start",
          sortable: true,
          value: "customerId",
          tooltip: "ID do cliente no Extend",
        },
        {
          text: "Nome do Cliente",
          align: "start",
          sortable: true,
          value: "name",
          tooltip: "Nome do cliente",
        },
        {
          text: "Email do Cliente",
          align: "start",
          sortable: true,
          value: "customer_email",
          tooltip: "Email do cliente",
        },
        {
          text: "Permite o Compartilhamento dos Dados Pessoais",
          align: "start",
          sortable: true,
          value: "personalDataAllowShare",
          tooltip: "Cliente permitiu compartilhar dados pessoais",
        },
        {
          text: "Integrado",
          align: "start",
          sortable: true,
          value: "integrated",
          tooltip: "Se houve sucesso na integração",
        },
        {
          text: "Detalhes Sobre o Status da Integração",
          align: "start",
          sortable: true,
          value: "detailsAboutIntegrationStatus",
          tooltip: "Detalhes sobre o status da integração",
        },
        {
          text: "Observações Sobre o Status da Integração",
          align: "start",
          sortable: true,
          value: "observationsAboutIntegrationStatus",
          tooltip: "Observações sobre o status da integração",
        },
        {
          text: "ID Contato do CRM",
          align: "start",
          sortable: true,
          value: "thirdPartyId",
          tooltip: "ID do contato do CRM integrado com o Extend",
        },
        {
          text: "Requisição",
          align: "start",
          sortable: true,
          value: "logHypnobox_payload",
          tooltip: "Requisição enviada",
        },
        {
          text: "Retorno",
          align: "start",
          sortable: true,
          value: "logHypnobox_log",
          tooltip: "Resposta da requisição",
        },
        {
          text: "Data de Envio da Visita",
          align: "start",
          sortable: true,
          value: "sendVisitMoment",
          tooltip: "Data do envio da visita para o CRM",
        },
      ],
      tableItems: [],
      tableItemsExcel: [],
      dataDownload: {
        class: "report-logs-integration",
        title: this.moduleData.headerTitle,
      },
      products: [],
      selectedProduct: [],
      legendObservationsAboutIntegrationStatus: {
        IC: "INTEGRADO CORRETAMENTE",
        CFE:
          "CONTATO DO TIPO FORA DO EXPEDIENTE NAO INTEGRA DEVIDO FALTA DE DADOS DO CLIENTE",
        ECO:
          "QUANDO É ATRIBUIDO PARA O CORRETOR O E-MAIL : outros@smartstaff.com, NAO INTEGRA SE O CORRETOR NAO ESTIVER NA BASE HYPNOBOX, PRECISA ESTAR NA BASE PARA ENVIAR O ID DO RESPONSAVEL(CORRETOR)",
        IE: "INSTABILIDADE EXTEND",
      },
    };
  },
  computed: {
    hasFilterDateRange() {
      return (
        this.filterDateRange &&
        this.filterDateRange.length == 2 &&
        this.filterDateRange[0].length > 1 &&
        this.filterDateRange[1].length > 1
      );
    },
    getSelectedProducts() {
      return this.$store.getters.selectedProducts;
    },
    getViewProducts() {
      return this.$store.getters.viewProducts;
    },
  },
  methods: {
    resetModule() {
      this.tableItems = [];
      this.tableItemsExcel = [];
    },
    formatDate(dateToFormat) {
      const date = new Date(dateToFormat);
      const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      const month =
        date.getMonth() < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      const hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      const minutes =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      const seconds =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return `${day}/${month}/${date.getFullYear()} ${
        hours + ":" + minutes + ":" + seconds
      }`;
    },
    async sanitize(listItem) {
      return Promise.all(
        listItem.map(async (item) => {
          item.contact_created = this.formatDate(item.contact_created);
          item.sendVisitMoment = this.formatDate(item.sendVisitMoment);
          item.integrated = item.integrated === 1 ? "SIM" : "NÃO";
          item.personalDataAllowShare = item.personalDataAllowShare === 1 ? "SIM" : "NÃO";
          item.observationsAboutIntegrationStatus = this.legendObservationsAboutIntegrationStatus[
            item.detailsAboutIntegrationStatus
          ];

          return item;
        })
      );
    },
    getData() {
      if (
        this.hasFilterDateRange &&
        this.selectedProduct &&
        this.selectedProduct !== "" &&
        this.selectedProduct > 0 &&
        (this.whichFunctionRequest === "" ||
          this.whichFunctionRequest === "filterDateRange")
      ) {
        this.loading = true;

        contactService
          .getIntegratedAndNonIntegratedContactsWithDetails({
            productId: this.selectedProduct,
            dateBegin: this.filterDateRange[0],
            dateEnd: this.filterDateRange[1],
          })
          .then(async (r) => {
            this.resetModule();
            this.tableItems = await this.sanitize(r);
            const product = this.getSelectedProducts.filter(
              (product) => product.id === this.selectedProduct
            );

            console.log(product[0]?.name);
            this.tableItems = this.tableItems.map((item, index) => {
              if (index === 0) {
                return {
                  selectedProducts: product[0]?.name,
                  ...item,
                };
              } else {
                return { selectedProducts: "", ...item };
              }
            });

            this.loading = false;
          })
          .catch(this.errorHandler);
      }
    },
    getProducts() {
      productService
        .getAvailableProductsWithTotalContacts(
          this.filterDateRange && this.filterDateRange.length == 2
            ? {
                dateBegin: this.filterDateRange[0],
                dateEnd: this.filterDateRange[1],
                choosen: this.getViewProducts,
              }
            : {}
        )
        .then((result) => {
          this.products = result;
        })
        .catch(this.errorHandler);
    },
  },
  mounted() {
    this.getProducts();
  },
  watch: {
    filterDateRange() {
      if (!this.hasFilterDateRange && this.selectedProduct) {
        this.resetModule();
      }
    },
    getViewProducts() {
      this.getProducts();
    },
  },
};
</script>

<style scoped>
.report-logs-integration {
  background-color: #fff;
  margin: 0 auto 1% auto;
  width: 98.1%;
  padding-bottom: 2%;
}

.w-100 {
  z-index: 999;
}

span {
  color: red;
  width: 15px;
}
</style>
