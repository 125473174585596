export default [
    {
        urlStandPin: require("../../../public/assets/images/standsPins/01-pin.png"),
        urlCustomerPin: require("../../../public/assets/images/customersPins/01-pin.png"),
        color: "#338EC7"
    },
    {
        urlStandPin: require("../../../public/assets/images/standsPins/02-pin.png"),
        urlCustomerPin: require("../../../public/assets/images/customersPins/02-pin.png"),
        color: "#E60023"
    },
    {
        urlStandPin: require("../../../public/assets/images/standsPins/03-pin.png"),
        urlCustomerPin: require("../../../public/assets/images/customersPins/03-pin.png"),
        color: "#EFE949"
    },
    {
        urlStandPin: require("../../../public/assets/images/standsPins/04-pin.png"),
        urlCustomerPin: require("../../../public/assets/images/customersPins/04-pin.png"),
        color: "#510C4A"
    },
    {
        urlStandPin: require("../../../public/assets/images/standsPins/05-pin.png"),
        urlCustomerPin: require("../../../public/assets/images/customersPins/05-pin.png"),
        color: "#1BAA57"
    },
    {
        urlStandPin: require("../../../public/assets/images/standsPins/06-pin.png"),
        urlCustomerPin: require("../../../public/assets/images/customersPins/06-pin.png"),
        color: "#F6449D"
    },
    {
        urlStandPin: require("../../../public/assets/images/standsPins/07-pin.png"),
        urlCustomerPin: require("../../../public/assets/images/customersPins/07-pin.png"),
        color: "#3301CC"
    },
    {
        urlStandPin: require("../../../public/assets/images/standsPins/08-pin.png"),
        urlCustomerPin: require("../../../public/assets/images/customersPins/08-pin.png"),
        color: "#A8862C"
    },
    {
        urlStandPin: require("../../../public/assets/images/standsPins/09-pin.png"),
        urlCustomerPin: require("../../../public/assets/images/customersPins/09-pin.png"),
        color: "#E25135"
    },
    {
        urlStandPin: require("../../../public/assets/images/standsPins/10-pin.png"),
        urlCustomerPin: require("../../../public/assets/images/customersPins/10-pin.png"),
        color: "#000000"
    },
    {
        urlStandPin: require("../../../public/assets/images/standsPins/11-pin.png"),
        urlCustomerPin: require("../../../public/assets/images/customersPins/11-pin.png"),
        color: "#583D10"
    },
    {
        urlStandPin: require("../../../public/assets/images/standsPins/12-pin.png"),
        urlCustomerPin: require("../../../public/assets/images/customersPins/12-pin.png"),
        color: "#F8FDCA"
    },
    {
        urlStandPin: require("../../../public/assets/images/standsPins/13-pin.png"),
        urlCustomerPin: require("../../../public/assets/images/customersPins/13-pin.png"),
        color: "#74FECF"
    },
    {
        urlStandPin: require("../../../public/assets/images/standsPins/14-pin.png"),
        urlCustomerPin: require("../../../public/assets/images/customersPins/14-pin.png"),
        color: "#5AEA18"
    },
    {
        urlStandPin: require("../../../public/assets/images/standsPins/15-pin.png"),
        urlCustomerPin: require("../../../public/assets/images/customersPins/15-pin.png"),
        color: "#5D6D7C"
    },
    {
        urlStandPin: require("../../../public/assets/images/standsPins/16-pin.png"),
        urlCustomerPin: require("../../../public/assets/images/customersPins/16-pin.png"),
        color: "#FA1C6E"
    },
    {
        urlStandPin: require("../../../public/assets/images/standsPins/17-pin.png"),
        urlCustomerPin: require("../../../public/assets/images/customersPins/17-pin.png"),
        color: "#AE91E5"
    },
    {
        urlStandPin: require("../../../public/assets/images/standsPins/18-pin.png"),
        urlCustomerPin: require("../../../public/assets/images/customersPins/18-pin.png"),
        color: "#FFFFFF"
    },
    {
        urlStandPin: require("../../../public/assets/images/standsPins/19-pin.png"),
        urlCustomerPin: require("../../../public/assets/images/customersPins/19-pin.png"),
        color: "#2F4554"
    },
    {
        urlStandPin: require("../../../public/assets/images/standsPins/20-pin.png"),
        urlCustomerPin: require("../../../public/assets/images/customersPins/20-pin.png"),
        color: "#22142b"
    },
];