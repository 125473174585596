<template>
  <div v-if="!infoLoading" :class="customStyle">
    <img
      :src="require(`../../public/assets/images/${img}.png`)"
      alt=""
      width="300"
      height="300"
    />
    <p class="text">{{ phrase }}</p>
  </div>
</template>

<script>
export default {
  name: "message-status-data",
  props: {
    selectedProducts: Array,
    infoLoading: Boolean,
    selectInPanel: {
      type: Object,
      default: null,
    },
    customStyle: {
      type: String,
      default: "container",
    },
  },
  data: function () {
    return {
      img: "select-data",
      phrase: "Selecione algum produto",
    };
  },
  watch: {
    selectedProducts(value) {
      if (value && value.length === 0) {
        this.img = "select-data";
        this.phrase = "Selecione algum produto";
      } else if (value && value.length > 0 && this.selectInPanel?.active) {
        this.img = "select-data";
        this.phrase = this.selectInPanel.message;
      } else {
        this.img = "no-data";
        this.phrase = "Nenhum dado encontrado";
      }
    },
  },
  mounted() {
    if (!this.selectedProducts) {
      this.img = "no-data";
      this.phrase = "Nenhum dado encontrado";
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  inset: 0;
  margin-top: 100px;
}

.container-message-status-data {
  position: absolute;
  top: 5%;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.text {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--smarts-black);
}
</style>
