<template>
  <div>
    <div class="container bg-white col-md-10 my-6">
      <div class="form-group col-12 row">
        <div class="input-group col-3">
          <legend>Gerenciar painéis</legend>
        </div>
      </div>

      <div class="form-group col-12 row">
        <div class="col-6">
          <template>
              <v-autocomplete
                v-model="idSelectedEnterprise"
                :items="enterprises"
                item-text="name"
                item-value="id"
                @change="getUsers()"
                label="Escolha uma empresa"
                id="selectEnterprise"
                class="ml-4"
                clearable
                outlined
              ></v-autocomplete>
          </template>
        </div>

        <div class="col-6">
          <template>
              <v-autocomplete
                v-model="idSelectedUser"
                :items="users"
                :item-text="item => `${item.name} ${item.last_name}`"
                item-value="id"
                @change="getUserPanels()"
                label="Escolha um usuário"
                id="selectUser"
                class="ml-4"
                :disabled="!selectedEnterprise"
                clearable
                outlined
              ></v-autocomplete>
          </template>
        </div>
      </div>

      <div class="p-3" v-if="viewListPanel">
        <div
          class="
            row row-cols-auto
            justify-content-between
            align-items-center
            mx-2
            menu
          "
        >
          <div class="col d-flex align-items-center mt-6">
            <div class="custom-control">
              <v-switch
                v-model="checkedAllPanels.checked"
                color="#000000"
                :label="`${checkedAllPanels.checked ? 'Desmarcar' : 'Marcar'} todos`"
                @change="handleSelectAllPanels"
              ></v-switch>
            </div>
          </div>

          <div class="col d-flex justify-content-end align-items-center">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon color="grey lighten-1"> fas fa-info-circle </v-icon>
                </v-btn>
              </template>
              <span
                >Só demonstra os painéis salvos do usuário antes de clicar em
                atualizar.<br />
                Após atualizar, sempre trará os painéis atualizados.</span
              >
            </v-tooltip>

            <button class="btn btn-custom-yellow" @click="getUserPanels">
              <i class="fas fa-eye"></i>
              Rever painéis atuais
            </button>
          </div>
        </div>

        <v-expansion-panels class="col col-12 mt-3" accordion hover>
          <v-expansion-panel v-for="(item, tagId) in panelsByTagId" :key="tagId">
            <v-expansion-panel-header ripple>
              <div class="d-flex align-items-center expansion-panel-header-content">
                <div class="col col-auto col-sm-2 col-md-1 p-0 m-0">
                  <v-badge
                    :content="item.tag.name"
                    :color="item.tag.color"
                    inline
                  ></v-badge>
                </div>

                <div class="col col-auto p-0 m-0">
                  <v-switch
                    v-model="checkedPanelsByTag[tagId].checked"
                    :color="item.tag.color"
                    @click.native.stop="handlePanelsByTag(tagId)"
                    :label="`${checkedPanelsByTag[tagId].checked ? 'Desmarcar' : 'Marcar'} Todos`"
                  ></v-switch>
                </div>
              </div>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <div class="col col-12 row m-0 p-0">
                <div
                  class="col-md-6 d-flex p-0"
                  v-for="panel in item.panels"
                  :key="panel.id"
                >
                  <div class="custom-control">
                    <v-switch
                      v-model="panel.checked"
                      color="#000000"
                      @change="managementPanel(panel.id, panel.tag.id)"
                      :disabled="panel.title === 'Produtos - listagem com todos'"
                      :label="panel.title"
                    ></v-switch>
                  </div>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <div class="input-group col-12 justify-content-center">
          <button
            class="btn btn-dark btn-lg btn-block col-6"
            @click="updateAccountPanel"
          >
            Atualizar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultMixin from "../../mixins/DefaultMixin";
import ManageMixin from "../../mixins/ManageMixin";

import PanelService from "../../services/PanelService";
import TagService from "../../services/TagService";
import UserService from "../../services/UserService";
import AccountPanelService from "../../services/AccountPanelService";
import DashboardService from "../../services/DashboardService";
import EnterpriseService from "../../services/EnterpriseService";

export default {
  name: "ManageAccountPanel",
  mixins: [DefaultMixin, ManageMixin],
  data() {
    return {
      panelService: PanelService.build(),
      tagService: TagService.build(),
      userService: UserService.build(),
      accountPanelService: AccountPanelService.build(),
      dashboardService: DashboardService.build(),
      enterpriseService: EnterpriseService.build(),

      panels: [],
      tags: [],
      users: [],
      listInsertPanels: [],
      listRemovePanels: [],
      listNamePanelRemoveDashboard: [],
      enterprises: [],
      selectedEnterprise: null,
      idSelectedEnterprise: null,
      selectedUser: null,
      idSelectedUser: null,
      viewListPanel: false,
      allSelectTitle: "Marcar Todos",
      panelsByTagId: {},
      checkedAllPanels: {
        panelIds: [],
        checked: false,
        updateChecked() {
          this.checked = this.panelIds.length > 0;
        },
        handle(panelId) {
          let i = this.panelIds.indexOf(panelId);

          if (i < 0) this.panelIds.push(panelId);
          else this.panelIds.splice(i, 1);

          this.updateChecked();
        },
      },
      checkedPanelsByTag: {},
      dropdownItems: [
        { title: "Gerenciar acessos externos", redirect: "ManageAccessBI" },
        { title: "Gerenciar painéis", redirect: "ManageAccountPanel" },
      ],
      offset: true,
    };
  },
  computed: {
    getUserIdLogin() {
      return Number(this.$store.getters.userId);
    },
    getUserSystemRole() {
      return this.$store.getters.userIsManager;
    },
  },
  methods: {
    resetCheckedPanels() {
      this.checkedAllPanels.panelIds = [];
      this.checkedAllPanels.updateChecked();

      Object.values(this.checkedPanelsByTag).forEach(item => {
        item.panelIds = [];
        item.updateChecked();
      });
    },
    getPanels() {
      this.panelService
        .findAll()
        .then((response) => {
          this.panels = response;
        })
        .catch(this.errorHandler);
    },
    getTags() {
      return this.tagService.getAll().then(response => {
        this.tags = response;

        response.forEach(tag => this.checkedPanelsByTag[tag.id] = {
          panelIds: [],
          checked: false,
          updateChecked() {
            this.checked = this.panelIds.length > 0;
          },
          handle(panelId) {
            let i = this.panelIds.indexOf(panelId);

            if (i < 0) this.panelIds.push(panelId);
            else this.panelIds.splice(i, 1);

            this.updateChecked();
          },
        });

      }).catch(this.errorHandler);
    },
    getUsers() {
      if (this.idSelectedEnterprise) {
        this.selectedEnterprise = this.enterprises.filter(
          (enterprise) => enterprise.id === this.idSelectedEnterprise
        )[0];

        this.viewListPanel = false;
        this.selectedUser = null;
        this.idSelectedUser = null;
        this.getPanels();
        this.getTags();

        this.userService
          .getUsersByEnterprise({
            enterprise: this.selectedEnterprise.dataBase,
          })
          .then((response) => {
            this.users = response;
          })
          .catch(this.errorHandler);
      }
    },
    getEnterprisesIsActiveBI() {
      this.enterpriseService
        .findAllEnterprisesIsActiveBI()
        .then((r) => {
          this.enterprises = r;
        })
        .catch(this.errorHandler);
    },
    async getUserPanels() {
      this.listInsertPanels = [];
      this.listRemovePanels = [];

      if (this.getUserIdSelect() > 0) {
        this.viewListPanel = true;
      } else {
        this.viewListPanel = false;
      }

      this.panelService
        .findAllAvailableWithAccountPanel(this.getUserIdSelect())
        .then((listUserPanels) => {
          if (listUserPanels.length > 0) {
            this.listInsertPanels = listUserPanels.map((panel) => {
              return {
                id: panel.account_panel[0].id,
                accountId: this.getUserIdSelect(),
                panelId: panel.id,
                createdBy: panel.account_panel[0].createdBy,
                updatedBy: this.getUserIdLogin,
                createdAt: panel.account_panel[0].createdAt,
                updatedAt: panel.account_panel[0].updatedAt,
              };
            });
          } else {
            const panelListProduct = this.panels.filter(
              (panel) => panel.title === "Produtos - listagem com todos"
            );

            if (panelListProduct.length > 0) {
              this.listInsertPanels.push({
                accountId: this.getUserIdSelect(),
                panelId: Number(panelListProduct[0].id),
                createdBy: this.getUserIdLogin,
                updatedBy: null,
              });
            }
          }

          this.resetCheckedPanels();

          this.panelsByTagId = this.panels.reduce((acc, panel) => {
            let panelClone = JSON.parse(JSON.stringify(panel));
            const validatePanel = this.listInsertPanels.filter(
              (accountPanel) => accountPanel.panelId === panel.id
            );
            panelClone.checked = validatePanel.length > 0 ? true : false;

            if (panelClone.checked && panelClone.title !== 'Produtos - listagem com todos') {
              this.checkedAllPanels.handle(panelClone.id);
              this.checkedPanelsByTag[panel.tag.id].handle(panelClone.id);
            }

            if (!acc[panel.tagId]) acc[panel.tagId] = {
              tag: panelClone.tag,
              panels: [],
            };
            acc[panel.tagId].panels.push(panelClone);

            return acc;
          }, {});
          
        }).catch(this.errorHandler);
    },
    managementPanel(panelId, tagId) {
      this.checkedAllPanels.handle(panelId);
      this.checkedPanelsByTag[tagId].handle(panelId);

      const hasPanel = this.listInsertPanels.filter(
        (data) => data.panelId === Number(panelId)
      );

      if (hasPanel.length > 0) {
        const index = this.listInsertPanels.indexOf(hasPanel[0]);
        this.listInsertPanels.splice(index, 1);
        if (hasPanel[0].hasOwnProperty("id"))
          this.listRemovePanels.push(hasPanel[0]);
      } else {
        const hasPanelInRemove = this.listRemovePanels.filter(
          (data) => data.panelId === Number(panelId)
        );
        let panel = null;
        if (hasPanelInRemove.length > 0) {
          const index = this.listRemovePanels.indexOf(hasPanelInRemove[0]);
          panel = this.listRemovePanels.splice(index, 1);
        }
        this.listInsertPanels.push(
          panel !== null
            ? panel[0]
            : {
                accountId: this.getUserIdSelect(),
                panelId: Number(panelId),
                createdBy: this.getUserIdLogin,
                updatedBy: null,
              }
        );
      }
    },
    getUserIdSelect() {
      return this.idSelectedUser;
    },
    updateAccountPanel() {
      this.confirmationDialog({
        text: "Ao confirmar, a permissão de acesso aos painéis serão atualizados!",
        footer:
          "*** Restringindo o acesso à painéis estes serão REMOVIDOS DE TODOS OS DASHBOARDS DO USUÁRIO ***",
      }).then((result) => {
        if (result.isConfirmed) {
          if (this.listRemovePanels.length > 0) {
            const removeList = JSON.parse(
              JSON.stringify(this.listRemovePanels)
            );

            this.accountPanelService
              .removeAccountPanel(removeList)
              .then(() => {
                this.deletePanelsInDashboard()
                  .then(() => {
                    this.saveAccountPanels();
                  })
                  .catch(this.errorHandler);

                this.listRemovePanels = [];
              })
              .catch(this.errorHandler);
          } else this.saveAccountPanels();
        }
      });
    },
    saveAccountPanels() {
      this.accountPanelService
        .saveAccountPanel(this.listInsertPanels)
        .then(() => {
          this.getUserPanels();

          this.alertSuccess({ title: "Atualizado permissões de usuário!" });
        })
        .catch(this.errorHandler);
    },
    handleSelectAllPanels() {
      let newValue = this.checkedAllPanels.checked === true;

      Object.values(this.panelsByTagId).forEach(
        ({ panels }) => Object.values(panels).forEach(panel => {
          if (panel.title !== "Produtos - listagem com todos" && panel.checked !== newValue) {
            this.managementPanel(panel.id, panel.tag.id);
            panel.checked = newValue;
          }
        }),
      );
    },
    handlePanelsByTag(tagId) {
      let newValue = this.checkedPanelsByTag[tagId].checked === true;

      Object.values(this.panelsByTagId[tagId].panels).forEach(panel => {
        if (panel.tag.id === tagId && panel.title !== "Produtos - listagem com todos" && panel.checked !== newValue) {
          this.managementPanel(panel.id, panel.tag.id);
          panel.checked = newValue;
        }
      });
    },
    backDashboard() {
      this.$router.push({ path: "/dashboard" });
    },
    async deletePanelsInDashboard() {
      this.listNamePanelRemoveDashboard = [];
      let user = {}

      for (const accountPanel of this.listRemovePanels) {
        const panelRemove = this.panels.filter(
          (p) => p.id === accountPanel.panelId
        );
        if (panelRemove.length > 0) {
          this.listNamePanelRemoveDashboard.push(panelRemove[0]);
          user = { accountId: accountPanel.accountId,  enterpriseName: this.selectedEnterprise.dataBase}
        }
      }

      return await this.dashboardService
        .deletePanelsDeniedInDashboard(this.listNamePanelRemoveDashboard, user)
        .catch(this.errorHandler);
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.getters.userIsManager) next();
      else next({ path: "/dashboard" });
    });
  },
  async mounted() {
    await this.inactiveEnterpriseBI();
    this.getPanels();
    this.getTags();
    this.getEnterprisesIsActiveBI();
  },
  watch: {
    idSelectedEnterprise() {
      if(!this.idSelectedEnterprise) {
        this.viewListPanel = false;
        this.idSelectedUser = null;
        this.users = [];
        this.selectedEnterprise = null;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  -webkit-box-shadow: 5px 5px 14px -8px #000000;
  box-shadow: 5px 5px 14px -8px #000000;
  border-radius: 5px;
  border: 1px solid #ccc;
  border-radius: 2rem !important;
}

.menu {
  border-radius: 5px;
  border: 1px solid #ccc;
}

.expansion-panel-header-content {
  gap: 1rem;
}
</style>
