import Rest from './base/Rest'

/**
 * @typedef {LoginService}
 */
export default class LoginService extends Rest {
    /**
     * @type {String}
     */
    static resource = '/auth'


    /**
     * @param {Object} parameters
     * @returns {*|PromiseLike<T | never>|Promise<T | never>}
     */
    login (params = {}) {
        return this.post(`/login`, params);
    }

    generateJWT (params = {}){
        return this.post(`/generateJWT`, params);
    }

    generateJWTByDatabase (params = {}){
        return this.post(`/generateJWTByDatabase`, params);
    }

    sendResetPasswordEmail(email) {
      return this.get(`/sendResetPasswordEmail/${email}`);
    }
}