import TelephonyRest from './telephony/TelephonyRest';

/**
 * @typedef {TelephonyService}
 */
export default class TelephonyService extends TelephonyRest {
  /**
   * @type {String}
   */
  static resource = '/';

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  getReportCalls (idEnterpriseTel, dateBegin, dateEnd) {
    return this.get(`${idEnterpriseTel}?date_ini=${dateBegin}&date_end=${dateEnd}`)
  }

}
