<template>
  <MobuleBaseBuilder
    :module-data="moduleData"
    ref="tableParent"
    :editMode="editMode"
    :loading="loading"
    :downloadImage="dataDownload"
    :tableHeaders="tableHeaders"
    :tableHeadersExcel="tableHeaders"
    :tableItems="tableItems"
    :typePanelPDF="typePanelPDF"
  >
    <template v-slot:content>
      <v-app id="table" ref="tableApp" class="products">
        <!-- <v-layout v-resize="updateDataTableHeight"> -->
        <div class="echarts">
          <v-layout class="products">
            <v-data-table
              :headers="tableHeaders"
              :items="tableItems"
              :show-select="canSelectRows"
              :items-per-page="itemsPerPage"
              :footer-props="footerProps"
              class="elevation-1"
              ref="dataTable"
              v-model="selectedRows"
              @input="changedSelectedRows"
            >
              <template
                v-for="h in tableHeadersWithTootip"
                v-slot:[`header.${h.value}`]="{ tableHeadersWithTootip }"
              >
                <v-tooltip bottom :key="h.text">
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{ h.text }}</span>
                  </template>

                  <span>{{ h.tooltip }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-layout>
        </div>
      </v-app>
    </template>
  </MobuleBaseBuilder>
</template>

<script>
import MobuleBaseBuilder from "./MobuleBaseBuilder.vue";

export default {
  name: "module-table-list-data",
  components: {
    MobuleBaseBuilder,
  },
  props: {
    moduleData: Object,
    editMode: {
      type: Boolean,
      default: false,
    },
    loading: {
      default: false,
    },
    error: {
      default: null,
    },
    tableHeaders: {
      type: Array,
      default: () => [],
    },
    tableItems: {
      type: Array,
      default: () => [],
    },
    canSelectRows: {
      type: Boolean,
      default: false,
    },
    typePanelPDF: {
      type: String,
      default: "",
    },
  },
  data: function () {
    return {
      selectedRows: [],
      itemsPerPage: 100,
      optionsLength: 6,
      footerProps: {
        "items-per-page-options": [5, 10, 15, 30, 50, 100],
        "items-per-page-text": "Produtos por página",
      },
      dataDownload: { class: "products", title: this.moduleData.headerTitle },
    };
  },
  computed: {
    tableHeadersWithTootip() {
      return this.tableHeaders
        ? this.tableHeaders.filter((header) => header.tooltip)
        : [];
    },
    getViewProducts() {
      return this.$store.getters.viewProducts;
    },
    getSelectedProducts() {
      return this.$store.getters.selectedProducts;
    },
    getIsDownloadMap() {
      return this.$store.getters.isDownloadMap;
    },
  },
  methods: {
    /* updateDataTableHeight() {
      let tableHeight = this.$refs.dataTable.$el.offsetHeight;
      let headerHeight = this.$refs.dataTable.$children[0].$children[0].$children[0].$el.clientHeight;
      let offsetTop = this.$refs.tableApp.$el.offsetTop;

      if(!offsetTop || offsetTop < 0) {
        offsetTop = 0;
      }

      this.itemsPerPage = parseInt((tableHeight - headerHeight - offsetTop) / 50) - 1;
      if(this.itemsPerPage <= 0){
        this.itemsPerPage = 1;
      }
      if (this.tableItems.length && this.tableItems.length < this.itemsPerPage){
        this.itemsPerPage = this.tableItems.length;
      }

      if (!this.footerProps['items-per-page-options'].includes(this.itemsPerPage)) {
        if (this.footerProps['items-per-page-options'].length == this.optionsLength) {
          this.footerProps['items-per-page-options'].unshift(this.itemsPerPage);

        } else {
          this.footerProps['items-per-page-options'].shift();
          this.footerProps['items-per-page-options'].unshift(this.itemsPerPage);
        }
      }
    }, */

    changedSelectedRows() {
      this.$emit("selected-rows", this.selectedRows);
    },
  },
  watch: {
    getViewProducts() {
      this.selectedRows = [];
    },
    tableItems() {
      this.selectedRows = this.tableItems;
      this.changedSelectedRows();
    },
  },
  async mounted() {
    if (this.getSelectedProducts && this.getIsDownloadMap) {
      this.selectedRows = this.getSelectedProducts;
      await this.$store.dispatch("storeIsDownloadMap", false);
    }
  },
};
</script>

<style lang="scss" scoped>
.v-application {
  position: relative;
  min-height: 100%;
  max-height: 100%;

  .v-application--wrap {
    max-height: 100%;
    min-height: 100%;

    .v-data-table {
      width: 100%;
    }
  }

  #table {
    width: 97%;
    max-height: 100%;
    margin: 0 auto;
  }
}

.products {
  background-color: #fff;
}

.echarts {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
