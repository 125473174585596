import Vue from 'vue';
import Vuex from 'vuex';
import CryptoService from '../services/CryptoService';

const cryptoService = new CryptoService();

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userId: 0,
    userIsManager: false,
    account: null,
    enterprise: "",
    jwtTemp: null,
    jwtTempSendReports: null,
    userTemp: null,
    enterpriseName: null,
    enterpriseLogo: null,
    multipleEnterprise: [],
    selectedProducts: [],
    filterDateRange: "",
    clearTempValues: false,
    fixedLoginDatas: {
      enterprise: null,
      enterpriseName: null,
      enterpriseLogo: null,
    },
    viewProducts: "activies",
    currentDashboard: null,
    isDownloadMap: false,
    userPanelsById: null,
    editMode: true,
    reloadDashboard: false
  },
  getters: {
    userId: (state) => state.userId,
    userIsManager: (state) =>
      state.account &&
      ["ADMIN", "DESENVOLVEDOR", "ADMINISTRADOR"].includes(
        state.account.systemRole
      ),
    account: (state) => state.account,
    enterprise: (state) => state.enterprise,
    jwtTemp: (state) => state.jwtTemp,
    jwtTempSendReports: (state) => state.jwtTempSendReports,
    userTemp: (state) => state.userTemp,
    enterpriseName: (state) => state.enterpriseName,
    enterpriseLogo: (state) => state.enterpriseLogo,
    multipleEnterprise: (state) => state.multipleEnterprise,
    selectedProducts: (state) => state.selectedProducts,
    filterDateRange: (state) => state.filterDateRange,
    clearTempValues: (state) => state.clearTempValues,
    fixedLoginDatas: (state) => state.fixedLoginDatas,
    viewProducts: (state) => state.viewProducts,
    currentDashboard: (state) => state.currentDashboard,
    isDownloadMap: (state) => state.isDownloadMap,
    editMode: (state) => state.editMode,
    reloadDashboard: (state) => state.reloadDashboard
  },
  mutations: {
    initialiseStore(state) {
      const lsHash = localStorage.getItem("store");
      if (lsHash) {
        const lsDecrypt = cryptoService.decodeCrypto(lsHash);
        const ls = JSON.parse(lsDecrypt);
        const listValidProp = [
          "userId",
          "enterprise",
          "enterpriseName",
          "enterpriseLogo",
          "currentDashboard",
          "selectedProducts",
          "viewProducts",
          "filterDateRange",
          "isDownloadMap",
        ];
        const validateLocalStorage = Object.keys(ls).filter((key) => {
          const isValidKey = listValidProp.includes(key);
          if (!isValidKey) {
            return key;
          }
        });
        if (ls && validateLocalStorage.length === 0) {
          // Replace the state object with the stored item
          this.replaceState(Object.assign(state, ls));
        } else {
          localStorage.clear();
          document.cookie =
            "PLAY_BISESSION=" +
            "" +
            ";expires=Thu, 01 Jan 1970 00:00:01 GMT" +
            "; path=/";
          document.cookie =
            "EXT=" + "" + ";expires=Thu, 01 Jan 1970 00:00:01 GMT" + "; path=/";
          window.location.href = "/erro";
        }
      }
    },
    changeUserId(state, userId) {
      state.userId = userId;
    },
    changeUserIsManager(state, userIsManager) {
      state.userIsManager = [
        "ADMIN",
        "DESENVOLVEDOR",
        "ADMINISTRADOR",
      ].includes(userIsManager);
    },
    changeAccount(state, account) {
      state.account = account;
      state.userId = account.id;
    },
    changeEnterprise(state, enterprise) {
      state.enterprise = enterprise;
    },
    changeJwtTemp(state, jwtTemp) {
      state.jwtTemp = jwtTemp;
    },
    changeJwtTempSendReports(state, jwtTempSendReports) {
      state.jwtTempSendReports = jwtTempSendReports;
    },
    changeUserTemp(state, userTemp) {
      state.userTemp = userTemp;
    },
    changeEnterpriseName(state, enterpriseName) {
      state.enterpriseName = enterpriseName;
    },
    changeEnterpriseLogo(state, enterpriseLogo) {
      state.enterpriseLogo = enterpriseLogo;
    },
    changeMultipleEnterprise(state, multipleEnterprise) {
      state.multipleEnterprise = multipleEnterprise;
    },
    changeSelectedProducts(state, selectedProducts) {
      state.selectedProducts = selectedProducts;
    },
    changeFilterDateRange(state, filterDateRange) {
      state.filterDateRange = filterDateRange;
    },
    changeClearTempValues(state, clearTempValues) {
      state.clearTempValues = clearTempValues;
    },
    changeFixedLoginDatas(state, fixedLoginDatas) {
      state.fixedLoginDatas = fixedLoginDatas;
    },
    changeViewProducts(state, viewProducts) {
      state.viewProducts = viewProducts;
    },
    changeCurrentDashboard(state, currentDashboard) {
      state.currentDashboard = currentDashboard;
    },
    changeIsDownloadMap(state, isDownloadMap) {
      state.isDownloadMap = isDownloadMap;
    },
    changeUserPanelsById(state, newUserPanelsById) {
      state.userPanelsById = newUserPanelsById;
    },
    changeEditMode(state, editMode) {
      state.editMode = editMode;
    },
    changeReloadDashboard(state, reloadDashboard){
      state.reloadDashboard = reloadDashboard;
    }
  },
  actions: {
    storeUserId(context, userId) {
      context.commit("changeUserId", userId);
    },
    storeUserIsManager(context, userIsManager) {
      context.commit("changeUserIsManager", userIsManager);
    },
    storeAccount(context, account) {
      context.commit("changeAccount", account);
    },
    storeEnterprise(context, enterprise) {
      context.commit("changeEnterprise", enterprise);
    },
    storeJwtTemp(context, jwtTemp) {
      context.commit("changeJwtTemp", jwtTemp);
    },
    storeJwtTempSendReports(context, jwtTempSendReports) {
      context.commit("changeJwtTempSendReports", jwtTempSendReports);
    },
    storeUserTemp(context, userTemp) {
      context.commit("changeUserTemp", userTemp);
    },
    storeEnterpriseName(context, enterpriseName) {
      context.commit("changeEnterpriseName", enterpriseName);
    },
    storeEnterpriseLogo(context, enterpriseLogo) {
      context.commit("changeEnterpriseLogo", enterpriseLogo);
    },
    storeMultipleEnterprise(context, multipleEnterprise) {
      context.commit("changeMultipleEnterprise", multipleEnterprise);
    },
    storeSelectedProducts(context, selectedProducts) {
      context.commit("changeSelectedProducts", selectedProducts);
    },
    storeFilterDateRange(context, filterDateRange) {
      context.commit("changeFilterDateRange", filterDateRange);
    },
    storeClearTempValues(context, clearTempValues) {
      context.commit("changeClearTempValues", clearTempValues);
    },
    storeFixedLoginDatas(context, fixedLoginDatas) {
      context.commit("changeFixedLoginDatas", fixedLoginDatas);
    },
    storeViewProducts(context, viewProducts) {
      context.commit("changeViewProducts", viewProducts);
    },
    storeCurrentDashboard(context, currentDashboard) {
      context.commit("changeCurrentDashboard", currentDashboard);
    },
    storeIsDownloadMap(context, isDownloadMap) {
      context.commit("changeIsDownloadMap", isDownloadMap);
    },
    storeEditMode(context, editMode) {
      context.commit("changeEditMode", editMode);
    },
    storeReloadDashboard(context, reloadDashboard){
      context.commit("changeReloadDashboard", reloadDashboard);
    }
  },
});
