<template>
  <gmap-map
    :center="centerMap"
    :zoom="zoom"
    ref="gmap"
    style="width: 100%; height: 100%"
    :options="options"
  >
    <gmap-info-window
      :options="infoOptions"
      :position="infoWindowPos"
      :opened="infoWinOpen"
      @closeclick="infoWinOpen = false"
    >
    </gmap-info-window>

    <gmap-marker
      v-for="lc in infoWindowCircle"
      :key="lc.id"
      :position="lc.position"
      :clickable="false"
      :draggable="true"
      :icon="lc.image"
      :visible="infoWindowCircleView"
    >
    </gmap-marker>

    <gmap-circle
      v-for="circle in localCircles"
      :key="circle.circle.id"
      :editable="editable"
      :draggable="draggable"
      :radius="circle.circle.radius"
      :center="circle.circle.center"
      :circleCenter="circle.circle.circleCenter"
      :clickable="true"
      :visible="viewCircle"
      :options="{
        strokeColor: circle.circle.color,
        strokeOpacity: 1,
        strokeWeight: 4,
        fillColor: circle.circle.color,
        fillOpacity: 0,
      }"
      ref="circle"
    >
    </gmap-circle>

    <div v-if="viewPins">
      <gmap-marker
        v-for="(l, index) in locations"
        :key="l.id"
        :position="l.position"
        :clickable="true"
        :draggable="false"
        @click="toggleStandInfoWindow(l, index), (center = l.position)"
        :icon="l.image"
        :label="l.label"
      >
      </gmap-marker>
    </div>

    <gmap-cluster v-if="viewCluster">
      <gmap-marker
        v-for="(l, index) in locations"
        :key="l.id"
        :position="l.position"
        :clickable="true"
        :draggable="false"
        @click="toggleStandInfoWindow(l, index), (center = l.position)"
        :icon="l.image"
        :label="l.label"
      >
      </gmap-marker>
    </gmap-cluster>

    <gmap-marker
      v-for="(sl, index) in standsLocations"
      :key="index"
      :position="sl.position"
      :clickable="true"
      :draggable="false"
      @click="toggleStandInfoWindow(sl, index), (center = sl.position)"
      :icon="sl.image"
      :title="sl.standName"
    >
    </gmap-marker>

    <gmap-heatmap-layer
      :data="heatMapMarkers"
      :options="heatmapLayerOptions"
      v-if="viewHeatMap"
    />
  </gmap-map>
</template>

<script>
import * as GmapVue from "gmap-vue";
import { getGoogleMapsAPI } from "gmap-vue";
import circlesKMLegends from "../../../utils/maps/circlesKMLegends";

export default {
  name: "module-google-map",
  components: { GmapVue },
  data() {
    return {
      centerMap: { lat: -23.624963, lng: -46.580415 },
      zoom: 8,
      options: {
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: true,
        fullscreenControl: true,
        disableDefaultUi: false,
        minZoom: 3,
      },
      editable: false,
      draggable: false,
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        content: "",
        pixelOffset: {
          width: 0,
          height: -40,
        },
      },
      infoWindowCircle: [],
      infoWindowCircleView: false,
      heatMapMarkers: [],
      heatmapLayerOptions: { maxIntensity: 120, dissipating: true },
      localCircles: [],
      viewCircle: false,
    };
  },
  props: {
    locations: {
      type: Array,
      default: function () {
        return [];
      },
    },
    heatMapLocations: {
      type: Array,
      default: function () {
        return [];
      },
    },
    standsLocations: {
      type: Array,
      default: function () {
        return [];
      },
    },
    circles: {
      type: Array,
      default: function () {
        return [];
      },
    },
    viewCluster: {
      type: Boolean,
    },
    viewHeatMap: {
      type: Boolean,
      default: false,
    },
    viewPins: {
      type: Boolean,
    },
    viewRadiusCircle: {
      type: Boolean,
    },
    viewLegendRadiusCircle: {
      type: Boolean,
    },
    qtdRadiusCircle: {
      type: Number,
    },
  },
  computed: {
    google() {
      return getGoogleMapsAPI();
    },
    getSelectedProducts() {
      return this.$store.getters.selectedProducts;
    },
    markers() {
      if (this.google && this.heatMapLocations.length > 0) {
        return this.heatMapLocations.map((location) => {
          return {
            location: new this.google.maps.LatLng({
              lat: Number(location.lat),
              lng: Number(location.lng),
            }),
            weight: 120,
          };
        });
      }
      return [];
    },
  },
  methods: {
    toggleStandInfoWindow: function (marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoOptions.content = marker.infoText;
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      } else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    toggleCircleInfoWindow: function () {
      this.infoWindowCircle = [];
      let circleComponent = this.$refs.circle;

      if (circleComponent !== null && circleComponent.length > 0) {
        circleComponent.forEach((circle, index) => {
          let circleTemp = new this.google.maps.Circle({
            center: new this.google.maps.LatLng(circle.center.lat, circle.center.lng),
            radius: circle.radius,
          });

          let centerLat = circleTemp.getCenter().lat();
          let rightEdgeLng = circleTemp.getBounds().getNorthEast().lng();

          const legend = Number(String(circle.radius).replace("000", "")) - 1;
          let customIcon = {
            url: circlesKMLegends[legend].urlCircleKMLegend, // url
            scaledSize: new this.google.maps.Size(42, 42), // scaled size
          };

          const infoWindowCircleLocal = {
            id: Math.random() + index,
            options: {
              pixelOffset: {
                width: 0,
                height: 0,
              },
            },
            position: {
              lat: centerLat,
              lng: rightEdgeLng,
            },
            image: customIcon,
          };

          this.infoWindowCircle.push(infoWindowCircleLocal);
        });

        this.infoWindowCircleView = true;
      }
    },
    handlerHeatMapMakers() {
      if (this.heatMapLocations.length !== this.heatMapMarkers.length) {
        this.heatMapMarkers = [];
        this.heatMapMarkers = this.markers;
      }
    },
  },
  watch: {
    standsLocations() {
      this.infoWinOpen = false;
    },
    qtdRadiusCircle() {
      this.infoWindowCircle = [];
      this.infoWindowCircleView = false;
      this.localCircles = [];
      const allCircles = [];
      allCircles.push(...this.circles);
      this.viewCircle = false;
      let listTemp2 = [];

      if (this.getSelectedProducts.length > 0) {
        for (const product of this.getSelectedProducts) {
          let local = allCircles.filter((item) => {
            return item.circle.id.split("-")[0] == product.id;
          });

          let listTemp = [];

          if (this.qtdRadiusCircle > 1) {
            const totalCircles = 21;
            if (this.qtdRadiusCircle === totalCircles) {
              this.localCircles = [];
              this.localCircles.push(...this.circles);
            } else {
              local.splice(this.qtdRadiusCircle - 1, totalCircles - this.qtdRadiusCircle);
              listTemp.push(...local);
            }

            listTemp2.push(...listTemp);
            this.viewCircle = true;
          } else {
            this.viewCircle = false;
          }
        }
        this.localCircles.push(...listTemp2);
      }
    },
    viewLegendRadiusCircle() {
      if (this.infoWindowCircleView) {
        this.infoWindowCircleView = false;
      } else {
        this.toggleCircleInfoWindow();
      }
    },
    localCircles() {
      this.infoWindowCircleView = false;
    },
    viewHeatMap: {
      handler(newValue, oldValue) {
        if (newValue) this.handlerHeatMapMakers();
      },
      immediate: true,
    },
    heatMapLocations() {
      this.handlerHeatMapMakers();
    },
    deep: true,
  },
};
</script>

<style>
/*.gm-ui-hover-effect {
  display: none !important;
}

.gm-style-iw-d {
  overflow: hidden !important;
}

.gm-style-iw-d div {
  min-width: 100% !important;
  min-height: 100% !important;
}

.gm-style-iw-d div div {
  margin: 0 5px 10px -5px !important;
}

.gm-style-iw-d div div span {
  margin-bottom: 5px !important;
}*/
</style>
