var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"echarts"},[(_vm.showFilters)?_c('div',{staticClass:"filters"},_vm._l((_vm.labels),function(label,index){return _c('div',{key:index,staticClass:"filter"},[_c('div',{staticClass:"button-filter",style:({
          backgroundColor:
            _vm.chartBarDataFilter.length > 0 &&
            _vm.chartBarDataFilter[index].itemStyle.color
              ? _vm.chartBarDataFilter[index].itemStyle.color
              : '#c23531',
        }),on:{"click":_vm.filterChange}}),_c('span',{staticClass:"text-filter"},[_vm._v(_vm._s(label))])])}),0):_vm._e(),(_vm.data != null)?_c('v-chart',{ref:"doughnut",attrs:{"options":_vm.chartData,"init-options":{ renderer: _vm.renderer },"autoresize":""}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }