<template>
  <mobule-base-builder
    :module-data="moduleData"
    :editMode="editMode"
    :loading="loading"
    :error="error"
    :downloadImage="dataDownload"
    :tableHeaders="tableHeadersWithQuestionsExcel"
    :tableHeadersExcel="tableHeadersWithQuestionsExcel"
    :tableItems="tableItems"
    :tableItemsExcel="tableItems"
    :typePanelPDF="'TABLE'"
  >
    <template v-slot:content>
      <div class="pa-3" v-if="questionnaires.length > 0">
        <v-autocomplete
          v-model="selectedQuestionnaire"
          :items="questionnaires"
          item-text="title"
          item-value="id"
          @change="buildTableHeaderWithQuestions()"
          id="customers-with-questionnaire-and-last-contact"
          label="Selecione o questionário"
          class="px-4 pt-2"
          :disabled="!hasFilterDateRange"
          outlined
          dense
        ></v-autocomplete>
      </div>

      <module-table-list-data-v2
        v-if="tableItems.length > 0"
        :tableHeaders="tableHeadersWithQuestions"
        :tableItems="tableItems"
        class="customers-with-questionnaire-and-last-contact"
      />
      <message-status-data
        v-else
        :selected-products="selectedProductsIds()"
        :infoLoading="loading"
      />
    </template>
  </mobule-base-builder>
</template>

<script>
import MixinModuleBase from "../../../mixins/MixinModuleBase";

import MobuleBaseBuilder from "../base/MobuleBaseBuilder.vue";
import ModuleChartBar from "../base/ModuleChartBar.vue";
import ModuleChartDoughnut from "../base/ModuleChartDoughnut.vue";
import ModuleTableListDataV2 from "../base/ModuleTableListDataV2.vue";
import MessageStatusData from "../../MessageStatusData.vue";

import CustomerService from "../../../services/CustomerService";
const customerService = CustomerService.build();
import QuestionnaireService from "../../../services/QuestionnaireService";
const questionnaireService = QuestionnaireService.build();

export default {
  name: "module-customers-with-questionnaire-and-last-contact",
  mixins: [MixinModuleBase],
  components: {
    MobuleBaseBuilder,
    ModuleChartBar,
    ModuleChartDoughnut,
    ModuleTableListDataV2,
    MessageStatusData,
  },
  data: function () {
    return {
      labels: [],
      tableHeadersWithQuestions: [],
      tableHeadersWithQuestionsExcel: [],
      tableHeaders: [
        {
          text: "Data - Último Contato",
          align: "start",
          sortable: true,
          value: "contactCreated",
          width: "300px",
        },
        {
          text: "Semana - Último Contato",
          align: "start",
          sortable: true,
          value: "contactWeek",
          width: "300px",
        },
        {
          text: "Mês - Último Contato",
          align: "start",
          sortable: true,
          value: "contactMonth",
          width: "300px",
        },
        {
          text: "Ano - Último Contato",
          align: "start",
          sortable: true,
          value: "contactYear",
          width: "300px",
        },
        {
          text: "Data - Último Contato",
          align: "start",
          sortable: true,
          value: "contactCreated",
          width: "300px",
        },
        {
          text: "ID - Último Contato",
          align: "start",
          sortable: true,
          value: "contactId",
          width: "300px",
        },
        {
          text: "Tipo - Último Contato",
          align: "start",
          sortable: true,
          value: "typeOf",
          width: "300px",
        },
        {
          text: "Produto de Interesse - Último Contato",
          align: "start",
          sortable: true,
          value: "productType",
          width: "300px",
        },
        {
          text: "Indicado - Último Contato",
          align: "start",
          sortable: true,
          value: "indication",
          width: "300px",
        },
        {
          text: "Indicado Por - Último Contato",
          align: "start",
          sortable: true,
          value: "indicationBrokerName",
          width: "300px",
        },
        {
          text: "Indicado Por - Último Contato",
          align: "start",
          sortable: true,
          value: "indicationBrokerEmail",
          width: "300px",
        },
        {
          text: "Empresa de Vendas - Último Contato",
          align: "start",
          sortable: true,
          value: "salesCompany",
          width: "300px",
        },
        {
          text: "Outras - Último Contato",
          align: "start",
          sortable: true,
          value: "another",
          width: "300px",
        },
        {
          text: "Corretor - Último Contato",
          align: "start",
          sortable: true,
          value: "brokerName",
          width: "300px",
        },
        {
          text: "Gerente de Vendas - Último Contato",
          align: "start",
          sortable: true,
          value: "managerName",
          width: "300px",
        },
        {
          text: "Superintendente de Vendas - Último Contato",
          align: "start",
          sortable: true,
          value: "superintendentName",
          width: "300px",
        },
        {
          text: "Proprietário - Último Contato",
          align: "start",
          sortable: true,
          value: "owner",
          width: "300px",
        },
        {
          text: "Cliente Cadastrado Em",
          align: "start",
          sortable: true,
          value: "created",
          width: "300px",
        },
        {
          text: "Cliente Cadastrado Por",
          align: "start",
          sortable: true,
          value: "createdBy",
          width: "300px",
        },
        {
          text: "ID Cliente",
          align: "start",
          sortable: true,
          value: "id",
          width: "300px",
        },
        {
          text: "Nome",
          align: "start",
          sortable: true,
          value: "name",
          width: "300px",
        },
        {
          text: "Sobrenome",
          align: "start",
          sortable: true,
          value: "lastName",
          width: "300px",
        },
        {
          text: "Email",
          align: "start",
          sortable: true,
          value: "email",
          width: "300px",
        },
        {
          text: "CPF",
          align: "start",
          sortable: true,
          value: "cpf",
          width: "300px",
        },
        {
          text: "Data de Nascimento",
          align: "start",
          sortable: true,
          value: "birthDate",
          width: "300px",
        },
        {
          text: "Idade",
          align: "start",
          sortable: true,
          value: "age",
          width: "300px",
        },
        {
          text: "Gênero",
          align: "start",
          sortable: true,
          value: "gender",
          width: "300px",
        },
        {
          text: "Telefone Celular",
          align: "start",
          sortable: true,
          value: "cel",
          width: "300px",
        },
        {
          text: "Telefone Residencial",
          align: "start",
          sortable: true,
          value: "personalPhone",
          width: "300px",
        },
        {
          text: "Telefone Comercial",
          align: "start",
          sortable: true,
          value: "businessPhone",
          width: "300px",
        },
        {
          text: "CEP",
          align: "start",
          sortable: true,
          value: "addressZipCode",
          width: "300px",
        },
        {
          text: "Endereço",
          align: "start",
          sortable: true,
          value: "address",
          width: "300px",
        },
        {
          text: "Número",
          align: "start",
          sortable: true,
          value: "addressNumber",
          width: "300px",
        },
        {
          text: "Complemento",
          align: "start",
          sortable: true,
          value: "addressAdjunct",
          width: "300px",
        },
        {
          text: "Região",
          align: "start",
          sortable: true,
          value: "regiao",
          width: "300px",
        },
        {
          text: "Bairro",
          align: "start",
          sortable: true,
          value: "addressDistrict",
          width: "300px",
        },
        {
          text: "Cidade",
          align: "start",
          sortable: true,
          value: "addressCity",
          width: "300px",
        },
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "addressState",
          width: "300px",
        },
        {
          text: "Latitude",
          align: "start",
          sortable: true,
          value: "latAddress",
          width: "300px",
        },
        {
          text: "Longitude",
          align: "start",
          sortable: true,
          value: "lngAddress",
          width: "300px",
        },
        {
          text: "CEP Comercial",
          align: "start",
          sortable: true,
          value: "businessAddressZipCode",
          width: "300px",
        },
        {
          text: "Endereço Comercial",
          align: "start",
          sortable: true,
          value: "businessAddress",
          width: "300px",
        },
        {
          text: "Número Comercial",
          align: "start",
          sortable: true,
          value: "businessAddressNumber",
          width: "300px",
        },
        {
          text: "Complemento Comercial",
          align: "start",
          sortable: true,
          value: "businessAddressAdjunct",
          width: "300px",
        },
        {
          text: "Bairro Comercial",
          align: "start",
          sortable: true,
          value: "businessAddressDistrict",
          width: "300px",
        },
        {
          text: "Cidade Comercial",
          align: "start",
          sortable: true,
          value: "businessAddressCity",
          width: "300px",
        },
        {
          text: "Estado Comercial",
          align: "start",
          sortable: true,
          value: "businessAddressState",
          width: "300px",
        },
        {
          text: "Latitude Comercial",
          align: "start",
          sortable: true,
          value: "latBusiness",
          width: "300px",
        },
        {
          text: "Longitude Comercial",
          align: "start",
          sortable: true,
          value: "lngBusiness",
          width: "300px",
        },
        {
          text: "Mídia Primária",
          align: "start",
          sortable: true,
          value: "mainMedia",
          width: "300px",
        },
        {
          text: "Mídias Secundárias",
          align: "start",
          sortable: true,
          value: "medias",
          width: "300px",
        },
        {
          text: "Cliente Pesquisado Por",
          align: "start",
          sortable: true,
          value: "userId",
          width: "300px",
        },
        {
          text: "Questionário Respondido Em",
          align: "start",
          sortable: true,
          value: "replyCreated",
          width: "300px",
        },
        {
          text: "Total de Contatos",
          align: "start",
          sortable: true,
          value: "totalContacts",
          width: "300px",
        },
        {
          text: "Permitiu o Uso dos Dados",
          align: "start",
          sortable: true,
          value: "personalDataAllowUse",
          width: "300px",
        },
        {
          text: "Permitiu o Compartilhamento dos Dados",
          align: "start",
          sortable: true,
          value: "personalDataAllowShare",
          width: "300px",
        },
      ],
      tableHeadersExcel: [
        {
          text: "Produtos Selecionados",
          align: "start",
          sortable: true,
          value: "selectedProducts",
        },
        {
          text: "Semana - Último Contato",
          align: "start",
          sortable: true,
          value: "contactWeek",
          width: "300px",
        },
        {
          text: "Mês - Último Contato",
          align: "start",
          sortable: true,
          value: "contactMonth",
          width: "300px",
        },
        {
          text: "Ano - Último Contato",
          align: "start",
          sortable: true,
          value: "contactYear",
          width: "300px",
        },
        {
          text: "Data - Último Contato",
          align: "start",
          sortable: true,
          value: "contactCreated",
          width: "300px",
        },
        {
          text: "ID - Último Contato",
          align: "start",
          sortable: true,
          value: "contactId",
          width: "300px",
        },
        {
          text: "Tipo - Último Contato",
          align: "start",
          sortable: true,
          value: "typeOf",
          width: "300px",
        },
        {
          text: "Produto de Interesse - Último Contato",
          align: "start",
          sortable: true,
          value: "productType",
          width: "300px",
        },
        {
          text: "Indicado - Último Contato",
          align: "start",
          sortable: true,
          value: "indication",
          width: "300px",
        },
        {
          text: "Indicado Por - Último Contato",
          align: "start",
          sortable: true,
          value: "indicationBrokerName",
          width: "300px",
        },
        {
          text: "Indicado Por - Último Contato",
          align: "start",
          sortable: true,
          value: "indicationBrokerEmail",
          width: "300px",
        },
        {
          text: "Empresa de Vendas - Último Contato",
          align: "start",
          sortable: true,
          value: "salesCompany",
          width: "300px",
        },
        {
          text: "Outras - Último Contato",
          align: "start",
          sortable: true,
          value: "another",
          width: "300px",
        },
        {
          text: "Corretor - Último Contato",
          align: "start",
          sortable: true,
          value: "brokerName",
          width: "300px",
        },
        {
          text: "Gerente de Vendas - Último Contato",
          align: "start",
          sortable: true,
          value: "managerName",
          width: "300px",
        },
        {
          text: "Superintendente de Vendas - Último Contato",
          align: "start",
          sortable: true,
          value: "superintendentName",
          width: "300px",
        },
        {
          text: "Proprietário - Último Contato",
          align: "start",
          sortable: true,
          value: "owner",
          width: "300px",
        },
        {
          text: "Cliente Cadastrado Em",
          align: "start",
          sortable: true,
          value: "created",
          width: "300px",
        },
        {
          text: "Cliente Cadastrado Por",
          align: "start",
          sortable: true,
          value: "createdBy",
          width: "300px",
        },
        {
          text: "ID Cliente",
          align: "start",
          sortable: true,
          value: "id",
          width: "300px",
        },
        {
          text: "Nome",
          align: "start",
          sortable: true,
          value: "name",
          width: "300px",
        },
        {
          text: "Sobrenome",
          align: "start",
          sortable: true,
          value: "lastName",
          width: "300px",
        },
        {
          text: "Email",
          align: "start",
          sortable: true,
          value: "email",
          width: "300px",
        },
        {
          text: "CPF",
          align: "start",
          sortable: true,
          value: "cpf",
          width: "300px",
        },
        {
          text: "Data de Nascimento",
          align: "start",
          sortable: true,
          value: "birthDate",
          width: "300px",
        },
        {
          text: "Idade",
          align: "start",
          sortable: true,
          value: "age",
          width: "300px",
        },
        {
          text: "Gênero",
          align: "start",
          sortable: true,
          value: "gender",
          width: "300px",
        },
        {
          text: "Telefone Celular",
          align: "start",
          sortable: true,
          value: "cel",
          width: "300px",
        },
        {
          text: "Telefone Residencial",
          align: "start",
          sortable: true,
          value: "personalPhone",
          width: "300px",
        },
        {
          text: "Telefone Comercial",
          align: "start",
          sortable: true,
          value: "businessPhone",
          width: "300px",
        },
        {
          text: "CEP",
          align: "start",
          sortable: true,
          value: "addressZipCode",
          width: "300px",
        },
        {
          text: "Endereço",
          align: "start",
          sortable: true,
          value: "address",
          width: "300px",
        },
        {
          text: "Número",
          align: "start",
          sortable: true,
          value: "addressNumber",
          width: "300px",
        },
        {
          text: "Complemento",
          align: "start",
          sortable: true,
          value: "addressAdjunct",
          width: "300px",
        },
        {
          text: "Região",
          align: "start",
          sortable: true,
          value: "regiao",
          width: "300px",
        },
        {
          text: "Bairro",
          align: "start",
          sortable: true,
          value: "addressDistrict",
          width: "300px",
        },
        {
          text: "Cidade",
          align: "start",
          sortable: true,
          value: "addressCity",
          width: "300px",
        },
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "addressState",
          width: "300px",
        },
        {
          text: "Latitude",
          align: "start",
          sortable: true,
          value: "latAddress",
          width: "300px",
        },
        {
          text: "Longitude",
          align: "start",
          sortable: true,
          value: "lngAddress",
          width: "300px",
        },
        {
          text: "CEP Comercial",
          align: "start",
          sortable: true,
          value: "businessAddressZipCode",
          width: "300px",
        },
        {
          text: "Endereço Comercial",
          align: "start",
          sortable: true,
          value: "businessAddress",
          width: "300px",
        },
        {
          text: "Número Comercial",
          align: "start",
          sortable: true,
          value: "businessAddressNumber",
          width: "300px",
        },
        {
          text: "Complemento Comercial",
          align: "start",
          sortable: true,
          value: "businessAddressAdjunct",
          width: "300px",
        },
        {
          text: "Bairro Comercial",
          align: "start",
          sortable: true,
          value: "businessAddressDistrict",
          width: "300px",
        },
        {
          text: "Cidade Comercial",
          align: "start",
          sortable: true,
          value: "businessAddressCity",
          width: "300px",
        },
        {
          text: "Estado Comercial",
          align: "start",
          sortable: true,
          value: "businessAddressState",
          width: "300px",
        },
        {
          text: "Latitude Comercial",
          align: "start",
          sortable: true,
          value: "latBusiness",
          width: "300px",
        },
        {
          text: "Longitude Comercial",
          align: "start",
          sortable: true,
          value: "lngBusiness",
          width: "300px",
        },
        {
          text: "Mídia Primária",
          align: "start",
          sortable: true,
          value: "mainMedia",
          width: "300px",
        },
        {
          text: "Mídias Secundárias",
          align: "start",
          sortable: true,
          value: "medias",
          width: "300px",
        },
        {
          text: "Cliente Pesquisado Por",
          align: "start",
          sortable: true,
          value: "userId",
          width: "300px",
        },
        {
          text: "Questionário Respondido Em",
          align: "start",
          sortable: true,
          value: "replyCreated",
          width: "300px",
        },
        {
          text: "Total de Contatos",
          align: "start",
          sortable: true,
          value: "totalContacts",
          width: "300px",
        },
        {
          text: "Permitiu o Uso dos Dados",
          align: "start",
          sortable: true,
          value: "personalDataAllowUse",
          width: "300px",
        },
        {
          text: "Permitiu o Compartilhamento dos Dados",
          align: "start",
          sortable: true,
          value: "personalDataAllowShare",
          width: "300px",
        },
      ],
      tableItems: [],
      dataDownload: {
        class: "customers-with-questionnaire",
        title: this.moduleData.headerTitle,
      },
      questionnaires: [],
      selectedQuestionnaire: [],
    };
  },
  computed: {
    hasFilterDateRange() {
      return (
        this.filterDateRange &&
        this.filterDateRange.length == 2 &&
        this.filterDateRange[0].length > 1 &&
        this.filterDateRange[1].length > 1
      );
    },
    getSelectedProducts() {
      return this.$store.getters.selectedProducts;
    },
  },
  methods: {
    resetModule() {
      this.tableItems = [];
      this.questionnaires = [];
      this.selectedQuestionnaire = [];
    },
    formatDate(dateToFormat, key = "") {
      const date = new Date(dateToFormat);
      const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      const month =
        date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      const minutes =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      return `${day}/${month}/${date.getFullYear()} ${
        key !== "birthDate" ? date.getHours() + ":" + minutes : ""
      }`;
    },
    formatTypeOfContact(type) {
      switch (type) {
        case "FIRSTCONTACT":
          return "Primeira Visita";
        case "RECURRENCE":
          return "Retorno";
        case "PHONECONTACT":
          return "Telefonema";
        case "WHATSAPP":
          return "WhatsApp";
      }
    },
    weekOfYear(dateToWeek) {
      let date = new Date(dateToWeek);
      date.setHours(0, 0, 0, 0);
      date.setDate(date.getDate() - (date.getDay() % 7));
      let week1 = new Date(date.getFullYear(), 0, 4);

      return (
        1 +
        Math.round(
          ((date.getTime() - week1.getTime()) / 86400000 + (week1.getDay() % 7)) / 7
        )
      );
    },
    sanitizeContactsType(customers) {
      if (customers && customers.length > 0) {
        customers = customers.map((customer) => {
          const lastContact = customer.contacts[customer.contacts.length - 1];
          delete customer["contacts"];

          lastContact["contactId"] = lastContact["id"];
          lastContact["contactCreated"] = this.formatDate(lastContact["created"]);
          lastContact["contactWeek"] = this.weekOfYear(lastContact["created"]);
          lastContact["contactMonth"] = lastContact["contactCreated"]
            .split(" ")[0]
            .split("/")[1];
          lastContact["contactYear"] = lastContact["contactCreated"]
            .split(" ")[0]
            .split("/")[2];
          lastContact["contactCreatedBy"] = lastContact["createdBy"];
          lastContact["userId"] =
            customer["replies"].length > 0 ? customer["replies"][0].userId : "-";
          lastContact["replyCreated"] =
            customer["replies"].length > 0
              ? this.formatDate(customer["replies"][0]["created"])
              : "-";
          lastContact["salesCompany"] = lastContact.salesCompany
            ? lastContact.salesCompany.name
            : "-";
          lastContact["productType"] = lastContact["productsType"]
            .map((productType) => productType.name)
            .join(",");
          lastContact["indication"] = lastContact["indication"] ? "SIM" : "NÃO";
          if (lastContact["indication"] === "SIM") {
            lastContact["indicationBrokerName"] =
              lastContact["indicationBrokerName"] &&
              lastContact["indicationBrokerName"].length > 0
                ? lastContact["indicationBrokerName"]
                : lastContact["indicationBrokerEmail"];
          }
          lastContact["typeOf"] = this.formatTypeOfContact(lastContact["typeOf"]);

          customer["birthDate"] = this.formatDate(customer["birthDate"], "birthDate");
          customer["age"] =
            new Date().getFullYear() - Number(customer["birthDate"].split("/")[2]);
          customer["created"] = this.formatDate(customer["created"]);
          customer["gender"] = customer["gender"] === "f" ? "Feminino" : "Masculino";
          customer["regiao"] = customer["regiao"] ? customer["regiao"].name : "-";

          customer["personalDataAllowShare"] = customer["personalDataAllowShare"]
            ? "SIM"
            : "NÃO";
          customer["personalDataAllowUse"] = customer["personalDataAllowUse"]
            ? "SIM"
            : "NÃO";

          if (lastContact["mainMediaId"]) {
            if (lastContact["medias"].length > 1) {
              lastContact["mainMedia"] = lastContact["medias"].filter(
                (media) => media.id === lastContact["mainMediaId"]
              )[0].title;
              lastContact["medias"] = lastContact["medias"]
                .filter((media) => media.id !== lastContact["mainMediaId"])
                .map((media) => media.title)
                .join(",");
            } else {
              lastContact["mainMedia"] =
                lastContact["medias"].length > 0 ? lastContact["medias"][0].title : "-";
              lastContact["medias"] = "-";
            }
          } else {
            lastContact["mainMedia"] =
              lastContact["medias"].length > 0
                ? lastContact["medias"].shift().title
                : "-";
            if (lastContact["medias"].length > 1) {
              lastContact["medias"] = lastContact["medias"]
                .map((media) => media.title)
                .join(",");
            } else {
              lastContact["medias"] =
                lastContact["medias"].length > 0 ? lastContact["medias"][0].title : "-";
            }
          }

          if (lastContact["productsType"].length > 1) {
            lastContact["productsType"] = lastContact["productsType"]
              .map((productType) => productType.name)
              .join(",");
          } else {
            lastContact["productsType"] = lastContact["productsType"][0].name;
          }

          delete lastContact["id"];
          delete lastContact["created"];
          customer = { ...customer, ...lastContact };
          const selectedQuest = this.questionnaires.filter(
            (q) => q.id === this.selectedQuestionnaire
          )[0];

          selectedQuest.questions.forEach((question) => {
            const reply = customer.replies.filter(
              (reply) =>
                reply?.choice?.question?.id === question.id &&
                reply?.choice?.question?.title === question.title
            )[0];
            let answer = "-";
            if (reply) {
              if (reply.answer) answer = reply.answer;
              else answer = reply.choice.textFor;
            }
            customer[question.id] = answer;
          });

          Object.keys(customer).forEach((key) => {
            if (!customer[key]) customer[key] = "-";
          });

          return customer;
        });
      }
      return customers;
    },
    buildTableHeaderWithQuestions() {
      this.loading = true;
      const selectedQuest = this.questionnaires.filter(
        (q) => q.id === this.selectedQuestionnaire
      )[0];
      this.tableHeadersWithQuestions = [...this.tableHeaders];
      this.tableHeadersWithQuestionsExcel = [...this.tableHeadersExcel];
      selectedQuest.questions.forEach((question) => {
        this.tableHeadersWithQuestions.push({
          text: question.title,
          align: "start",
          sortable: true,
          value: String(question.id),
          width: "500px",
        });
        this.tableHeadersWithQuestionsExcel.push({
          text: question.title,
          align: "start",
          sortable: true,
          value: String(question.id),
          width: "500px",
        });
      });

      customerService
        .getCustomerWithQuestionnaireAndLastContact({
          selectedProductsIds: selectedQuest.productId,
          dateBegin: this.filterDateRange[0],
          dateEnd: this.filterDateRange[1],
        })
        .then((r) => {
          this.tableItems = this.sanitizeContactsType(r);

          const productsSelectedLength = this.getSelectedProducts.length;

          if (r.length >= productsSelectedLength) {
            this.tableItems = this.tableItems.map((item, index) => {
              if (index < productsSelectedLength) {
                return {
                  selectedProducts: this.getSelectedProducts[index]?.name,
                  ...item,
                };
              } else {
                return { selectedProducts: "", ...item };
              }
            });
          } else {
            this.tableItems = this.getSelectedProducts.map((product, index) => {
              const item = r[index] ? r[index] : "";
              if (item) {
                return { selectedProducts: product?.name, ...item };
              } else {
                return { selectedProducts: product?.name };
              }
            });
          }

          this.loading = false;
        })
        .catch(this.errorHandler);
    },

    getData() {
      this.resetModule();

      if (this.dataRetrievalOk()) {
        this.loading = true;

        questionnaireService
          .getCompletedQuestionnairesActiveAndNotActive({
            selectedProductsIds: this.selectedProductsIds(),
            dateBegin: this.filterDateRange[0],
            dateEnd: this.filterDateRange[1],
          })
          .then((q) => {
            if (q && q.length > 0) {
              this.questionnaires = q;
              this.selectedQuestionnaire = this.questionnaires[0].id;
              this.buildTableHeaderWithQuestions();
            } else {
              this.selectInPanel = {
                active: false,
                message: "Nenhum dado encontrado",
              };

              this.loading = false;
            }
          })
          .catch(this.errorHandler);
      }
    },
  },
};
</script>

<style scoped>
.echarts {
  width: 100%;
  height: 100%;
  position: relative;
}

.customers-with-questionnaire-and-last-contact {
  background-color: #fff;
}

.w-100 {
  z-index: 999;
}
</style>
