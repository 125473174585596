<template>
  <mobule-base-builder
    :module-data="moduleData"
    :editMode="editMode"
    :loading="loading"
    :error="error"
    :downloadImage="dataDownload"
    :tableHeaders="tableHeaders"
    :tableItems="tableItems"
    :tableHeadersExcel="tableHeadersExcel"
    :typePanelPDF="moduleData.displayType.chosen"
  >
    <template v-slot:content>
      <div class="pa-3" v-if="questionnaires.length > 0">
        <v-autocomplete
          v-model="selectedQuestionnaire"
          :items="questionnaires"
          item-text="title"
          item-value="id"
          @change="buildingData()"
          id="completed-questionnaire"
          label="Selecione o questionário"
          class="px-4 pt-2"
          :disabled="!hasFilterDateRange"
          outlined
          dense
        ></v-autocomplete>
      </div>

      <module-table-list-data-v2
        v-if="moduleData.displayType.chosen == 'TABLE' && tableItems.length > 0"
        :tableHeaders="tableHeaders"
        :tableItems="tableItems"
        :panelName="moduleData.headerTitle"
        class="completed-questionnaire"
      />
      <div
        class="container completed-questionnaire"
        v-else-if="
          moduleData.displayType.chosen == 'CHART_PIZZA' &&
          chartValues.length > 0
        "
        ref="container"
      >
        <div
          class="container-pizza"
          v-for="(chartValue, index) in chartValues"
          :key="index"
        >
          <module-chart-doughnut
            v-if="chartValue.length > 0"
            :dataTitle="questionTitle[index]"
            :legendOptions="legendOption"
            :chartValues="chartValue"
            :dataLegend="labelsChartPizza[index]"
            :ref="'chart' + index"
            class="echarts-pizza"
          />
          <div v-else class="no-data-question">
            <p>{{ questionTitle[index] }}</p>
            <message-status-data
              :selected-products="null"
              :customStyle="'container-message-status-data'"
              :infoLoading="false"
            />
          </div>
        </div>
      </div>
      <message-status-data
        v-else
        :selected-products="selectedProductsIds()"
        :infoLoading="loading"
        :selectInPanel="selectInPanel"
      />
    </template>
  </mobule-base-builder>
</template>

<script>
import QuestionnaireService from "../../../services/QuestionnaireService";
const questionnaireService = QuestionnaireService.build();

import MixinModuleBase from "../../../mixins/MixinModuleBase";
import MobuleBaseBuilder from "../base/MobuleBaseBuilder.vue";
import ModuleChartBar from "../base/ModuleChartBar.vue";
import ModuleChartDoughnut from "../base/ModuleChartDoughnut.vue";
import ModuleTableListDataV2 from "../base/ModuleTableListDataV2.vue";
import MessageStatusData from "../../MessageStatusData.vue";

export default {
  name: "module-completed-questionnaires",
  mixins: [MixinModuleBase],
  components: {
    MobuleBaseBuilder,
    ModuleChartBar,
    ModuleChartDoughnut,
    ModuleTableListDataV2,
    MessageStatusData,
  },
  data: function () {
    return {
      legendOption: {
        type: "scroll",
        orient: "vertical",
        left: "1rem",
        top: "5%",
        bottom: "5%",
      },
      selectInPanel: {
        active: true,
        message: "Selecione algum questionário",
      },
      questionnaires: [],
      labels: [],
      labelsChartPizza: [],
      tableHeaders: [
        { text: "Perguntas", align: "start", sortable: false, value: "title" },
        {
          text: "Total de Respostas",
          align: "start",
          sortable: false,
          value: "totalReplies",
        },
        {
          text: "Porcentagem",
          align: "start",
          sortable: false,
          value: "percent",
        },
      ],
      tableHeadersExcel: [
        {
          text: "Produto",
          align: "start",
          sortable: true,
          value: "selectedProduct",
        },
        {
          text: "Questionário Selecionado",
          align: "start",
          sortable: true,
          value: "selectedQuestionnaire",
        },
        { text: "Perguntas", align: "start", sortable: true, value: "title" },
        {
          text: "Total de Respostas",
          align: "start",
          sortable: true,
          value: "totalReplies",
        },
        {
          text: "Porcentagem",
          align: "start",
          sortable: true,
          value: "percent",
        },
      ],
      tableItems: [],
      dataDownload: {
        class: "completed-questionnaire",
        title: this.moduleData.headerTitle,
      },
      legend: [],
      chartValues: [],
      selectedQuestionnaire: [],
      questionTitle: [],
    };
  },
  computed: {
    hasFilterDateRange() {
      return (
        this.filterDateRange &&
        this.filterDateRange.length == 2 &&
        this.filterDateRange[0].length > 1 &&
        this.filterDateRange[1].length > 1
      );
    },
    getSelectedProducts() {
      return this.$store.getters.selectedProducts;
    },
  },
  methods: {
    resetModule() {
      this.tableItems = [];
      this.labels = [];
      this.legend = [];
      this.chartValues = [];
      this.questionnaires = [];
      this.questionTitle = [];
    },
    convertToChartPizzaValues(data) {
      let visitsValues = [];
      data.forEach((item) => {
        visitsValues.push({
          value: item.totalReplies,
          name: item.title,
        });
      });

      this.chartValues.push(visitsValues);
    },

    getData() {
      this.resetModule();

      if (this.dataRetrievalOk()) {
        this.loading = true;

        questionnaireService
          .getCompletedQuestionnaires({
            selectedProductsIds: this.selectedProductsIds(),
            dateBegin: this.filterDateRange[0],
            dateEnd: this.filterDateRange[1],
          })
          .then((r) => {
            if(r && r.length > 0) {
              this.questionnaires = r;
              this.buildingData();
            } else {
              this.selectInPanel = {
                active: false,
                message: "Nenhum dado encontrado",
              };
            }
            this.loading = false;
          })
          .catch(this.errorHandler);
      } else {
        this.selectedQuestionnaire = [];
      }
    },
    mountedChartItems(questionnaire) {
      let list = [];

      if (questionnaire && questionnaire?.questions) {
        questionnaire.questions.forEach((question, index) => {
          let listPie = [];
          let labelArray = [];
          let totalRepliesInQuestion = question.choices.reduce(
            (acc, cur) => acc + cur.replies.length,
            0
          );

          if (index > 0) {
            list.push({
              title: "",
              totalReplies: "",
              percent: "",
            });

            list.push({
              title: `${index + 1}ª Pergunta: ${question.title}`,
              totalReplies: "",
              percent: "",
            });
          } else {
            list.push({
              title: `${index + 1}ª Pergunta: ${question.title}`,
              totalReplies: "",
              percent: "",
            });
          }

          this.questionTitle.push(question.title);
          question.choices.forEach((choice) => {
            if (choice.textFor.length > 0) {
              list.push({
                idChoice: choice.id,
                title: choice.textFor,
                totalReplies: choice.replies.length,
                percent:
                  choice.replies.length > 0
                    ? parseFloat(
                        (choice.replies.length / totalRepliesInQuestion) * 100
                      ).toFixed(2)
                    : 0,
              });
              listPie.push({
                idChoice: choice.id,
                title: choice.textFor,
                totalReplies: choice.replies.length,
              });
              labelArray.push(choice.textFor);
            } else {
              choice.replies.forEach((reply) => {
                let filterList = list.filter(
                  (item) =>
                    item.idChoice === reply.choiceId &&
                    item.title.replace(/ /g, "").toLowerCase() ===
                      reply.answer.replace(/ /g, "").toLowerCase()
                )[0];
                if (filterList) {
                  let indexObject = list.findIndex(
                    (item) =>
                      item.idChoice === filterList.idChoice &&
                      item.title.replace(/ /g, "").toLowerCase() ===
                        filterList.title.replace(/ /g, "").toLowerCase()
                  );
                  let indexPieObject = listPie.findIndex(
                    (item) =>
                      item.idChoice === filterList.idChoice &&
                      item.title.replace(/ /g, "").toLowerCase() ===
                        filterList.title.replace(/ /g, "").toLowerCase()
                  );
                  list[indexObject].totalReplies++;
                  listPie[indexPieObject].totalReplies++;
                } else {
                  list.push({
                    idChoice: reply.choiceId,
                    title: reply.answer,
                    totalReplies: 1,
                    percent: 0,
                  });
                  listPie.push({
                    idChoice: reply.choiceId,
                    title: reply.answer,
                    totalReplies: 1,
                  });
                }
              });
            }
          });
          this.labelsChartPizza.push(labelArray);
          this.convertToChartPizzaValues(listPie);
        });
      }

      return list;
    },
    buildingData() {
      const questionnairesAux = this.questionnaires;
      this.resetModule();
      this.questionnaires = questionnairesAux;

      const selectedQuest = this.questionnaires.filter(
        (q) => q.id === this.selectedQuestionnaire
      )[0];
      if (selectedQuest) {
        this.tableItems = this.mountedChartItems(selectedQuest);
        const product = this.getSelectedProducts.filter(
          (prod) => prod.id === selectedQuest.productId
        )[0];
        this.tableItems[0] = {
          selectedQuestionnaire: selectedQuest.title,
          selectedProduct: product?.name,
          ...this.tableItems[0],
        };
      } else {
        this.selectedQuestionnaire = this.questionnaires[0].id;
        this.buildingData();
      }
    },
  },
  watch: {
    selectedQuestionnaire(value) {
      if (value && this.selectInPanel.active) {
        this.selectInPanel.active = false;
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.container,
.no-data-question {
  min-width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  min-height: 100%;
  max-height: 100%;
  background-color: #fff !important;
  padding-bottom: 50px;

  .container-pizza {
    min-height: 900px;
    max-height: 900px;
    min-width: 100%;
    max-width: 100%;
    border: 1px solid #ccc;
    position: relative;
  }
}

.v-application .elevation-1 {
  box-shadow: 0 2px 1px -1px rgba(116, 22, 22, 0.2),
    0 1px 1px 0 rgba(63, 7, 7, 0.14), 0 1px 3px 0 rgba(201, 24, 24, 0.12) !important;
}

.completed-questionnaire {
  background-color: #fff;
  .v-application .elevation-1 {
    box-shadow: 0 2px 1px -1px rgba(116, 22, 22, 0.2),
      0 1px 1px 0 rgba(63, 7, 7, 0.14), 0 1px 3px 0 rgba(201, 24, 24, 0.12) !important;
  }
}

.echarts-pizza {
  width: 100% !important;
  height: 50rem !important;
  position: relative !important;
}

p {
  font: bolder 15px "Inter", Helvetica, Arial;
}

.w-100 {
  z-index: 999;
}
</style>
