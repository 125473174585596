import Vue from 'vue';
import VueRouter from 'vue-router';
import VueJwtDecode from 'vue-jwt-decode'

import App from '../App.vue';
import Public from '../views/Public.vue';
import LoginPage from '../components/public/LoginPage.vue';
import RecoverPassword from '../components/public/RecoverPassword.vue';
import ChangePassword from '../components/public/ChangePassword.vue';
import Home from '../views/Home.vue';
import Dashboards from '../components/home/Dashboards.vue';
import ManageAccessBI from '../components/home/ManageAccessBI.vue';
import ManageInternalUsersAccessBI from '../components/home/ManageInternalUsersAccessBI.vue';
import ManageAccountPanel from '../components/home/ManageAccountPanels.vue';
import PageNotFound from '../views/PageNotFound.vue';
import MultipleEnterprise from '../views/MultipleEnterprise.vue'
import ManageSendReports from "../components/home/ManageSendReports.vue";
import ManageEmailReports from "../components/home/ManageEmailReports.vue";
import ManageReports from "../components/home/ManageReports.vue";
import ManageResendReports from "../components/home/ManageResendReports.vue"

import UserService from "../services/UserService";
const userService = UserService.build();
import CryptoService from "../services/CryptoService";
const cryptoService = new CryptoService();

import hexToASCII from "../utils/hexToASCII";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "App",
    component: App,
  },
  {
    path: "/login",
    name: "Public",
    component: Public,
    children: [
      {
        path: "/login",
        name: "Login",
        component: LoginPage,
      },
      {
        path: "/recover-password",
        name: "RecoverPassword",
        component: RecoverPassword,
      },
      {
        path: "/change-password/:token",
        name: "ChangePassword",
        component: ChangePassword,
        props: true,
      },
    ],
  },
  {
    path: "/multiple-enterprise",
    name: "MultipleEnterprise",
    component: MultipleEnterprise,
  },
  {
    path: "/dashboard",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboards,
        meta: {
          addPanelToDashboard: {
            methodName: "addPanelToDashboard",
            arguments: "panelId",
          },
          keepAlive: true,
        },
      },
      {
        path: "/manage-account-panel",
        name: "ManageAccountPanel",
        component: ManageAccountPanel,
        keepAlive: false,
      },
      {
        path: "/manage-access-bi",
        name: "ManageAccessBI",
        component: ManageAccessBI,
        keepAlive: false,
      },
      {
        path: "/manage-internal-users-access-bi",
        name: "ManageInternalUsersAccessBI",
        component: ManageInternalUsersAccessBI,
        keepAlive: false,
      },
      {
        path: "/manage-reports",
        name: "ManageReports",
        component: ManageReports,
        keepAlive: false,
        children: [
          {
            path: "/manage-email-reports",
            name: "ManageEmailReports",
            component: ManageEmailReports,
            keepAlive: false,
          },
          {
            path: "/manage-send-reports",
            name: "ManageSendReports",
            component: ManageSendReports,
            keepAlive: false,
          },
          {
            path: "/manage-resend-reports",
            name: "ManageResendReports",
            component: ManageResendReports,
            keepAlive: false,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    component: PageNotFound,
  },
];

const router = new VueRouter({
  routes,
  mode: "history"
});

router.beforeEach(async (to, from, next) => {
  router.app.$store.commit('initialiseStore');
  const cookieJWT = document.cookie.split(";").filter(data => data.includes("PLAY_BISESSION"));
  const cookieExtend = document.cookie.split(";").filter(data => data.includes("EXT"));
  let jwt = null;

  if (cookieJWT.length > 0 && cookieJWT) {
    jwt = cookieJWT[0].split("=")[1];
  }

  if (cookieExtend.length > 0 && cookieExtend) {
    document.cookie = "EXT=" + "" + ";expires=Thu, 01 Jan 1970 00:00:01 GMT" + "; path=/";
    if (cookieExtend[0].split("=")[1] !== "") {
      const store = hexToASCII(cookieExtend[0].split("=")[1]);
      localStorage.setItem("store", cryptoService.generateCrypto(store));
      router.app.$store.commit('initialiseStore');
    }
  }

  if (to.matched[0].name != 'Public' && (router.app.$store.getters.userId == 0 || !jwt || jwt.length === 0)) {
    next({ name: 'Login' });

  } else {
    const storeAccount = router.app.$store.getters.account;
    if (storeAccount === null && jwt !== null && jwt.length !== 0) {
      await userService.getLoggedUser().then(loggedUser => {
        router.app.$store.dispatch('storeAccount', loggedUser);
        router.app.$store.dispatch('storeUserTemp', loggedUser);
        const { dataBase } = VueJwtDecode.decode(jwt);
        router.app.$store.dispatch('storeEnterprise', dataBase);
      }).catch(error => {
        if (error.status === 401) {
          localStorage.clear();
          document.cookie = "PLAY_BISESSION=" + "" + ";expires=Thu, 01 Jan 1970 00:00:01 GMT" + "; path=/";
          document.cookie = "EXT=" + "" + ";expires=Thu, 01 Jan 1970 00:00:01 GMT" + "; path=/";
          window.location.href = "/erro";
        }
      });

      if (to.name === 'Login') next({ path: '/dashboard' });
    }
    next();
  }
})

export default router
