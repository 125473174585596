<template>
  <mobule-base-builder
    :module-data="moduleData"
    :editMode="editMode"
    :loading="loading"
    :error="error"
    :downloadImage="dataDownload"
    :tableHeaders="tableHeaders"
    :tableItems="tableItems"
    :tableHeadersExcel="tableHeadersExcel"
    :tableItemsExcel="tableItemsExcel"
    :typePanelPDF="moduleData.displayType.chosen"
    :selectLabelToPDF="selectedOption"
  >
    <template v-slot:content>
      <v-autocomplete
        v-if="getSelectedProducts.length > 0"
        v-model="selectedOption"
        :items="options"
        @change="getData()"
        id="selectOption"
        class="px-4 pt-2"
        outlined
        dense
      ></v-autocomplete>

      <module-chart-bar
        v-if="
          moduleData.displayType.chosen == 'CHART_BAR' &&
          barChartData.length > 0
        "
        :data="barChartData"
        :labels="labels"
        class="districts"
      />
      <div
        class="echarts"
        v-else-if="
          moduleData.displayType.chosen == 'CHART_PIZZA' &&
          contactsByDistrict.length > 0
        "
      >
        <module-chart-doughnut
          :dataTitle="''"
          :dataLegend="labels"
          :chartValues="contactsByDistrict"
          class="districts"
        />
      </div>
      <module-table-list-data-v-2
        v-else-if="
          moduleData.displayType.chosen == 'TABLE' && tableItems.length > 0
        "
        :tableHeaders="tableHeaders"
        :tableItems="tableItems"
        :footerPropsItemsPerPageText="footerPropsItemsPerPageText"
        class="districts"
      />
      <message-status-data
        v-else
        :selected-products="selectedProductsIds()"
        :infoLoading="loading"
      />
    </template>
  </mobule-base-builder>
</template>

<script>
import MixinModuleBase from "../../../mixins/MixinModuleBase";
import MobuleBaseBuilder from "../base/MobuleBaseBuilder.vue";

import ModuleChartBar from "../base/ModuleChartBar.vue";
import ModuleChartDoughnut from "../base/ModuleChartDoughnut.vue";
import ModuleTableListDataV2 from "../base/ModuleTableListDataV2.vue";

import CustomerService from "../../../services/CustomerService";
const customerService = CustomerService.build();

import MessageStatusData from "../../MessageStatusData.vue";

export default {
  name: "module-districts",
  mixins: [MixinModuleBase],
  components: {
    MobuleBaseBuilder,
    ModuleTableListDataV2,
    MessageStatusData,
    ModuleChartDoughnut,
    ModuleChartBar,
  },
  data: function () {
    return {
      tableHeaders: [
        {
          text: "Bairro",
          align: "start",
          sortable: true,
          value: "address_district",
        },
        {
          text: "Contagem",
          align: "start",
          sortable: true,
          value: "customerCount",
        },
      ],
      tableHeadersExcel: [
        {
          text: "Produtos Selecionados",
          align: "start",
          sortable: true,
          value: "selectedProducts",
        },
        {
          text: "Bairro",
          align: "start",
          sortable: true,
          value: "address_district",
        },
        {
          text: "Contagem",
          align: "start",
          sortable: true,
          value: "customerCount",
        },
      ],
      tableItems: [],
      tableItemsExcel: [],
      footerPropsItemsPerPageText: "Bairros por página",
      dataDownload: { class: "districts", title: this.moduleData.headerTitle },
      barChartData: [],
      labels: [],
      contactsByDistrict: [],
      tableFromFilter: [],
      options: ["Residencial", "Comercial"],
      selectedOption: "",
    };
  },
  computed: {
    getSelectedProducts() {
      return this.$store.getters.selectedProducts;
    },
  },
  methods: {
    resetModule() {
      this.contactsByDistrict = [];
      this.tableItems = [];
      this.tableItemsExcel = [];
      this.barChartData = [];
      this.labels = [];
    },
    getData() {
      this.resetModule();

      if (this.dataRetrievalOk() && this.selectedOption.length > 0) {
        this.loading = true;

        customerService
          .getTotalCustomersByDistrict({
            selectedProductsIds: this.selectedProductsIds(),
            dateBegin: this.filterDateRange[0],
            dateEnd: this.filterDateRange[1],
            isResidential: this.selectedOption === "Residencial" ? true : false,
          })
          .then((r) => {
            this.resetModule();

            const productsSelectedLength = this.getSelectedProducts.length;

            if (r.length >= productsSelectedLength) {
              r.forEach((item, index) => {
                if (index < productsSelectedLength) {
                  this.tableItems.push({ ...item });
                  this.tableItemsExcel.push({
                    selectedProducts: this.getSelectedProducts[index]?.name,
                    ...item,
                  });
                } else {
                  this.tableItems.push({ ...item });
                  this.tableItemsExcel.push({
                    selectedProducts: "",
                    ...item,
                  });
                }
              });
            } else {
              this.getSelectedProducts.forEach((product, index) => {
                const item = r[index] ? r[index] : "";
                if (item) {
                  this.tableItems.push({ ...item });
                  this.tableItemsExcel.push({
                    selectedProducts: product?.name,
                    ...item,
                  });
                } else {
                  this.tableItemsExcel.push({
                    selectedProducts: product?.name,
                  });
                }
              });
            }

            r.map((item) => {
              this.barChartData.push(item.customerCount);
              this.labels.push(item.address_district);

              this.contactsByDistrict.push({
                value: item.customerCount,
                name: item.address_district,
              });
            });

            this.loading = false;
          })
          .catch(this.errorHandler);
      }
    },
  },
  mounted() {
    this.selectedOption = "Residencial";
  },
};
</script>

<style scoped>
.districts {
  background-color: #fff;
}

.w-100 {
  z-index: 999;
}

.v-application .pa-3 {
  background-color: #fff !important;
}
</style>
