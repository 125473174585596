<template>
  <mobule-base-builder
    :module-data="moduleData"
    :editMode="editMode"
    :loading="loading"
    :error="error"
    :downloadImage="dataDownload"
    :tableHeaders="tableHeaders"
    :tableItems="tableItems"
    :tableHeadersExcel="tableHeadersExcel"
    :tableItemsExcel="tableItemsExcel"
    :typePanelPDF="moduleData.displayType.chosen"
  >
    <template v-slot:content>
      <module-chart-bar
        v-if="
          moduleData.displayType.chosen == 'CHART_BAR' &&
          barChartData.length > 0
        "
        :data="barChartData"
        :labels="labels"
        class="contacts-types"
      />
      <div
        class="echarts"
        v-else-if="
          moduleData.displayType.chosen == 'CHART_PIZZA' &&
          contactTypes.length > 0
        "
      >
        <module-chart-doughnut
          :dataTitle="dataTitle"
          :dataLegend="labels"
          :chartValues="contactTypes"
          class="contacts-types"
        />
      </div>
      <module-table-list-data-v-2
        v-else-if="
          moduleData.displayType.chosen == 'TABLE' && tableItems.length > 0
        "
        :tableHeaders="tableHeaders"
        :tableItems="tableItems"
        class="contacts-types"
      />
      <message-status-data
        v-else
        :selected-products="selectedProductsIds()"
        :infoLoading="loading"
      />
    </template>
  </mobule-base-builder>
</template>

<script>
import MixinModuleBase from "../../../mixins/MixinModuleBase";
import MobuleBaseBuilder from "../base/MobuleBaseBuilder.vue";

import ModuleChartBar from "../base/ModuleChartBar.vue";
import ModuleChartDoughnut from "../base/ModuleChartDoughnut.vue";
import ModuleTableListDataV2 from "../base/ModuleTableListDataV2.vue";

import ContactService from "../../../services/ContactService";
const contactService = ContactService.build();

import MessageStatusData from "../../MessageStatusData.vue";

import percentageSort from "../../../utils/sorting/percentage.sort";

export default {
  name: "module-contact-types",
  mixins: [MixinModuleBase],
  components: {
    ModuleChartBar,
    MobuleBaseBuilder,
    ModuleChartDoughnut,
    ModuleTableListDataV2,
    MessageStatusData,
  },
  data: function () {
    return {
      contactTypes: [],
      barChartData: [],
      labels: [],
      dataTitle: "",
      tableHeaders: [
        {
          text: "Tipo de visita",
          align: "start",
          sortable: true,
          value: "type_of",
        },
        {
          text: "Quantidade",
          align: "start",
          sortable: true,
          value: "contactCount",
        },
        /* {
          text: "%",
          align: "start",
          sortable: true,
          value: "percentage",
          sort: percentageSort,
        }, */
      ],
      tableHeadersExcel: [
        {
          text: "Produtos Selecionados",
          align: "start",
          sortable: true,
          value: "selectedProducts",
        },
        {
          text: "Tipo de visita",
          align: "start",
          sortable: true,
          value: "type_of",
        },
        {
          text: "Quantidade",
          align: "start",
          sortable: true,
          value: "contactCount",
        },
        /* {
          text: "%",
          align: "start",
          sortable: true,
          value: "percentage",
          sort: percentageSort,
        }, */
      ],
      tableItems: [],
      tableItemsExcel: [],
      dataDownload: {
        class: "contacts-types",
        title: this.moduleData.headerTitle,
      },
    };
  },
  computed: {
    getSelectedProducts() {
      return this.$store.getters.selectedProducts;
    },
  },
  methods: {
    resetModule() {
      this.contactTypes = [];
      this.tableItems = [];
      this.tableItemsExcel = [];
      this.barChartData = [];
      this.labels = [];
    },

    sanitizeContactsType(contacts) {
      if (typeof contacts !== "undefined" && contacts.length > 0) {
        for (let i = 0; i < contacts.length; i++) {
          if (contacts[i]["type_of"] == "Return") {
            contacts[i]["type_of"] = "Retorno";
          } else if (contacts[i]["type_of"] == "Telephone") {
            contacts[i]["type_of"] = "Telefone";
          } else if (contacts[i]["type_of"] == "Indication") {
            contacts[i]["type_of"] = "Indicação";
          } else if (contacts[i]["type_of"] == "Spontaneous") {
            contacts[i]["type_of"] = "Espontânea";
          } else if (contacts[i]["type_of"] == "FirstVisit") {
            contacts[i]["type_of"] = "1º Visita";
          }
        }
      }
      return contacts;
    },

    convertToChartValues(contacts) {
      let ret = [];
      if (typeof contacts !== "undefined" && contacts.length > 0) {
        for (let i = 0; i < contacts.length; i++) {
          //Chart Pizza
          if (contacts[i]["type_of"] !== "1º Visita") {
            ret.push({
              value: contacts[i]["contactCount"],
              name: contacts[i]["type_of"],
            });
          } else {
            this.dataTitle = `Total de 1º Visita: ${contacts[i]["contactCount"]}`;
          }

          //Chart Bar
          this.barChartData.push(contacts[i]["contactCount"]);
          this.labels.push(contacts[i]["type_of"]);
        }
      }
      return ret;
    },

    getData() {
      this.resetModule();

      if (this.dataRetrievalOk()) {
        this.loading = true;

        contactService
          .getTotalContactsByType({
            selectedProductsIds: this.selectedProductsIds(),
            dateBegin: this.filterDateRange[0],
            dateEnd: this.filterDateRange[1],
          })
          .then((r) => {
            this.resetModule();
            if (
              r &&
              (r["FirstVisit"] > 0 ||
                r["Return"] > 0 ||
                r["Telephone"] > 0 ||
                r["Indication"] > 0 ||
                r["Spontaneous"] > 0)
            ) {
              let tot = 0;
              r = Object.entries(r).map((typeArray) => {
                return {
                  type_of: typeArray[0],
                  contactCount: typeArray[1],
                };
              });

              for (let i = 0; i < r.length; i++) {
                tot += parseInt(r[i]["contactCount"]);
              }
              /* for (let i = 0; i < r.length; i++) {
                r[i]["percentage"] =
                  ((r[i]["contactCount"] / tot) * 100).toFixed(2) + " %";
              } */

              const productsSelectedLength = this.getSelectedProducts.length;

              const tempTableItems = this.sanitizeContactsType(r);

              if (r.length >= productsSelectedLength) {
                tempTableItems.forEach((item, index) => {
                  if (index < productsSelectedLength) {
                    this.tableItems.push({ ...item });
                    this.tableItemsExcel.push({
                      selectedProducts: this.getSelectedProducts[index]?.name,
                      ...item,
                    });
                  } else {
                    this.tableItems.push({ ...item });
                    this.tableItemsExcel.push({
                      selectedProducts: "",
                      ...item,
                    });
                  }
                });
              } else {
                this.getSelectedProducts.forEach((product, index) => {
                  const item = r[index] ? r[index] : "";
                  if (item) {
                    this.tableItems.push({ ...item });
                    this.tableItemsExcel.push({
                      selectedProducts: product?.name,
                      ...item,
                    });
                  } else {
                    this.tableItemsExcel.push({
                      selectedProducts: product?.name,
                    });
                  }
                });
              }

              this.contactTypes = this.convertToChartValues(
                this.sanitizeContactsType(r)
              );
            }
            this.loading = false;
          })
          .catch(this.errorHandler);
      }
    },
  },
};
</script>

<style scoped>
.echarts {
  width: 100%;
  height: 100%;
  position: relative;
}

.contacts-types {
  background-color: #fff;
}
</style>
