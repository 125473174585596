<template>
  <wordcloud
    :data="defaultWords"
    nameKey="resposta"
    valueKey="total"
    :showTooltip="true"
    :wordClick="wordClickHandler"
    class="container-cloud"
    :rotate="{ from: 0, to: 0, numOfOrientation: 5 }"
    :wordPadding="10"
    :spiral="'rectangular'"
  >
  </wordcloud>
</template>
  
  <script>
import wordcloud from "vue-wordcloud";

export default {
  name: "app",
  components: {
    wordcloud,
  },
  props: {
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  methods: {
    wordClickHandler(name, value, vm) {
      console.log("wordClickHandler", name, value, vm);
    },
  },
  data() {
    return {
      defaultWords: this.data,
    };
  },
};
</script>

<style>
.container-cloud {
  background-color: #ffffff;
  min-width: 100%;
  min-height: 83%;
}

div.tooltip {
  background-color: #000000;
  min-height: 200px;
  padding: 20px 32px;
}
</style>