<template>
  <div v-if="!infoLoading" class="container-message-status-unique-product">
    <img
      :src="require(`../../public/assets/images/${img}.png`)"
      alt
      width="300"
      height="300"
    />
    <p class="text">{{ phrase }}</p>
  </div>
</template>

<script>
export default {
  name: "message-status-data-unique-product",
  props: {
    selectedProduct: Boolean,
    infoLoading: Boolean,
  },
  data: function () {
    return {
      img: "select-data",
      phrase: "Selecione algum produto",
    };
  },
  watch: {
    selectedProduct(value) {
      if (value) {
        this.img = "select-data";
        this.phrase = "Selecione algum produto";
      } else {
        this.img = "no-data";
        this.phrase = "Nenhum dado encontrado";
      }
    },
  },
};
</script>

<style scoped>
.container-message-status-unique-product {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  inset: 0;
  margin-top: 100px;
}
.text {
  font-size: 1.5rem;
  font-weight: bold;
}
</style>