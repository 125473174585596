import Rest from './base/Rest'

/**
 * @typedef {QuestionService}
 */
export default class QuestionnaireService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/questionnaire'


  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  getQuestions (params = {}) {
    return this.get(`/getQuestions?${this.createQueryString(params)}`)
  }

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  getQuestionnaires (params = {}) {
    return this.get(`/getQuestionnaires?${this.createQueryString(params)}`)
  }

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  getReplies (params = {}) {
    return this.get(`/getReplies?${this.createQueryString(params)}`)
  }

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
   getCompletedQuestionnaires (params = {}) {
    return this.get(`/getCompletedQuestionnaires?${this.createQueryString(params)}`)
  }

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  getCompletedQuestionnairesActiveAndNotActive (params = {}) {
    return this.get(`/getCompletedQuestionnairesActiveAndNotActive?${this.createQueryString(params)}`)
  }

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
   getQuestionnaire (params = {}) {
    return this.get(`/getQuestionnaire?${this.createQueryString(params)}`)
  }

  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  getQuestionnaireActiveAndNotActive (params = {}) {
    return this.get(`/getQuestionnaireActiveAndNotActive?${this.createQueryString(params)}`)
  }

}