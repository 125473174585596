<template>
  <div class="echarts">
    <div class="filters" v-if="showFilters">
      <div class="filter" v-for="(label, index) in labels" :key="index">
        <div
          class="button-filter"
          :style="{
            backgroundColor:
              chartBarDataFilter.length > 0 &&
              chartBarDataFilter[index].itemStyle.color
                ? chartBarDataFilter[index].itemStyle.color
                : '#c23531',
          }"
          v-on:click="filterChange"
        ></div>
        <span class="text-filter">{{ label }}</span>
      </div>
    </div>
    <v-chart
      v-if="data != null"
      :options="chartData"
      :init-options="{ renderer }"
      ref="doughnut"
      autoresize
    />
  </div>
</template>

<script>
import ECharts from "vue-echarts";

import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/component/title";
import "echarts/lib/component/dataset";
import "zrender/lib/svg/svg";

import theme from "../theme/theme.json";
ECharts.registerTheme("ovilia-green", theme);

export default {
  name: "module-chart-bar",

  components: {
    "v-chart": ECharts,
  },

  props: {
    dataTitle: {
      type: String,
      default: "contatos",
    },
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    labels: {
      type: Array,
      default: function () {
        return [];
      },
    },
    series: {
      type: Array,
      default: function () {
        return [];
      },
    },
    renderer: {
      type: String,
      default: "svg",
    },
    legend: {
      type: Array,
      default: function () {
        return [];
      },
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
    tooltip: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },

  data: function () {
    return {
      chartBarData: [],
      chartBarDataFilter: [],
      labelsFilter: [],
      chartBarDataDisabled: [],
    };
  },

  mounted() {
    this.updateChartData();
  },

  computed: {
    chartData: function () {
      let dt = {
        legend: {
          data: this.legend,
        },
        tooltip: this.tooltip,
        xAxis: {
          type: "category",
          axisTick: { show: false },
          data: this.labelsFilter,
        },
        yAxis: {},
        series: [
          {
            data: this.chartBarData,
            type: "bar",
            label: {
              show: true,
              rotate: 0,
              position: "top",
              fontWeight: "bold",
              fontSize: 13,
              lineHeight: 20,
              overflow: "truncate",
            },
          },
        ],

        color: [
          "#EA1D2C",
          "#1ed760",
          "#F69A03",
          "#0061FE",
          "#221B36",
          "#F67101",
          "#9D02D9",
          "#01F75C",
          "#39DCE5",
          "#4B53BC",
          "#F02F83",
          "#91cc75",
          "#fac858",
          "#ee6666",
          "#73c0de",
          "#3ba272",
          "#fc8452",
          "#9a60b4",
          "#ea7ccc",
          "#5470c6",
        ],
      };

      if (typeof this.series != "undefined" && this.series.length > 0) {
        dt.series = this.series;
      }

      return dt;
    },
  },

  watch: {
    data: function (newVal, oldVal) {
      this.updateChartData();
    },
  },

  methods: {
    updateChartData() {
      this.chartBarData = [];
      this.chartBarDataFilter = [];
      this.labelsFilter = [];
      for (let i = 0; i < this.data.length; i++) {
        this.chartBarData.push({
          idValue: this.labels[i],
          indexDefault: i,
          value: this.data[i],
          itemStyle: { color: this.chartData.color[i] },
        });
      }

      this.chartBarDataFilter.push(...this.chartBarData);
      this.labelsFilter.push(...this.labels);
    },
    filterChange(e) {
      let buttonFilter = e.target;
      let textFilter = e.target.parentElement.querySelector(".text-filter");
      if (buttonFilter.className.includes("button-filter-disabled")) {
        buttonFilter.className = "button-filter";
        textFilter.className = "text-filter";
        this.activeBarData(textFilter.textContent);
      } else {
        buttonFilter.className = "button-filter button-filter-disabled";
        textFilter.className = "text-filter text-filter-disabled";
        this.disabledBarData(textFilter.textContent);
      }
    },
    disabledBarData(nameDataToDisabled) {
      let itemDisabled = this.chartBarData.filter(
        (item) => item.idValue === nameDataToDisabled
      )[0];
      let indexItemToPopList = this.chartBarData.indexOf(itemDisabled);
      if (indexItemToPopList > -1) {
        this.chartBarDataDisabled.push({
          data: itemDisabled,
          label: this.labelsFilter[indexItemToPopList],
        });
        this.chartBarData.splice(indexItemToPopList, 1);
        this.labelsFilter.splice(indexItemToPopList, 1);
      }
    },
    activeBarData(nameDataToActive) {
      /* Array.prototype.insert = function ( index, ...items ) {
        this.splice( index, 0, ...items );
      }; */
      let itemActive = this.chartBarDataDisabled.filter(
        (item) => item.data.idValue === nameDataToActive
      )[0];
      let indexItemToPopList = this.chartBarDataDisabled.indexOf(itemActive);
      if (indexItemToPopList > -1) {
        this.chartBarData.push(itemActive.data);
        this.labelsFilter.push(itemActive.label);
        this.sortList();
        this.chartBarDataDisabled.splice(indexItemToPopList, 1);
      }
    },
    sortList() {
      this.chartBarData.sort((a, b) => {
        if (a.indexDefault > b.indexDefault) {
          return 1;
        } else if (a.indexDefault < b.indexDefault) {
          return -1;
        }
        return 0;
      });

      this.labelsFilter = this.chartBarData.map((item) => {
        return item.idValue;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.echarts {
  width: 100%;
  height: 100%;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  margin: 0 auto;
  justify-content: center;
}
.filter {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-right: 2%;
  margin-bottom: 5px;
}
.button-filter {
  width: 25px;
  height: 14px;
  border-radius: 3px;
  cursor: pointer;
}

.button-filter-disabled {
  background-color: #ccc !important;
}

.text-filter {
  font-family: "Inter", Helvetica, Arial;
  font-size: 12px;
  margin-left: 5px;
}

.text-filter-disabled {
  color: #ccc !important;
}
</style>
