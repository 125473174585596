import Http from '../base/Http';
import ipify from './ipify'

/**
 * @typedef {IpifyHttp}
 */
export default class IpifyHttp extends Http {
  /**
   * @param {String} path
   * @param {Object} options
   * @param {Object} http
   */
  constructor (path, options = {}, http = null) {
    super(options);
    this.path = path;
    this.http = http || ipify;
  }
}
