<template>
  <mobule-base-builder
    :module-data="moduleData"
    :editMode="editMode"
    :loading="loading"
    :error="error"
    :downloadImage="dataDownload"
    :tableHeaders="tableHeaders"
    :tableItems="tableItems"
    :tableHeadersExcel="tableHeadersExcel"
    :tableItemsExcel="tableItemsExcel"
    :typePanelPDF="moduleData.displayType.chosen"
  >
    <template v-slot:content>
      <module-table-list-data-v-2
        v-if="moduleData.displayType.chosen == 'TABLE' && tableItems.length > 0"
        :tableHeaders="tableHeaders"
        :tableItems="tableItems"
        class="contacts-evs"
      />
      <module-chart-bar
        v-else-if="
          moduleData.displayType.chosen == 'CHART_BAR' &&
          barChartData.length > 0
        "
        :data="barChartData"
        :labels="labels"
        class="contacts-evs"
      />
      <message-status-data
        v-else
        :selected-products="selectedProductsIds()"
        :infoLoading="loading"
      />
    </template>
  </mobule-base-builder>
</template>

<script>
import ContactService from "../../../services/ContactService";
const contactService = ContactService.build();

import MobuleBaseBuilder from "../base/MobuleBaseBuilder.vue";
import MixinModuleBase from "../../../mixins/MixinModuleBase";

import ModuleChartBar from "../base/ModuleChartBar.vue";
import ModuleTableListDataV2 from "../base/ModuleTableListDataV2.vue";
import MessageStatusData from "../../MessageStatusData.vue";

export default {
  mixins: [MixinModuleBase],
  name: "module-contacts-evs",

  components: {
    MobuleBaseBuilder,
    ModuleChartBar,
    ModuleTableListDataV2,
    MessageStatusData,
  },

  data: function () {
    return {
      questions: [],
      questionnaires: [],
      selectedQuestion: "",

      chartValues: [],

      barChartData: [],
      barSeries: [],
      labels: [],

      tableHeaders: [
        { text: "Contato EVS", align: "start", sortable: true, value: "0" },
        { text: "Quantidade", align: "start", sortable: true, value: "1" },
      ],
      tableHeadersExcel: [
        {
          text: "Produtos Selecionados",
          align: "start",
          sortable: true,
          value: "selectedProducts",
        },
        { text: "Contato EVS", align: "start", sortable: true, value: "0" },
        { text: "Quantidade", align: "start", sortable: true, value: "1" },
      ],
      tableItems: [],
      tableItemsExcel: [],
      dataDownload: {
        class: "contacts-evs",
        title: this.moduleData.headerTitle,
      },
    };
  },
  computed: {
    getSelectedProducts() {
      return this.$store.getters.selectedProducts;
    },
  },
  methods: {
    resetModule() {
      this.tableItems = [];
      this.tableItemsExcel = [];
      this.barChartData = [];
      this.barSeries = [];
    },
    getData() {
      this.resetModule();

      if (this.dataRetrievalOk()) {
        this.loading = true;

        this.labels = ["Espontâneo", "Indicação", "Retorno", "WhatsApp", "Total"];

        contactService
          .getTotalContactsEvs({
            selectedProductsIds: this.selectedProductsIds(),
            dateBegin: this.filterDateRange[0],
            dateEnd: this.filterDateRange[1],
          })
          .then((r) => {
            this.resetModule();
            this.convertToChartValues(r);
            this.loading = false;
          })
          .catch(this.errorHandler);
      }
    },

    convertToChartValues(registers) {
      let rawData = {
        espontaneo: 0,
        indicacao: 0,
        retorno: 0,
        whatsapp: 0,
        total: 0,
      };
      var empresasVendas = [];

      if (registers && registers.length > 0) {
        for (let i = 0; i < registers.length; i++) {
          let letsalesCompanyName = "";
          switch (registers[i]["sales_company_id"]) {
            case "1":
              letsalesCompanyName = "Seller";
              break;
            case "2":
              letsalesCompanyName = "GHI";
              break;
            case "3":
              letsalesCompanyName = "SELLER CY";
              break;
            case "4":
              letsalesCompanyName = "SELLER LIV";
              break;
            case "5":
              letsalesCompanyName = "CIA CY";
              break;
            case "6":
              letsalesCompanyName = "CIA LIV";
              break;
          }

          if (!empresasVendas[letsalesCompanyName]) {
            empresasVendas[letsalesCompanyName] = Object.assign({}, rawData);
          }

          empresasVendas[letsalesCompanyName]["total"] += parseInt(
            registers[i]["contactCount"]
          );
          if (registers[i]["indication"] == 1) {
            empresasVendas[letsalesCompanyName]["indicacao"] += parseInt(
              registers[i]["contactCount"]
            );
          } else if (registers[i]["type_of"] == "FIRSTCONTACT") {
            empresasVendas[letsalesCompanyName]["espontaneo"] += parseInt(
              registers[i]["contactCount"]
            );
          } else if (registers[i]["type_of"] == "RECURRENCE") {
            empresasVendas[letsalesCompanyName]["retorno"] += parseInt(
              registers[i]["contactCount"]
            );
          }
        }

        for (let i in empresasVendas) {
          Object.entries(empresasVendas[i]).forEach(([key, value]) => {
            rawData[key] += value;
          });

          this.barChartData = [
            empresasVendas[i]["espontaneo"],
            empresasVendas[i]["indicacao"],
            empresasVendas[i]["retorno"],
            empresasVendas[i]["whatsapp"],
            empresasVendas[i]["total"],
          ];
        }
        this.tableItems = [];
        this.tableItems = Object.entries(rawData);

        const itens = this.tableItems.map((item) => {
          if (item[0] === "espontaneo") {
            return [(item[0] = "Espontâneo"), item[1]];
          } else if (item[0] === "indicacao") {
            return [(item[0] = "Indicação"), item[1]];
          } else if (item[0] === "retorno") {
            return [(item[0] = "Retorno"), item[1]];
          } else if (item[0] === "whatsapp") {
            return [(item[0] = "WhatsApp"), item[1]];
          } else if (item[0] === "total") {
            return [(item[0] = "Total"), item[1]];
          } else {
            return [...item];
          }
        });

        const productsSelectedLength = this.getSelectedProducts.length;

        this.tableItems = [];
        if (itens.length >= productsSelectedLength) {
          itens.forEach((item, index) => {
            if (index < productsSelectedLength) {
              this.tableItems.push({ ...item });
              this.tableItemsExcel.push({
                selectedProducts: this.getSelectedProducts[index]?.name,
                ...item,
              });
            } else {
              this.tableItems.push({ ...item });
              this.tableItemsExcel.push({ selectedProducts: "", ...item });
            }
          });
        } else {
          this.getSelectedProducts.forEach((product, index) => {
            const item = itens[index] ? itens[index] : "";
            if (item) {
              this.tableItems.push({ ...item });
              this.tableItemsExcel.push({
                selectedProducts: product?.name,
                ...item,
              });
            } else {
              this.tableItemsExcel.push({
                selectedProducts: product?.name,
              });
            }
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.question-main {
  padding: 5px;

  select {
    width: 100%;
  }
}

.contacts-evs {
  background-color: #fff;
}
</style>
