import Rest from './base/Rest'

/**
 * @typedef {SalesCompany}
 */
export default class SalesCompany extends Rest {
  /**
   * @type {String}
   */
  static resource = '/sales-company'


  /**
   * @param {Object} parameters
   * @returns {*|PromiseLike<T | never>|Promise<T | never>}
   */
  getSalesCompaniesGroupContact (params = {}) {
    return this.get(`/salesCompaniesGroupContact?${this.createQueryString(params)}`)
  }

  contactsBySalesCompanies (params = {}) {
    return this.get(`/contactsBySalesCompanies?${this.createQueryString(params)}`)
  }
}