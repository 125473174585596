<template>
  <mobule-base-builder
    :module-data="moduleData"
    :editMode="editMode"
    :loading="loading"
    :error="error"
    :downloadImage="dataDownload"
    :tableHeaders="[]"
    :tableItems="tableItems"
    :tableItemsExcel="[]"
  >
    <template v-slot:content>
      <div class="pa-3">
        <span v-if="!hasFilterDateRange"
          >***É necessário preencher os campos de "Período dos dados" para buscar as
          informações!***</span
        >

        <v-autocomplete
          v-model="selectedProduct"
          :items="getSelectedProducts"
          item-text="name"
          item-value="id"
          @change="getData()"
          id="daily-report"
          label="Selecione o produto"
          class="px-4 pt-2"
          :disabled="!hasFilterDateRange"
          outlined
          dense
        ></v-autocomplete>
      </div>
      <div
        v-if="
          tableItems.length > 0 ||
          tableItemsSalesCompanies.length > 0 ||
          tableItemsProductType.length > 0
        "
      >
        <module-table-list-data-v-2
          v-if="tableItems.length > 0"
          :tableHeaders="tableHeaders"
          :tableItems="tableItems"
          :showFooter="false"
          :footerPropsItemsPerPageText="footerPropsItemsPerPageText"
          class="daily-report echarts-table"
        />
        <module-table-list-data-v-2
          v-if="tableItemsSalesCompanies.length > 0"
          :tableHeaders="tableHeadersSalesCompanies"
          :tableItems="tableItemsSalesCompanies"
          :footerPropsItemsPerPageText="footerPropsItemsPerPageText"
          class="daily-report echarts-table"
        />
        <module-table-list-data-v-2
          v-if="tableItemsProductType.length > 0"
          :tableHeaders="tableHeadersProductType"
          :tableItems="tableItemsProductType"
          :footerPropsItemsPerPageText="footerPropsItemsPerPageText"
          class="daily-report echarts-table"
        />
        <div class="daily-report echarts-pizza" v-if="chartValuesMainMedia.length > 0">
          <module-chart-doughnut
            dataTitle="Mídia Principal"
            :legendOptions="legendOption"
            :dataLegend="labelsMainMedia"
            :legendOrientation="'top'"
            :chartValues="chartValuesMainMedia"
            class="main-media"
          />
        </div>
        <div class="daily-report echarts-pizza" v-if="chartValuesMainMedia.length > 0">
          <module-chart-doughnut
            dataTitle="Mídia Secundária"
            :legendOptions="legendOption"
            :dataLegend="labelsMainMedia"
            :legendOrientation="'top'"
            :chartValues="chartValuesSecondary"
            class="main-media"
          />
        </div>
      </div>

      <message-status-data
        v-else
        :selected-products="selectedProductsIds()"
        :infoLoading="loading"
      />
    </template>
  </mobule-base-builder>
</template>

<script>
import MixinModuleBase from "../../../mixins/MixinModuleBase";
import MobuleBaseBuilder from "../base/MobuleBaseBuilder.vue";

import ModuleChartBar from "../base/ModuleChartBar.vue";
import ModuleChartDoughnut from "../base/ModuleChartDoughnut.vue";
import ModuleTableListDataV2 from "../base/ModuleTableListDataV2.vue";
import MessageStatusData from "../../MessageStatusData.vue";

import ContactService from "../../../services/ContactService";
const contactService = ContactService.build();

import ProductService from "../../../services/ProductService";
const productService = ProductService.build();

import SalesCompanyService from "../../../services/SalesCompanyService";
const salesCompanyService = SalesCompanyService.build();

import MediaService from "../../../services/MediaService";
const mediaService = MediaService.build();

export default {
  name: "module-daily-report",
  mixins: [MixinModuleBase],
  components: {
    MobuleBaseBuilder,
    ModuleTableListDataV2,
    MessageStatusData,
    ModuleChartDoughnut,
    ModuleChartBar,
  },
  data: function () {
    return {
      legendOption: {
        type: "scroll",
        orient: "vertical",
        left: "1rem",
        top: "5%",
        bottom: "5%",
      },
      tableHeaders: [
        {
          text: "Intervalo de Tempo",
          align: "start",
          sortable: true,
          value: "timeInterval",
          tooltip: "Intervalos de Tempo do Produto",
          width: "170px",
        },
        {
          text: "VE",
          align: "start",
          sortable: true,
          value: "spontaneous",
          tooltip: "Total de Visita Espontânea",
          width: "100px",
        },
        {
          text: "IC",
          align: "start",
          sortable: true,
          value: "indication",
          tooltip: "Total de Indicação",
          width: "100px",
        },
        {
          text: "R",
          align: "start",
          sortable: true,
          value: "return",
          tooltip: "Total de Retorno",
          width: "100px",
        },
        {
          text: "TEL",
          align: "start",
          sortable: true,
          value: "telephone",
          tooltip: "Total de Telefonema",
          width: "100px",
        },
        {
          text: "FE",
          align: "start",
          sortable: true,
          value: "outOfService",
          tooltip: "Total Fora do Expediente",
          width: "100px",
        },
        {
          text: "TT",
          align: "start",
          sortable: true,
          value: "total",
          tooltip: "Total de Contatos",
          width: "100px",
        },
      ],
      tableHeadersSalesCompanies: [
        {
          text: "Empresas de Vendas",
          align: "start",
          sortable: true,
          value: "name",
          tooltip: "Nome das Empresas de Vendas",
          width: "170px",
        },
        {
          text: "VE",
          align: "start",
          sortable: true,
          value: "contactsSpontaneous",
          tooltip: "Total de Visita Espontânea",
          width: "100px",
        },
        {
          text: "IC",
          align: "start",
          sortable: true,
          value: "contactsIndication",
          tooltip: "Total de Indicação",
          width: "100px",
        },
        {
          text: "R",
          align: "start",
          sortable: true,
          value: "contactsReturn",
          tooltip: "Total de Retorno",
          width: "100px",
        },
        {
          text: "TEL",
          align: "start",
          sortable: true,
          value: "contactsTelephone",
          tooltip: "Total de Telefonema",
          width: "100px",
        },
        {
          text: "FE",
          align: "start",
          sortable: true,
          value: "contactsOutOfService",
          tooltip: "Total Fora do Expediente",
          width: "100px",
        },
        {
          text: "TT",
          align: "start",
          sortable: true,
          value: "contactCount",
          tooltip: "Total de Contatos",
          width: "100px",
        },
      ],
      tableHeadersProductType: [
        {
          text: "Produto de Interesse",
          align: "start",
          sortable: true,
          value: "productTypeName",
          tooltip: "Nome do Produto de Interesse",
          width: "170px",
        },
        {
          text: "VE",
          align: "start",
          sortable: true,
          value: "spontaneous",
          tooltip: "Total de Visita Espontânea",
          width: "100px",
        },
        {
          text: "IC",
          align: "start",
          sortable: true,
          value: "indication",
          tooltip: "Total de Indicação",
          width: "100px",
        },
        {
          text: "R",
          align: "start",
          sortable: true,
          value: "return",
          tooltip: "Total de Retorno",
          width: "100px",
        },
        {
          text: "TEL",
          align: "start",
          sortable: true,
          value: "telephone",
          tooltip: "Total de Telefonema",
          width: "100px",
        },
        {
          text: "FE",
          align: "start",
          sortable: true,
          value: "outOfService",
          tooltip: "Total Fora do Expediente",
          width: "100px",
        },
        {
          text: "TT",
          align: "start",
          sortable: true,
          value: "totalContacts",
          tooltip: "Total de Contatos",
          width: "100px",
        },
      ],
      labelsMainMedia: [],
      labelsSecondary: [],
      tableItems: [],
      tableItemsSalesCompanies: [],
      tableItemsProductType: [],
      chartValuesMainMedia: [],
      chartValuesSecondary: [],
      footerPropsItemsPerPageText: "Itens por página",
      dataDownload: { class: "daily-report", title: this.moduleData.headerTitle },
      labels: [],
      selectedOption: "",
      productsWithTotalContactsInYear: [],
      productsWithTotalContactsFull: [],
      selectedProduct: [],
    };
  },
  computed: {
    getSelectedProducts() {
      return this.$store.getters.selectedProducts;
    },
    hasFilterDateRange() {
      return (
        this.filterDateRange &&
        this.filterDateRange.length == 2 &&
        this.filterDateRange[0].length > 1 &&
        this.filterDateRange[1].length > 1
      );
    },
  },
  methods: {
    resetModule() {
      this.tableItems = [];
      this.tableItemsSalesCompanies = [];
      this.tableItemsProductType = [];
      this.chartValuesMainMedia = [];
      this.labels = [];
    },
    convertToTable() {
      let list = [];

      const prodSelected = this.getSelectedProducts.filter(
        (prod) => prod.id === this.selectedProduct
      )[0];
      list.push({
        timeInterval: "No Período",
        spontaneous: prodSelected.contactsSpontaneous,
        indication: prodSelected.contactsIndication,
        return: prodSelected.contactsReturn,
        telephone: prodSelected.contactsTelephone,
        outOfService: prodSelected.contactsOutOfService,
        total: prodSelected.contactsTotal,
      });

      const prodWithTotalContactsInYear = this.productsWithTotalContactsInYear.filter(
        (prod) => this.selectedProduct === prod.id
      )[0];
      list.push({
        timeInterval: "Ano Vigente",
        spontaneous: prodWithTotalContactsInYear.contactsSpontaneous,
        indication: prodWithTotalContactsInYear.contactsIndication,
        return: prodWithTotalContactsInYear.contactsReturn,
        telephone: prodWithTotalContactsInYear.contactsTelephone,
        outOfService: prodWithTotalContactsInYear.contactsOutOfService,
        total: prodWithTotalContactsInYear.contactsTotal,
      });

      const productsWithTotalContactsFull = this.productsWithTotalContactsFull.filter(
        (prod) => this.selectedProduct === prod.id
      )[0];
      list.push({
        timeInterval: "Desde o Início",
        spontaneous: productsWithTotalContactsFull.contactsSpontaneous,
        indication: productsWithTotalContactsFull.contactsIndication,
        return: productsWithTotalContactsFull.contactsReturn,
        telephone: productsWithTotalContactsFull.contactsTelephone,
        outOfService: productsWithTotalContactsFull.contactsOutOfService,
        total: productsWithTotalContactsFull.contactsTotal,
      });

      return list;
    },
    convertToChartValues(registers, isMainMedia = true) {
      let ret = [];
      if (typeof registers !== "undefined" && registers.length > 0) {
        for (let i = 0; i < registers.length; i++) {
          //Chart Pizza
          ret.push({
            value: registers[i]["mediaCount"],
            name: registers[i]["title"],
          });

          if (isMainMedia) this.labelsMainMedia.push(registers[i]["title"]);
          else this.labelsSecondary.push(registers[i]["title"]);
        }
      }
      return ret;
    },
    async getData() {
      this.resetModule();

      if (this.selectedProduct) {
        const isProdSelected = this.getSelectedProducts.filter(
          (prod) => prod.id === this.selectedProduct
        );
        if (isProdSelected.length === 0) this.selectedProduct = [];
      }

      if (this.selectedProduct.length === 0 && this.getSelectedProducts.length > 0) {
        this.selectedProduct = this.getSelectedProducts[0].id;
      }

      if (this.selectedProduct.length > 0) {
        this.loading = true;

        const yearStart = Number(this.filterDateRange[0].split("-")[0]);
        const dateBeginOfYear = new Date(yearStart, 0, 1);
        const dateBeginToFullData = new Date(1922, 0, 1);
        const dateNow = new Date();

        this.productsWithTotalContactsInYear = await productService
          .getAvailableProductsWithTotalContacts(
            this.filterDateRange && this.filterDateRange.length == 2
              ? {
                  dateBegin: dateBeginOfYear.toISOString(),
                  dateEnd: dateNow.toISOString(),
                  choosen: this.getViewProducts,
                  selectedProductsIds: [this.selectedProduct],
                }
              : {}
          )
          .catch(this.errorHandler);

        this.productsWithTotalContactsFull = await productService
          .getAvailableProductsWithTotalContacts(
            this.filterDateRange && this.filterDateRange.length == 2
              ? {
                  dateBegin: dateBeginToFullData.toISOString(),
                  dateEnd: dateNow.toISOString(),
                  choosen: this.getViewProducts,
                  selectedProductsIds: [this.selectedProduct],
                }
              : {}
          )
          .catch(this.errorHandler);

        this.tableItemsSalesCompanies = await salesCompanyService
          .contactsBySalesCompanies({
            selectedProductsIds: [this.selectedProduct],
            dateBegin: this.filterDateRange[0],
            dateEnd: this.filterDateRange[1],
          })
          .catch(this.errorHandler);

        this.tableItemsProductType = await contactService
          .getContactByProductType({
            selectedProductsIds: [this.selectedProduct],
            dateBegin: this.filterDateRange[0],
            dateEnd: this.filterDateRange[1],
          })
          .catch(this.errorHandler);

        const r = this.convertToTable();

        this.tableItems = r;

        await mediaService
          .getCountMainMedia({
            selectedProductsIds: [this.selectedProduct],
            dateBegin: this.filterDateRange[0],
            dateEnd: this.filterDateRange[1],
          })
          .then((r) => {
            this.chartValuesMainMedia = this.convertToChartValues(r);
          })
          .catch(this.errorHandler);

        await mediaService
          .getCountSecondaryMedia({
            selectedProductsIds: [this.selectedProduct],
            dateBegin: this.filterDateRange[0],
            dateEnd: this.filterDateRange[1],
          })
          .then((r) => {
            this.chartValuesSecondary = this.convertToChartValues(r, false);
          })
          .catch(this.errorHandler);

        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.echarts-table {
  height: auto !important;
  margin-bottom: 10px;
}

.echarts-pizza {
  min-height: 50rem;
  max-height: 50rem;

  width: 100% !important;
  height: 50rem !important;
  position: relative !important;
  border: 1px solid #ccc;
}

.daily-report {
  background-color: #fff;
}

.w-100 {
  z-index: 999;
}

span {
  color: red;
  width: 15px;
}
</style>
