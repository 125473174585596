<template>
  <div>
    <v-tabs class="tabs" color="#000E17" centered grow height="60px" v-model="activeTab">
      <v-tab v-for="tab in tabs" :key="tab.id" :to="tab.route" exact>
        {{ tab.name }}
      </v-tab>
    </v-tabs>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "ManageReports",
  data() {
    return {
      activeTab: `manage-email-reports`,
      tabs: [
        { id: 1, name: "Gerenciar e-mails", route: `/manage-email-reports` },
        { id: 2, name: "Gerenciar envio de e-mails", route: `/manage-send-reports` },
        { id: 3, name: "Reenvio de e-mails", route: `/manage-resend-reports` },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
