<template>
  <mobule-base-builder
    :module-data="moduleData"
    :editMode="editMode"
    :loading="loading"
    :error="error"
    :downloadImage="dataDownload"
    :tableHeaders="tableHeaders"
    :tableItems="tableItems"
    :tableHeadersExcel="tableHeadersExcel"
    :tableItemsExcel="tableItemsExcel"
    :typePanelPDF="moduleData.displayType.chosen"
  >
    <template v-slot:content>
      <module-chart-bar
        v-if="
          moduleData.displayType.chosen == 'CHART_BAR' &&
          barChartData.length > 0
        "
        :data="barChartData"
        :labels="labels"
        :class="dataDownloadNameClass"
      />
      <div
        class="echarts"
        v-else-if="
          moduleData.displayType.chosen == 'CHART_PIZZA' &&
          contactTypes.length > 0
        "
      >
        <module-chart-doughnut
          dataTitle=""
          :dataLegend="labels"
          :legendOrientation="'top'"
          :chartValues="contactTypes"
          :class="dataDownloadNameClass"
        />
      </div>
      <module-table-list-data-v2
        v-else-if="
          moduleData.displayType.chosen == 'TABLE' && tableItems.length > 0
        "
        :tableHeaders="tableHeaders"
        :tableItems="tableItems"
        :class="dataDownloadNameClass"
      />
      <message-status-data
        v-else
        :selected-products="selectedProductsIds()"
        :infoLoading="loading"
      />
    </template>
  </mobule-base-builder>
</template>

<script>
import MixinModuleBase from "../../../mixins/MixinModuleBase";

import MobuleBaseBuilder from "../base/MobuleBaseBuilder.vue";
import ModuleChartBar from "../base/ModuleChartBar.vue";
import ModuleChartDoughnut from "../base/ModuleChartDoughnut.vue";
import ModuleTableListDataV2 from "../base/ModuleTableListDataV2.vue";
import MessageStatusData from "../../MessageStatusData.vue";

import CustomerService from "../../../services/CustomerService";
const customerService = CustomerService.build();

import percentageSort from "../../../utils/sorting/percentage.sort";

export default {
  name: "module-total-customers-lgpd",
  mixins: [MixinModuleBase],
  components: {
    MobuleBaseBuilder,
    ModuleChartBar,
    ModuleChartDoughnut,
    ModuleTableListDataV2,
    MessageStatusData,
  },
  data: function () {
    return {
      contactTypes: [],
      barChartData: [],
      labels: [],
      tableHeaders: [
        {
          text: "Permitido?",
          align: "start",
          sortable: true,
          value: "allowed",
        },
        {
          text: "Quantidade",
          align: "start",
          sortable: true,
          value: "customerCount",
        },
        {
          text: "%",
          align: "start",
          sortable: true,
          value: "percentage",
          sort: percentageSort,
        },
      ],
      tableHeadersExcel: [
        {
          text: "Produtos Selecionados",
          align: "start",
          sortable: true,
          value: "selectedProducts",
        },
        {
          text: "Permitido?",
          align: "start",
          sortable: true,
          value: "allowed",
        },
        {
          text: "Quantidade",
          align: "start",
          sortable: true,
          value: "customerCount",
        },
        {
          text: "%",
          align: "start",
          sortable: true,
          value: "percentage",
          sort: percentageSort,
        },
      ],
      tableItems: [],
      tableItemsExcel: [],
      dataDownload: {
        class:
          this.moduleData.headerTitle === "LGPD - Permissão de compartilhamento"
            ? "share-permission-lgpd"
            : "total-customers-lgpd",
        title: this.moduleData.headerTitle,
      },
    };
  },
  computed: {
    dataDownloadNameClass() {
      if (
        this.moduleData.headerTitle === "LGPD - Permissão de compartilhamento"
      ) {
        return "share-permission-lgpd";
      }
      return "total-customers-lgpd";
    },
    getSelectedProducts() {
      return this.$store.getters.selectedProducts;
    },
  },
  methods: {
    resetModule() {
      this.tableItems = [];
      this.tableItemsExcel = [];
      this.contactTypes = [];
      this.barChartData = [];
      this.labels = [];
    },

    sanitizeContactsType(contacts) {
      if (typeof contacts !== "undefined" && contacts.length > 0) {
        for (let i = 0; i < contacts.length; i++) {
          if (contacts[i]["allowed"] == "1") {
            contacts[i]["allowed"] = "Sim";
          } else if (contacts[i]["allowed"] == "0") {
            contacts[i]["allowed"] = "Não";
          }
        }
      }
      return contacts;
    },

    convertToChartValues(contacts) {
      let ret = [];
      if (typeof contacts !== "undefined" && contacts.length > 0) {
        for (let i = 0; i < contacts.length; i++) {
          //Chart Pizza
          ret.push({
            value: contacts[i]["customerCount"],
            name: contacts[i]["allowed"],
          });

          //Chart Bar
          this.barChartData.push(contacts[i]["customerCount"]);
          this.labels.push(contacts[i]["allowed"]);
        }
      }
      return ret;
    },

    getData() {
      this.resetModule();

      if (this.dataRetrievalOk()) {
        this.loading = true;

        customerService
          .getTotalCustomersLgpd({
            selectedProductsIds: this.selectedProductsIds(),
            dateBegin: this.filterDateRange[0],
            dateEnd: this.filterDateRange[1],
            dataType: this.typePanel,
          })
          .then((r) => {
            this.resetModule();

            let tot = 0;
            for (let i = 0; i < r.length; i++) {
              tot += parseInt(r[i]["customerCount"]);
            }
            for (let i = 0; i < r.length; i++) {
              r[i]["percentage"] =
                ((parseInt(r[i]["customerCount"]) / tot) * 100).toFixed(2) +
                " %";
            }

            const productsSelectedLength = this.getSelectedProducts.length;

            const tempTableItems = this.sanitizeContactsType(r);

            if (r.length >= productsSelectedLength) {
              tempTableItems.forEach((item, index) => {
                if (index < productsSelectedLength) {
                  this.tableItems.push({ ...item });
                  this.tableItemsExcel.push({
                    selectedProducts: this.getSelectedProducts[index]?.name,
                    ...item,
                  });
                } else {
                  this.tableItems.push({ ...item });
                  this.tableItemsExcel.push({
                    selectedProducts: "",
                    ...item,
                  });
                }
              });
            } else {
              this.getSelectedProducts.forEach((product, index) => {
                const item = r[index] ? r[index] : "";
                if (item) {
                  this.tableItems.push({ ...item });
                  this.tableItemsExcel.push({
                    selectedProducts: product?.name,
                    ...item,
                  });
                } else {
                  this.tableItemsExcel.push({
                    selectedProducts: product?.name,
                  });
                }
              });
            }

            this.contactTypes = this.convertToChartValues(
              this.sanitizeContactsType(r)
            );
            this.loading = false;
          })
          .catch(this.errorHandler);
      }
    },
  },
};
</script>

<style scoped>
.echarts {
  width: 100%;
  height: 100%;
  position: relative;
}

.total-customers-lgpd,
.share-permission-lgpd {
  background-color: #fff;
}
</style>
