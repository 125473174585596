import TelephonyHttp from './TelephonyHttp';

/**
 * @type TelephonyApi
 */
export default class TelephonyApi extends TelephonyHttp {
    /**
     * @type {String}
     */
    static base = process.env.VUE_APP_API_BASE_URL_TELEPHONY;
    
    /**
     * @type {String}
     */
    static key = process.env.VUE_APP_KEY_TEL_API;

    /**
     * @type {String}
     */
    static token = process.env.VUE_APP_TOKEN_TEL_API;

    /**
     * @param {String} path
     * @param {Object} options
     * @param {Object} http
     * @return {this}
     */
    static build (path = '', options = {}, http = null) {
        return new this(this.normalize(`${this.base}/cdr/${this.token}/${this.key}/`, path), options, http)
    }

    createQueryString(params = {}) {
        const usp = new URLSearchParams();
        for (var [key, value] of Object.entries(params)) {
            if (Array.isArray(value)) {
                value.forEach(function (v, k) {
                    usp.append(key+'[]', v);
                })
            } else {
                usp.append(key, value);
            }
        }
        return usp.toString();
    }
}
