import EnterpriseService from "../services/EnterpriseService";
const enterpriseService = EnterpriseService.build();

export default {
    methods: {
        async getEnterpriseByDBName() {
            const enterprise = await enterpriseService.findOneActiveByDBName(this.$store.getters.enterprise);
            if(enterprise) {
                await this.$store.dispatch('storeEnterpriseName', enterprise.name);
                await this.$store.dispatch('storeEnterpriseLogo', enterprise.logoImage);
            }      

        }
    }
}